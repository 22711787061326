<div class="col-md-12 grid-margin stretch-card">
	<div class="card">
		<div class="card-body">
			<h4 class="card-title">Create Stock Adjustment</h4>
            <form class="form-sample"  [formGroup]="inquiryMasterProductForm"> 
                <div class="form-group row">

                    <div class="col">
                        <label>Product Name</label>
                            <input type="text" class="form-control form-control-md" 
                            formControlName="productName" id="productName" name="productName"/>
                    </div>  
                    <div class="col">
                        <label style="color: transparent;">-</label>
                        <button type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" 
                        [disabled]="inquiryMasterProductForm.invalid" data-toggle="modal" 
                        data-target="#exampleModal" (click)="searchbyname()">Search Product</button>
                    </div>  
                    <div class="col">
                      <label style="color: transparent;">-</label>
                      <button type="submit" class="btn btn-danger mr-2" style="min-width: 100%;" 
                      [disabled]="stockAdjustmentForm.invalid" (click)="createStockAdjustment()">Create Adjustment</button>
                  </div>  

                </div>
                <div class="form-group row">
                	<div class="table-responsive">
                    <table class="table table-hover" >
                      <thead>
                        <tr>
                          <th>Product Name</th>
                        </tr>
                      </thead>
                      <tbody >
                       <tr *ngFor="let listProductMasterAdd of listOfProductMasterModelAdd; let i= index" >
                          <td>{{listProductMasterAdd.NamaBarang}} - Current Stock : {{stockModel.Stock}}</td>
                       </tr>                           
                      </tbody>
                    </table>
                	</div> 
            </div>
            </form>    
            <form class="form-sample"  [formGroup]="stockAdjustmentForm"> 			         
 						 <div class="form-group row">
                            <div class="col">
                                <label>Adjustment Qty</label>
                                <div id="the-basics">
                                    <input class="typeahead"  id="qty" formControlName="qty" 
                                    name="qty" type="text" placeholder="Adjustment Qty"> </div>
                            </div>                                                     
                            <div class="col">
                                <label>Transaction Type</label>
                                <div id="the-basics">
                                    <select  class="form-control" formControlName="tipeTransaksi" id="tipeTransaksi" name="tipeTransaksi"
                                     type="text" placeholder="Transaction Type">
                                        <option value="" >-</option>
                                        <option value="tambah" >Add (+)</option>
                                        <option value="kurang" >Substract (-)</option>
                                    </select> 
                                </div>
                            </div>                        
                        </div>
                        <div class="form-group row">
                            <div class="col">
                                <label>Notes</label>
                                <div id="the-basics">
                                    <input class="typeahead"  id="keterangan" formControlName="keterangan" 
                                    name="keterangan" type="text" placeholder="Notes"> </div>
                            </div>                                                                           
                        </div>
                    </form>  
    	 
		</div>
	</div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Product List</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
          <div class="modal-body" style="overflow-y: auto; max-height:400px;">
            <table class="table table-hover" >
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Brand</th>
                    <th>Category</th>
                  </tr>
                </thead>
                <tbody >
                 <tr *ngFor="let listProductMaster of listOfProductMasterModel; let i= index" style="cursor: pointer;"
                 data-toggle="modal" data-target="#exampleModal2" (click)="searchbynameDetail(listProductMaster.Id, 
                 listProductMaster.CategoryId,listProductMaster.MerkId)">
                    <td>{{listProductMaster.NamaBarang}}</td>
                    <td>{{listProductMaster.NamaMerk}}</td>
                    <td>{{listProductMaster.NamaCategory}}</td>
                 </tr>       
                </tbody>
              </table>

          </div>          
        <div class="modal-footer">
         
          <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Product Name</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
          <div class="modal-body" style="overflow-y: auto; max-height:400px;">

            <div class="table-responsive">
                                <!-- table for type joran-->
                                <table class="table table-hover" *ngIf="productTypeforHTML=='Rod'">
                                    <thead>
                                      <tr>
                                        <th>Product Name</th>
                                        <th>Brand</th>
                                        <th>Size</th>
                                        <th>Min Lbs</th>
                                        <th>Max Lbs</th>
                                        <th>Ring</th>
                                        <th>Min PE</th>
                                        <th>Max PE</th>
                                        <th>Colour</th>
                                        <th>Material</th>
                                        <th>Prod. Date</th>
                                        <th>Type</th>
                                      </tr>
                                    </thead>
                                    <tbody >
                                     <tr *ngFor="let listJoranDetail of listOfProductDetailJoran; let i= index" >
                                      <td><button type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" 
                                        (click)="addToList(listJoranDetail,1)">Adjust</button></td>                                          
                                        <td>{{listJoranDetail.Nama}}</td>
                                        <td>{{listJoranDetail.Merk}}</td>
                                        <td>{{listJoranDetail.Ukuran}}</td>
                                        <td>{{listJoranDetail.MinLbs}}</td>
                                        <td>{{listJoranDetail.MaxLbs}}</td>
                                        <td>{{listJoranDetail.Ring}}</td>
                                        <td>{{listJoranDetail.MinPE}}</td>
                                        <td>{{listJoranDetail.MaxPE}}</td>
                                        <td>{{listJoranDetail.Warna}}</td>
                                        <td>{{listJoranDetail.Bahan}}</td>
                                        <td>{{listJoranDetail.TahunProduksi}}</td>
                                        <td>{{listJoranDetail.Tipe}}</td>
                                     </tr>       
                                    </tbody>
                                  </table>
              
                                  <!-- table for type Reel-->
                                  <table class="table table-hover" *ngIf="productTypeforHTML=='Reel'">
                                    <thead>
                                      <tr>
                                        <th></th>
                                        <th>Product Name</th>
                                        <th>Brand</th>
                                        <th>Size</th>
                                        <th>Type</th>
                                        <th>Ball Bearing</th>
                                        <th>Colour</th>
                                        <th>PH Type</th>
                                        <th>Way Type</th>
                                      </tr>
                                    </thead>
                                    <tbody >
                                     <tr *ngFor="let listReelDetail of listOfProductDetailReel; let i= index" >           
                                      <td><button type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" 
                                        (click)="addToList(listReelDetail,2)">Adjust</button></td>              
                                        <td>{{listReelDetail.Nama}}</td>
                                        <td>{{listReelDetail.Merk}}</td>
                                        <td>{{listReelDetail.Ukuran}}</td>
                                        <td>{{listReelDetail.Tipe}}</td>
                                        <td>{{listReelDetail.BallBearing}}</td>
                                        <td>{{listReelDetail.Warna}}</td>
                                        <td>{{listReelDetail.PhType}}</td>
                                        <td>{{listReelDetail.WayType}}</td>
                                     </tr>       
                                    </tbody>
                                  </table>
              
                                  <!-- table for type line-->
                                  <table class="table table-hover" *ngIf="productTypeforHTML=='Line'">
                                    <thead>
                                      <tr>
                                        <th></th>                          
                                        <th>Product Name</th>
                                        <th>Brand</th>
                                        <th>Size</th>
                                        <th>Msr. Unit</th>
                                        <th>Type</th>
                                        <th>Colour</th>
                                        <th>Lbs</th>
                                        <th>PE</th>
                                        <th>Fluorocarbon</th>
                                        <th>Connector</th>
                                        <th>Diameter</th>
                                        <th>Power</th>
                                      </tr>
                                    </thead>
                                    <tbody >
                                     <tr *ngFor="let listLineDetail of listOfProductDetailLine; let i= index" >      
                                      <td><button type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" 
                                        (click)="addToList(listLineDetail,3)">Adjust</button></td>                        
                                        <td>{{listLineDetail.Nama}}</td>
                                        <td>{{listLineDetail.Merk}}</td>
                                        <td>{{listLineDetail.Ukuran}}</td>
                                        <td>{{listLineDetail.Satuan}}</td>
                                        <td>{{listLineDetail.Tipe}}</td>
                                        <td>{{listLineDetail.Warna}}</td>
                                        <td>{{listLineDetail.Lbs}}</td>
                                        <td>{{listLineDetail.Pe}}</td>
                                        <td>{{listLineDetail.TypeFluoroCarbon}}</td>
                                        <td>{{listLineDetail.TypeConnectorSenar}}</td>
                                        <td>{{listLineDetail.Diameter}}</td>
                                        <td>{{listLineDetail.Kekuatan}}</td>
                                     </tr>       
                                    </tbody>
                                  </table>
              
                                  <!-- table for type kail-->
                                  <table class="table table-hover" *ngIf="productTypeforHTML=='Kail'">
                                    <thead>
                                      <tr>
                                        <th></th>                            
                                        <th>Product Name</th>
                                        <th>Brand</th>
                                        <th>Size</th>
                                      </tr>
                                    </thead>
                                    <tbody >
                                     <tr *ngFor="let listKailDetail of listOfProductDetailKail; let i= index" >           
                                      <td><button type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" 
                                        (click)="addToList(listKailDetail,4)">Adjust</button></td>                                             
                                      <td>{{listKailDetail.Nama}}</td>
                                        <td>{{listKailDetail.Merk}}</td>
                                        <td>{{listKailDetail.Ukuran}}</td>
                                     </tr>       
                                    </tbody>
                                  </table>      
              
                                  <!-- table for type Acc-->
                                  <table class="table table-hover" *ngIf="productTypeforHTML=='Acc'">
                                    <thead>
                                      <tr>
                                        <th></th>                            
                                        <th>Product Name</th>
                                        <th>Brand</th>
                                        <th>Ukuran</th>
                                        <th>Colour</th>
                                        <th>Type</th>
                                        <th>Size</th>
                                        <th>Lbs</th>
                                        <th>Liter</th>                                                                                                        
                                      </tr>
                                    </thead>
                                    <tbody >
                                     <tr *ngFor="let listAccDetail of listOfProductDetailAcc; let i= index" >    
                                      <td><button type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" 
                                        (click)="addToList(listAccDetail,5)">Adjust</button></td>                                                        
                                        <td>{{listAccDetail.Nama}}</td>
                                        <td>{{listAccDetail.Merk}}</td>
                                        <td>{{listAccDetail.Ukuran}}</td>
                                        <td>{{listAccDetail.Warna}}</td>
                                        <td>{{listAccDetail.Tipe}}</td>
                                        <td>{{listAccDetail.Size}}</td>
                                        <td>{{listAccDetail.Lbs}}</td>
                                        <td>{{listAccDetail.Liter}}</td>                                                                                                        
                                     </tr>       
                                    </tbody>
                                  </table>  
                                    <!-- table for type Bait-->
                                    <table class="table table-hover" *ngIf="productTypeforHTML=='Bait'">
                                      <thead>
                                        <tr>
                                          <th></th>                            
                                          <th>Product Name</th>
                                          <th>Brand</th>
                                          <th>Ukuran</th>
                                          <th>Colour</th>
                                          <th>Type</th>
                                          <th>Weight</th>    
                                          <th>Varian</th>                                                                                                  
                                        </tr>
                                      </thead>
                                      <tbody >
                                       <tr *ngFor="let listBaitDetail of listOfProductDetailBait; let i= index" >    
                                        <td><button type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" 
                                          (click)="addToList(listBaitDetail,6)">Adjust</button></td>                                                                   
                                          <td>{{listBaitDetail.Nama}}</td>
                                          <td>{{listBaitDetail.Merk}}</td>
                                          <td>{{listBaitDetail.Ukuran}}</td>
                                          <td>{{listBaitDetail.Warna}}</td>
                                          <td>{{listBaitDetail.Tipe}}</td>
                                          <td>{{listBaitDetail.Berat}}</td>
                                          <td>{{listBaitDetail.Varian}}</td>                                                                                                             
                                       </tr>       
                                      </tbody>
                                    </table>                                       
            </div>

          </div>          
        <div class="modal-footer">
         
          <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>