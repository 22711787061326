import { Injectable } from '@angular/core';


@Injectable()

export class PaymentMethod {

constructor() { }


getPaymentMethod(type : number)
{
	if(type==1) {return "Cash"}
	else if(type==2) {return "Debit"}
	else if(type==3) {return "Credit"}
	else if(type==4) {return "OVO"}
	else if(type==5) {return "Gopay"}
	else if(type==6) {return "QRIS"}
	return '';
}

}