export class productdetailjoranmodel{

    constructor(values: Object = {}) {
          Object.assign(this, values);
    }
    private id : number;
    private detailId : number;
    private nama : string;
    private barcode :string;
    private merk : string; 
    private merkId : string;    
    private ukuran : string;
    private maxLbs : string;  
    private minLbs : string;
    private maxPE : string;
    private minPE : string;
    private ring : string;
    private tahunProduksi : string;
    private warna : string;
    private warnaId : string;
    private bahan : string;
    private bahanId : string;
    private tipe : string;
    private tipeId : string;
    private connectorCategoryId : number;
    public get ConnectorCategoryId():number{
        return this.connectorCategoryId;
    } 

    public get Id():number{
        return this.id;
    }
    public get DetailId():number{
        return this.detailId;
    }
    public get Nama():string{
        return this.nama;
    }
    public get Merk():string{
        return this.merk;
    }  
    public get MerkId():string{
        return this.merkId;
    }     
    public get Ukuran():string{
        return this.ukuran;
    }
    public get Barcode ():string{
        return this.barcode;
    }
    public get MaxLbs():string{
        return this.maxLbs;
    }
    public get MinLbs():string{
        return this.minLbs;
    }
    public get MaxPE():string{
        return this.maxPE;
    }
    public get MinPE():string{
        return this.minPE;
    }
    public get Ring():string{
        return this.ring;
    }
    public get TahunProduksi():string{
        return this.tahunProduksi;
    }
    public get Warna():string{
        return this.warna;
    }
    public get WarnaId():string{
        return this.warnaId;
    }
    public get Bahan():string{
        return this.bahan;
    }
    public get BahanId():string{
        return this.bahanId;
    }
    public get Tipe():string{
        return this.tipe;
    }
    public get TipeId():string{
        return this.tipeId;
    }

    public set Id (id:number)
    {
        this.id=id;
    }
    public set DetailId (detailId:number)
    {
        this.detailId=detailId;
    }
    public set Nama (nama:string)
    {
        this.nama=nama;
    }   
    public set Barcode (barcode:string)
    {
        this.barcode=barcode;
    }
    public set Merk (merk:string)
    {
        this.merk=merk;
    } 
    public set MerkId (merkId:string)
    {
        this.merkId=merkId;
    }        
    public set Ukuran (ukuran:string)
    {
        this.ukuran=ukuran;
    }
    public set MaxLbs (maxLbs:string)
    {
        this.maxLbs=maxLbs;
    }
    public set MinLbs (minLbs:string)
    {
        this.minLbs=minLbs;
    }
    public set MaxPE (maxPE:string)
    {
        this.maxPE=maxPE;
    }
    public set MinPE (minPE:string)
    {
        this.minPE=minPE;
    }
    public set Ring (ring:string)
    {
        this.ring=ring;
    }
    public set TahunProduksi (tahunProduksi:string)
    {
        this.tahunProduksi=tahunProduksi;
    }
    public set Warna (warna:string)
    {
        this.warna=warna;
    }
    public set WarnaId (warnaId:string)
    {
        this.warnaId=warnaId;
    }
    public set Bahan (bahan:string)
    {
        this.bahan=bahan;
    }
    public set BahanId (bahanId:string)
    {
        this.bahanId=bahanId;
    }
    public set Tipe (tipe:string)
    {
        this.tipe=tipe;
    }
    public set TipeId (tipeId:string)
    {
        this.tipeId=tipeId;
    }
    public set ConnectorCategoryId (connectorCategoryId:number)
    {
        this.connectorCategoryId=connectorCategoryId;
    }


    }