export class productdetaillinemodel{

    constructor(values: Object = {}) {
          Object.assign(this, values);
    }
    private id : number;
    private detailId : number;
    private nama : string;
    private merk : string;
    private merkId : string;        
    private ukuran : string;
    private warna : string;
    private tipe : string;
    private barcode :string;
    private tipeId : number;
    private tipeNo : number;
    private ballBearing : string;
    private warnaId : number;
    private lbs : string;
    private kekuatan : string;
    private diameter : string;
    private pe : string;
    private satuan : string;
    private typeFluoroCarbon : string;
    private typeConnectorSenar : string;
    private connectorCategoryId : number;

    public get ConnectorCategoryId():number{
        return this.connectorCategoryId;
    } 

    public get Id():number{
        return this.id;
    }
    public get DetailId():number{
        return this.detailId;
    }
    public get Nama ():string{
        return this.nama;
    }
    public get Merk ():string{
        return this.merk;
    }
    public get MerkId ():string{
        return this.merkId;
    }    
    public get Ukuran ():string{
        return this.ukuran;
    }
    public get Warna ():string{
        return this.warna;
    }
    public get Tipe ():string{
        return this.tipe;
    }
    public get TipeId ():number{
        return this.tipeId;
    }
    public get Barcode ():string{
        return this.barcode;
    }
    public get TipeNo ():number{
        return this.tipeNo;
    }
    public get BallBearing ():string{
        return this.ballBearing;
    }
    public get WarnaId ():number{
        return this.warnaId;
    }
    public get Lbs ():string{
        return this.lbs;
    }
    public get Kekuatan ():string{
        return this.kekuatan;
    }
    public get Diameter ():string{
        return this.diameter;
    }
    public get Pe ():string{
        return this.pe;
    }
    public get Satuan ():string{
        return this.satuan;
    }
    public get TypeFluoroCarbon ():string{
        return this.typeFluoroCarbon;
    }
    public get TypeConnectorSenar ():string{
        return this.typeConnectorSenar;
    }

    public set Id (id:number)
    {
        this.id=id;
    }
    public set DetailId (detailId:number)
    {
        this.detailId=detailId;
    }    

    public set Nama (nama:string)
    {
        this.nama=nama;
    }
    public set Barcode (barcode:string)
    {
        this.barcode=barcode;
    }
    public set Merk (merk:string)
    {
        this.merk=merk;
    }
    public set MerkId (merkId:string)
    {
        this.merkId=merkId;
    }    
    public set Ukuran (ukuran:string)
    {
        this.ukuran=ukuran;
    }
    public set Warna (warna:string)
    {
        this.warna=warna;
    }
    public set Tipe (tipe:string)
    {
        this.tipe=tipe;
    }
    public set TipeId (tipeId:number)
    {
        this.tipeId=tipeId;
    }
    public set TipeNo (tipeNo:number)
    {
        this.tipeNo=tipeNo;
    }
    public set BallBearing (ballBearing:string)
    {
        this.ballBearing=ballBearing;
    }
    public set WarnaId (warnaId:number)
    {
        this.warnaId=warnaId;
    }
    public set Lbs (lbs:string)
    {
        this.lbs=lbs;
    }
    public set Kekuatan (kekuatan:string)
    {
        this.kekuatan=kekuatan;
    }
    public set Diameter (diameter:string)
    {
        this.diameter=diameter;
    }
    public set Pe (pe:string)
    {
        this.pe=pe;
    }
    public set Satuan (satuan:string)
    {
        this.satuan=satuan;
    }
    public set TypeFluoroCarbon (typeFluoroCarbon:string)
    {
        this.typeFluoroCarbon=typeFluoroCarbon;
    }
    public set TypeConnectorSenar (typeConnectorSenar:string)
    {
        this.typeConnectorSenar=typeConnectorSenar;
    }
    public set ConnectorCategoryId (connectorCategoryId:number)
    {
        this.connectorCategoryId=connectorCategoryId;
    }

    }