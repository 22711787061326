export class color{

    constructor(values: Object = {}) {
          Object.assign(this, values);
    }
    private id : number;
    private namaWarna : string;  
    private kodeWarna : string;
    
    
    public get Id():number{
        return this.id;
    }
    public get NamaWarna():string{
        return this.namaWarna;
    }
    public get KodeWarna():string{
        return this.kodeWarna;
    }


    public set Id (id:number){
        this.id=id;
    }
    public set NamaWarna (namaWarna:string){
        this.namaWarna=namaWarna;
    }
    public set KodeWarna (kodeWarna:string){
        this.kodeWarna=kodeWarna;
    }
    }