import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute, PreloadingStrategy } from "@angular/router";
import { FormGroup, FormControl, FormBuilder, Validators,ReactiveFormsModule } from '@angular/forms';
import  { callGlobalFuction } from "../../callGlobalFuction";
import { transactionMaster } from '../../model/transaction/transactionpos';
import { Observable } from 'rxjs';
import  { imageGenerator } from "../../globalfunction/imageGenerator";
import  { PaymentMethod } from "../../globalfunction/methodUtil";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { DatePipe } from '@angular/common';
import { productmastermodel } from '../../model/product/productmaster';
import { productdetailjoranmodel } from '../../model/product/productDetailJoran';
import { productdetailreelmodel } from '../../model/product/productDetailReel';
import { productdetaillinemodel } from '../../model/product/productDetailLine';
import { productdetailkailmodel } from '../../model/product/productDetailKail';
import { productdetailaccmodel } from '../../model/product/productDetailAcc';
import { productdetailbaitmodel } from '../../model/product/productDetailBait';
import { stock } from '../../model/product/stock';
import { async } from 'rxjs/internal/scheduler/async';
// import { Printer} from '../../../../node_modules/node-thermal-printer/lib/interfaces/printer';
// import { Epson} from '../../../../node_modules/node-thermal-printer/lib/types/epson';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var $: any; 

@Component({
  selector: 'app-retailpos',
  templateUrl: './retailpos.component.html',
  styleUrls: ['./retailpos.component.css']
})
export class RetailposComponent implements OnInit {

  constructor(private fb: FormBuilder, private getService:callGlobalFuction,public router:Router,public route:ActivatedRoute,
    public imageGenerator: imageGenerator, private datepipe: DatePipe, private paymentMethod:PaymentMethod) { 
    }


  pdf:any;
  statusShow=0;
  productTypeforHTML="";

  totalHargaPrint=0;
  jsonListPrint={};
  listJsonListPrint=[];
  transNumber="";
  grandtotal=0;
  change=0;
  cariBarangForm= new FormGroup({
    barcode: new FormControl('',[
      Validators.required
    ])                
  });
  cariBarangFormNama= new FormGroup({

    productName: new FormControl('',[
      Validators.required
    ])                
  });

  checkoutForm= new FormGroup({
    metodeBayar: new FormControl('',[
      Validators.required
    ]),
    jumlahUang: new FormControl('',[
    ])                  
  });

  serviceReturn:any;
  serviceReturn2:any; 
  serviceReturn3:any;
  serviceReturn4:any;
  serviceReturn5:any;
  serviceReturn6:any;
  trMasterModel :transactionMaster;
  listOfTransactionModel=[];
  jsonPaymentMethod={};
  listOfPaymentMethod=[];

  jsonListSales={};
  lisfOfSales=[];

  searchPaymentMethod()
  {

    let jsonInput = {};
    jsonInput['kode']="paymentType";
    jsonInput['categoryId']="";
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      this.listOfPaymentMethod=[];

       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn5 = data;
          console.log(this.serviceReturn5);
          if(this.serviceReturn5.status=="S")
          {
            for(let i=0;i<this.serviceReturn5.listCode.length;i++)
            {
            this.jsonPaymentMethod={};
            this.jsonPaymentMethod['namaPayment']=this.serviceReturn5.listCode[i].namaPayment;
            this.jsonPaymentMethod['id']=this.serviceReturn5.listCode[i].id;
            this.listOfPaymentMethod.push(this.jsonPaymentMethod);
            }
          }
          else{
            alert("List of Payment Menthod Not Found");
          }
        }
      )  
  }

 searchProduct()
  {
    this.statusShow=0;
    let jsonInput = {};
    jsonInput['barcode'] = this.cariBarangForm.get('barcode').value;
    jsonInput['transactionTypeId']=2;
    
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);

      console.log(stringJsonInput);
       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryProductByBarcodePOS',stringJsonInput)
      .subscribe(
        async data => {
          this.serviceReturn2 = data;
          console.log(this.cariStockBarcode(this.serviceReturn2.result.categoryId,this.serviceReturn2.result.productDetailId));
          if(this.serviceReturn2.status=="S")
          {
          
            this.trMasterModel = new transactionMaster();
             this.trMasterModel.Id = this.serviceReturn2.result.productDetailId;
             this.trMasterModel.NamaBarang=this.serviceReturn2.result.nama_barang;
             this.trMasterModel.NamaCategory=this.serviceReturn2.result.nama_kategori;
             this.trMasterModel.MerkId=this.serviceReturn2.result.merk_id;
             this.trMasterModel.CategoryId=this.serviceReturn2.result.categoryId;
             this.trMasterModel.NamaMerk=this.serviceReturn2.result.nama_merk;
             this.trMasterModel.SellPrice= this.serviceReturn2.result.sell_price;
             this.trMasterModel.Ukuran=this.serviceReturn2.result.ukuran;
             this.trMasterModel.Qty= 1;
             this.trMasterModel.Disc= 0;
             //this.trMasterModel.Stock = this.cariStockBarcode(this.serviceReturn2.result.categoryId,this.serviceReturn2.result.productDetailId)
             this.listOfTransactionModel.push(this.trMasterModel);
             this.cariBarangForm.reset();
             this.calculateTotal(this.listOfTransactionModel.length-1);
           
          }
          else
          {
            console.log(this.serviceReturn2)
            alert("Master Product Search Failed");
            this.cariBarangForm.reset()
          }
        }
      )  
  }

  searchUpdatePriceByQty(index:number)
  {
    let jsonInput = {};
    jsonInput['categoryId'] =this.listOfTransactionModel[index].CategoryId;
    jsonInput['productDetailId']=this.listOfTransactionModel[index].Id;;
    jsonInput['qty']=parseInt(this.listOfTransactionModel[index].Qty);
    jsonInput['transactionTypeId']=2;
    
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);

      console.log(stringJsonInput);
      res=this.getService.restItemsServiceGetwithParamRestItems('transaction/getPrice',stringJsonInput)
      .subscribe(
        data => {
          this.serviceReturn6 = data;
          console.log(this.serviceReturn6)
          if(this.serviceReturn6.status=="S")
          {
            this.listOfTransactionModel[index].SellPrice=this.serviceReturn6.result.sellPrice;
            this.calculateTotal(index);
          }
          else
          {
            alert("Update Price Failed");
          }
        }
      ) 
  }

//search by name

serviceReturn7:any;
productMasterModel :productmastermodel;
listOfProductMasterModel=[];

searchbyname()
{

  let jsonInput = {};
  jsonInput['namaBarang'] = this.cariBarangFormNama.get('productName').value;
  
  let res={}
    let stringJsonInput = JSON.stringify(jsonInput);
    this.listOfProductMasterModel=[];

     res=this.getService.restItemsServiceGetwithParamRestItems('inquiryCategoryAndBrandByProductName',stringJsonInput)
    .subscribe(
      data => {
        this.serviceReturn7 = data;
        if(this.serviceReturn7.status=="S")
        {
          for(let i=0;i<this.serviceReturn7.listProduct.length;i++)
          {
          this.productMasterModel = new productmastermodel();
          this.productMasterModel.Id = this.serviceReturn7.listProduct[i].id;
          this.productMasterModel.MerkId = this.serviceReturn7.listProduct[i].merk_id;
          this.productMasterModel.CategoryId = this.serviceReturn7.listProduct[i].category_id;
          this.productMasterModel.NamaBarang = this.serviceReturn7.listProduct[i].nama_barang;
          this.productMasterModel.NamaMerk = this.serviceReturn7.listProduct[i].nama_merk;
          this.productMasterModel.NamaCategory = this.serviceReturn7.listProduct[i].nama_kategori;
          this.listOfProductMasterModel.push(this.productMasterModel);
          }
        }
        else
        {
          alert("Master Product Search Failed");
        }
      }
    )  
}

activeCategory : any;
   //inquiry detail product
serviceReturn8:any;
productDetailJoran :productdetailjoranmodel;
listOfProductDetailJoran=[];
productDetailReel :productdetailreelmodel;
listOfProductDetailReel=[];
productDetailLine :productdetaillinemodel;
listOfProductDetailLine=[];
productDetailKail :productdetailkailmodel;
listOfProductDetailKail=[];
productDetailAcc :productdetailaccmodel;
listOfProductDetailAcc=[];
productDetailBait :productdetailbaitmodel;
listOfProductDetailBait=[];


searchbynameDetail(id:string, categoryId:string, merkId:string)
{
  this.activeCategory=categoryId;

  $('#exampleModal2').modal('hide'); 
  let jsonInput = {};
  
  let res={}
  jsonInput['productId'] = id;
  jsonInput['kategoriId'] = categoryId;
  jsonInput['merkId'] = merkId;
  let stringJsonInput = JSON.stringify(jsonInput);
  console.log(stringJsonInput)
    
     res=this.getService.restItemsServiceGetwithParamRestItems('inquiryProductDetailAllByCategoryAndBrand',stringJsonInput)
    .subscribe(
      data => {
        this.serviceReturn8 = data;
        console.log(this.serviceReturn8)
        if(this.serviceReturn8.status=="S")
        {
      
          console.log(this.serviceReturn8)
          //inquiry detail untuk joran
          if(this.serviceReturn8.categoryId==1 && this.serviceReturn8.listProduct.listJoranDetail.length>0)
          {
            this.productTypeforHTML="Rod";
            this.listOfProductDetailJoran=[];
            for(let i=0;i<this.serviceReturn8.listProduct.listJoranDetail.length;i++)
            {
            this.productDetailJoran = new productdetailjoranmodel();
            this.productDetailJoran.Ukuran = this.serviceReturn8.listProduct.listJoranDetail[i].ukuran;
            this.productDetailJoran.Warna = this.serviceReturn8.listProduct.listJoranDetail[i].nama_warna;
            this.productDetailJoran.WarnaId = this.serviceReturn8.listProduct.listJoranDetail[i].warna_joran_id;
            this.productDetailJoran.Ring = this.serviceReturn8.listProduct.listJoranDetail[i].ring;
            this.productDetailJoran.Bahan = this.serviceReturn8.listProduct.listJoranDetail[i].nama_bahan;
            this.productDetailJoran.BahanId = this.serviceReturn8.listProduct.listJoranDetail[i].bahan_joran_id;
            this.productDetailJoran.Nama = this.serviceReturn8.listProduct.listJoranDetail[i].nama_barang;
            this.productDetailJoran.TahunProduksi = this.serviceReturn8.listProduct.listJoranDetail[i].tahun_produksi;
            this.productDetailJoran.MinLbs = this.serviceReturn8.listProduct.listJoranDetail[i].lbs_min;  
            this.productDetailJoran.MaxLbs = this.serviceReturn8.listProduct.listJoranDetail[i].nama_tipe_joran; 
            this.productDetailJoran.Merk = this.serviceReturn8.listProduct.listJoranDetail[i].nama_merk; 
            this.productDetailJoran.MerkId = this.serviceReturn8.listProduct.listJoranDetail[i].merk_id;
            this.productDetailJoran.MaxLbs = this.serviceReturn8.listProduct.listJoranDetail[i].lbs_max;  
            this.productDetailJoran.MaxPE = this.serviceReturn8.listProduct.listJoranDetail[i].pe_max;  
            this.productDetailJoran.MinPE = this.serviceReturn8.listProduct.listJoranDetail[i].pe_min; 
            this.productDetailJoran.Tipe = this.serviceReturn8.listProduct.listJoranDetail[i].nama_tipe;   
            this.productDetailJoran.TipeId = this.serviceReturn8.listProduct.listJoranDetail[i].tipe_joran_id;
            this.productDetailJoran.DetailId = this.serviceReturn8.listProduct.listJoranDetail[i].productDetailId; 
            this.productDetailJoran.Id = this.serviceReturn8.listProduct.listJoranDetail[i].productId;
            this.listOfProductDetailJoran.push(this.productDetailJoran);
            }
          }
          //end of inquiry detail untuk joran

           //inquiry detail untuk reel
           else if(this.serviceReturn8.categoryId==2 && this.serviceReturn8.listProduct.listReelDetail.length>0)
           {
             this.productTypeforHTML="Reel";
             this.listOfProductDetailReel=[];
             for(let i=0;i<this.serviceReturn8.listProduct.listReelDetail.length;i++)
             {
             this.productDetailReel = new productdetailreelmodel();
             console.log(this.serviceReturn8)
             this.productDetailReel.Ukuran = this.serviceReturn8.listProduct.listReelDetail[i].ukuran;                                                                 
             this.productDetailReel.WarnaId = this.serviceReturn8.listProduct.listReelDetail[i].warna_reel_id;
             this.productDetailReel.PhType = this.serviceReturn8.listProduct.listReelDetail[i].ph_type;
             this.productDetailReel.Warna = this.serviceReturn8.listProduct.listReelDetail[i].nama_warna;
             this.productDetailReel.BallBearing = this.serviceReturn8.listProduct.listReelDetail[i].ball_bearing;
             this.productDetailReel.TipeId = this.serviceReturn8.listProduct.listReelDetail[i].tipe_reel_id;
             this.productDetailReel.WayType = this.serviceReturn8.listProduct.listReelDetail[i].way_type;
             this.productDetailReel.Nama = this.serviceReturn8.listProduct.listReelDetail[i].nama_barang;
             this.productDetailReel.Tipe = this.serviceReturn8.listProduct.listReelDetail[i].nama_tipe;                                                                 
             this.productDetailReel.Merk = this.serviceReturn8.listProduct.listReelDetail[i].nama_merk;
             this.productDetailReel.MerkId = this.serviceReturn8.listProduct.listReelDetail[i].merk_id;               
             this.productDetailReel.DetailId = this.serviceReturn8.listProduct.listReelDetail[i].productDetailId;
             this.productDetailReel.Id = this.serviceReturn8.listProduct.listReelDetail[i].productId;               
             this.listOfProductDetailReel.push(this.productDetailReel);
             }
           }
           //end of inquiry detail untuk reel

           //inquiry detail untuk line
          else if(this.serviceReturn8.categoryId==3 && this.serviceReturn8.listProduct.listLineDetail.length>0)
           {
             this.productTypeforHTML="Line";
             this.listOfProductDetailLine=[];
             for(let i=0;i<this.serviceReturn8.listProduct.listLineDetail.length;i++)
             {
             this.productDetailLine = new productdetaillinemodel();
             this.productDetailLine.WarnaId = this.serviceReturn8.listProduct.listLineDetail[i].warna_line_id;
             this.productDetailLine.Satuan = this.serviceReturn8.listProduct.listLineDetail[i].satuan_ukur;
             this.productDetailLine.Ukuran = this.serviceReturn8.listProduct.listLineDetail[i].ukr_panjang;
             this.productDetailLine.Kekuatan = this.serviceReturn8.listProduct.listLineDetail[i].kekuatan;
             this.productDetailLine.TypeConnectorSenar = this.serviceReturn8.listProduct.listLineDetail[i].tipe_konektor_senar;
             this.productDetailLine.TipeNo = this.serviceReturn8.listProduct.listLineDetail[i].tipe_no;
             this.productDetailLine.TypeFluoroCarbon = this.serviceReturn8.listProduct.listLineDetail[i].tipe_fluor_carbon;
             this.productDetailLine.Warna = this.serviceReturn8.listProduct.listLineDetail[i].nama_warna;
             this.productDetailLine.Diameter = this.serviceReturn8.listProduct.listLineDetail[i].diameter;
             this.productDetailLine.Pe = this.serviceReturn8.listProduct.listLineDetail[i].pe;
             this.productDetailLine.TipeId = this.serviceReturn8.listProduct.listLineDetail[i].tipe_line_id;
             this.productDetailLine.Nama = this.serviceReturn8.listProduct.listLineDetail[i].nama_barang;
             this.productDetailLine.Tipe = this.serviceReturn8.listProduct.listLineDetail[i].nama_tipe;
             this.productDetailLine.Lbs = this.serviceReturn8.listProduct.listLineDetail[i].lbs;
             this.productDetailLine.Merk = this.serviceReturn8.listProduct.listLineDetail[i].nama_merk;
             this.productDetailLine.MerkId = this.serviceReturn8.listProduct.listLineDetail[i].merk_id;               
             this.productDetailLine.Id = this.serviceReturn8.listProduct.listLineDetail[i].productId;
             this.productDetailLine.DetailId = this.serviceReturn8.listProduct.listLineDetail[i].productDetailId;                              
             this.listOfProductDetailLine.push(this.productDetailLine);
             }
           }
           //end of inquiry detail untuk line

           //inquiry detail untuk kail
          else if(this.serviceReturn8.categoryId==4 && this.serviceReturn8.listProduct.listKailDetail.length>0)
          {
            this.productTypeforHTML="Kail";
            this.listOfProductDetailKail=[];
            for(let i=0;i<this.serviceReturn8.listProduct.listKailDetail.length;i++)
            {
            this.productDetailKail = new productdetailkailmodel();
            this.productDetailKail.Id = this.serviceReturn8.listProduct.listKailDetail[i].productId;
            this.productDetailKail.DetailId = this.serviceReturn8.listProduct.listKailDetail[i].productDetailId;
            this.productDetailKail.Ukuran = this.serviceReturn8.listProduct.listKailDetail[i].ukuran;
            this.productDetailKail.Merk = this.serviceReturn8.listProduct.listKailDetail[i].nama_merk;
            this.productDetailKail.MerkId = this.serviceReturn8.listProduct.listKailDetail[i].merk_id;              
            this.productDetailKail.Nama = this.serviceReturn8.listProduct.listKailDetail[i].nama_barang;              
            this.listOfProductDetailKail.push(this.productDetailKail);
            }
          }
          //end of inquiry detail untuk kail

           //inquiry detail untuk acc
           else if(this.serviceReturn8.categoryId==5 && this.serviceReturn8.listProduct.listAksesorisDetail.length>0)
           {
             this.productTypeforHTML="Acc";
             this.listOfProductDetailAcc=[];
             for(let i=0;i<this.serviceReturn8.listProduct.listAksesorisDetail.length;i++)
             {
             this.productDetailAcc = new productdetailaccmodel();
             this.productDetailAcc.DetailId = this.serviceReturn8.listProduct.listAksesorisDetail[i].productDetailId;
             this.productDetailAcc.Id = this.serviceReturn8.listProduct.listAksesorisDetail[i].productId;               
             this.productDetailAcc.Ukuran = this.serviceReturn8.listProduct.listAksesorisDetail[i].ukuran;
             this.productDetailAcc.Merk = this.serviceReturn8.listProduct.listAksesorisDetail[i].nama_merk;
             this.productDetailAcc.MerkId = this.serviceReturn8.listProduct.listAksesorisDetail[i].merk_id;               
             this.productDetailAcc.Nama = this.serviceReturn8.listProduct.listAksesorisDetail[i].nama_barang;
             this.productDetailAcc.Warna = this.serviceReturn8.listProduct.listAksesorisDetail[i].nama_warna;
             this.productDetailAcc.WarnaId = this.serviceReturn8.listProduct.listAksesorisDetail[i].warna_aksesoris_id; 
             this.productDetailAcc.Lbs = this.serviceReturn8.listProduct.listAksesorisDetail[i].lbs; 
             this.productDetailAcc.Liter = this.serviceReturn8.listProduct.listAksesorisDetail[i].liter; 
             this.productDetailAcc.Tipe = this.serviceReturn8.listProduct.listAksesorisDetail[i].nama_tipe; 
             this.productDetailAcc.TipeId = this.serviceReturn8.listProduct.listAksesorisDetail[i].tipe_aksesoris_id;  
             this.productDetailAcc.Size = this.serviceReturn8.listProduct.listAksesorisDetail[i].size;                             
             this.listOfProductDetailAcc.push(this.productDetailAcc);
             }
           }
           //end of inquiry detail untuk acc            
           //inquiry detail untuk bait
           else if(this.serviceReturn8.categoryId==6 && this.serviceReturn8.listProduct.listUmpanDetail.length>0)
           {
             this.productTypeforHTML="Bait";
             this.listOfProductDetailBait=[];
             for(let i=0;i<this.serviceReturn8.listProduct.listUmpanDetail.length;i++)
             {
             this.productDetailBait = new productdetailbaitmodel();
             this.productDetailBait.DetailId = this.serviceReturn8.listProduct.listUmpanDetail[i].productDetailId;
             this.productDetailBait.Id = this.serviceReturn8.listProduct.listUmpanDetail[i].productId;               
             this.productDetailBait.Ukuran = this.serviceReturn8.listProduct.listUmpanDetail[i].ukuran;
             this.productDetailBait.Merk = this.serviceReturn8.listProduct.listUmpanDetail[i].nama_merk;
             this.productDetailBait.MerkId = this.serviceReturn8.listProduct.listUmpanDetail[i].merkId;               
             this.productDetailBait.Nama = this.serviceReturn8.listProduct.listUmpanDetail[i].nama_barang;
             this.productDetailBait.Warna = this.serviceReturn8.listProduct.listUmpanDetail[i].nama_warna;
             this.productDetailBait.WarnaId = this.serviceReturn8.listProduct.listUmpanDetail[i].warna_umpan_id; 
             this.productDetailBait.Tipe = this.serviceReturn8.listProduct.listUmpanDetail[i].nama_tipe; 
             this.productDetailBait.TipeId = this.serviceReturn8.listProduct.listUmpanDetail[i].tipe_umpan_id;   
             this.productDetailBait.Berat = this.serviceReturn8.listProduct.listUmpanDetail[i].berat;     
             this.productDetailBait.Varian = this.serviceReturn8.listProduct.listUmpanDetail[i].namaVarian;   
             this.productDetailBait.VarianId = this.serviceReturn8.listProduct.listUmpanDetail[i].varianId;                       
             this.listOfProductDetailBait.push(this.productDetailBait);
             }
           }
           //end of inquiry detail untuk bait    

          else{
            alert("Product Detail Not Found");
          }

        }
        else
        {
          alert("Inquiry Detail Product Failed");
        }
      }
    )  
}
serviceReturn10:any;
cariStockBarcode(catId:any,detailId:any)
{
  let jsonInput = {};
  jsonInput['categoryId']=parseInt(catId);
  jsonInput['productDetailId']=parseInt(detailId); 
  let res={}
  let returnData;
    let stringJsonInput = JSON.stringify(jsonInput);
    console.log(stringJsonInput)
     res=this.getService.restItemsServiceGetwithParamRestItems('warehouse/inquiryStock',stringJsonInput)
    .subscribe( 
      data => {
        this.serviceReturn10 = data;
        console.log(this.serviceReturn10);
        if(this.serviceReturn10.status=="S")
        {
          returnData= parseInt(this.serviceReturn10.result.stock);
        }
        else{
          alert("Inquiry Stock Failed");
          return -1;
        }
      }
    )
}


stockModel :stock = new stock();
serviceReturn9:any;
addToList(objectModel:any)
{

  let jsonInput = {};
  jsonInput['categoryId']=parseInt(this.activeCategory);
  jsonInput['productDetailId']=parseInt(objectModel.DetailId); 
  let res={}
    let stringJsonInput = JSON.stringify(jsonInput);
    console.log(stringJsonInput)
     res=this.getService.restItemsServiceGetwithParamRestItems('warehouse/inquiryStock',stringJsonInput)
    .subscribe( 
      data => {
        this.serviceReturn9 = data;
        console.log(this.serviceReturn9);
        if(this.serviceReturn9.status=="S")
        {
          this.stockModel = new stock();
          //this.stockModel.MasterStockId = this.serviceReturn9.result.masterStockId;
          this.stockModel.Uom = this.serviceReturn9.result.uom;
          this.stockModel.Stock = this.serviceReturn9.result.stock;
          this.stockModel.JumlahPerDus = this.serviceReturn9.result.jumlahPerDus;


          this.trMasterModel = new transactionMaster();
  this.trMasterModel.Id = objectModel.DetailId;

  if(this.activeCategory==1 || this.activeCategory==2)
  {
  this.trMasterModel.NamaBarang=objectModel.Merk + " " +objectModel.Nama+ " " +objectModel.Ukuran+ " " +objectModel.Tipe+ " " +objectModel.Warna;
  }
  else if(this.activeCategory==3)
  {
  this.trMasterModel.NamaBarang=objectModel.Merk + " " +objectModel.Nama+ " " +objectModel.Ukuran+ " Tp.No " +objectModel.TipeNo
  + " PE: " +objectModel.Pe+ " Lbs: " +objectModel.Lbs+" "+objectModel.Diameter +" "+objectModel.Kekuatan +" "+objectModel.Warna;
  }    
  else if(this.activeCategory==4)
  {
  this.trMasterModel.NamaBarang=objectModel.Merk + " " +objectModel.Nama+ " " +objectModel.Ukuran;
  }
  else if(this.activeCategory==5)
  {
  this.trMasterModel.NamaBarang=objectModel.Merk + " " +objectModel.Nama+ " " +objectModel.Ukuran
  + " Tipe: " +objectModel.Tipe+ " Lbs: " +objectModel.Lbs+" "+objectModel.Size+" "+objectModel.Warna;
  }
  else if(this.activeCategory==6)
  {
  this.trMasterModel.NamaBarang=objectModel.Merk + " " +objectModel.Nama+ " " +objectModel.Ukuran
  + " Tipe: " +objectModel.Tipe+ " " +objectModel.Berat+" "+objectModel.Varian+" "+objectModel.Warna;
  }    
  this.trMasterModel.Stock=this.stockModel.Stock;
  this.trMasterModel.NamaCategory=this.productTypeforHTML;
  this.trMasterModel.MerkId=objectModel.MerkId;
  this.trMasterModel.CategoryId=this.activeCategory;
  this.trMasterModel.NamaMerk=objectModel.Merk;
  this.trMasterModel.SellPrice= "0";
  this.trMasterModel.Ukuran=objectModel.Ukuran;
  this.trMasterModel.Qty= 1;
  this.listOfTransactionModel.push(this.trMasterModel);
  $('#exampleModal3').modal('hide');
  this.cariBarangForm.reset();
        }
        else{
          alert("Inquiry Stock Failed");
        }
      }
    )
}

//end search bynanme

 // DiHide karena menggunakan mekanisme perhitungan harga terbaru.
  // calculateTotal(index:number)
  // {
  //   this.grandtotal=0;
  //   let tempHargaTotal=this.listOfTransactionModel[index].SellPrice*this.listOfTransactionModel[index].Qty;
  //   this.listOfTransactionModel[index].DiscNominal=this.listOfTransactionModel[index].SellPrice*this.listOfTransactionModel[index].Disc/100;
  //   this.listOfTransactionModel[index].Total=tempHargaTotal-(tempHargaTotal*this.listOfTransactionModel[index].Disc/100)
  //   for(let i=0;i<this.listOfTransactionModel.length;i++)
  //   {this.grandtotal+=this.listOfTransactionModel[i].total}
  // }

  // calculateTotalNominal(index:number)
  // {

  //   this.listOfTransactionModel[index].Disc=parseFloat((this.listOfTransactionModel[index].DiscNominal/this.listOfTransactionModel[index].SellPrice*100).toString()).toFixed(2);
  //   this.grandtotal=0;
  //   let tempHargaTotal=this.listOfTransactionModel[index].SellPrice*this.listOfTransactionModel[index].Qty;
  //   this.listOfTransactionModel[index].Total=tempHargaTotal-(tempHargaTotal*this.listOfTransactionModel[index].Disc/100)
  //   for(let i=0;i<this.listOfTransactionModel.length;i++)
  //   {this.grandtotal+=this.listOfTransactionModel[i].total}
  // }
  buttonStatus = "false";
   calculateTotal(index:number)
   {
    if(this.listOfTransactionModel[index].Qty<=0 || this.listOfTransactionModel[index].SellPrice<=0)
    {
      alert("Qty or Price Must be Greater Than 0");
      this.buttonStatus="false";
    }
    else
    {
     this.buttonStatus="true";
     this.grandtotal=0;
     this.listOfTransactionModel[index].Total=this.listOfTransactionModel[index].SellPrice*this.listOfTransactionModel[index].Qty;
     for(let i=0;i<this.listOfTransactionModel.length;i++)
     {this.grandtotal+=this.listOfTransactionModel[i].total}
    }
   }
  
  hapusTransaksi(index:number)
  {
    this.listOfTransactionModel.splice(index, 1);
    if(this.listOfTransactionModel.length==0)
    {
      this.grandtotal=0;
    }
    for (let i=0;i<this.listOfTransactionModel.length;i++)
    {
      this.calculateTotal(this.listOfTransactionModel.length-1);
    }
  }

  calculateChange()
  {
    this.change=0;
    this.change=parseInt(this.checkoutForm.get('jumlahUang').value)-this.grandtotal;
  }

   numberWithCommas(x : number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  date=new Date();
  latest_date="";
  checkout()
  {
    
    this.latest_date =this.datepipe.transform(this.date, 'dd-MM-yyyy hh:mm');
    this.listJsonListPrint=[];
    let jsonInput = {};
    let listOfProductTr=[];
    this.totalHargaPrint=0;
    jsonInput['customerId']=0;
    jsonInput['userId']=sessionStorage.getItem('userId');
    jsonInput['noPo']="";
    jsonInput['paymentTypeId']=this.checkoutForm.get('metodeBayar').value;
    jsonInput['kodeTransaksi']=2; 
    jsonInput['customerName']=sessionStorage.getItem("userid");
    jsonInput['tanggalTransaksi']="";
    jsonInput['noTransaksi']="";
    jsonInput['totalHarga']=this.grandtotal;
    for(let i=0;i<this.listOfTransactionModel.length;i++)
    {
      let jsonInputProduct={};
      jsonInputProduct["productDetailId"]= this.listOfTransactionModel[i].Id;
      jsonInputProduct["categoryId"]= this.listOfTransactionModel[i].CategoryId;
      jsonInputProduct["basePrice"]= 0
      jsonInputProduct["sellPrice"]= this.listOfTransactionModel[i].SellPrice;
      jsonInputProduct["sellPriceDiscount"]= this.listOfTransactionModel[i].Total/this.listOfTransactionModel[i].Qty;
      jsonInputProduct["nominalDiscount"]= this.listOfTransactionModel[i].DiscNominal;
      jsonInputProduct["discount"]= this.listOfTransactionModel[i].Disc,
      jsonInputProduct["qtyTotal"]= this.listOfTransactionModel[i].Qty;
      listOfProductTr.push(jsonInputProduct);
    }
    jsonInput['listProduct']=listOfProductTr;
    console.log(jsonInput);

    let res={}
    let stringJsonInput = JSON.stringify(jsonInput);
    this.transNumber="";
    console.log(stringJsonInput);
     res=this.getService.restItemsServiceGetwithParamRestItems('transaction/createTransaction',stringJsonInput)
    .subscribe(
      data => {
        this.serviceReturn4 = data;
        console.log(JSON.stringify(this.serviceReturn4))
        if(this.serviceReturn4.status=="S")
        {

          this.transNumber = this.serviceReturn4.result.referenceNumber;
          alert("Transaction Success - "+this.serviceReturn4.result.referenceNumber);
          this.totalHargaPrint = this.serviceReturn4.result.totalHarga;
          for(let i=0;i<this.serviceReturn4.result.salesProductList.length;i++)
          {
            this.jsonListPrint={};
            this.jsonListPrint['merkBarang'] = this.serviceReturn4.result.salesProductList[i].productWrap.productDetail.namaMerk;
            this.jsonListPrint['namaBarang'] = this.serviceReturn4.result.salesProductList[i].productWrap.productDetail.namaBarang + " " + this.serviceReturn4.result.salesProductList[i].productWrap.productDetail.detail2;
            //this.jsonListPrint['detail2'] = this.serviceReturn4.result.salesProductList[i].productWrap.productDetail.detail2;
            this.jsonListPrint['total'] = this.numberWithCommas(this.serviceReturn4.result.salesProductList[i].productWrap.sellPrice*this.serviceReturn4.result.salesProductList[i].productWrap.qty);
            this.jsonListPrint['sellPrice'] = this.numberWithCommas(this.serviceReturn4.result.salesProductList[i].productWrap.sellPrice);
            this.jsonListPrint['qty'] = this.serviceReturn4.result.salesProductList[i].productWrap.qty;
            this.listJsonListPrint.push(this.jsonListPrint);
          }
          if(this.checkoutForm.get('jumlahUang').value=="" || this.checkoutForm.get('jumlahUang').value==null)
          {
            this.checkoutForm.get('jumlahUang').setValue(0);
          }
          this.printPOpdf();
          this.change=0;
          this.checkoutForm.reset();
          this.cariBarangForm.reset();
          this.grandtotal=0;
          this.listOfTransactionModel=[];
        }
        else
        {
          alert("Transaction Failed - "+this.serviceReturn4.errMes);
          location.reload();
        }
      }
    )  
  }


  // createTransaction()
  // {
  //   this.statusShow=0;
  //   let jsonInput = {};
  //   jsonInput['barcode'] = this.cariBarangForm.get('barcode').value;
    
    
  //   let res={}
  //     let stringJsonInput = JSON.stringify(jsonInput);

  //     console.log(stringJsonInput);
  //      res=this.getService.restItemsServiceGetwithParamRestItems('inquiryProductByBarcodePOS',stringJsonInput)
  //     .subscribe(
  //       data => {
  //         this.serviceReturn2 = data;
  //         if(this.serviceReturn2.status=="S")
  //         {
  //           console.log(JSON.stringify(this.serviceReturn2))
  //            this.trMasterModel = new transactionMaster();
  //            this.trMasterModel.Id = this.serviceReturn2.result.productId;
  //            this.trMasterModel.NamaBarang=this.serviceReturn2.result.nama_barang;
  //            this.trMasterModel.NamaCategory=this.serviceReturn2.result.nama_kategori;
  //            this.trMasterModel.MerkId=this.serviceReturn2.result.merk_id;
  //            this.trMasterModel.CategoryId=this.serviceReturn2.result.categoryId;
  //            this.trMasterModel.NamaMerk=this.serviceReturn2.result.nama_merk;
  //            this.trMasterModel.SellPrice= this.serviceReturn2.result.sell_price;
  //            this.trMasterModel.Ukuran=this.serviceReturn2.result.ukuran;
  //            this.trMasterModel.Qty= 1;
  //            this.trMasterModel.Disc= 0;
  //            this.listOfTransactionModel.push(this.trMasterModel);
  //            this.cariBarangForm.reset();
  //            this.calculateTotal(this.listOfTransactionModel.length-1);
  //         }
  //         else
  //         {
  //           alert("Master Product Search Failed");
  //           this.cariBarangForm.reset()
  //         }
  //       }
  //     )  
  // }


  buildTableBody(data, columns) {
    var body = [];

    data.forEach(function(row) {
        var dataRow = [];

        columns.forEach(function(column) {
            dataRow.push(row[column].toString());
            console.log(row[column].toString());
        })

        body.push(dataRow);
    });

    return body;
}

 tableData(data, columns) {
    return {
      layout: 'noBorders', // optional
        table: {
            headerRows: 1,
            fontSize:7, 
            widths: [22,45, 15, 25, 30],
            body: this.buildTableBody(data, columns),
        },style:'contain3'
    };
}  


  print()
  {

    var dd = {
      pageSize: {
        width: 204,
        height: 3000,
        pageOrientation: 'portrait',
      },
      pageMargins: [10,20],

      styles: {
        judul: {
           margin:[0,8,0,8],
           alignment:'center',
         },
         judul2: {
          margin:[0,5,0,2],
          alignment:'center',
          fontSize:'9'          
        },         
         contain: {
          margin:[5,2,0,2],
          alignment:'left',
          fontSize:'8.5'
        }, 
        contain3: {
          margin:[5,2,0,2],
          alignment:'left',
          fontSize:'7'
        }, 
        contain2: {
          margin:[0,2,5,2],
          alignment:'right',
          fontSize:'8.5'
        },  
        total: {
          margin:[5,2,0,2],
          alignment:'left',
          fontSize:'9',
          bold: true
        }, 
        total2: {
          margin:[0,2,5,2],
          alignment:'right',
          fontSize:'9',
          bold: true
        }, 
        footer: {
          margin:[0,2,5,2],
          alignment:'center',
          fontSize:'9'
        },      
        footer2: {
          margin:[0,2,5,2],
          alignment:'center',
          fontSize:'8'
        }, 
        header: {
          margin:[0,5,5,2],
          alignment:'center',
          fontSize:'8'
        },                                                 
       },

      content: [
        {image: this.imageGenerator.goGetImage(),alignment:'center',width:80},
        {text:'Ruko Terrace 9  Jl. Jati Utama Blok D NO.52 Suvarna Sutera. Phone/Whatsapp : 0813-1415-9678',style:'header'},
        {text: this.latest_date+' - ' + this.transNumber+'#'+sessionStorage.getItem('username'),style:'judul2',bold:true},                
        {text:'SALES RECEIPT',fontSize: 12,
        bold: true,style:'judul'},
        {canvas: [{ type: 'line', x1: 0, y1: 3, x2: 226-2*20, y2: 3, lineWidth: 2 }], margin:[0,0,0,8]},
        
        this.tableData(this.listJsonListPrint, ['merkBarang','namaBarang','qty','sellPrice','total']),
        
          {canvas: [{ type: 'line', x1: 0, y1: 3, x2: 226-2*20, y2: 3, lineWidth: 2 }], margin:[0,0,0,8]},
          {  columns : [
            {text:'Payment Method',style:'contain'},
            {text: this.paymentMethod.getPaymentMethod(this.checkoutForm.get('metodeBayar').value),style:'contain2'}]
            }, 
            {  columns : [
              {text:'Payment Amount',style:'contain'},
              {text: this.numberWithCommas(this.checkoutForm.get('jumlahUang').value),style:'contain2'}]
              },               
            {  columns : [
              {text:'Change',style:'contain'},
              {text: this.numberWithCommas(this.change),style:'contain2'}]
              }, 
          {canvas: [{ type: 'line', x1: 0, y1: 3, x2: 226-2*20, y2: 3, lineWidth: 2 }], margin:[0,0,0,8]},
          {  columns : [
          {text:'GRAND TOTAL',style:'contain'},
          {text:'Rp. '+this.numberWithCommas(this.totalHargaPrint),style:'contain2'}]
          },           
          {canvas: [{ type: 'line', x1: 0, y1: 3, x2: 226-2*20, y2: 3, lineWidth: 2 }], margin:[0,0,0,8]},
            {text:'TERIMA KASIH',style:'footer'},
            {text:'Barang yang sudah dibeli tidak dapat ditukar atau dikembalikan',style:'footer2'},
            {text:'--- GUDANG PANCING ---',style:'footer2'}            
      ]
    }
    return dd;
  }

  printPOpdf()
{
this.pdf = pdfMake;
    this.pdf.createPdf(this.print()).open();
}


  ngOnInit(): void {
    this.searchPaymentMethod();
  }

}
