import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators,ReactiveFormsModule } from '@angular/forms';
import  { callGlobalFuction } from "../../callGlobalFuction";
import { Router,ActivatedRoute } from "@angular/router";
import { group } from '../../model/product/group';
import { NgLocalization } from '@angular/common';

@Component({
  selector: 'app-groupsetting',
  templateUrl: './groupsetting.component.html',
  styleUrls: ['./groupsetting.component.css']
})
export class GroupsettingComponent implements OnInit {

  constructor(private fb: FormBuilder, private getService:callGlobalFuction,
    public router:Router,public route:ActivatedRoute) { }


        //inquiry Group 
        serviceReturn:any;
        group :group;    
        listOfGroup=[];
        p:number=1;
    groupForm= new FormGroup({
      groupName: new FormControl('',[
      ]),                            
    });

    groupFormCreate= new FormGroup({
      groupName: new FormControl('',[
      ]),   
      groupDescription: new FormControl('',[
      ]),                                  
    });


    inquiryGroup()
    {
      let jsonInput = {};
      jsonInput['groupName'] = this.groupForm.get('groupName').value;
      jsonInput['groupDescription'] = "";
  
      let res={}
        let stringJsonInput = JSON.stringify(jsonInput);
  
         res=this.getService.restItemsServiceGetwithParamRestItems('warehouse/inquiryProductGroup',stringJsonInput)
        .subscribe(
          data => {
            this.serviceReturn = data;
            console.log(this.serviceReturn);
            if(this.serviceReturn.status=="S")
            {
              this.listOfGroup=[];
              for(let i=0;i<this.serviceReturn.result.productGroup.length;i++)
              {
              this.group= new group();
              this.group.Id=this.serviceReturn.result.productGroup[i].id;
              this.group.GroupName=this.serviceReturn.result.productGroup[i].groupName;
              this.group.GroupDescription=this.serviceReturn.result.productGroup[i].groupDescription;
              this.listOfGroup.push(this.group);  
              }
              
            }

            else
            {
              alert("Failed Inquiry Group");
            }
          }
        )  
    }


    serviceReturn2:any;
    createGroup()
    {
    let jsonInput = {};
    jsonInput['groupName'] = this.groupFormCreate.get('groupName').value;
    jsonInput['groupDescription'] = this.groupFormCreate.get('groupDescription').value;

    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);

       res=this.getService.restItemsServiceGetwithParamRestItems('warehouse/createProductGroupForMixItem',stringJsonInput)
      .subscribe(
        data => {
          this.serviceReturn2 = data;
          if(this.serviceReturn2.status=="S")
          {
            alert("Success Create Group");
            location.reload();
          }
          else
          {
            alert("Failed Create Group");
          }
        }
      )  
    }


    deleteIdTemp:number;
    deleteGroup(groupHeaderObj:any)
    {
      this.deleteIdTemp=0;
      console.log(groupHeaderObj);
      this.deleteIdTemp=groupHeaderObj;
    }
    serviceReturn4:any;
    deleteConfirm()
    {
      let jsonInput = {};
      jsonInput['groupId'] = this.deleteIdTemp;
  
      let res={}
        let stringJsonInput = JSON.stringify(jsonInput);
  
         res=this.getService.restItemsServiceGetwithParamRestItems('warehouse/deleteMixItemGroup',stringJsonInput)
        .subscribe(
          data => {
            this.serviceReturn4 = data;
            console.log(this.serviceReturn4);
            if(this.serviceReturn4.status=="S")
            {
              alert("Success Delete Group");
              location.reload();
            }

            else
            {
              alert("Failed Delete Group");
            }
          }
        )  
    }



  ngOnInit(): void {
    this.inquiryGroup()
    
  }

}
