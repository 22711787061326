export class incoming{

    constructor(values: Object = {}) {
          Object.assign(this, values);
    }
    private id : number;
    private supplierId : number;
    private qty : number;
    private namaSupplier : string;
    private receivedBy : string;  
    private tanggalTransaksi : string;
    private nomorSj : string;
    private nomorPO : string;
    private product : string;
    
    
    public get Id():number{
        return this.id;
    }
    public get SupplierId():number{
        return this.supplierId;
    }
    public get NamaSupplier():string{
        return this.namaSupplier;
    }    
    public get ReceivedBy():string{
        return this.receivedBy;
    }
    public get TanggalTransaksi():string{
        return this.tanggalTransaksi;
    }
    public get NomorPO():string{
        return this.nomorPO;
    }
    public get NomorSj():string{
        return this.nomorSj;
    }
    public get Product():string{
        return this.product;
    }
    public get Qty():number{
        return this.qty;
    }


    public set Id (id:number){
        this.id=id;
    }
    public set SupplierId (supplierId:number){
        this.supplierId=supplierId;
    }
    public set Qty (qty:number){
        this.qty=qty;
    }
    public set NamaSupplier (namaSupplier:string){
        this.namaSupplier=namaSupplier;
    }    
    public set ReceivedBy (receivedBy:string){
        this.receivedBy=receivedBy;
    }
    public set TanggalTransaksi (tanggalTransaksi:string){
        this.tanggalTransaksi=tanggalTransaksi;
    }
    public set NomorPO (nomorPO:string){
        this.nomorPO=nomorPO;
    }
    public set NomorSj (nomorSj:string){
        this.nomorSj=nomorSj;
    }
    public set Product (product:string){
        this.product=product;
    }
   
    }