import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators,ReactiveFormsModule } from '@angular/forms';
import  { callGlobalFuction } from "../../callGlobalFuction";
import { Router,ActivatedRoute } from "@angular/router";
import { purchaseOrder } from '../../model/transaction/purchaseorder';
import { stockadjustment } from '../../model/transaction/stockAdjustment';
import  { DateTimeUtil } from "../../globalfunction/dateTimeUtil";
declare var $ :any;
@Component({
  selector: 'app-stockadjustment',
  templateUrl: './stockadjustment.component.html',
  styleUrls: ['./stockadjustment.component.css']
})
export class StockadjustmentComponent implements OnInit {

  p:number=1;
  adjustmentForm= new FormGroup({
   startDate: new FormControl('',[
   ]),
   endDate: new FormControl('',[
   ])                                
 });

  constructor(private fb: FormBuilder, private getService:callGlobalFuction,
    public router:Router,public route:ActivatedRoute,public dateTimeUtil: DateTimeUtil) { }

  serviceReturn:any;
  listOFStockAdjusment=[];
  stockadjustment :stockadjustment; 
  inquiryAdjustment()
  {
    if(this.dateTimeUtil.reverseDateToBVFormat2($('#startDate').first().val())=="" || this.dateTimeUtil.reverseDateToBVFormat2($('#endDate').first().val())=="")
    {
      alert("Start Date & End Date Cannot be Empty")
    }
    {
    let jsonInput = {};
    jsonInput['startDate'] = this.dateTimeUtil.reverseDateToBVFormat2($('#startDate').first().val());
    jsonInput['endDate'] = this.dateTimeUtil.reverseDateToBVFormat2($('#endDate').first().val());
    let listOFStockAdjusment = [];
    let res={}
    let stringJsonInput = JSON.stringify(jsonInput);
    console.log(stringJsonInput)
     res=this.getService.restItemsServiceGetwithParamRestItems('warehouse/inquiryStockAdjustment',stringJsonInput)
    .subscribe(
      data => {
        this.serviceReturn = data;

        if(this.serviceReturn.status=="S")
        {
          this.listOFStockAdjusment=[];
          for(let i=0;i<this.serviceReturn.result.productList.length;i++)
          {
          this.stockadjustment = new stockadjustment()
          this.stockadjustment.Keterangan = this.serviceReturn.result.productList[i].keterangan;
          this.stockadjustment.NamaTransaksi = this.serviceReturn.result.productList[i].namaTransaksi;
          this.stockadjustment.TanggalTransaksi = this.serviceReturn.result.productList[i].tanggalTransaksi;
          this.stockadjustment.Qty = this.serviceReturn.result.productList[i].qty;
          this.stockadjustment.Username = this.serviceReturn.result.productList[i].username;
          this.stockadjustment.ProductDetail = this.serviceReturn.result.productList[i].productDetail.namaMerk+" "+ '\n'+
          this.serviceReturn.result.productList[i].productDetail.namaBarang+" "+ '\n'+
          this.serviceReturn.result.productList[i].productDetail.detail+" "+ '\n'+
          this.serviceReturn.result.productList[i].productDetail.detail2;
          this.listOFStockAdjusment.push(this.stockadjustment);
          } 
          console.log(this.listOFStockAdjusment)
        }
        else
        {
          alert("Failed Inquiry Stock Adjustment");
        }
      }
    )  
    }

  }

  ngOnInit(): void {

    $('#datepicker-popup').datepicker({
      enableOnReadonly: true,
      todayHighlight: true,
    });

    $('#datepicker-popup2').datepicker({
      enableOnReadonly: true,
      todayHighlight: true,
    });

  }

}
