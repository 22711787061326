export class stockadjustment{

    constructor(values: Object = {}) {
          Object.assign(this, values);
    }
    private traceNumber : number;
    private keterangan : string;
    private namaTransaksi : string;  
    private tanggalTransaksi : string;
    private username : string;
    private productDetail : string;
    private qty : number;
    
    
    public get TraceNumber():number{
        return this.traceNumber;
    }
    public get Qty():number{
        return this.qty;
    }

    public get NamaTransaksi():string{
        return this.namaTransaksi;
    }    
    public get Keterangan():string{
        return this.keterangan;
    }
    public get TanggalTransaksi():string{
        return this.tanggalTransaksi;
    }
    public get Username():string{
        return this.username;
    }
    public get ProductDetail():string{
        return this.productDetail;
    }


    public set TraceNumber (traceNumber:number){
        this.traceNumber=traceNumber;
    }
    public set Qty (qty:number){
        this.qty=qty;
    }
    public set NamaTransaksi (namaTransaksi:string){
        this.namaTransaksi=namaTransaksi;
    }
    public set Keterangan (keterangan:string){
        this.keterangan=keterangan;
    }    
    public set TanggalTransaksi (tanggalTransaksi:string){
        this.tanggalTransaksi=tanggalTransaksi;
    }
    public set Username (username:string){
        this.username=username;
    }
    public set ProductDetail (productDetail:string){
        this.productDetail=productDetail;
    }
   
    }