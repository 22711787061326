export class transactionMaster{

    constructor(values: Object = {}) {
          Object.assign(this, values);
    }
    private id : string;
    private merkId : string;
    private categoryId : string;  
    private namaBarang : string;
    private ukuran : string;
    private namaMerk : string;
    private namaCategory : string;
    private qty: number;
    private basePrice: number;
    private sellPrice : string;
    private total : number;
    private disc : number;
    private discNominal : number;
    private stock: number;
    
    
    public get Id():string{
        return this.id;
    }
    public get MerkId():string{
        return this.merkId;
    }
    public get CategoryId():string{
        return this.categoryId;
    }
    public get NamaBarang():string{
        return this.namaBarang;
    }
    public get NamaMerk():string{
        return this.namaMerk;
    }
    public get Ukuran():string{
        return this.ukuran;
    }    
    public get NamaCategory():string{
        return this.namaCategory;
    }
    public get SellPrice():string{
        return this.sellPrice;
    }
  
    public get Qty():number{
        return this.qty;
    }
    public get Stock():number{
        return this.stock;
    }
    public get BasePrice():number{
        return this.basePrice;
    }
    public get Total():number{
        return this.total;
    }  
    public get Disc():number{
        return this.disc;
    }  
    public get DiscNominal():number{
        return this.discNominal;
    }            
    public set Id (id:string){
        this.id=id;
    }
    public set MerkId (merkId:string){
        this.merkId=merkId;
    }
    public set CategoryId (categoryId:string){
        this.categoryId=categoryId;
    }
    public set NamaBarang (namaBarang:string){
        this.namaBarang=namaBarang;
    }
    public set NamaMerk (namaMerk:string){
        this.namaMerk=namaMerk;
    }
    public set Ukuran (ukuran:string){
        this.ukuran=ukuran;
    }    
    public set NamaCategory (namaCategory:string){
        this.namaCategory=namaCategory;
    }
    public set SellPrice (sellPrice:string){
        this.sellPrice=sellPrice;
    }
    
    public set Qty (qty:number){
        this.qty=qty;
    }
    public set Stock (stock:number){
        this.stock=stock;
    }
    public set BasePrice (basePrice:number){
        this.basePrice=basePrice;
    }
    public set Total (total:number){
        this.total=total;
    }
    public set Disc (disc:number){
        this.disc=disc;
    }  
    public set DiscNominal (discNominal:number){
        this.discNominal=discNominal;
    }        

    }