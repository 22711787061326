import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FormGroup, FormControl, FormBuilder, Validators,ReactiveFormsModule } from '@angular/forms';
import  { callGlobalFuction } from "../../callGlobalFuction";
import { Router,ActivatedRoute } from "@angular/router";
import { productmastermodel } from '../../model/product/productmaster';

@Component({
  selector: 'app-inquiryproductmaster',
  templateUrl: './inquiryproductmaster.component.html',
  styleUrls: ['./inquiryproductmaster.component.css']
})
export class InquiryproductmasterComponent implements OnInit {

  constructor(private fb: FormBuilder, private getService:callGlobalFuction,public router:Router) { }

  //inquiry master product
  serviceReturn:any;
  productMasterModel :productmastermodel;
  listOfProductMasterModel=[];

   inquiryMasterProductForm= new FormGroup({
    productName: new FormControl('',[
    Validators.required
    ])                     
  });


  searchbyname()
  {

    let jsonInput = {};
    jsonInput['namaBarang'] = this.inquiryMasterProductForm.get('productName').value;
    
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      this.listOfProductMasterModel=[];
      console.log(stringJsonInput)
       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryCategoryAndBrandByProductName',stringJsonInput)
      .subscribe(
        data => {
          this.serviceReturn = data;
          if(this.serviceReturn.status=="S")
          {
            for(let i=0;i<this.serviceReturn.listProduct.length;i++)
            {
            this.productMasterModel = new productmastermodel();
            this.productMasterModel.Id = this.serviceReturn.listProduct[i].id;
            this.productMasterModel.MerkId = this.serviceReturn.listProduct[i].merk_id;
            this.productMasterModel.CategoryId = this.serviceReturn.listProduct[i].category_id;
            this.productMasterModel.NamaBarang = this.serviceReturn.listProduct[i].nama_barang;
            this.productMasterModel.NamaMerk = this.serviceReturn.listProduct[i].nama_merk;
            this.productMasterModel.NamaCategory = this.serviceReturn.listProduct[i].nama_kategori;
            this.listOfProductMasterModel.push(this.productMasterModel);
            }
          }
          else
          {
            alert("Master Product Search Failed");
          }
        }
      )  
  }

  ngOnInit(): void {
  }

}
