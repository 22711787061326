export class supplier{

    constructor(values: Object = {}) {
          Object.assign(this, values);
    }
    private id : number;
    private namaSupplier : string;  
    private kodeSupplier : string;
    private nomorHp : string;
    private keterangan  : string;
    private alamat  : string;
    private email  : string;
    
    public get Id():number{
        return this.id;
    }
    public get NamaSupplier():string{
        return this.namaSupplier;
    }
    public get KodeSupplier():string{
        return this.kodeSupplier;
    }
    public get NomorHp():string{
        return this.nomorHp;
    }
    public get Keterangan():string{
        return this.keterangan;
    }
    public get Alamat():string{
        return this.alamat;
    }
    public get Email():string{
        return this.email;
    }


    public set Id (id:number){
        this.id=id;
    }
    public set NamaSupplier (namaSupplier:string){
        this.namaSupplier=namaSupplier;
    }
    public set KodeSupplier (kodeSupplier:string){
        this.kodeSupplier=kodeSupplier;
    }
    public set NomorHp (nomorHp:string){
        this.nomorHp=nomorHp;
    }
    public set Keterangan (keterangan:string){
        this.keterangan=keterangan;
    }
    public set Alamat (alamat:string){
        this.alamat=alamat;
    }
    public set Email (email:string){
        this.email=email;
    }

    }