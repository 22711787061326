<div class="col-md-12 grid-margin stretch-card">
	<div class="card">
		<div class="card-body">
			<h4 class="card-title">Daily Sales Report</h4>
			            <form class="form-sample"  [formGroup]="dailyReportForm"> 
 						 <div class="form-group row">
                            <div class="col">
                                <label>Start Date</label>
                                <div id="the-basics">
                                    <div id="datepicker-popup"  class="input-group date datepicker">
                                        <input type="text" class="form-control"  formControlName="tanggalMulai" id="tanggalMulai" name="tanggalMulai">
                                        <span class="input-group-addon input-group-append border-left">
                                          <span class="mdi mdi-calendar input-group-text"></span>
                                        </span>
                                      </div> 
                                </div>
                            </div>   
                            <div class="col">
                                <label>End Date</label>
                                <div id="the-basics">
                                    <div id="datepicker-popup2"  class="input-group date datepicker">
                                        <input type="text" class="form-control"  formControlName="tanggalAkhir" id="tanggalAkhir" name="tanggalAkhir">
                                        <span class="input-group-addon input-group-append border-left">
                                          <span class="mdi mdi-calendar input-group-text"></span>
                                        </span>
                                      </div> 
                                </div>
                            </div>                                                                                 
                            <div class="col">
                                <label style="color: transparent;">-</label>
                                <button type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" (click)="inquiryDailySales()">Search</button>
                            </div> 
                            <div class="col">
                              <label style="color: transparent;">-</label>
                              <button class="btn btn-danger mr-2" style="min-width: 100%;" (click)="printPOpdf()">Print</button>
                          </div> 
                                                        
                        </div>
		              </form>        		

                	<div class="table-responsive">
                    <table class="table table-hover" >
                      <thead>
                        <tr>
                          <th>Tr.Date</th>
                          <th>Tr.Type</th>
                          <th>Trace Num.</th>
                          <th>Items</th>
                          <th>Qty</th>
                          <th>Base Pr.</th>
                          <th>Sell Pr.</th>
                          <th>Ttl. Base</th>
                          <th>Ttl. Sell</th>
                          <th>Ttl. Profit</th>
                        </tr>
                      </thead>
                      <tbody >
                       <tr *ngFor="let listOFDailyReport of listOFDailyReport | paginate: { itemsPerPage: 10, currentPage: p }; let i= index">
                          <td>{{listOFDailyReport.TanggalTransaksi}}</td>
                          <td>{{listOFDailyReport.JenisTransaksi}}</td>
                          <td>{{listOFDailyReport.TraceNumber}}</td>
                          <td>{{listOFDailyReport.DetailBarang}}</td>
                          <td>{{listOFDailyReport.Qty  | number}}</td>
                          <td>{{listOFDailyReport.HargaModal  | number}}</td>
                          <td>{{listOFDailyReport.HargaJual  | number}}</td>
                          <td>{{listOFDailyReport.TotalModal  | number}}</td>
                          <td>{{listOFDailyReport.TotalJual  | number}}</td>
                          <td>{{listOFDailyReport.TotalProfit | number}}</td>
                       </tr> 
                       <td colspan="10" style="text-align: center;">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                     </td>                             
                      </tbody>
                    </table>
                	</div>  
		</div>
	</div>
</div>