import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators,ReactiveFormsModule } from '@angular/forms';
import  { callGlobalFuction } from "../../callGlobalFuction";
import { Router,ActivatedRoute } from "@angular/router";
import { color } from '../../model/config/color';

@Component({
  selector: 'app-colorconfig',
  templateUrl: './colorconfig.component.html',
  styleUrls: ['./colorconfig.component.css']
})
export class ColorconfigComponent implements OnInit {

  constructor(private fb: FormBuilder, private getService:callGlobalFuction,
    public router:Router,public route:ActivatedRoute) { }

  listOfWarna=[];
  serviceReturn:any;
  colorSetting :color;
  colorSettingUpdate :color;
  p:number=1;

  updateColorForm= new FormGroup({
    id: new FormControl('',[
      Validators.required  
    ]), 
    colorName: new FormControl('',[
      Validators.required
    ]),     
    colorCode: new FormControl('',[
      Validators.required
    ]),  
  });

  insertColorForm= new FormGroup({
    colorName: new FormControl('',[
      Validators.required
    ]),     
    colorCode: new FormControl('',[
      Validators.required
    ]),  
  });

  searchWarna()
  {
    let jsonInput = {};
    jsonInput['kode']="warna";
    jsonInput['categoryId']="";
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      this.listOfWarna=[];

       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn = data;
          if(this.serviceReturn.status=="S")
          {
            console.log(this.serviceReturn);
            for(let i=0;i<this.serviceReturn.listCode.length;i++)
            {
            this.colorSetting = new color();
            this.colorSetting.Id = this.serviceReturn.listCode[i].id;
            this.colorSetting.NamaWarna = this.serviceReturn.listCode[i].namaWarna;
            this.colorSetting.KodeWarna = this.serviceReturn.listCode[i].kodeWarna;
            this.listOfWarna.push(this.colorSetting);
            }
          }
          else{
            alert("List of Color Not Found");
          }
        }
      )  
  }

  openModalUpdateColor(colorModel:any)
  {
    this.colorSettingUpdate = colorModel;
    this.updateColorForm.get('id').setValue(this.colorSettingUpdate.Id);
    this.updateColorForm.get('colorName').setValue(this.colorSettingUpdate.NamaWarna);
    this.updateColorForm.get('colorCode').setValue(this.colorSettingUpdate.KodeWarna);
  }

  serviceReturn2:any;
  updateColor()
  {
    let jsonInput = {};
    jsonInput['id']=parseInt(this.updateColorForm.get('id').value);
    jsonInput['nama']=this.updateColorForm.get('colorName').value;
    jsonInput['kode']= this.updateColorForm.get('colorCode').value;
    jsonInput['tipeKode']= "warna"
    jsonInput['keterangan']= "";
    jsonInput['categoryId']= "";
    console.log(jsonInput)

    let res={}
    let stringJsonInput = JSON.stringify(jsonInput);
    console.log(stringJsonInput);

     res=this.getService.restItemsServiceGetwithParamRestItems('updateCode',stringJsonInput)
    .subscribe( 
      data => {
        this.serviceReturn2 = data;
        console.log(this.serviceReturn2)
        if(this.serviceReturn2.status=="S")
        {
          alert("Success Update Color");
          location.reload();
        }
        else{
          alert("Failed Update Price "+this.serviceReturn2.errMes);
        }
      }
    )  
  }

  serviceReturn3:any;
  insertColor()
  {
    let jsonInput = {};
    jsonInput['nama']=this.insertColorForm.get('colorName').value;
    jsonInput['kode']= this.insertColorForm.get('colorCode').value;
    jsonInput['tipeKode']= "warna"
    jsonInput['keterangan']= "";
    jsonInput['categoryId']= "";
    console.log(jsonInput)

    let res={}
    let stringJsonInput = JSON.stringify(jsonInput);
    console.log(stringJsonInput);

     res=this.getService.restItemsServiceGetwithParamRestItems('insertNewCode',stringJsonInput)
    .subscribe( 
      data => {
        this.serviceReturn3 = data;
        console.log(this.serviceReturn3)
        if(this.serviceReturn3.status=="S")
        {
          alert("Success insert Color");
          location.reload();
        }
        else{
          alert("Failed Insert Price "+this.serviceReturn3.errMes);
        }
      }
    )  
  }

  deleteConfirm(id:number)
  {
    this.deleteTempId=0;
    this.deleteTempId=id;
  }
  deleteTempId=0;
  deleteColor()
  {
    let jsonInput = {};
    jsonInput['codeId'] = this.deleteTempId;
    jsonInput['tipeKode'] = "warna";
    
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      console.log(stringJsonInput);
       res=this.getService.restItemsServiceGetwithParamRestItems('deleteCode',stringJsonInput)
      .subscribe(
        data => {
          this.serviceReturn3 = data;
          console.log(this.serviceReturn3);
          if(this.serviceReturn3.status=="S")
          {
            alert("Success Delete Color");
            location.reload();
          }
  
          else
          {
            alert("Failed Delete Color "+this.serviceReturn3.errMes);
          }
        }
      )   
  }

  ngOnInit(): void {
    this.searchWarna();
  }

}
