  
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth auth-bg-1 theme-one">
          <div class="row w-100">
            <div class="col-lg-4 mx-auto">
              <div class="auto-form-wrapper">
                <form [formGroup]="loginForm">
                  <div class="form-group">
                    <label class="label">Username</label>
                    <div class="input-group">
                      <input type="text" class="form-control" formControlName="username" id="username" name="username" placeholder="Username">
                      <div class="input-group-append">
                        <span class="input-group-text">
                          
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="label">Password</label>
                    <div class="input-group">
                      <input type="password" class="form-control" formControlName="password" id="password" name="password" placeholder="*********">
                      <div class="input-group-append">
                        <span class="input-group-text">
                          
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <button class="btn btn-primary submit-btn btn-block"  [disabled]="loginForm.invalid" (click) ="loginAction()" >Login</button>
                  </div>

                  <div class="form-group" style="color: darkred; text-align: center;">
                  	 <p id="errMessage"  *ngIf="loginForm.controls.username.invalid && loginForm.controls.username.dirty"> Username Cannot Empty </p>
                  	 <p id="errMessage"  *ngIf="loginForm.controls.password.invalid && loginForm.controls.password.dirty"> Password Cannot Empty </p> 
                  	<p>{{errMessage}}</p>
                  </div>

                </form>
                <br>
              </div>
            </div>
          </div>
        </div>
        <!-- content-wrapper ends -->
      </div>

