export class productdetailbaitmodel{

    constructor(values: Object = {}) {
          Object.assign(this, values);
    }
    private id : number;
    private detailId : number;
    private nama : string;
    private merk : string;  
    private merkId : string;        
    private ukuran : string;
    private warna : string;
    private warnaId : number;
    private barcode :string;
    private berat : string;
    private varian : string; 
    private varianId : string; 
    private tipe : string;
    private tipeId : number;
    private connectorCategoryId : number;


    public get ConnectorCategoryId():number{
        return this.connectorCategoryId;
    } 
    public get Barcode ():string{
        return this.barcode;
    }
    public get Id():number{
        return this.id;
    }
    public get DetailId():number{
        return this.detailId;
    }    
    public get Nama ():string{
        return this.nama;
    }
    public get Merk ():string{
        return this.merk;
    }
    public get MerkId ():string{
        return this.merkId;
    }    
    public get Ukuran ():string{
        return this.ukuran;
    }
    public get Berat ():string{
        return this.berat;
    }
    public get Varian ():string{
        return this.varian;
    }
    public get VarianId ():string{
        return this.varianId;
    }
    public get Warna ():string{
        return this.warna;
    }
    public get WarnaId ():number{
        return this.warnaId;
    }

    public get Tipe ():string{
        return this.tipe;
    }
    public get TipeId ():number{
        return this.tipeId;
    }    

    public set Id (id:number)
    {
        this.id=id;
    }
    public set DetailId (detailId:number)
    {
        this.detailId=detailId;
    }
    public set Nama (nama:string)
    {
        this.nama=nama;
    }
    public set Merk (merk:string)
    {
        this.merk=merk;
    }
    public set MerkId (merkId:string)
    {
        this.merkId=merkId;
    }    
    public set Ukuran (ukuran:string)
    {
        this.ukuran=ukuran;
    }
    public set Warna (warna:string)
    {
        this.warna=warna;
    }
    public set WarnaId (warnaId:number)
    {
        this.warnaId=warnaId;
    }
    public set Tipe (tipe:string)
    {
        this.tipe=tipe;
    }
    public set Berat (berat:string)
    {
        this.berat=berat;
    }
    public set Varian (varian:string)
    {
        this.varian=varian;
    } 
    public set VarianId (varianId:string)
    {
        this.varianId=varianId;
    }    
    public set TipeId (tipeId:number)
    {
        this.tipeId=tipeId;
    }
    public set ConnectorCategoryId (connectorCategoryId:number)
    {
        this.connectorCategoryId=connectorCategoryId;
    }
    public set Barcode (barcode:string)
    {
        this.barcode=barcode;
    }

    }