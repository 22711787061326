import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators,ReactiveFormsModule } from '@angular/forms';
import  { callGlobalFuction } from "../../callGlobalFuction";
import { Router,ActivatedRoute } from "@angular/router";
import { purchaseOrder } from '../../model/transaction/purchaseorder';
import { purchaseOrderDetail } from '../../model/transaction/purchaseorderdetail';
import { supplier } from '../../model/transaction/supplier';
import  { DateTimeUtil } from "../../globalfunction/dateTimeUtil";
import  { imageGenerator } from "../../globalfunction/imageGenerator";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { DatePipe } from '@angular/common';
declare var $ :any;

@Component({
  selector: 'app-purchaseorder',
  templateUrl: './purchaseorder.component.html',
  styleUrls: ['./purchaseorder.component.css']
})
export class PurchaseorderComponent implements OnInit {
  pdf:any;
  constructor(private fb: FormBuilder, private getService:callGlobalFuction,
    public router:Router,public route:ActivatedRoute,public dateTimeUtil: DateTimeUtil,
    public imageGeneratorService: imageGenerator) { }

    //inquiry purchase order
    serviceReturn:any;
    serviceReturn2:any;
    purchaseOrderHeader :purchaseOrder; 
    purchaseOrderHeader2 :purchaseOrder;    
    listOfPOHeader=[];
    supplier :supplier;
    listOfSupplier=[];    
    p:number=1;

    //inquiry PO Detail
    serviceReturn3:any;
    purchaseOrderDetail :purchaseOrderDetail;    
    listOfPODetail=[];
     purchaseOrderForm= new FormGroup({
      poNumber: new FormControl('',[
      ]),
      supplierId: new FormControl('',[
      ]),
      tanggalTransaksi: new FormControl('',[
      ]),      
      status: new FormControl('',[
      ]),                                 
    });

    inquiryPOHeader()
    {
      let jsonInput = {};
      jsonInput['nomorPO']=this.purchaseOrderForm.get('poNumber').value;
      jsonInput['tanggalTransaksi']=this.dateTimeUtil.reverseDateToBVFormat2($('#tanggalTransaksi').first().val());
      jsonInput['supplierId']=this.purchaseOrderForm.get('supplierId').value;
      jsonInput['status']=this.purchaseOrderForm.get('status').value;

      console.log(jsonInput);

      let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
       res=this.getService.restItemsServiceGetwithParamRestItems('transaction/inquiryPOHeader',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn2 = data;
          if(this.serviceReturn2.status=="S")
          {
            this.listOfPOHeader=[];
            console.log(this.serviceReturn2)
            for(let i=0;i<this.serviceReturn2.result.listPoHeader.length;i++)
            {
            this.purchaseOrderHeader = new purchaseOrder();
            this.purchaseOrderHeader.Id = this.serviceReturn2.result.listPoHeader[i].poHeaderId;
            this.purchaseOrderHeader.SupplierId = this.serviceReturn2.result.listPoHeader[i].supplierId;
            this.purchaseOrderHeader.NamaSupplier = this.serviceReturn2.result.listPoHeader[i].namaSupplier;            
            this.purchaseOrderHeader.CreateUsername = this.serviceReturn2.result.listPoHeader[i].createUsername;
            this.purchaseOrderHeader.NomorPO = this.serviceReturn2.result.listPoHeader[i].nomorPO;
            this.purchaseOrderHeader.StatusPO = this.serviceReturn2.result.listPoHeader[i].statusPO;
            this.purchaseOrderHeader.TanggalTransaksi = this.dateTimeUtil.getFormattedDate2(this.serviceReturn2.result.listPoHeader[i].tanggalTransaksi);
            this.purchaseOrderHeader.TotalHarga = this.serviceReturn2.result.listPoHeader[i].totalHarga;
            this.listOfPOHeader.push(this.purchaseOrderHeader);            
            }
          }
          else{
            alert("Purchase Order Not Found");
          }
        }
      ) 
    }

    inquirySupplierList()
    {
      let jsonInput = {};
      jsonInput['kode']="supplier";
      let res={}
        let stringJsonInput = JSON.stringify(jsonInput);
         res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
        .subscribe( 
          data => {
            this.serviceReturn = data;
            if(this.serviceReturn.status=="S")
            {
              this.listOfSupplier=[];
              console.log(this.serviceReturn)
              for(let i=0;i<this.serviceReturn.listCode.length;i++)
              {
              this.supplier = new supplier();
              this.supplier.NamaSupplier = this.serviceReturn.listCode[i].namaSupplier;
              this.supplier.KodeSupplier = this.serviceReturn.listCode[i].kodeSupplier;
              this.supplier.Alamat = this.serviceReturn.listCode[i].alamat;
              this.supplier.Email = this.serviceReturn.listCode[i].noHP;
              this.supplier.NoHP = this.serviceReturn.listCode[i].email;
              this.supplier.Keterangan = this.serviceReturn.listCode[i].keterangan;
              this.supplier.Id = this.serviceReturn.listCode[i].id;
              this.listOfSupplier.push(this.supplier);            
              }
            }
            else{
              alert("Supplier Not Found");
            }
          }
        ) 
    }

    inquiryPoDetail(poHeaderObj:any)
    {
      let jsonInput = {};
      jsonInput['poHeaderId']=poHeaderObj.Id;

      let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
       res=this.getService.restItemsServiceGetwithParamRestItems('transaction/inquiryPODetail',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn3 = data;
          if(this.serviceReturn3.status=="S")
          {

            this.purchaseOrderHeader2 = new purchaseOrder;
            this.purchaseOrderHeader2.NamaSupplier = poHeaderObj.NamaSupplier;
            this.purchaseOrderHeader2.NomorPO = poHeaderObj.NomorPO;
            this.purchaseOrderHeader2.StatusPO = poHeaderObj.StatusPO.toUpperCase();
            this.purchaseOrderHeader2.TanggalTransaksi = poHeaderObj.TanggalTransaksi;
            this.purchaseOrderHeader2.TotalHarga = poHeaderObj.TotalHarga;

            this.listOfPODetail=[];
            for(let i=0;i<this.serviceReturn3.result.listPoDetail.length;i++)
            {
            this.purchaseOrderDetail = new purchaseOrderDetail();
            this.purchaseOrderDetail.NamaMerek = this.serviceReturn3.result.listPoDetail[i].namaMerk;
            this.purchaseOrderDetail.SudahMasuk = this.serviceReturn3.result.listPoDetail[i].qtyMasuk;
            this.purchaseOrderDetail.TotalMasuk = this.serviceReturn3.result.listPoDetail[i].totalQty;
            this.purchaseOrderDetail.BelumMasuk = this.serviceReturn3.result.listPoDetail[i].qtyPending;
            this.purchaseOrderDetail.Detail1 = this.serviceReturn3.result.listPoDetail[i].detail;
            this.purchaseOrderDetail.Detail2 = this.serviceReturn3.result.listPoDetail[i].detail2;
            this.purchaseOrderDetail.NamaBarang = this.serviceReturn3.result.listPoDetail[i].namaBarang;
            this.purchaseOrderDetail.HargaDasar = this.serviceReturn3.result.listPoDetail[i].basePrice;
            this.listOfPODetail.push(this.purchaseOrderDetail);            
            }
          }
          else{
            alert("Purchase Order Not Found");
          }
        }
      ) 
    }

    
    buildTableBody(data, columns) {
      var body = [];
      console.log(this.jsonListPrint);
      data.forEach(function(row) {
          var dataRow = [];
  
          columns.forEach(function(column) {
              dataRow.push(row[column].toString());
              console.log(row[column].toString());
          })
  
          body.push(dataRow);
      });
  
      return body;
  }
    jsonListPrint=[];
    totalPO=0;
    table(data :any, columns) {
      this.jsonListPrint=[];
      let jsonPrint={};
      jsonPrint['productName']="Product Name";
      jsonPrint['totalOrder']="Quantity";
      jsonPrint['price']="Price";
      jsonPrint['ammount']="Accumulated Price";
      this.jsonListPrint.push(jsonPrint);
      for(let i=0;i<data.length;i++)
      {
        let jsonPrint={};
        jsonPrint['productName']=data[i].NamaMerek+" "+data[i].NamaBarang+" "+data[i].Detail1+" "+data[i].Detail2;
        jsonPrint['totalOrder']=this.numberWithCommas(data[i].TotalMasuk);
        jsonPrint['price']=this.numberWithCommas(data[i].HargaDasar);
        jsonPrint['ammount']=this.numberWithCommas(parseInt(data[i].TotalMasuk)*parseInt(data[i].HargaDasar));
        this.totalPO+=parseInt(data[i].TotalMasuk)*parseInt(data[i].HargaDasar);
        this.jsonListPrint.push(jsonPrint)
      }

      return {
        layout: 'lightHorizontalLines', // optional
          table: {
              headerRows: 1,
              fontSize:10, 
              widths: [240,80, 100, 100],
              body: this.buildTableBody(this.jsonListPrint, columns),
          },style:'table'
      };
  }
  
  numberWithCommas(x : number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  
   buildPdf() {
  
     var docDefinition = {
    pageSize: { width: 684, height: 1000 },
    pageOrientation: 'portrait',
    pageMargins: [60,30],
  
        styles: {
         judul: {
           
            margin:[0,5,0,15]
          },
          headerInfo: {
            fontSize: 10,
            margin:[0,0,0,2]
          },
          ttd: {
            fontSize: 10,
            margin:[0,35,0,0]
          },
          headerInfoBottom: {
            fontSize: 11,
            margin:[0,10,0,0],
            alignment:'right'
          }
           ,
          table: {
            fontSize: 9,
            alignment:'left',
            margin:[0,15,0,0]
          }
        },
  
      content:[
  
       {
        columns : [
        
        {text:'PURCHASE ORDER',fontSize: 14,
            bold: true,},{image: this.imageGeneratorService.goGetImage(),alignment:'right',width:70}],style:'judul'
        },
  
        {  columns : [
        {text:'Create Date          : '+this.purchaseOrderHeader2.TanggalTransaksi,alignment:'left',style:'headerInfo'},
        {text:'Ruko Terrace 9  Jl. Jati Utama Blok D NO.52 Suvarna Sutera',alignment:'right',style:'headerInfo'}]
        },
  
        {  columns : [
        {text:'PO No.                  : '+this.purchaseOrderHeader2.NomorPO,alignment:'left',style:'headerInfo'},
        {text:'Phone/Whatsapp : 0813-1415-9678',alignment:'right',style:'headerInfo'}]
        },  
  
        {  columns : [      
        {text:'To                          : '+this.purchaseOrderHeader2.NamaSupplier,alignment:'left',style:'headerInfo'}]
        },    
  
         //this.table(jsonParsed['itemList'], ['productId', 'productType','productName','price','totalOrder','ammount']),
         this.table(this.listOfPODetail, ['productName','totalOrder','price','ammount']),
  
       {text:'TOTAL : Rp. '+this.numberWithCommas(this.totalPO),alignment:'right',style:'headerInfoBottom'},
  
       {
  //      columns : [{text: 'DISETUJUI',alignment:'left',margin:[40,0,0,0]},{text: 'DITERIMA',alignment:'right',margin:[0,0,40,0]}],style:'ttd'
        columns : [{text: 'HORMAT KAMI',alignment:'right',margin:[0,0,40,0]}],style:'ttd'
        },
   /*    {
        columns : [{text: '(                    )',alignment:'left',margin:[35,0,0,0]}],style:'ttd2'},*/
  
  
        ],
      }
  
     
     return docDefinition;
  }

  printPOpdf()
  {
  this.pdf = pdfMake;
      this.pdf.createPdf(this.buildPdf()).open();
  }
  closePOIdTemp=0;
  closePoConfirm(id:number)
  {
    $('#exampleModal').modal('hide');
    this.closePOIdTemp=0;
    this.closePOIdTemp=id;
  }

  serviceReturn4:any;
  closePo()
  {
    let jsonInput = {};
    jsonInput['id'] = this.closePOIdTemp;
    
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      console.log(stringJsonInput);
       res=this.getService.restItemsServiceGetwithParamRestItems('transaction/forceClosePO',stringJsonInput)
      .subscribe(
        data => {
          this.serviceReturn4 = data;
          console.log(this.serviceReturn4);
          if(this.serviceReturn4.status=="S")
          {
            alert("Success Force Close PO");
            location.reload();
          }
  
          else
          {
            alert("Failed Force Close PO "+this.serviceReturn4.errMes);
          }
        }
      )   
  }

  ngOnInit(): void {
    this.inquirySupplierList();

    $('#datepicker-popup2').datepicker({
      enableOnReadonly: true,
      todayHighlight: true,
    });
  }

}
