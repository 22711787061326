export class dailyReport{

    constructor(values: Object = {}) {
          Object.assign(this, values);
    }
    private tanggalTransaksi : string;
    private jenisTransaksi : string;
    private traceNumber : string;  
    private detailBarang : string;
    private qty : number;
    private nominalDiscount : number;
    private hargaModal : number;
    private hargaJual : number;
    private totalModal: number;
    private totalJual: number;
    private totalProfit : number;

    
    public get TanggalTransaksi():string{
        return this.tanggalTransaksi;
    }
    public get JenisTransaksi():string{
        return this.jenisTransaksi;
    }
    public get TraceNumber():string{
        return this.traceNumber;
    }
    public get DetailBarang():string{
        return this.detailBarang;
    }
    public get Qty():number{
        return this.qty;
    }
    public get NominalDiscount():number{
        return this.nominalDiscount;
    }
    public get HargaModal():number{
        return this.hargaModal;
    }
    public get HargaJual():number{
        return this.hargaJual;
    }
    public get TotalModal():number{
        return this.totalModal;
    }
    public get TotalJual():number{
        return this.totalJual;
    }
    public get TotalProfit():number{
        return this.totalProfit;
    }
 
    public set TanggalTransaksi (tanggalTransaksi:string){
        this.tanggalTransaksi=tanggalTransaksi;
    }
    public set JenisTransaksi (jenisTransaksi:string){
        this.jenisTransaksi=jenisTransaksi;
    }
    public set TraceNumber (traceNumber:string){
        this.traceNumber=traceNumber;
    }
    public set DetailBarang (detailBarang:string){
        this.detailBarang=detailBarang;
    }
    public set Qty (qty:number){
        this.qty=qty;
    }
    public set NominalDiscount (nominalDiscount:number){
        this.nominalDiscount=nominalDiscount;
    }
    public set HargaModal (hargaModal:number){
        this.hargaModal=hargaModal;
    }
    public set HargaJual (hargaJual:number){
        this.hargaJual=hargaJual;
    }
    public set TotalModal (totalModal:number){
        this.totalModal=totalModal;
    }
    public set TotalJual (totalJual:number){
        this.totalJual=totalJual;
    }
    public set TotalProfit (totalProfit:number){
        this.totalProfit=totalProfit;
    }

    }