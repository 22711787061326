import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
//product
import { InquiryproductmasterComponent } from './product/inquiryproductmaster/inquiryproductmaster.component';
import { InquiryproductmasterdetailComponent } from './product/inquiryproductmasterdetail/inquiryproductmasterdetail.component';
import { InquiryproductdetailbycategoryComponent } from './product/inquiryproductdetailbycategory/inquiryproductdetailbycategory.component';
import { InsertproductComponent } from './product/insertproduct/insertproduct.component';
import { PriceconfigComponent } from './product/priceconfig/priceconfig.component';

//transaksi
import { RetailposComponent } from './transaksi/retailpos/retailpos.component';
import { PurchaseorderComponent } from './transaksi/purchaseorder/purchaseorder.component';
import { CreatepoComponent } from './transaksi/createpo/createpo.component';
import { StockadjustmentComponent } from './transaksi/stockadjustment/stockadjustment.component';
import { CreatestockadjustmentComponent } from './transaksi/createstockadjustment/createstockadjustment.component';
import { IncomingComponent } from './transaksi/incoming/incoming.component';
import { InquiryIncomingComponent } from './transaksi/inquiry-incoming/inquiry-incoming.component';
import { GrosirposComponent } from './transaksi/grosirpos/grosirpos.component';

//config
import { GroupsettingComponent } from './config/groupsetting/groupsetting.component';
import { MixitemComponent } from './config/mixitem/mixitem.component';
import { ColorconfigComponent } from './config/colorconfig/colorconfig.component';
import { SupplierconfigComponent } from './config/supplierconfig/supplierconfig.component';
import { MerkconfigComponent } from './config/merkconfig/merkconfig.component';
import { VarianumpanComponent } from './config/varianumpan/varianumpan.component';

//report
import { DailyreportComponent } from './report/dailyreport/dailyreport.component';

export const AppRoutes: Routes = [
    { path: 'home', children:[
        {path:'',component : HomeComponent},
        {path: '' , component: HeaderComponent, outlet: 'header'},
        {path: '' , component: FooterComponent, outlet: 'footer'}
    ]},
    { path: '', children:[
        {path:'',component : LoginComponent, outlet: 'login'}
    ]},
//product routing path
    { path: 'inquiryproductmaster', children:[
        {path:'',component : InquiryproductmasterComponent},
        {path: '' , component: HeaderComponent, outlet: 'header'},
        {path: '' , component: FooterComponent, outlet: 'footer'}
    ]},  
    { path: 'inquiryproductmasterdetail', children:[
        {path:'',component : InquiryproductmasterdetailComponent},
        {path: '' , component: HeaderComponent, outlet: 'header'},
        {path: '' , component: FooterComponent, outlet: 'footer'}
    ]},  
    { path: 'inquiryproductbycategorydetail', children:[
        {path:'',component : InquiryproductdetailbycategoryComponent},
        {path: '' , component: HeaderComponent, outlet: 'header'},
        {path: '' , component: FooterComponent, outlet: 'footer'}
    ]},   
    { path: 'insertproduct', children:[
        {path:'',component : InsertproductComponent},
        {path: '' , component: HeaderComponent, outlet: 'header'},
        {path: '' , component: FooterComponent, outlet: 'footer'}
    ]}, 
    { path: 'priceconfig', children:[
        {path:'',component : PriceconfigComponent},
        {path: '' , component: HeaderComponent, outlet: 'header'},
        {path: '' , component: FooterComponent, outlet: 'footer'}
    ]},     
//transaksi     
    { path: 'retailpos', children:[
        {path:'',component : RetailposComponent},
        {path: '' , component: HeaderComponent, outlet: 'header'},
        {path: '' , component: FooterComponent, outlet: 'footer'}
    ]}, 
    { path: 'purchaseorder', children:[
        {path:'',component : PurchaseorderComponent},
        {path: '' , component: HeaderComponent, outlet: 'header'},
        {path: '' , component: FooterComponent, outlet: 'footer'}
    ]}, 
    { path: 'createpo', children:[
        {path:'',component : CreatepoComponent},
        {path: '' , component: HeaderComponent, outlet: 'header'},
        {path: '' , component: FooterComponent, outlet: 'footer'}
    ]},                                                   
    { path: 'stockadjustment', children:[
        {path:'',component : StockadjustmentComponent},
        {path: '' , component: HeaderComponent, outlet: 'header'},
        {path: '' , component: FooterComponent, outlet: 'footer'}
    ]}, 
    { path: 'createstockadjustment', children:[
        {path:'',component : CreatestockadjustmentComponent},
        {path: '' , component: HeaderComponent, outlet: 'header'},
        {path: '' , component: FooterComponent, outlet: 'footer'}
    ]},  
    { path: 'incoming', children:[
        {path:'',component : IncomingComponent},
        {path: '' , component: HeaderComponent, outlet: 'header'},
        {path: '' , component: FooterComponent, outlet: 'footer'}
    ]},    
    { path: 'inquiryincoming', children:[
        {path:'',component : InquiryIncomingComponent},
        {path: '' , component: HeaderComponent, outlet: 'header'},
        {path: '' , component: FooterComponent, outlet: 'footer'}
    ]},   
    { path: 'grosirpos', children:[
        {path:'',component : GrosirposComponent},
        {path: '' , component: HeaderComponent, outlet: 'header'},
        {path: '' , component: FooterComponent, outlet: 'footer'}
    ]},   
    
    //config
    { path: 'groupsetting', children:[
        {path:'',component : GroupsettingComponent},
        {path: '' , component: HeaderComponent, outlet: 'header'},
        {path: '' , component: FooterComponent, outlet: 'footer'}
    ]},    
    { path: 'mixitem', children:[
        {path:'',component : MixitemComponent},
        {path: '' , component: HeaderComponent, outlet: 'header'},
        {path: '' , component: FooterComponent, outlet: 'footer'}
    ]},  
    { path: 'colorconfig', children:[
        {path:'',component : ColorconfigComponent},
        {path: '' , component: HeaderComponent, outlet: 'header'},
        {path: '' , component: FooterComponent, outlet: 'footer'}
    ]},    
    { path: 'supplierconfig', children:[
        {path:'',component : SupplierconfigComponent},
        {path: '' , component: HeaderComponent, outlet: 'header'},
        {path: '' , component: FooterComponent, outlet: 'footer'}
    ]},         
    { path: 'merkconfig', children:[
        {path:'',component : MerkconfigComponent},
        {path: '' , component: HeaderComponent, outlet: 'header'},
        {path: '' , component: FooterComponent, outlet: 'footer'}
    ]},   
    { path: 'varianconfig', children:[
        {path:'',component : VarianumpanComponent},
        {path: '' , component: HeaderComponent, outlet: 'header'},
        {path: '' , component: FooterComponent, outlet: 'footer'}
    ]},   
    //report
    { path: 'dailyreport', children:[
        {path:'',component : DailyreportComponent},
        {path: '' , component: HeaderComponent, outlet: 'header'},
        {path: '' , component: FooterComponent, outlet: 'footer'}
    ]}, 
      
];
export const AppComponents: any = [
    HomeComponent
];