<div class="col-md-12 grid-margin stretch-card">
	<div class="card">
		<div class="card-body">
			<h4 class="card-title">Product Group</h4>
			            <form class="form-sample"  [formGroup]="groupForm"> 
 						 <div class="form-group row">
                            <div class="col">
                                <label>Group Name</label>
                                <div id="the-basics">
                                    <input class="typeahead"  id="groupName" formControlName="groupName" 
                                    name="groupName" type="text" placeholder="Group Name"> </div>
                            </div>  
                            <div class="col">
                              <label style="color: transparent;">-</label>
                              <button type="submit" (click)="inquiryGroup()" class="btn btn-warning mr-2" style="min-width: 100%;">Search</button>
                          </div>
                          <div class="col">
                            <label style="color: transparent;">-</label>
                            <button type="submit" style="cursor: pointer;" data-toggle="modal" 
                            data-target="#exampleModal" class="btn btn-success mr-2" style="min-width: 100%;">Add New</button>
                        </div>                                                           
                        </div>
		              </form>        		

                	<div class="table-responsive">
                    <table class="table table-hover" >
                      <thead>
                        <tr>
                          <th>Group Name</th>
                          <th>Group Description</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody >
                       <tr *ngFor="let listOfGroup of listOfGroup | paginate: { itemsPerPage: 10, currentPage: p }; let i= index"
                       style="cursor: pointer;">
                          <td>{{listOfGroup.GroupName}}</td>
                          <td>{{listOfGroup.GroupDescription}}</td>
                          <td><i id="icon" class="link-icon mdi mdi-delete-forever" style="color: darkred"
                            data-toggle="modal" data-target="#deleteModal" (click)="deleteGroup(listOfGroup.Id)"></i> &nbsp;
                            <i id="icon" class="link-icon mdi mdi-playlist-check" style="color: darkgreen" 
                            routerLink="/mixitem" [queryParams]="{ groupId:listOfGroup.Id}"></i>
                          </td>
                       </tr> 
                       <td colspan="3" style="text-align: center;">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                     </td>                             
                      </tbody>
                    </table>
                	</div>                
		</div>
	</div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Create New Group</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
        <div class="modal-body" style="overflow-y: auto; max-height:400px;">

          <form class="form-sample"  [formGroup]="groupFormCreate"> 			         
              <div class="form-group row">
                <div class="col">
                    <label>Group Name</label>
                    <div id="the-basics">
                        <input class="typeahead"  id="groupName" formControlName="groupName" 
                        name="groupName" type="text" placeholder="Group Name"> </div>
                </div>  
                <div class="col">
                    <label>Group Description</label>
                    <div id="the-basics">
                      <input class="typeahead"  id="groupDescription" formControlName="groupDescription" 
                      name="groupDescription" type="text" placeholder="Group Description"> </div>
                    </div>                                       
                </div>                                                                          
        </form> 
        </div>          
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-dismiss="modal" (click)="createGroup()">Create</button>
        <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Delete Product Group</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
        <div class="modal-body" style="overflow-y: auto; max-height:400px;">
            <p>Delete This Group ?</p>
        </div>          
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-dismiss="modal" (click)="deleteConfirm()">Delete</button>
        <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>