import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators,ReactiveFormsModule } from '@angular/forms';
import  { callGlobalFuction } from "../../callGlobalFuction";
import { Router,ActivatedRoute } from "@angular/router";
import { supplier } from '../../model/transaction/supplier';
import  { DateTimeUtil } from "../../globalfunction/dateTimeUtil";
import { productmastermodel } from '../../model/product/productmaster';
import { productdetailjoranmodel } from '../../model/product/productDetailJoran';
import { productdetailreelmodel } from '../../model/product/productDetailReel';
import { productdetaillinemodel } from '../../model/product/productDetailLine';
import { productdetailkailmodel } from '../../model/product/productDetailKail';
import { productdetailaccmodel } from '../../model/product/productDetailAcc';
import { purchaseOrder } from '../../model/transaction/purchaseorder';
import { stock } from '../../model/product/stock';

@Component({
  selector: 'app-priceconfig',
  templateUrl: './priceconfig.component.html',
  styleUrls: ['./priceconfig.component.css']
})
export class PriceconfigComponent implements OnInit {

  constructor(private fb: FormBuilder, private getService:callGlobalFuction,
    public router:Router,public route:ActivatedRoute,public dateTimeUtil: DateTimeUtil) { }

    serviceReturn:any;
    priceModelBase :stock = new stock();
    listPriceModelBase=[];
    priceModelRetail :stock = new stock();
    listPriceModelRetail=[];
    priceModelGrosir :stock = new stock();
    listPriceModelGrosir=[]
    productDetailId:string;
    categoryId:string;

    inquiryProdiuctPrice(categoryId:string, productDetailId:string)
    {
      
    let jsonInput = {};
    jsonInput['categoryId']=categoryId;
    jsonInput['productDetailId']=productDetailId; 
    console.log("INI REQUEST"+JSON.stringify(jsonInput))
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      
       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryProductPrice',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn = data;
          if(this.serviceReturn.status=="S")
          {
            console.log(this.serviceReturn)
            this.listPriceModelBase=[];
            for(let i=0;i<this.serviceReturn.result.basePriceList.length;i++)
            {
              this.priceModelBase = new stock();
              this.priceModelBase.NomorPo=this.serviceReturn.result.basePriceList[i].nomorPO;
              this.priceModelBase.BasePrice=this.serviceReturn.result.basePriceList[i].base_price;
              this.priceModelBase.Stock=this.serviceReturn.result.basePriceList[i].stock;
              this.listPriceModelBase.push(this.priceModelBase);
            }
            for(let i=0;i<this.serviceReturn.result.sellPriceList[0].priceList.length;i++)
            {
              this.priceModelRetail = new stock();
              this.priceModelRetail.SellPrice=this.serviceReturn.result.sellPriceList[0].priceList[i].sellPrice;
              this.priceModelRetail.NamaHarga=this.serviceReturn.result.sellPriceList[0].priceList[i].namaHarga;
              this.priceModelRetail.MinQty=this.serviceReturn.result.sellPriceList[0].priceList[i].minQty;
              this.priceModelRetail.MinAllowQty=this.serviceReturn.result.sellPriceList[0].priceList[i].allowMinQty;
              this.priceModelRetail.PriceDetailId= this.serviceReturn.result.sellPriceList[0].priceList[i].priceDetailId;
              this.listPriceModelRetail.push(this.priceModelRetail);
            }
            for(let i=0;i<this.serviceReturn.result.sellPriceList[1].priceList.length;i++)
            {
              this.priceModelGrosir = new stock();
              this.priceModelGrosir.SellPrice=this.serviceReturn.result.sellPriceList[1].priceList[i].sellPrice;
              this.priceModelGrosir.NamaHarga=this.serviceReturn.result.sellPriceList[1].priceList[i].namaHarga;
              this.priceModelGrosir.MinQty=this.serviceReturn.result.sellPriceList[1].priceList[i].minQty;
              this.priceModelGrosir.MinAllowQty=this.serviceReturn.result.sellPriceList[1].priceList[i].allowMinQty;
              this.priceModelGrosir.PriceDetailId=this.serviceReturn.result.sellPriceList[1].priceList[i].priceDetailId;
              this.listPriceModelGrosir.push(this.priceModelGrosir);
            }            

          }
          else{
            alert("Inquiry Price Detail Failed");
          }
        }
      )
    }

    priceModelUpdate =new stock()
    serviceReturn2:any;
    updatePriceForm= new FormGroup({
      price: new FormControl('',[
        Validators.required  
      ]), 
      allowMix: new FormControl('',[
        Validators.required
      ]),     
      allowMinQty: new FormControl('',[
        Validators.required
      ]), 
      minQty: new FormControl('',[
        Validators.required
      ]), 
      namaHarga: new FormControl('',[
        Validators.required
      ]),          
    });

    inquiryPriceUpdate(priceModel:any)
    {
      this.priceModelUpdate = priceModel;
      this.updatePriceForm.get('price').setValue(priceModel.SellPrice);
      this.updatePriceForm.get('allowMix').setValue(priceModel.SellPrice);
      this.updatePriceForm.get('allowMinQty').setValue(priceModel.MinAllowQty);
      this.updatePriceForm.get('minQty').setValue(priceModel.MinQty);
      this.updatePriceForm.get('namaHarga').setValue(priceModel.NamaHarga);
    }

    updatePrice()
    {
      let jsonInput = {};
      jsonInput['categoryId']=parseInt(this.categoryId);
      jsonInput['productDetailId']=parseInt(this.productDetailId);
      jsonInput['newPrice']= parseInt(this.updatePriceForm.get('price').value);
      jsonInput['userId']=sessionStorage.getItem('userid');
      jsonInput['kodeTransaksi']=7;
      jsonInput['priceDetailId']=this.priceModelUpdate.PriceDetailId;
      jsonInput['allowMix']=parseInt(this.updatePriceForm.get('allowMix').value);
      jsonInput['allowMinQty']=parseInt(this.updatePriceForm.get('allowMinQty').value);
      jsonInput['minQty']=parseInt(this.updatePriceForm.get('minQty').value);

      let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      console.log(stringJsonInput);

       res=this.getService.restItemsServicePutRestItems('warehouse/updatePriceDetail',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn2 = data;
          console.log(this.serviceReturn2)
          if(this.serviceReturn2.status=="S")
          {
            alert("Success Update Price");
            location.reload();
          }
          else{
            alert("Failed Update Price");
          }
        }
      )  
    }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
    if(params!=null){
      this.inquiryProdiuctPrice(params.categoryId,params.productDetailId);
      this.productDetailId=params.productDetailId;
      this.categoryId=params.categoryId;
      }
      else
      {
        alert("Cannot Show Item Detail");    
      }
    });
  }

}
