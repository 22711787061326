<nav class="navbar horizontal-layout col-lg-12 col-12 p-0">
      <div class="container d-flex flex-row nav-top">
        <div class="text-center navbar-brand-wrapper d-flex align-items-top">
          <a class="navbar-brand brand-logo" href="">
            <img src="assets/gp.png" alt="logo" /> </a>
          <a class="navbar-brand brand-logo-mini" href="">
            <img src="assets/logo-mini.svg" alt="logo" /> </a>
        </div>
        <div class="navbar-menu-wrapper d-flex align-items-center">
          <ul class="navbar-nav ml-auto">
           
          
            <li class="nav-item dropdown d-none d-xl-inline-block">
              <a class="nav-link dropdown-toggle" id="UserDropdown" href="#" data-toggle="dropdown" aria-expanded="false">Hello, {{loginUser}}
                <img class="img-xs rounded-circle ml-3" src="assets/faces/face1.jpg" alt="Profile image"> </a>
              <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="UserDropdown">
                <a class="dropdown-item" (click)="logout()"> Sign Out </a>
              </div>
            </li>
          </ul>
          <button class="navbar-toggler align-self-center" type="button" data-toggle="minimize">
            <span class="mdi mdi-menu"></span>
          </button>
        </div>
      </div>
      <div class="nav-bottom" style="background-color: #0C754B">
        <div class="container">
          <ul class="nav page-navigation" style="background-color: #0C754B">
            <!-- <li class="nav-item">
              <a href="index.html" class="nav-link">
                <i class="link-icon mdi mdi-airplay"></i>
                <span class="menu-title">Dashboard</span>
              </a>
            </li> -->
            <li class="nav-item mega-menu">
              <a class="nav-link">
                <i class="link-icon mdi mdi-book"></i>
                <span class="menu-title">Main Menu</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="submenu">
                <div class="col-group-wrapper row">
                  <div class="col-group col-md-3">
                    <p class="category-heading">Master Product</p>
                    <ul class="submenu-item">
                      <li class="nav-item">
                        <a class="nav-link" style="cursor: pointer;" routerLink="/insertproduct" >Insert Product</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" style="cursor: pointer;" routerLink="/inquiryproductmaster" >Quick Search</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" style="cursor: pointer;" routerLink="/inquiryproductbycategorydetail" >Detail Search</a>
                      </li>                      
                    </ul>
                  </div>
                  <div class="col-group col-md-3">
                    <p class="category-heading">Transaction</p>
                    <ul class="submenu-item">
                      <li class="nav-item">
                        <a class="nav-link" style="cursor: pointer;" routerLink="/retailpos" >Retail Transaction</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" style="cursor: pointer;" routerLink="/grosirpos" >Wholesale Transaction</a>
                      </li>                      
                      <li class="nav-item">
                        <a class="nav-link" style="cursor: pointer;" routerLink="/purchaseorder" >Purchase Order</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" style="cursor: pointer;" routerLink="/inquiryincoming" >Incoming</a>
                      </li>  
                      <li class="nav-item">
                        <a class="nav-link" style="cursor: pointer;" routerLink="/stockadjustment" >Stock Adjustment</a>
                      </li>                                                                  
                    </ul>
                  </div>
                  <div class="col-group col-md-3">
                    <p class="category-heading">Report</p>
                    <ul class="submenu-item">
                      <li class="nav-item">
                        <a class="nav-link" style="cursor: pointer;" routerLink="/retailpos" >Sales Report</a>
                      </li>   
                      <li class="nav-item">
                        <a class="nav-link" style="cursor: pointer;" routerLink="/retailpos" >Transaction Report</a>
                      </li>                                                                
                    </ul>
                  </div>                  
                </div>
              </div>
            </li>   

            <li class="nav-item mega-menu">
              <a class="nav-link">
                <i class="link-icon mdi mdi-apple-safari"></i>
                <span class="menu-title">Configuration</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="submenu">
                <div class="col-group-wrapper row">
                  <div class="col-group col-md-3">
                    <p class="category-heading">Product</p>
                    <ul class="submenu-item">
                      <li class="nav-item">
                        <a class="nav-link" style="cursor: pointer;" routerLink="/groupsetting" >Product Group</a>
                      </li>            
                    </ul>
                  </div>
                  <div class="col-group col-md-3">
                    <p class="category-heading">Other Configuration</p>
                    <ul class="submenu-item">
                      <li class="nav-item">
                        <a class="nav-link" style="cursor: pointer;" routerLink="/colorconfig" >Color</a>
                      </li> 
                      <li class="nav-item">
                        <a class="nav-link" style="cursor: pointer;" routerLink="/supplierconfig" >Supplier</a>
                      </li>   
                      <li class="nav-item">
                        <a class="nav-link" style="cursor: pointer;" routerLink="/merkconfig" >Brand</a>
                      </li>                                  
                    </ul>
                  </div>
                  <div class="col-group col-md-3">
                    <p class="category-heading">Other Configuration</p>
                    <ul class="submenu-item">
                      <li class="nav-item">
                        <a class="nav-link" style="cursor: pointer;" routerLink="/varianconfig" >Varian</a>
                      </li>                               
                    </ul>
                  </div>
                </div>
              </div>
          
            </li>     
            <li class="nav-item mega-menu">
              <a class="nav-link">
                <i class="link-icon mdi mdi-apple-safari"></i>
                <span class="menu-title">Report</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="submenu">
                <div class="col-group-wrapper row">
                  <div class="col-group col-md-3">
                    <p class="category-heading">Sales</p>
                    <ul class="submenu-item">
                      <li class="nav-item">
                        <a class="nav-link" style="cursor: pointer;" routerLink="/dailyreport" >Daily Sales</a>
                      </li>            
                    </ul>
                  </div>

                </div>
              </div>
            </li>     

          </ul>
        </div>
      </div>
</nav>