import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators,ReactiveFormsModule } from '@angular/forms';
import  { callGlobalFuction } from "../../callGlobalFuction";
import { Router,ActivatedRoute } from "@angular/router";
import  { DateTimeUtil } from "../../globalfunction/dateTimeUtil";
import { supplier } from '../../model/transaction/supplier';
import { incoming } from '../../model/transaction/incoming';

declare var $ :any;

@Component({
  selector: 'app-inquiry-incoming',
  templateUrl: './inquiry-incoming.component.html',
  styleUrls: ['./inquiry-incoming.component.css']
})
export class InquiryIncomingComponent implements OnInit {

  constructor(private fb: FormBuilder, private getService:callGlobalFuction,
    public router:Router,public route:ActivatedRoute,public dateTimeUtil: DateTimeUtil) { }



  
  incomingForm= new FormGroup({
    nomorSJ: new FormControl('',[
    ]),
    diterimaOleh: new FormControl('',[
    ]),
    tanggalTransaksi: new FormControl('',[
    ]),   
    supplierId: new FormControl('',[
    ]),                                 
  });
  p:number=1;
  serviceReturn:any;
  supplier :supplier;
  listOfSupplier=[];    

  serviceReturn2:any;
  incoming :incoming;
  incomingList=[];

  serviceReturn3:any;
  incomingDetail :incoming;
  incomingDetailList=[];

  inquirySupplierList()
  {
    let jsonInput = {};
    jsonInput['kode']="supplier";
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn = data;
          if(this.serviceReturn.status=="S")
          {
            this.listOfSupplier=[];
            console.log(this.serviceReturn)
            for(let i=0;i<this.serviceReturn.listCode.length;i++)
            {
            this.supplier = new supplier();
            this.supplier.NamaSupplier = this.serviceReturn.listCode[i].namaSupplier;
            this.supplier.KodeSupplier = this.serviceReturn.listCode[i].kodeSupplier;
            this.supplier.Alamat = this.serviceReturn.listCode[i].alamat;
            this.supplier.Email = this.serviceReturn.listCode[i].noHP;
            this.supplier.NoHP = this.serviceReturn.listCode[i].email;
            this.supplier.Keterangan = this.serviceReturn.listCode[i].keterangan;
            this.supplier.Id = this.serviceReturn.listCode[i].id;
            this.listOfSupplier.push(this.supplier);            
            }
          }
          else{
            alert("Supplier Not Found");
          }
        }
      ) 
  }

  inquiryIncoming()
  {
    let jsonInput = {};
    jsonInput['nomorSuratJalan'] = this.incomingForm.get('nomorSJ').value;
    if(this.incomingForm.get('supplierId').value==0 || this.incomingForm.get('supplierId').value=='')
    {
      jsonInput['supplierId'] = 0;
    }
    else{
    jsonInput['supplierId'] = parseInt(this.incomingForm.get('supplierId').value);
    }
    jsonInput['receivedBy'] = this.incomingForm.get('diterimaOleh').value;
    jsonInput['tanggalTransaksi'] = this.dateTimeUtil.reverseDateToBVFormat2($('#tanggalTransaksi').first().val());

    console.log(jsonInput)

    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);

       res=this.getService.restItemsServiceGetwithParamRestItems('transaction/inquiryIncomingTransaction',stringJsonInput)
      .subscribe(
        data => {
          this.serviceReturn2 = data;
          console.log(this.serviceReturn2);
          this.incomingList=[];
          if(this.serviceReturn2.status=="S")
          {
            for(let i=0;i<this.serviceReturn2.result.incomingHeader.length;i++)
            {
              this.incoming= new incoming();
              this.incoming.Id = this.serviceReturn2.result.incomingHeader[i].incomingHeaderId;
              this.incoming.NamaSupplier = this.serviceReturn2.result.incomingHeader[i].supplierName;
              this.incoming.NomorSj = this.serviceReturn2.result.incomingHeader[i].nomorSuratJalan;
              this.incoming.ReceivedBy = this.serviceReturn2.result.incomingHeader[i].receivedBy;
              this.incoming.TanggalTransaksi = this.dateTimeUtil.getFormattedDate2(this.serviceReturn2.result.incomingHeader[i].tanggalTransaksi);
              this.incomingList.push(this.incoming);
            }
          }
          else
          {
            alert("Inquiry Incoming Failed");
          }
        }
      )  
  }

  inquiryIncomingDetail(incomingHeaderId:any)
  {
    let jsonInput = {};
    jsonInput['incomingHeaderId'] = incomingHeaderId;
    let res={}
    let stringJsonInput = JSON.stringify(jsonInput);    
     res=this.getService.restItemsServiceGetwithParamRestItems('transaction/inquiryIncomingTransactionDetail',stringJsonInput)
    .subscribe(
      data => {
        this.serviceReturn3 = data;
        console.log(this.serviceReturn3);
        
        if(this.serviceReturn3.status=="S")
        {
          this.incomingDetailList=[];
           for(let i=0;i<this.serviceReturn3.result.incomingDetail.length;i++)
           {
             this.incomingDetail= new incoming();
             this.incomingDetail.NamaSupplier = this.serviceReturn3.result.incomingDetail[i].productCompact.supplierName;
             this.incomingDetail.Qty = this.serviceReturn3.result.incomingDetail[i].qty;
             this.incomingDetail.Product = this.serviceReturn3.result.incomingDetail[i].productCompact.namaMerk+" "+
             this.serviceReturn3.result.incomingDetail[i].productCompact.namaMerk+" "+
             this.serviceReturn3.result.incomingDetail[i].productCompact.detail+" "+
             this.serviceReturn3.result.incomingDetail[i].productCompact.detail2;
             this.incomingDetailList.push(this.incomingDetail);
           }
        }
        else
        {
          alert("Inquiry Incoming Failed");
        }
      }
    )  
    
  }

  ngOnInit(): void {
    this.inquirySupplierList();
    $('#datepicker-popup2').datepicker({
      enableOnReadonly: true,
      todayHighlight: true,
    });
  }


}
