export class brand{

    constructor(values: Object = {}) {
          Object.assign(this, values);
    }
    private id : number;
    private namaMerek : string;  
    private kodeMerek : string;
    
    
    public get Id():number{
        return this.id;
    }
    public get NamaMerek():string{
        return this.namaMerek;
    }
    public get KodeMerek():string{
        return this.kodeMerek;
    }


    public set Id (id:number){
        this.id=id;
    }
    public set NamaMerek (namaMerek:string){
        this.namaMerek=namaMerek;
    }
    public set KodeMerek (kodeMerek:string){
        this.kodeMerek=kodeMerek;
    }
    }