import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators,ReactiveFormsModule } from '@angular/forms';
import  { callGlobalFuction } from "../../callGlobalFuction";
import { Router,ActivatedRoute } from "@angular/router";
import { productdetailjoranmodel } from '../../model/product/productDetailJoran';
import { productdetailreelmodel } from '../../model/product/productDetailReel';
import { productdetaillinemodel } from '../../model/product/productDetailLine';
import { productdetailkailmodel } from '../../model/product/productDetailKail';
import { productdetailaccmodel } from '../../model/product/productDetailAcc';
import { productdetailbaitmodel } from '../../model/product/productDetailBait';
import  { DateTimeUtil } from "../../globalfunction/dateTimeUtil";
import { stock } from '../../model/product/stock';
import { ThrowStmt } from '@angular/compiler';
declare var $: any; 
@Component({
  selector: 'app-inquiryproductmasterdetail',
  templateUrl: './inquiryproductmasterdetail.component.html',
  styleUrls: ['./inquiryproductmasterdetail.component.css']
})
export class InquiryproductmasterdetailComponent implements OnInit {

  constructor(private fb: FormBuilder, private getService:callGlobalFuction,
    public router:Router,public route:ActivatedRoute,public dateTimeUtil: DateTimeUtil) { }

  productTypeforHTML="";
  serviceReturn:any;
  serviceReturn3:any;
  listOfWarna=[];
  jsonWarna={};
  serviceReturn4:any;
  listOfMerk=[]; 
  jsonMerk={}; 

  serviceReturn6:any;

  //for list tipe joran
  productDetailJoran :productdetailjoranmodel;
  listOfProductDetailJoran=[];
  listOfBahanJoran=[];
  jsonBahanJoran={};
  serviceReturn2:any;
  listOfTipeJoran=[];
  jsonTipeJoran={};
  serviceReturn5:any;


    // model untuk joran
    insertProductJoranForm= new FormGroup({
      nama: new FormControl('',[
        Validators.required  
      ]), 
      merk: new FormControl('',[
        Validators.required
      ]),     
      ukuran: new FormControl('',[
        Validators.required
      ]), 
      minLbs: new FormControl('',[
      ]),
      maxLbs: new FormControl('',[
      ]),
      minPE: new FormControl('',[
      ]),
      maxPE: new FormControl('',[
      ]),    
      ring: new FormControl('',[
        Validators.required
      ]),
      tahunProduksi: new FormControl('',[
      ]),
      warna: new FormControl('',[
        Validators.required
      ]),
      bahan: new FormControl('',[
        Validators.required
      ]),
      tipe: new FormControl('',[
        Validators.required
      ]), 
      id: new FormControl('',[
        Validators.required
      ]), 
      detailId: new FormControl('',[
        Validators.required
      ]),                    
    });
    // end of model untuk joran

   //for list tipe reel
   productDetailReel :productdetailreelmodel;
   listOfProductDetailReel=[];
   serviceReturn7:any;
   listOfTipeReel=[];    
   jsonTipeReel={};

     // model untuk reel
  insertProductReelForm= new FormGroup({
    nama: new FormControl('',[
      Validators.required  
    ]), 
    merk: new FormControl('',[
      Validators.required
    ]),     
    ukuran: new FormControl('',[
      Validators.required
    ]), 
    ballBearing: new FormControl('',[
      Validators.required
    ]), 
    tipePh: new FormControl('',[
      Validators.required
    ]),
    tipeWay: new FormControl('',[
      Validators.required
    ]),
    warna: new FormControl('',[
      Validators.required
    ]),
    tipe: new FormControl('',[
      Validators.required
    ]), 
    id: new FormControl('',[
      Validators.required
    ]), 
    detailId: new FormControl('',[
      Validators.required
    ]),                     
  });
  // end of model untuk reel

    // model untuk line
    insertProductLineForm= new FormGroup({
      nama: new FormControl('',[
        Validators.required  
      ]), 
      merk: new FormControl('',[
        Validators.required
      ]),     
      ukuran: new FormControl('',[
        Validators.required
      ]), 
      satuanUkur: new FormControl('',[
        Validators.required
      ]), 
      kekuatan: new FormControl('',[
      ]),
      pe: new FormControl('',[
      ]),
      diameter: new FormControl('',[
      ]),    
      lbs: new FormControl('',[
      ]),
      warna: new FormControl('',[
        Validators.required
      ]),
      tipe: new FormControl('',[
        Validators.required
      ]),
      tipeNo: new FormControl('',[
      ]),
      tipeFluoroCarbon: new FormControl('',[
        Validators.required
      ]),
      tipeConnector: new FormControl('',[
        Validators.required
      ]), 
      id: new FormControl('',[
        Validators.required
      ]), 
      detailId: new FormControl('',[
        Validators.required
      ]),                      
    });
    // end of model untuk line  
   //for list tipe line
   productDetailLine :productdetaillinemodel;
   listOfProductDetailLine=[];
   serviceReturn8:any;
   listOfTipeLine=[];    
   jsonTipeLine={};

     // model untuk kail
     insertProductHookForm= new FormGroup({
      nama: new FormControl('',[
        Validators.required  
      ]), 
      merk: new FormControl('',[
        Validators.required
      ]),     
      ukuran: new FormControl('',[
        Validators.required
      ]), 
      id: new FormControl('',[
        Validators.required
      ]), 
      detailId: new FormControl('',[
        Validators.required
      ]),          
    });
    // end of model untuk kail    

  //for list tipe kail
  productDetailKail :productdetailkailmodel;
  listOfProductDetailKail=[];

  //for list tipe acc
  productDetailAcc :productdetailaccmodel;
  listOfProductDetailAcc=[];
  serviceReturn9:any;
  listOfTipeAcc=[];    
  jsonTipeAcc={};
    // model untuk acc
    insertProductAccForm= new FormGroup({
      nama: new FormControl('',[
        Validators.required  
      ]), 
      merk: new FormControl('',[
        Validators.required
      ]),
      warna: new FormControl('',[
        Validators.required
      ]),           
      ukuran: new FormControl('',[
        Validators.required
      ]), 
      id: new FormControl('',[
        Validators.required
      ]), 
      detailId: new FormControl('',[
        Validators.required
      ]), 
      tipe: new FormControl('',[
        Validators.required
      ]),
      size: new FormControl('',[
      ]),
      lbs: new FormControl('',[
      ]),
      liter: new FormControl('',[
      ])                                   
    });
    // end of model untuk acc    

      //for list tipe bait
  productDetailBait :productdetailbaitmodel;
  listOfProductDetailBait=[];
  serviceReturn12:any; //tipe
  serviceReturn13:any; //varian
  listOfTipeBait=[];    
  jsonTipeBait={};
  listOfVarianBait=[];    
  jsonVarianBait={};

    insertProductBaitForm= new FormGroup({
      nama: new FormControl('',[
        Validators.required  
      ]), 
      merk: new FormControl('',[
        Validators.required
      ]),
      warna: new FormControl('',[
        Validators.required
      ]),           
      ukuran: new FormControl('',[
      ]),           
      berat: new FormControl('',[
      ]),           
      tipe: new FormControl('',[
      ]),           
      varian: new FormControl('',[
      ]),           
      id: new FormControl('',[
        Validators.required
      ]),           
      detailId: new FormControl('',[
        Validators.required
      ])    
         
              
    });
    // end of model untuk bait  

  

  searchbyname(id:string, categoryId:string, merkId:string)
  {

    let jsonInput = {};
    
    let res={}
    jsonInput['productId'] = parseInt(id);
    jsonInput['kategoriId'] = parseInt(categoryId);
    jsonInput['merkId'] = parseInt(merkId);
    let stringJsonInput = JSON.stringify(jsonInput);
    console.log(stringJsonInput)
      
       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryProductDetailAllByCategoryAndBrand',stringJsonInput)
      .subscribe(
        data => {
          this.serviceReturn = data;
          console.log(this.serviceReturn)
          if(this.serviceReturn.status=="S")
          {
            console.log(this.serviceReturn)
            //inquiry detail untuk joran
            if(this.serviceReturn.categoryId==1 && this.serviceReturn.listProduct.listJoranDetail.length>0)
            {
              this.productTypeforHTML="Rod";
              this.listOfProductDetailJoran=[];
              for(let i=0;i<this.serviceReturn.listProduct.listJoranDetail.length;i++)
              {
              this.productDetailJoran = new productdetailjoranmodel();
              this.productDetailJoran.Ukuran = this.serviceReturn.listProduct.listJoranDetail[i].ukuran;
              this.productDetailJoran.Barcode = this.serviceReturn.listProduct.listJoranDetail[i].barcode;
              this.productDetailJoran.Warna = this.serviceReturn.listProduct.listJoranDetail[i].nama_warna;
              this.productDetailJoran.WarnaId = this.serviceReturn.listProduct.listJoranDetail[i].warna_joran_id;
              this.productDetailJoran.Ring = this.serviceReturn.listProduct.listJoranDetail[i].ring;
              this.productDetailJoran.Bahan = this.serviceReturn.listProduct.listJoranDetail[i].nama_bahan;
              this.productDetailJoran.BahanId = this.serviceReturn.listProduct.listJoranDetail[i].bahan_joran_id;
              this.productDetailJoran.Nama = this.serviceReturn.listProduct.listJoranDetail[i].nama_barang;
              this.productDetailJoran.TahunProduksi = this.serviceReturn.listProduct.listJoranDetail[i].tahun_produksi;
              this.productDetailJoran.MinLbs = this.serviceReturn.listProduct.listJoranDetail[i].lbs_min;  
              this.productDetailJoran.MaxLbs = this.serviceReturn.listProduct.listJoranDetail[i].nama_tipe_joran; 
              this.productDetailJoran.Merk = this.serviceReturn.listProduct.listJoranDetail[i].nama_merk; 
              this.productDetailJoran.MerkId = this.serviceReturn.listProduct.listJoranDetail[i].merk_id;
              this.productDetailJoran.MaxLbs = this.serviceReturn.listProduct.listJoranDetail[i].lbs_max;  
              this.productDetailJoran.MaxPE = this.serviceReturn.listProduct.listJoranDetail[i].pe_max;  
              this.productDetailJoran.MinPE = this.serviceReturn.listProduct.listJoranDetail[i].pe_min; 
              this.productDetailJoran.Tipe = this.serviceReturn.listProduct.listJoranDetail[i].nama_tipe;   
              this.productDetailJoran.TipeId = this.serviceReturn.listProduct.listJoranDetail[i].tipe_joran_id;
              this.productDetailJoran.DetailId = this.serviceReturn.listProduct.listJoranDetail[i].productDetailId; 
              this.productDetailJoran.Id = this.serviceReturn.listProduct.listJoranDetail[i].productId;
              this.listOfProductDetailJoran.push(this.productDetailJoran);
              }
            }
            //end of inquiry detail untuk joran

             //inquiry detail untuk reel
             else if(this.serviceReturn.categoryId==2 && this.serviceReturn.listProduct.listReelDetail.length>0)
             {
               this.productTypeforHTML="Reel";
               this.listOfProductDetailReel=[];
               for(let i=0;i<this.serviceReturn.listProduct.listReelDetail.length;i++)
               {
               this.productDetailReel = new productdetailreelmodel();
               console.log(this.serviceReturn)
               this.productDetailReel.Ukuran = this.serviceReturn.listProduct.listReelDetail[i].ukuran;                                                                 
               this.productDetailReel.WarnaId = this.serviceReturn.listProduct.listReelDetail[i].warna_reel_id;
               this.productDetailReel.PhType = this.serviceReturn.listProduct.listReelDetail[i].ph_type;
               this.productDetailReel.Barcode = this.serviceReturn.listProduct.listReelDetail[i].barcode;
               this.productDetailReel.Warna = this.serviceReturn.listProduct.listReelDetail[i].nama_warna;
               this.productDetailReel.BallBearing = this.serviceReturn.listProduct.listReelDetail[i].ball_bearing;
               this.productDetailReel.TipeId = this.serviceReturn.listProduct.listReelDetail[i].tipe_reel_id;
               this.productDetailReel.WayType = this.serviceReturn.listProduct.listReelDetail[i].way_type;
               this.productDetailReel.Nama = this.serviceReturn.listProduct.listReelDetail[i].nama_barang;
               this.productDetailReel.Tipe = this.serviceReturn.listProduct.listReelDetail[i].nama_tipe;                                                                 
               this.productDetailReel.Merk = this.serviceReturn.listProduct.listReelDetail[i].nama_merk;
               this.productDetailReel.MerkId = this.serviceReturn.listProduct.listReelDetail[i].merk_id;               
               this.productDetailReel.DetailId = this.serviceReturn.listProduct.listReelDetail[i].productDetailId;
               this.productDetailReel.Id = this.serviceReturn.listProduct.listReelDetail[i].productId;               
               this.listOfProductDetailReel.push(this.productDetailReel);
               }
             }
             //end of inquiry detail untuk reel

             //inquiry detail untuk line
            else if(this.serviceReturn.categoryId==3 && this.serviceReturn.listProduct.listLineDetail.length>0)
             {
               this.productTypeforHTML="Line";
               this.listOfProductDetailLine=[];
               for(let i=0;i<this.serviceReturn.listProduct.listLineDetail.length;i++)
               {
               this.productDetailLine = new productdetaillinemodel();
               this.productDetailLine.WarnaId = this.serviceReturn.listProduct.listLineDetail[i].warna_line_id;
               this.productDetailLine.Satuan = this.serviceReturn.listProduct.listLineDetail[i].satuan_ukur;
               this.productDetailLine.Ukuran = this.serviceReturn.listProduct.listLineDetail[i].ukr_panjang;
               this.productDetailLine.Kekuatan = this.serviceReturn.listProduct.listLineDetail[i].kekuatan;
               this.productDetailLine.TypeConnectorSenar = this.serviceReturn.listProduct.listLineDetail[i].tipe_konektor_senar;
               this.productDetailLine.TipeNo = this.serviceReturn.listProduct.listLineDetail[i].tipe_no;
               this.productDetailLine.Barcode = this.serviceReturn.listProduct.listLineDetail[i].barcode;
               this.productDetailLine.TypeFluoroCarbon = this.serviceReturn.listProduct.listLineDetail[i].tipe_fluor_carbon;
               this.productDetailLine.Warna = this.serviceReturn.listProduct.listLineDetail[i].nama_warna;
               this.productDetailLine.Diameter = this.serviceReturn.listProduct.listLineDetail[i].diameter;
               this.productDetailLine.Pe = this.serviceReturn.listProduct.listLineDetail[i].pe;
               this.productDetailLine.TipeId = this.serviceReturn.listProduct.listLineDetail[i].tipe_line_id;
               this.productDetailLine.Nama = this.serviceReturn.listProduct.listLineDetail[i].nama_barang;
               this.productDetailLine.Tipe = this.serviceReturn.listProduct.listLineDetail[i].nama_tipe;
               this.productDetailLine.Lbs = this.serviceReturn.listProduct.listLineDetail[i].lbs;
               this.productDetailLine.Merk = this.serviceReturn.listProduct.listLineDetail[i].nama_merk;
               this.productDetailLine.MerkId = this.serviceReturn.listProduct.listLineDetail[i].merk_id;               
               this.productDetailLine.Id = this.serviceReturn.listProduct.listLineDetail[i].productId;
               this.productDetailLine.DetailId = this.serviceReturn.listProduct.listLineDetail[i].productDetailId;                              
               this.listOfProductDetailLine.push(this.productDetailLine);
               }
             }
             //end of inquiry detail untuk line

             //inquiry detail untuk kail
            else if(this.serviceReturn.categoryId==4 && this.serviceReturn.listProduct.listKailDetail.length>0)
            {
              this.productTypeforHTML="Kail";
              this.listOfProductDetailKail=[];
              for(let i=0;i<this.serviceReturn.listProduct.listKailDetail.length;i++)
              {
              this.productDetailKail = new productdetailkailmodel();
              this.productDetailKail.Id = this.serviceReturn.listProduct.listKailDetail[i].productId;
              this.productDetailKail.DetailId = this.serviceReturn.listProduct.listKailDetail[i].productDetailId;
              this.productDetailKail.Ukuran = this.serviceReturn.listProduct.listKailDetail[i].ukuran;
              this.productDetailKail.Barcode = this.serviceReturn.listProduct.listKailDetail[i].barcode;
              this.productDetailKail.Merk = this.serviceReturn.listProduct.listKailDetail[i].nama_merk;
              this.productDetailKail.MerkId = this.serviceReturn.listProduct.listKailDetail[i].merk_id;              
              this.productDetailKail.Nama = this.serviceReturn.listProduct.listKailDetail[i].nama_barang;              
              this.listOfProductDetailKail.push(this.productDetailKail);
              }
            }
            //end of inquiry detail untuk kail

             //inquiry detail untuk acc
             else if(this.serviceReturn.categoryId==5 && this.serviceReturn.listProduct.listAksesorisDetail.length>0)
             {
               this.productTypeforHTML="Acc";
               this.listOfProductDetailAcc=[];
               for(let i=0;i<this.serviceReturn.listProduct.listAksesorisDetail.length;i++)
               {
               this.productDetailAcc = new productdetailaccmodel();
               this.productDetailAcc.DetailId = this.serviceReturn.listProduct.listAksesorisDetail[i].productDetailId;
               this.productDetailAcc.Id = this.serviceReturn.listProduct.listAksesorisDetail[i].productId;               
               this.productDetailAcc.Ukuran = this.serviceReturn.listProduct.listAksesorisDetail[i].ukuran;
               this.productDetailAcc.Merk = this.serviceReturn.listProduct.listAksesorisDetail[i].nama_merk;
               this.productDetailAcc.MerkId = this.serviceReturn.listProduct.listAksesorisDetail[i].merk_id;               
               this.productDetailAcc.Nama = this.serviceReturn.listProduct.listAksesorisDetail[i].nama_barang;
               this.productDetailAcc.Barcode = this.serviceReturn.listProduct.listAksesorisDetail[i].barcode;
               this.productDetailAcc.Warna = this.serviceReturn.listProduct.listAksesorisDetail[i].nama_warna;
               this.productDetailAcc.WarnaId = this.serviceReturn.listProduct.listAksesorisDetail[i].warna_aksesoris_id; 
               this.productDetailAcc.Lbs = this.serviceReturn.listProduct.listAksesorisDetail[i].lbs; 
               this.productDetailAcc.Liter = this.serviceReturn.listProduct.listAksesorisDetail[i].liter; 
               this.productDetailAcc.Tipe = this.serviceReturn.listProduct.listAksesorisDetail[i].nama_tipe; 
               this.productDetailAcc.TipeId = this.serviceReturn.listProduct.listAksesorisDetail[i].tipe_aksesoris_id;  
               this.productDetailAcc.Size = this.serviceReturn.listProduct.listAksesorisDetail[i].size;                             
               this.listOfProductDetailAcc.push(this.productDetailAcc);
               }
             }
             //end of inquiry detail untuk acc    
             
             //inquiry detail untuk bait
             else if(this.serviceReturn.categoryId==6 && this.serviceReturn.listProduct.listUmpanDetail.length>0)
             {
               this.productTypeforHTML="Bait";
               this.listOfProductDetailBait=[];
               for(let i=0;i<this.serviceReturn.listProduct.listUmpanDetail.length;i++)
               {
               this.productDetailBait = new productdetailbaitmodel();
               this.productDetailBait.DetailId = this.serviceReturn.listProduct.listUmpanDetail[i].productDetailId;
               this.productDetailBait.Id = this.serviceReturn.listProduct.listUmpanDetail[i].productId;               
               this.productDetailBait.Ukuran = this.serviceReturn.listProduct.listUmpanDetail[i].ukuran;
               this.productDetailBait.Merk = this.serviceReturn.listProduct.listUmpanDetail[i].nama_merk;
               this.productDetailBait.MerkId = this.serviceReturn.listProduct.listUmpanDetail[i].merkId;               
               this.productDetailBait.Nama = this.serviceReturn.listProduct.listUmpanDetail[i].nama_barang;
               this.productDetailBait.Barcode = this.serviceReturn.listProduct.listUmpanDetail[i].barcode;
               this.productDetailBait.Warna = this.serviceReturn.listProduct.listUmpanDetail[i].nama_warna;
               this.productDetailBait.WarnaId = this.serviceReturn.listProduct.listUmpanDetail[i].warna_umpan_id; 
               this.productDetailBait.Tipe = this.serviceReturn.listProduct.listUmpanDetail[i].nama_tipe; 
               this.productDetailBait.TipeId = this.serviceReturn.listProduct.listUmpanDetail[i].tipe_umpan_id;   
               this.productDetailBait.Berat = this.serviceReturn.listProduct.listUmpanDetail[i].berat;     
               this.productDetailBait.Varian = this.serviceReturn.listProduct.listUmpanDetail[i].namaVarian;   
               this.productDetailBait.VarianId = this.serviceReturn.listProduct.listUmpanDetail[i].varianId;                       
               this.listOfProductDetailBait.push(this.productDetailBait);
               }
             }
             //end of inquiry detail untuk bait               

            else{
              alert("Product Detail Not Found");
              this.router.navigateByUrl("inquiryproductmaster");
            }

          }
          else
          {
            alert("Inquiry Detail Product Failed");
          }
        }
      )  
  }

  searchWarna()
  {

    let jsonInput = {};
    jsonInput['kode']="warna";
    jsonInput['categoryId']="";
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      this.listOfWarna=[];

       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn3 = data;
          if(this.serviceReturn3.status=="S")
          {

            for(let i=0;i<this.serviceReturn3.listCode.length;i++)
            {
             this.jsonWarna={};  
            this.jsonWarna['namaWarna'] = this.serviceReturn3.listCode[i].namaWarna;
            this.jsonWarna['id'] = this.serviceReturn3.listCode[i].id;
            this.listOfWarna.push(this.jsonWarna);
            }
          }
          else{
            alert("List of Color Not Found");
          }
        }
      )  
  }
  searchMerk()
  {

    let jsonInput = {};
    jsonInput['kode']="merk";
    jsonInput['categoryId']="";
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      this.listOfMerk=[];

       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn4 = data;
          if(this.serviceReturn4.status=="S")
          {
            for(let i=0;i<this.serviceReturn4.listCode.length;i++)
            {
            this.jsonMerk={};
            this.jsonMerk['namaMerk']=this.serviceReturn4.listCode[i].namaMerk;
            this.jsonMerk['id']=this.serviceReturn4.listCode[i].id;
            this.listOfMerk.push(this.jsonMerk);
            }
          }
          else{
            alert("List of Brand Not Found");
          }
        }
      )  
  }
  searchBahanJoran()
  {
    let jsonInput = {};
    jsonInput['kode']="bahan";
    jsonInput['categoryId']="1";
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      this.listOfBahanJoran=[];

       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn2 = data;
          if(this.serviceReturn2.status=="S")
          {
            for(let i=0;i<this.serviceReturn2.listCode.length;i++)
            {
             this.jsonBahanJoran={};  
            this.jsonBahanJoran['namaBahan'] = this.serviceReturn2.listCode[i].namaBahan;
            this.jsonBahanJoran['kodeBahan'] = this.serviceReturn2.listCode[i].id;
            this.listOfBahanJoran.push(this.jsonBahanJoran);
            }
          }
          else{
            alert("List of Rod Material Not Found");
          }

        }
      )  
  }   
  searchTipeJoran()
  {

    let jsonInput = {};
    jsonInput['kode']="tipe";
    jsonInput['categoryId']="1";
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      this.listOfTipeJoran=[];

       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn5 = data;
          if(this.serviceReturn5.status=="S")
          {

            for(let i=0;i<this.serviceReturn5.listCode.length;i++)
            {
            this.jsonTipeJoran={};
            this.jsonTipeJoran['namaTipe'] = this.serviceReturn5.listCode[i].namaTipe;
            this.jsonTipeJoran['kodeTipe'] = this.serviceReturn5.listCode[i].id;
            this.listOfTipeJoran.push(this.jsonTipeJoran);
            }
          }
          else{
            alert("List of Rod Type Not Found");
          }
        }
      )  
  }

  openModalUpdateRod(joranModel:any)
  {
    this.searchBahanJoran();
    this.searchTipeJoran();   
    this.insertProductJoranForm.get('ukuran').setValue(joranModel.ukuran);
    this.insertProductJoranForm.get('minLbs').setValue(joranModel.minLbs);
    this.insertProductJoranForm.get('maxLbs').setValue(joranModel.maxLbs);
    this.insertProductJoranForm.get('minPE').setValue(joranModel.minPE);
    this.insertProductJoranForm.get('maxPE').setValue(joranModel.maxPE);
    this.insertProductJoranForm.get('ring').setValue(joranModel.Ring);
    this.insertProductJoranForm.get('tahunProduksi').setValue(joranModel.tahunProduksi);
    this.insertProductJoranForm.get('warna').setValue(joranModel.warnaId);
    this.insertProductJoranForm.get('bahan').setValue(joranModel.BahanId);
    this.insertProductJoranForm.get('tipe').setValue(joranModel.tipeId);
    this.insertProductJoranForm.get('merk').setValue(joranModel.merkId);
    this.insertProductJoranForm.get('nama').setValue(joranModel.nama);
    this.insertProductJoranForm.get('id').setValue(joranModel.id);
    this.insertProductJoranForm.get('detailId').setValue(joranModel.detailId);
    this.insertProductJoranForm.controls['id'].disable();
    this.insertProductJoranForm.controls['detailId'].disable();

  }

  searchTipeReel()
  {
   let jsonInput = {};
   jsonInput['kode']="tipe";
   jsonInput['categoryId']="2";
   let res={}
     let stringJsonInput = JSON.stringify(jsonInput);
     this.listOfTipeReel=[];

      res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
     .subscribe( 
       data => {
         this.serviceReturn7 = data;
         if(this.serviceReturn7.status=="S")
         {

           for(let i=0;i<this.serviceReturn7.listCode.length;i++)
           {
           this.jsonTipeReel={};
           this.jsonTipeReel['namaTipe'] = this.serviceReturn7.listCode[i].namaTipe;
           this.jsonTipeReel['kodeTipe'] = this.serviceReturn7.listCode[i].id;
           this.listOfTipeReel.push(this.jsonTipeReel);            
           }
           console.log(this.listOfTipeReel)
         }
         else{
           alert("List of Reel Type Not Found");
         }
       }
     )  
  }

  openModalUpdateReel(reelModel:any)
  {
    this.searchTipeReel();  
    console.log(reelModel);
    this.insertProductReelForm.get('ukuran').setValue(reelModel.Ukuran);
    this.insertProductReelForm.get('ballBearing').setValue(reelModel.BallBearing);
    this.insertProductReelForm.get('tipePh').setValue(reelModel.PhType);
    this.insertProductReelForm.get('tipeWay').setValue(reelModel.WayType);
    this.insertProductReelForm.get('warna').setValue(reelModel.WarnaId);
    this.insertProductReelForm.get('tipe').setValue(reelModel.tipeId);
    this.insertProductReelForm.get('merk').setValue(reelModel.MerkId);
    this.insertProductReelForm.get('nama').setValue(reelModel.nama);
    this.insertProductReelForm.get('id').setValue(reelModel.Id);
    this.insertProductReelForm.get('detailId').setValue(reelModel.DetailId);
    this.insertProductReelForm.controls['id'].disable();
    this.insertProductReelForm.controls['detailId'].disable();

  }
  searchTipeLine()
  {
   let jsonInput = {};
   jsonInput['kode']="tipe";
   jsonInput['categoryId']="3";
   let res={}
     let stringJsonInput = JSON.stringify(jsonInput);
     this.listOfTipeReel=[];

      res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
     .subscribe( 
       data => {
         this.serviceReturn8 = data;
         if(this.serviceReturn8.status=="S")
         {

           for(let i=0;i<this.serviceReturn8.listCode.length;i++)
           {
           this.jsonTipeLine={};
           this.jsonTipeLine['namaTipe'] = this.serviceReturn8.listCode[i].namaTipe;
           this.jsonTipeLine['kodeTipe'] = this.serviceReturn8.listCode[i].id;
           this.listOfTipeLine.push(this.jsonTipeLine);            
           }
           console.log(this.listOfTipeLine)
         }
         else{
           alert("List of Line Type Not Found");
         }
       }
     )  
  }

  openModalUpdateLine(lineModel:any)
  {
    this.searchTipeLine();  
    console.log(lineModel);
    this.insertProductLineForm.get('id').setValue(lineModel.id);
    this.insertProductLineForm.get('detailId').setValue(lineModel.detailId);
    this.insertProductLineForm.get('nama').setValue(lineModel.nama);
    this.insertProductLineForm.get('merk').setValue(lineModel.merkId);
    this.insertProductLineForm.get('satuanUkur').setValue(lineModel.satuan);
    this.insertProductLineForm.get('ukuran').setValue(lineModel.ukuran);
    this.insertProductLineForm.get('kekuatan').setValue(lineModel.kekuatan);
    this.insertProductLineForm.get('diameter').setValue(lineModel.diameter);
    this.insertProductLineForm.get('pe').setValue(lineModel.pe);
    this.insertProductLineForm.get('lbs').setValue(lineModel.lbs);
    this.insertProductLineForm.get('warna').setValue(lineModel.warnaId);
    this.insertProductLineForm.get('tipe').setValue(lineModel.tipeId);
    this.insertProductLineForm.get('tipeFluoroCarbon').setValue(lineModel.typeFluoroCarbon);
    this.insertProductLineForm.get('tipeNo').setValue(lineModel.tipeNo);
    this.insertProductLineForm.get('tipeConnector').setValue(lineModel.typeConnectorSenar);
    this.insertProductLineForm.controls['id'].disable();
    this.insertProductLineForm.controls['detailId'].disable();    
  }

  openModalUpdateHook(hookModel:any)
  { 
    console.log(hookModel);
    this.insertProductHookForm.get('id').setValue(hookModel.id);
    this.insertProductHookForm.get('detailId').setValue(hookModel.detailId);
    this.insertProductHookForm.get('nama').setValue(hookModel.nama);
    this.insertProductHookForm.get('merk').setValue(hookModel.merkId);
    this.insertProductHookForm.get('ukuran').setValue(hookModel.ukuran);
    this.insertProductHookForm.controls['id'].disable();
    this.insertProductHookForm.controls['detailId'].disable();    
  }
  openModalUpdateAcc(accModel:any)
  {
  
    this.searchTipeAcc();
    this.insertProductAccForm.get('id').setValue(accModel.id);
    this.insertProductAccForm.get('detailId').setValue(accModel.detailId);
    this.insertProductAccForm.get('nama').setValue(accModel.nama);
    this.insertProductAccForm.get('warna').setValue(accModel.warnaId);
    this.insertProductAccForm.get('merk').setValue(accModel.merkId);
    this.insertProductAccForm.get('ukuran').setValue(accModel.ukuran);
    this.insertProductAccForm.get('tipe').setValue(accModel.tipeId);
    this.insertProductAccForm.get('liter').setValue(accModel.liter);
    this.insertProductAccForm.get('lbs').setValue(accModel.lbs);
    this.insertProductAccForm.get('size').setValue(accModel.size);            
    this.insertProductAccForm.controls['id'].disable();
    this.insertProductAccForm.controls['detailId'].disable(); 
  }
  openModalUpdateBait(baitModel:any)
  {
    this.searchTipeBait();
    this.searchVarianBait();
    console.log(baitModel);
    this.insertProductBaitForm.get('id').setValue(baitModel.id);
    this.insertProductBaitForm.get('detailId').setValue(baitModel.detailId);
    this.insertProductBaitForm.get('nama').setValue(baitModel.nama);
    this.insertProductBaitForm.get('warna').setValue(baitModel.warnaId);
    this.insertProductBaitForm.get('merk').setValue(baitModel.merkId);
    this.insertProductBaitForm.get('tipe').setValue(baitModel.tipeId);
    this.insertProductBaitForm.get('varian').setValue(baitModel.varianId);
    this.insertProductBaitForm.get('berat').setValue(baitModel.berat);
    this.insertProductBaitForm.get('ukuran').setValue(baitModel.ukuran);
    this.insertProductBaitForm.controls['id'].disable();
    this.insertProductBaitForm.controls['detailId'].disable(); 
  }
  searchTipeAcc()
  {

    let jsonInput = {};
    jsonInput['kode']="tipe";
    jsonInput['categoryId']="5";
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      this.listOfTipeAcc=[];

       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn9 = data;
          if(this.serviceReturn9.status=="S")
          {

            for(let i=0;i<this.serviceReturn9.listCode.length;i++)
            {
            this.jsonTipeAcc={};
            this.jsonTipeAcc['namaTipe'] = this.serviceReturn9.listCode[i].namaTipe;
            this.jsonTipeAcc['kodeTipe'] = this.serviceReturn9.listCode[i].id;
            this.listOfTipeAcc.push(this.jsonTipeAcc);
            }
          }
          else{
            alert("List of Acc Type Not Found");
          }
        }
      )  
  }
  searchTipeBait()
  {

    let jsonInput = {};
    jsonInput['kode']="tipe";
    jsonInput['categoryId']="6";
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      this.listOfTipeBait=[];

       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn12 = data;
          if(this.serviceReturn12.status=="S")
          {

            for(let i=0;i<this.serviceReturn12.listCode.length;i++)
            {
            this.jsonTipeBait={};
            this.jsonTipeBait['namaTipe'] = this.serviceReturn12.listCode[i].namaTipe;
            this.jsonTipeBait['kodeTipe'] = this.serviceReturn12.listCode[i].id;
            this.listOfTipeBait.push(this.jsonTipeBait);
            }
          }
          else{
            alert("List of Acc Type Not Found");
          }
        }
      )  
  }
  searchVarianBait()
  {

    let jsonInput = {};
    jsonInput['kode']="bahan";
    jsonInput['categoryId']="6";
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      this.listOfVarianBait=[];

       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn13 = data;
          if(this.serviceReturn13.status=="S")
          {

            for(let i=0;i<this.serviceReturn13.listCode.length;i++)
            {
            this.jsonVarianBait={};
            this.jsonVarianBait['namaVarian'] = this.serviceReturn13.listCode[i].namaBahan;
            this.jsonVarianBait['kodeVarian'] = this.serviceReturn13.listCode[i].id;
            this.listOfVarianBait.push(this.jsonVarianBait);
            }
          }
          else{
            alert("List of Acc Type Not Found");
          }
        }
      )  
  }

  updateData()
  {
    let jsonInput={};
    let jsonInput2={};
    if(this.productTypeforHTML=="Rod")
    {
        jsonInput2['ukuran']=this.insertProductJoranForm.get('ukuran').value;
        jsonInput2['minLbs']=this.insertProductJoranForm.get('minLbs').value;
        jsonInput2['maxLbs']=this.insertProductJoranForm.get('maxLbs').value;
        jsonInput2['minPE']=this.insertProductJoranForm.get('minPE').value;
        jsonInput2['maxPE']=this.insertProductJoranForm.get('maxPE').value;
        jsonInput2['ring']=this.insertProductJoranForm.get('ring').value;
        jsonInput2['id']=this.insertProductJoranForm.get('detailId').value;
        jsonInput2['tahunProduksi']=this.insertProductJoranForm.get('tahunProduksi').value;
        jsonInput2['warnaJoranId']=parseInt(this.insertProductJoranForm.get('warna').value);
        jsonInput2['bahanJoranId']=parseInt(this.insertProductJoranForm.get('bahan').value);
        jsonInput2['tipeJoranId']=parseInt(this.insertProductJoranForm.get('tipe').value);
        jsonInput2['connectorCategoryId']=0; //blm ada getnya- TBC ini apa
        jsonInput2['status']=""; //blm ada getnya
        jsonInput2['barcode']=""; //blm ada getnya            
        jsonInput['joranDetail']=jsonInput2;
        jsonInput['kategoriId']=1;
        jsonInput['merkId']=parseInt(this.insertProductJoranForm.get('merk').value);
        jsonInput['productName']=this.insertProductJoranForm.get('nama').value;
        jsonInput['productId']=this.insertProductJoranForm.get('id').value;
      }
     else if(this.productTypeforHTML=="Reel")
      {
          jsonInput2['ukuran']=this.insertProductReelForm.get('ukuran').value;

          jsonInput2['id']=this.insertProductReelForm.get('detailId').value;
          jsonInput2['ballBearing']=this.insertProductReelForm.get('ballBearing').value;
          jsonInput2['tipePh']=this.insertProductReelForm.get('tipePh').value;
          jsonInput2['tipeWay']=this.insertProductReelForm.get('tipeWay').value;
          jsonInput2['warnaReelId']=parseInt(this.insertProductReelForm.get('warna').value);
          jsonInput2['tipeReelId']=parseInt(this.insertProductReelForm.get('tipe').value);
          jsonInput2['connectorCategoryId']=0; //blm ada getnya- TBC ini apa
          jsonInput2['status']=""; //blm ada getnya
          jsonInput2['barcode']=""; //blm ada getnya           
          jsonInput['reelDetail']=jsonInput2;
          jsonInput['kategoriId']=2;
          jsonInput['merkId']=parseInt(this.insertProductReelForm.get('merk').value);
          jsonInput['productName']=this.insertProductReelForm.get('nama').value;
          jsonInput['productId']=this.insertProductReelForm.get('id').value;
      }       
     else if(this.productTypeforHTML=="Line")
        {
            jsonInput2['ukuranPanjang']=this.insertProductLineForm.get('ukuran').value;
            jsonInput2['id']=this.insertProductLineForm.get('detailId').value;
            jsonInput2['satuanUkur']=this.insertProductLineForm.get('satuanUkur').value;
            jsonInput2['kekuatan']=this.insertProductLineForm.get('kekuatan').value;
            jsonInput2['pe']=this.insertProductLineForm.get('pe').value;
            jsonInput2['lbs']=this.insertProductLineForm.get('lbs').value;
            jsonInput2['diameter']=this.insertProductLineForm.get('diameter').value;
            jsonInput2['tipeKonektorSenar']=this.insertProductLineForm.get('tipeConnector').value;
            jsonInput2['tipeFluorCarbon']=this.insertProductLineForm.get('tipeFluoroCarbon').value;
            jsonInput2['tipeNo']=this.insertProductLineForm.get('tipeNo').value;                                                
            jsonInput2['warnaLineId']=parseInt(this.insertProductLineForm.get('warna').value);
            jsonInput2['tipeLineId']=parseInt(this.insertProductLineForm.get('tipe').value);
            jsonInput2['connectorCategoryId']=0; //blm ada getnya- TBC ini apa
            jsonInput2['status']=""; //blm ada getnya
            jsonInput2['barcode']=""; //blm ada getnya              
            jsonInput['lineDetail']=jsonInput2;
            jsonInput['kategoriId']=3;
            jsonInput['merkId']=parseInt(this.insertProductLineForm.get('merk').value);
            jsonInput['productName']=this.insertProductLineForm.get('nama').value;
            jsonInput['productId']=this.insertProductLineForm.get('id').value;
      } 
     else if(this.productTypeforHTML=="Kail")
          {
              jsonInput2['ukuran']=this.insertProductHookForm.get('ukuran').value;
              jsonInput2['id']=this.insertProductHookForm.get('detailId').value;
              jsonInput2['connectorCategoryId']=0; //blm ada getnya- TBC ini apa
              jsonInput2['status']=""; //blm ada getnya
              jsonInput2['barcode']=""; //blm ada getnya              
              jsonInput['kailDetail']=jsonInput2;
              jsonInput['kategoriId']=4;
              jsonInput['merkId']=parseInt(this.insertProductHookForm.get('merk').value);
              jsonInput['productName']=this.insertProductHookForm.get('nama').value;
              jsonInput['productId']=this.insertProductHookForm.get('id').value;
      } 
      else if(this.productTypeforHTML=="Acc")
      {
          jsonInput2['ukuran']=this.insertProductAccForm.get('ukuran').value;
          jsonInput2['id']=this.insertProductAccForm.get('detailId').value;
          jsonInput2['tipeAksesorisId']=this.insertProductAccForm.get('tipe').value;
          jsonInput2['warnaAksesorisId']=this.insertProductAccForm.get('warna').value;          
          jsonInput2['liter']=this.insertProductAccForm.get('liter').value;
          jsonInput2['lbs']=this.insertProductAccForm.get('lbs').value;
          jsonInput2['size']=this.insertProductAccForm.get('size').value;
          jsonInput2['connectorCategoryId']=0; //blm ada getnya- TBC ini apa
          jsonInput2['status']=""; //blm ada getnya
          jsonInput2['barcode']=""; //blm ada getnya              
          jsonInput['aksesorisDetail']=jsonInput2;
          jsonInput['kategoriId']=5;
          jsonInput['merkId']=parseInt(this.insertProductAccForm.get('merk').value);
          jsonInput['productName']=this.insertProductAccForm.get('nama').value;
          jsonInput['productId']=this.insertProductAccForm.get('id').value;
     } 
     else if(this.productTypeforHTML=="Bait")
     {
      jsonInput2['ukuran']=this.insertProductBaitForm.get('ukuran').value;
      jsonInput2['id']=this.insertProductBaitForm.get('detailId').value;
      jsonInput2['tipeUmpanId']=this.insertProductBaitForm.get('tipe').value;
      jsonInput2['warnaUmpanId']=parseInt(this.insertProductBaitForm.get('warna').value);          
      jsonInput2['berat']=this.insertProductBaitForm.get('berat').value;
      jsonInput2['varian']=parseInt(this.insertProductBaitForm.get('varian').value);       
      jsonInput['umpanDetail']=jsonInput2;
      jsonInput['kategoriId']=6;
      jsonInput['merkId']=parseInt(this.insertProductBaitForm.get('merk').value);
      jsonInput['productName']=this.insertProductBaitForm.get('nama').value;
      jsonInput['productId']=this.insertProductBaitForm.get('id').value;
     } 
                     
      let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      console.log(stringJsonInput)

       res=this.getService.restItemsServicePutRestItems('updateProduct',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn6 = data;
          console.log(this.serviceReturn6);
          if(this.serviceReturn6.status=="S")
          {
            alert("Success Update Product");
            location.reload();
          }
          else{
            alert("Failed Update Product");
            $("#exampleModal").modal("hide");
          }
        }
      )  
  }

  stockModel :stock = new stock();
  stockModelDetail :stock = new stock();
  serviceReturn10:any;
  serviceReturn11:any;
  categoryIdGlobal:any;
  listStockModelDetail=[];
  inquiryStockHeader(modelStock:any)
  {

    let jsonInput = {};
    jsonInput['categoryId']=parseInt(this.categoryIdGlobal);
    jsonInput['productDetailId']=parseInt(modelStock.detailId); 
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      console.log(stringJsonInput)
       res=this.getService.restItemsServiceGetwithParamRestItems('warehouse/inquiryStock',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn10 = data;
          console.log(this.serviceReturn10);
          if(this.serviceReturn10.status=="S")
          {
            this.stockModel = new stock();
            this.stockModel.MasterStockId = this.serviceReturn10.result.masterStockId;
            this.stockModel.Uom = this.serviceReturn10.result.uom;
            this.stockModel.Stock = this.serviceReturn10.result.stock;
            this.stockModel.JumlahPerDus = this.serviceReturn10.result.jumlahPerDus;
            this.stockModel.ProductNameGabungan = modelStock.Merk +" "+ modelStock.Nama +" "+ modelStock.Ukuran;
            this.inquiryStockDetail( this.stockModel.MasterStockId);
          }
          else{
            alert("Inquiry Stock Failed");
          }
        }
      )
  }
  inquiryStockDetail(masterStockId:number)
  {
    let jsonInput = {};
    jsonInput['masterStockId']=masterStockId;
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);

       res=this.getService.restItemsServiceGetwithParamRestItems('warehouse/inquiryStockDetail',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn11 = data;
          console.log(this.serviceReturn11)
          if(this.serviceReturn11.status=="S")
          {
            this.listStockModelDetail=[];
            for(let i=0;i<this.serviceReturn11.result.listProduct.length;i++)
            {
            this.stockModelDetail = new stock();
            this.stockModelDetail.NomorPo = this.serviceReturn11.result.listProduct[i].nomorPO;
            this.stockModelDetail.TanggalCreatePo = this.serviceReturn11.result.listProduct[i].tanggalCreatePO.substring(6,8) +"/"+ 
            this.serviceReturn11.result.listProduct[i].tanggalCreatePO.substring(4,6) +"/"+this.serviceReturn11.result.listProduct[i].tanggalCreatePO.substring(0,4);
            this.stockModelDetail.Stock = this.serviceReturn11.result.listProduct[i].stock;
            this.listStockModelDetail.push(this.stockModelDetail);
            }
          }
          else{
            alert("Inquiry Stock Detail Failed");
          }
        }
      )
  }

  deleteIdTemp=0;
  serviceReturn14:any;
  deleteProduct()
  {
    let jsonInput = {};
    jsonInput['productDetailId'] = this.deleteIdTemp;
    jsonInput['categoryId'] = parseInt(this.categoryIdGlobal);
    
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      console.log(stringJsonInput);
       res=this.getService.restItemsServiceGetwithParamRestItems('deleteProduct',stringJsonInput)
      .subscribe(
        data => {
          this.serviceReturn14 = data;
          console.log(this.serviceReturn14);
          if(this.serviceReturn14.status=="S")
          {
            alert("Success Delete Product");
            location.reload();
          }
  
          else
          {
            alert("Failed Delete Product");
          }
        }
      ) 
  }

  deleteConfirm(productId:any)
{
  this.deleteIdTemp=0;
  this.deleteIdTemp=productId;
}

  //HARGA DIBUATKAN HALAMAN SENDIRI CONFIGPRICE
  // priceModel :stock = new stock();
  // priceModelDetail :stock = new stock();
  // serviceReturn12:any;
  // listPriceModelDetail=[];
  // inquiryPriceDetail(modelPrice:any)
  // {
  //   let jsonInput = {};
  //   jsonInput['categoryId']=this.categoryIdGlobal;
  //   jsonInput['productDetailId']=modelPrice.detailId; 
  //   let res={}
  //     let stringJsonInput = JSON.stringify(jsonInput);
  //     console.log(stringJsonInput)
  //       res=this.getService.restItemsServiceGetwithParamRestItems('/inquiryProductPrice',stringJsonInput)
  //      .subscribe( 
  //        data => {
  //          this.serviceReturn12 = data;
  //          console.log(this.serviceReturn12);
  //          if(this.serviceReturn12.status=="S")
  //          {
  //           this.priceModel = new stock();
  //           this.priceModel.MasterStockId = this.serviceReturn12.result.masterStockId;
  //            this.priceModel.SellPrice = this.serviceReturn12.result.sellPrice.sellPrice;
  //            this.priceModel.TanggalUpdateHarga = this.dateTimeUtil.getFormattedDate2(this.serviceReturn12.result.sellPrice.lastUpdateTime);
  //            this.priceModel.ProductNameGabungan = modelPrice.Merk +" "+ modelPrice.Nama +" "+ modelPrice.Ukuran;
  //            this.listPriceModelDetail=[];
  //            for(let i=0;i<this.serviceReturn12.result.basePriceList.length;i++)
  //            {
  //             this.priceModelDetail = new stock();
  //             this.priceModelDetail.NomorPo = this.serviceReturn12.result.basePriceList[i].nomorPO;
  //             this.priceModelDetail.TanggalCreatePo = this.serviceReturn12.result.basePriceList[i].create_datetime;
  //             this.priceModelDetail.BasePrice = this.serviceReturn12.result.basePriceList[i].base_price;
  //             this.priceModelDetail.Stock = this.serviceReturn12.result.basePriceList[i].stock;
  //             this.listPriceModelDetail.push(this.priceModelDetail);
  //            }
  //         }
  //         else{
  //            alert("Inquiry Price Failed");
  //         }
  //        }
  //      )
  // }
  // jsonInputUpdateProduct = {};
  // updatePricePop(productName:string, sellPrice:number, masterStockId:number)
  // {
  //   $("#exampleModalPrice").modal("hide");
  //   this.jsonInputUpdateProduct = {};
  //   this.jsonInputUpdateProduct['categoryId']=this.categoryIdGlobal;
  //   this.jsonInputUpdateProduct['productName']=productName;
  //   this.jsonInputUpdateProduct['productDetailId']=masterStockId;
  //   this.jsonInputUpdateProduct['newPrice']= 0,
  //   this.jsonInputUpdateProduct['userId']= 1,
  //   this.jsonInputUpdateProduct['kodeTransaksi']= 7 
  //   let res={}
  //     let stringJsonInput = JSON.stringify(this.jsonInputUpdateProduct);
  //     console.log(stringJsonInput)
  // }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {

      if(params!=null){
      this.searchbyname(params.id,params.categoryId,params.merkId);
      this.categoryIdGlobal=params.categoryId;
      }

      else
      {
        alert("Cannot Show Item Detail");    
      }
    
    });

    this.searchWarna();
    this.searchMerk();

  }     

}
