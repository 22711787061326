import { Injectable } from '@angular/core';


@Injectable()

export class MappingValue {

constructor() { }

getSektorUsaha(idSektorUsaha:number)
{
	let returnIdSektorUsaha = "";
	if (idSektorUsaha==7) {returnIdSektorUsaha="PERDAGANGAN BESAR DAN ECERAN"} 
    else if (idSektorUsaha==8) {returnIdSektorUsaha="PENYEDIAAN AKOMODASI DAN PENYEDIAAN MAKAN MINUM"}              
    else {returnIdSektorUsaha="JASA KEMASYARAKATAN, SOSIAL BUDAYA, HIBURAN DAN PERORANGAN LAINNYA"}

    return returnIdSektorUsaha;
}

getSektorUsahaReverse(sektorUsaha:string)
{
	let returnIdSektorUsaha = "";
	if (sektorUsaha=="PERDAGANGAN BESAR DAN ECERAN") {returnIdSektorUsaha="07"} 
    else if (sektorUsaha=="PENYEDIAAN AKOMODASI DAN PENYEDIAAN MAKAN MINUM") {returnIdSektorUsaha="08"}              
    else {returnIdSektorUsaha="15"}

    return returnIdSektorUsaha;
}

getKodeSkema(kodeSkemaKur:number)
{
	let returnKodeSkemaKur = "";
	if (kodeSkemaKur==1) {returnKodeSkemaKur="1 - Micro"}        
    else {returnKodeSkemaKur="3 - Kecil"}

    return returnKodeSkemaKur;
}

getKodeSkemaReverse(skemaKur:string)
{
	let returnKodeSkemaKur = "";
	if (skemaKur=="1 - Micro") {returnKodeSkemaKur="1"}        
    else {returnKodeSkemaKur="3"}

    return returnKodeSkemaKur;
}

}