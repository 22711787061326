export class supplier{

    constructor(values: Object = {}) {
          Object.assign(this, values);
    }
    private id : string;
    private namaSupplier : string;
    private kodeSupplier : string;  
    private alamat : string;
    private noHP : string;
    private email : string;
    private keterangan : string;
    
    public get Id():string{
        return this.id;
    }
    public get NamaSupplier():string{
        return this.namaSupplier;
    }
    public get KodeSupplier():string{
        return this.kodeSupplier;
    }
    public get Alamat():string{
        return this.alamat;
    }
    public get NoHP():string{
        return this.noHP;
    }
    public get Email():string{
        return this.email;
    }
    public get Keterangan():string{
        return this.keterangan;
    }        

    public set Id (id:string){
        this.id=id;
    }
    public set NamaSupplier (namaSupplier:string){
        this.namaSupplier=namaSupplier;
    }
    public set KodeSupplier (kodeSupplier:string){
        this.kodeSupplier=kodeSupplier;
    }
    public set Alamat (alamat:string){
        this.alamat=alamat;
    }
    public set NoHP (noHP:string){
        this.noHP=noHP;
    }
    public set Email (email:string){
        this.email=email;
    }
    public set Keterangan (keterangan:string){
        this.keterangan=keterangan;
    }

    }