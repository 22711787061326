<div class="col-md-12 grid-margin stretch-card">
	<div class="card">
		<div class="card-body">
			<h4 class="card-title">Price Configuration</h4>
            <div class="table-responsive">
                <table class="table table-hover" >
                  <thead>
                    <tr>
                        <th colspan="5" style="text-align: center">Wholesale Price</th>
                    </tr> 
                    <tr>
                      <th>Price Name</th>
                      <th>Min Qty</th>
                      <th>Allow Min Qty</th>
                      <th>Sell Price</th>
                      <th>Action</th>
                    </tr>
                    <tr *ngFor="let listPriceModelGrosir of listPriceModelGrosir;let i=index">
                      <td>{{listPriceModelGrosir.NamaHarga}}</td>
                      <td>{{listPriceModelGrosir.MinQty | number}}</td>
                      <td>{{listPriceModelGrosir.MinAllowQty}}</td>
                      <td>{{listPriceModelGrosir.SellPrice | number}}</td>
                      <td><i id="icon" class="link-icon mdi mdi-update" style="color: darkred;" data-toggle="modal" data-target="#exampleModalPrice" (click)="inquiryPriceUpdate(listPriceModelGrosir)"></i></td>
                    </tr>
                  </thead>
                  <tbody >
                      <tr>
                   </tr>                        
                  </tbody>
                </table>
                <br><br>
                <table class="table table-hover" >
                    <thead>
                      <tr>
                          <th colspan="5" style="text-align: center;">Retail Price</th>
                      </tr> 
                      <tr>
                        <th>Price Name</th>
                        <th>Min Qty</th>
                        <th>Allow Min Qty</th>
                        <th>Sell Price</th>
                        <th>Action</th>
                      </tr>
                      <tr *ngFor="let listPriceModelRetail of listPriceModelRetail;let i=index">
                        <td>{{listPriceModelRetail.NamaHarga}}</td>
                        <td>{{listPriceModelRetail.MinQty | number}}</td>
                        <td>{{listPriceModelRetail.MinAllowQty}}</td>
                        <td>{{listPriceModelRetail.SellPrice | number}}</td>
                        <td><i id="icon" class="link-icon mdi mdi-update" style="color: darkred;" data-toggle="modal" data-target="#exampleModalPrice" (click)="inquiryPriceUpdate(listPriceModelRetail)"></i></td>  
                      </tr>
                    </thead>
                    <tbody >
                        <tr>
                     </tr>                        
                    </tbody>
                  </table>
                  
                  <br><br>
                  <table class="table table-hover" >
                    <thead>
                      <tr>
                          <th colspan="3" style="text-align: center; ">Base Price</th>
                      </tr> 
                      <tr>
                        <th>PO Number</th>
                        <th>Base Price</th>
                        <th>Current Qty</th>
                      </tr>
                      <tr *ngFor="let listPriceModelBase of listPriceModelBase;let i=index">
                        <td>{{listPriceModelBase.NomorPo}}</td>
                        <td>{{listPriceModelBase.BasePrice | number}}</td>
                        <td>{{listPriceModelBase.Stock | number}}</td>
                      </tr>
                    </thead>
                    <tbody >
                        <tr>
                     </tr>                        
                    </tbody>
                  </table>

                  </div>                 
		</div>
	</div>
</div>


<div class="modal fade" id="exampleModalPrice" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Update Price</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
        <div class="modal-body" >
          <form [formGroup]="updatePriceForm">
            <div class="form-group row">                                   
                <div class="col">
                    <label>Price Name</label>
                    <div id="the-basics">
                        <input class="typeahead"  id="namaHarga" formControlName="namaHarga" name="namaHarga" type="text" placeholder="PriceName"> </div>
                </div>
          
              </div>      
              <div class="form-group row">           
                <div class="col">
                  <label>Sell Price</label>
                  <div id="bloodhound">
                    <input class="typeahead"  id="price" formControlName="price" name="price" type="text" placeholder="Price"> </div>
              </div>                   
                <div class="col">
                  <label>Min Qty</label>
                  <div id="the-basics">
                      <input class="typeahead"  id="minQty" formControlName="minQty" name="minQty" type="text" placeholder="MinQty"> </div>
                  </div>                          
            </div>
            <div class="form-group row">
              <div class="col">
                <label>Min Allow Qty</label>
                <div id="the-basics">
                    <select class="typeahead"  id="allowMinQty" formControlName="allowMinQty" name="allowMinQty" type="text" placeholder="MinAllowQty">
                        <option value="" >- Min Allow Qty -</option>  
                        <option value=1>Yes</option>
                        <option value=0>No</option>  
                      </select>
                  </div>
                </div> 
                <div class="col">
                  <label>Allow Mix</label>
                  <div id="the-basics">
                      <select class="typeahead"  id="allowMix" formControlName="allowMix" name="allowMix" type="text" placeholder="Allow Mix">
                          <option value="" >- Allow Mix -</option>  
                          <option value=1>Yes</option>
                          <option value=0>No</option>  
                        </select>
                    </div>
                  </div>                 
            </div>
          </form> 
        </div>          
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-dismiss="modal" (click)="updatePrice()">Update</button>
        <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div> 

