<div class="col-md-12 grid-margin stretch-card">
	<div class="card">
		<div class="card-body">
			<h4 class="card-title">Mix Item</h4>
			            <form class="form-sample"  [formGroup]="searchProductForm"> 
 						 <div class="form-group row">
                            <div class="col">
                                <label>Search Product</label>
                                <div id="the-basics">
                                    <input class="typeahead"  id="searchProduct" formControlName="searchProduct" 
                                    name="searchProduct" type="text" placeholder="Search for New Item"> </div>
                            </div>  
                            <div class="col">
                              <label style="color: transparent;">-</label>
                              <button type="submit"  [disabled]="searchProductForm.invalid" data-toggle="modal" 
                              data-target="#exampleModal" (click)="searchbyname()" class="btn btn-success mr-2" style="min-width: 100%;">Search</button>
                          </div>                                                          
                        </div>
		              </form>        		

                    <div class="table-responsive">
                    <table class="table table-hover" >
                      <thead>
                        <tr>
                          <th>Merek</th>
                          <th>Name</th>
                          <th>Description</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody >
                       <tr *ngFor="let listOfMixItem of listOfMixItem | paginate: { itemsPerPage: 10, currentPage: p }; let i= index">
                          <td>{{listOfMixItem.Merk}}</td>
                          <td>{{listOfMixItem.NamaBarang}}</td>
                          <td>{{listOfMixItem.ProductDetail}}<br>{{listOfMixItem.ProductDetail2}}</td>
                          <td><i id="icon" class="link-icon mdi mdi-delete-forever" style="color: darkred"
                            data-toggle="modal" data-target="#deleteModal" (click)="deleteConfirm(listOfMixItem.Id)"></i>
                          </td>
                       </tr> 
                       <td colspan="4" style="text-align: center;">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                     </td>                             
                      </tbody>
                    </table>
                	</div>                 
		</div>
	</div>
</div>

<!--searchByName -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Product List</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
          <div class="modal-body" style="overflow-y: auto; max-height:400px;">
            <table class="table table-hover" >
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Brand</th>
                    <th>Category</th>
                  </tr>
                </thead>
                <tbody >
                 <tr *ngFor="let listProductMaster of listOfProductMasterModel; let i= index" style="cursor: pointer;" data-toggle="modal" 
                 data-target="#exampleModal2" (click)="searchbynameDetail(listProductMaster.Id, 
                 listProductMaster.CategoryId,listProductMaster.MerkId)">
                    <td>{{listProductMaster.NamaBarang}}</td>
                    <td>{{listProductMaster.NamaMerk}}</td>
                    <td>{{listProductMaster.NamaCategory}}</td>
                 </tr>       
                </tbody>
              </table>

          </div>          
        <div class="modal-footer">
         
          <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>

<!-- Detail product to add-->
<div class="modal fade" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Product Name</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
          <div class="modal-body" style="overflow-y: auto; max-height:400px;">

            <div class="table-responsive">
                                <!-- table for type joran-->
                                <table class="table table-hover" *ngIf="productTypeforHTML=='Rod'">
                                    <thead>
                                      <tr>
                                        <th></th>
                                        <th>Product Name</th>
                                        <th>Brand</th>
                                        <th>Size</th>
                                        <th>Min Lbs</th>
                                        <th>Max Lbs</th>
                                        <th>Ring</th>
                                        <th>Min PE</th>
                                        <th>Max PE</th>
                                        <th>Colour</th>
                                        <th>Material</th>
                                        <th>Prod. Date</th>
                                        <th>Type</th>
                                      </tr>
                                    </thead>
                                    <tbody >
                                     <tr *ngFor="let listJoranDetail of listOfProductDetailJoran; let i= index" >
                                        <td> <button type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" 
                                        (click)="addToList(listJoranDetail)">Add</button></td>
                                        <td>{{listJoranDetail.Nama}}</td>
                                        <td>{{listJoranDetail.Merk}}</td>
                                        <td>{{listJoranDetail.Ukuran}}</td>
                                        <td>{{listJoranDetail.MinLbs}}</td>
                                        <td>{{listJoranDetail.MaxLbs}}</td>
                                        <td>{{listJoranDetail.Ring}}</td>
                                        <td>{{listJoranDetail.MinPE}}</td>
                                        <td>{{listJoranDetail.MaxPE}}</td>
                                        <td>{{listJoranDetail.Warna}}</td>
                                        <td>{{listJoranDetail.Bahan}}</td>
                                        <td>{{listJoranDetail.TahunProduksi}}</td>
                                        <td>{{listJoranDetail.Tipe}}</td>
                                     </tr>       
                                    </tbody>
                                  </table>
              
                                  <!-- table for type Reel-->
                                  <table class="table table-hover" *ngIf="productTypeforHTML=='Reel'">
                                    <thead>
                                      <tr>
                                        <th></th>
                                        <th>Product Name</th>
                                        <th>Brand</th>
                                        <th>Size</th>
                                        <th>Type</th>
                                        <th>Ball Bearing</th>
                                        <th>Colour</th>
                                        <th>PH Type</th>
                                        <th>Way Type</th>
                                      </tr>
                                    </thead>
                                    <tbody >
                                     <tr *ngFor="let listReelDetail of listOfProductDetailReel; let i= index" >
                                      <td> <button type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" 
                                        (click)="addToList(listReelDetail)">Add</button></td>                         
                                        <td>{{listReelDetail.Nama}}</td>
                                        <td>{{listReelDetail.Merk}}</td>
                                        <td>{{listReelDetail.Ukuran}}</td>
                                        <td>{{listReelDetail.Tipe}}</td>
                                        <td>{{listReelDetail.BallBearing}}</td>
                                        <td>{{listReelDetail.Warna}}</td>
                                        <td>{{listReelDetail.PhType}}</td>
                                        <td>{{listReelDetail.WayType}}</td>
                                     </tr>       
                                    </tbody>
                                  </table>
              
                                  <!-- table for type line-->
                                  <table class="table table-hover" *ngIf="productTypeforHTML=='Line'">
                                    <thead>
                                      <tr>
                                        <th></th>                          
                                        <th>Product Name</th>
                                        <th>Brand</th>
                                        <th>Size</th>
                                        <th>Msr. Unit</th>
                                        <th>Type</th>
                                        <th>Colour</th>
                                        <th>Lbs</th>
                                        <th>PE</th>
                                        <th>Fluorocarbon</th>
                                        <th>Connector</th>
                                        <th>Diameter</th>
                                        <th>Power</th>
                                      </tr>
                                    </thead>
                                    <tbody >
                                     <tr *ngFor="let listLineDetail of listOfProductDetailLine; let i= index" >
                                      <td><button type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" 
                                        (click)="addToList(listLineDetail)">Add</button></td>                             
                                        <td>{{listLineDetail.Nama}}</td>
                                        <td>{{listLineDetail.Merk}}</td>
                                        <td>{{listLineDetail.Ukuran}}</td>
                                        <td>{{listLineDetail.Satuan}}</td>
                                        <td>{{listLineDetail.Tipe}}</td>
                                        <td>{{listLineDetail.Warna}}</td>
                                        <td>{{listLineDetail.Lbs}}</td>
                                        <td>{{listLineDetail.Pe}}</td>
                                        <td>{{listLineDetail.TypeFluoroCarbon}}</td>
                                        <td>{{listLineDetail.TypeConnectorSenar}}</td>
                                        <td>{{listLineDetail.Diameter}}</td>
                                        <td>{{listLineDetail.Kekuatan}}</td>
                                     </tr>       
                                    </tbody>
                                  </table>
              
                                  <!-- table for type kail-->
                                  <table class="table table-hover" *ngIf="productTypeforHTML=='Kail'">
                                    <thead>
                                      <tr>
                                        <th></th>                            
                                        <th>Product Name</th>
                                        <th>Brand</th>
                                        <th>Size</th>
                                      </tr>
                                    </thead>
                                    <tbody >
                                     <tr *ngFor="let listKailDetail of listOfProductDetailKail; let i= index" >
                                      <td><button type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" 
                                        (click)="addToList(listKailDetail)">Add</button></td>                            
                                        <td>{{listKailDetail.Nama}}</td>
                                        <td>{{listKailDetail.Merk}}</td>
                                        <td>{{listKailDetail.Ukuran}}</td>
                                     </tr>       
                                    </tbody>
                                  </table>      
              
                                  <!-- table for type Acc-->
                                  <table class="table table-hover" *ngIf="productTypeforHTML=='Acc'">
                                    <thead>
                                      <tr>
                                        <th></th>                            
                                        <th>Product Name</th>
                                        <th>Brand</th>
                                        <th>Ukuran</th>
                                        <th>Colour</th>
                                        <th>Type</th>
                                        <th>Size</th>
                                        <th>Lbs</th>
                                        <th>Liter</th>                                                                                                        
                                      </tr>
                                    </thead>
                                    <tbody >
                                     <tr *ngFor="let listAccDetail of listOfProductDetailAcc; let i= index" >
                                      <td><button type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" 
                                        (click)="addToList(listAccDetail)">Add</button></td>                             
                                        <td>{{listAccDetail.Nama}}</td>
                                        <td>{{listAccDetail.Merk}}</td>
                                        <td>{{listAccDetail.Ukuran}}</td>
                                        <td>{{listAccDetail.Warna}}</td>
                                        <td>{{listAccDetail.Tipe}}</td>
                                        <td>{{listAccDetail.Size}}</td>
                                        <td>{{listAccDetail.Lbs}}</td>
                                        <td>{{listAccDetail.Liter}}</td>                                                                                                        
                                     </tr>       
                                    </tbody>
                                  </table>  
            </div>

          </div>          
        <div class="modal-footer">
         
          <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>

<!-- Delete Mix Item-->
<div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Delete Mix Item</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
          <div class="modal-body" style="overflow-y: auto; max-height:400px;">
              <p>Delete This Product ?</p>
          </div>          
        <div class="modal-footer">
          <button type="button" class="btn btn-success" data-dismiss="modal" (click)="deleteMixItem()">Delete</button>
          <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>