import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators,ReactiveFormsModule } from '@angular/forms';
import  { callGlobalFuction } from "../../callGlobalFuction";
import { Router,ActivatedRoute } from "@angular/router";
import { varian } from '../../model/config/varian';

@Component({
  selector: 'app-varianumpan',
  templateUrl: './varianumpan.component.html',
  styleUrls: ['./varianumpan.component.css']
})
export class VarianumpanComponent implements OnInit {

  constructor(private fb: FormBuilder, private getService:callGlobalFuction,
    public router:Router,public route:ActivatedRoute) { }

    serviceReturn:any;
    listOfBahan=[]; 
    varianSetting :varian;
    varianSettingUpdate :varian;
    p:number=1;

    updateVarianForm= new FormGroup({
      id: new FormControl('',[
        Validators.required  
      ]), 
      varianName: new FormControl('',[
        Validators.required
      ]),     
      varianCode: new FormControl('',[
        Validators.required
      ]),  
    });

    insertVarianForm= new FormGroup({
      varianName: new FormControl('',[
        Validators.required
      ]),     
      varianCode: new FormControl('',[
        Validators.required
      ]),  
    });

    searchVarian()
    {
  
      let jsonInput = {};
      jsonInput['kode']="bahan";
      jsonInput['categoryId']="6";
      let res={}
        let stringJsonInput = JSON.stringify(jsonInput);
        this.listOfBahan=[];
  
         res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
        .subscribe( 
          data => {
            this.serviceReturn = data;
            if(this.serviceReturn.status=="S")
            {
             console.log(this.serviceReturn);
             for(let i=0;i<this.serviceReturn.listCode.length;i++)
             {
             this.varianSetting = new varian();
             this.varianSetting.Id = this.serviceReturn.listCode[i].id;
             this.varianSetting.NamaVarian = this.serviceReturn.listCode[i].namaBahan;
             this.varianSetting.KodeVarian = this.serviceReturn.listCode[i].kodeBahan;
             this.listOfBahan.push(this.varianSetting);
             }
            }
            else{
              alert("List of Varian Not Found");
            }
          }
        )  
    }
    
    openModalUpdateVarian(varianModel:any)
    {
        this.varianSettingUpdate = varianModel;
        this.updateVarianForm.get('id').setValue(this.varianSettingUpdate.Id);
        this.updateVarianForm.get('varianName').setValue(this.varianSettingUpdate.NamaVarian);
        this.updateVarianForm.get('varianCode').setValue(this.varianSettingUpdate.KodeVarian);
    }

    serviceReturn2:any;
    updateVarian()
    {
      let jsonInput = {};
      jsonInput['id']=parseInt(this.updateVarianForm.get('id').value);
      jsonInput['nama']=this.updateVarianForm.get('varianName').value;
      jsonInput['kode']= this.updateVarianForm.get('varianCode').value;
      jsonInput['tipeKode']= "bahan"
      jsonInput['keterangan']= "";
      jsonInput['categoryId']= "6";
      console.log(jsonInput)
  
      let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      console.log(stringJsonInput);
  
       res=this.getService.restItemsServiceGetwithParamRestItems('updateCode',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn2 = data;
          console.log(this.serviceReturn2)
          if(this.serviceReturn2.status=="S")
          {
            alert("Success Update Varian");
            location.reload();
          }
          else{
            alert("Failed Update Varian "+this.serviceReturn2.errMes);
          }
        }
      )  
    }


    serviceReturn3:any;
    deleteTempId=0;
    deleteConfirm(id:number)
    {
      this.deleteTempId=0;
      this.deleteTempId=id;
    }
    deleteVarian()
    {
      let jsonInput = {};
      jsonInput['codeId'] = this.deleteTempId;
      jsonInput['tipeKode'] = "bahan";
      jsonInput['categoryId']= "6";
   
      let res={}
        let stringJsonInput = JSON.stringify(jsonInput);
        console.log(stringJsonInput);
         res=this.getService.restItemsServiceGetwithParamRestItems('deleteCode',stringJsonInput)
        .subscribe(
          data => {
            this.serviceReturn3 = data;
            console.log(this.serviceReturn3);
            if(this.serviceReturn3.status=="S")
            {
              alert("Success Delete Varian");
              location.reload();
            }
   
            else
            {
              alert("Failed Delete Varian "+this.serviceReturn3.errMes);
            }
          }
        )   
    }

    serviceReturn4:any;
   insertVarian()
   {
     let jsonInput = {};
     jsonInput['nama']=this.insertVarianForm.get('varianName').value;
     jsonInput['kode']= this.insertVarianForm.get('varianCode').value;
     jsonInput['tipeKode']= "bahan"
     jsonInput['keterangan']= "";
     jsonInput['categoryId']= "6";
     console.log(jsonInput)
 
     let res={}
     let stringJsonInput = JSON.stringify(jsonInput);
     console.log(stringJsonInput);
 
      res=this.getService.restItemsServiceGetwithParamRestItems('insertNewCode',stringJsonInput)
     .subscribe( 
       data => {
         this.serviceReturn4 = data;
         console.log(this.serviceReturn4)
         if(this.serviceReturn4.status=="S")
         {
           alert("Success insert Varian");
           location.reload();
         }
         else{
           alert("Failed Insert Varian "+this.serviceReturn4.errMes);
         }
       }
     )  
   }


  ngOnInit(): void {
    this.searchVarian();
  }

}
