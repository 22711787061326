<div class="col-md-12 grid-margin stretch-card">
    <div class="card">
		<div class="card-body">
            <div class="row">
            <div class="col-md-2">
                <h4 class="card-title">Product Category : </h4>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <select type="text" class="form-control" id="productTypeforHTMLFilter" [(ngModel)]="productTypeforHTMLFilter" (change)="searchXTCodebyCategory()">
                        <option value="" >- Select Category -</option> 
                        <option value="Rod">Rod</option>
                        <option value="Reel">Reel</option> 
                        <option value="Line">Line</option>
                        <option value="Kail">Hook</option>
                        <option value="Acc">Acc</option> 
                        <option value="Bait">Bait</option>      
                    </select> 
                  </div>
            </div>    
        </div>
            <div class="row">
                <div class="col-md-12">
                    <!-- form untuk filter joran -->
                    <form [formGroup]="inquiryProductJoranForm" *ngIf="productTypeforHTMLFilter == 'Rod' ">
                        <div class="form-group row">                        
                            <div class="col">
                            <label>Size</label>
                            <div id="the-basics">
                                <input class="typeahead"  id="ukuran" formControlName="ukuran" name="ukuran" type="text" placeholder="Size"> </div>
                            </div>
                            <!-- <div class="col">
                            <label>Min LBS</label>
                            <div id="bloodhound">
                                <input class="typeahead"  id="minLbs" formControlName="minLbs" name="minLbs" type="text" placeholder="Min LBS"> </div>
                            </div> -->
                            <div class="col">
                                <label>Max LBS</label>
                                <div id="bloodhound">
                                    <input class="typeahead"  id="maxLbs" formControlName="maxLbs" name="maxLbs" type="text" placeholder="Max LBS"> </div>
                            </div>
                            <div class="col">
                                <label>Production Date</label>
                                <div id="bloodhound">
                                    <input class="typeahead"  id="tahunProduksi" formControlName="tahunProduksi" name="tahunProduksi" type="text" placeholder="Production Date"> </div>
                            </div>
                            <div class="col">
                                <label>Ring</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="ring" formControlName="ring" name="ring" type="text" placeholder="Ring">
                                        <option value="" >- Select Ring -</option> 
                                        <option value="Fuji">Fuji</option>
                                        <option value="Non Fuji">Non Fuji</option>       
                                    </select> </div>
                            </div>                            

                        </div>
                        <div class="form-group row">   
                            <div class="col">
                                <label>Color</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="warna" formControlName="warna" name="warna" type="text" placeholder="Color">
                                        <option value="" >- Select Color -</option>  
                                        <option value="{{warnaList.namaWarna}}" *ngFor="let warnaList of listOfWarna;let i=index" >{{warnaList.namaWarna}}</option>  
                                    </select> </div>
                            </div>                            
                            <div class="col">
                                <label>Material</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="bahan" formControlName="bahan" name="bahan" type="text" placeholder="Material">
                                        <option value="" >- Select Material -</option>
                                        <option value="{{materialList}}" *ngFor="let materialList of listOfBahanJoran;let i=index" >{{materialList}}</option>  
                                    </select> </div>
                            </div>  
                            <div class="col">
                                <label>Type</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="tipe" formControlName="tipe" name="tipe" type="text" placeholder="Type">
                                        <option value="" >- Select Type -</option> 
                                        <option value="{{typeList}}" *ngFor="let typeList of listOfTipeJoran;let i=index" >{{typeList}}</option>
                                    </select> </div>
                            </div> 
                            <div class="col">
                                <label style="color: transparent;">-</label>
                                <div id="bloodhound">
                                    <button type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" (click)="searchJoran()">Search</button>                                    
                                     </div>
                            </div>                                                        	                   		
                        </div>
                    </form>
                    <!-- end of filter joran -->

                     <!-- form untuk filter reel -->
                     <form [formGroup]="inquiryProductReelForm" *ngIf="productTypeforHTMLFilter == 'Reel' ">
                        <div class="form-group row">                        
                            <div class="col">
                            <label>Size</label>
                            <div id="the-basics">
                                <input class="typeahead"  id="ukuran" formControlName="ukuran" name="ukuran" type="text" placeholder="Size"> </div>
                            </div>
                            <div class="col">
                            <label>Ball Bearings</label>
                            <div id="bloodhound">
                                <input class="typeahead"  id="ballBearing" formControlName="ballBearing" name="ballBearing" type="text" placeholder="Ball Bearings"> </div>
                            </div>
                            <div class="col">
                                <label>PH Type</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="tipePh" formControlName="tipePh" name="tipePh" type="text" placeholder="PH Type">
                                        <option value="" >- Select PH Type -</option> 
                                        <option value="PH">PH</option>
                                        <option value="Non PH">Non PH</option>       
                                    </select> </div>
                            </div>
                            <div class="col">
                                <label>Way Type</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="tipeWay" formControlName="tipeWay" name="tipeWay" type="text" placeholder="Way Type">
                                        <option value="" >- Select Way Type -</option> 
                                        <option value="Way">Way</option>
                                        <option value="Non One">Non One</option>       
                                    </select> </div>
                            </div>                              

                        </div>
                        <div class="form-group row">   
                            <div class="col">
                                <label>Color</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="warna" formControlName="warna" name="warna" type="text" placeholder="Color">
                                        <option value="" >- Select Color -</option>  
                                        <option value="{{warnaList.namaWarna}}" *ngFor="let warnaList of listOfWarna;let i=index" >{{warnaList.namaWarna}}</option>  
                                    </select> </div>
                            </div>                            
                            <div class="col">
                                <label>Reel Type</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="tipe" formControlName="tipe" name="tipe" type="text" placeholder="Reel Type">
                                        <option value="" >- Select Reel Type -</option>
                                        <option value="{{typeJoran.namaTipe}}" *ngFor="let typeJoran of listOfTipeJoran;let i=index" >{{typeJoran.namaTipe}}</option>  
                                    </select> </div>
                            </div>  
                            <div class="col">
                                <label style="color: transparent;">-</label>
                                <div id="bloodhound">
                                    <button type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" (click)="searchReel()">Search</button>                                    
                                     </div>
                            </div>                                                        	                   		
                        </div>
                    </form>
                    <!-- end of filter reel -->

                     <!-- form untuk filter line -->
                     <form [formGroup]="inquiryProductLineForm" *ngIf="productTypeforHTMLFilter == 'Line' ">
                        <div class="form-group row">                        
                            <div class="col">
                            <label>Length Size</label>
                            <div id="the-basics">
                                <input class="typeahead"  id="ukuranPanjang" formControlName="ukuranPanjang" name="ukuranPanjang" type="text" placeholder="Length Size"> </div>
                            </div>
                            <div class="col">
                                <label>Measurement Unit</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="satuanUkur" formControlName="satuanUkur" name="satuanUkur" type="text" placeholder="Msr Unit">
                                        <option value="" >- Select Msr Unit -</option> 
                                        <option value="Yards">Yards</option>
                                        <option value="Meter">Meter</option>       
                                    </select> </div>
                            </div>
                            <div class="col">
                                <label>Strength</label>
                                <div id="the-basics">
                                    <input class="typeahead"  id="kekuatan" formControlName="kekuatan" name="kekuatan" type="text" placeholder="Strength"> </div>
                                </div>                           
                            <div class="col">
                            <label>PE</label>
                            <div id="the-basics">
                                <input class="typeahead"  id="pe" formControlName="pe" name="pe" type="text" placeholder="PE"> </div>
                            </div>   
                        </div>

                        <div class="form-group row">                        
                            <div class="col">
                            <label>LBS</label>
                            <div id="the-basics">
                                <input class="typeahead"  id="lbs" formControlName="lbs" name="lbs" type="text" placeholder="LBS"> </div>
                            </div>
                            <div class="col">
                                <label>Diameter</label>
                                <div id="the-basics">
                                    <input class="typeahead"  id="diameter" formControlName="diameter" name="diameter" type="text" placeholder="Diameter"> </div>
                                </div>                           
                            <div class="col">
                                <label>Connector Type</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="tipeKonektorSenar" formControlName="tipeKonektorSenar" name="tipeKonektorSenar" type="text" placeholder="Connector Type">
                                        <option value="" >- Select Connector Type -</option> 
                                        <option value="Single Pack">Single Pack</option>
                                        <option value="Connecting">Connecting</option>
                                        <option value="Roll">Roll</option>       
                                    </select> </div>
                            </div>
                            <div class="col">
                                <label>Fl. Carbon Type</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="tipeFluorCarbon" formControlName="tipeFluorCarbon" name="tipeFluorCarbon" type="text" placeholder="Fl. Carbon Type">
                                        <option value="" >- Select Fl. Carbon Type -</option> 
                                        <option value="Y">Yes</option>
                                        <option value="N">No</option>       
                                    </select> </div>
                            </div>                            
                                
                        </div>                        

                        <div class="form-group row">   
                            <div class="col">
                                <label>Type No.</label>
                                <div id="the-basics">
                                    <input class="typeahead"  id="tipeNo" formControlName="tipeNo" name="tipeNo" type="text" placeholder="Tipe No"> </div>
                                </div>                              
                            <div class="col">
                                <label>Color</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="warna" formControlName="warna" name="warna" type="text" placeholder="Color">
                                        <option value="" >- Select Color -</option>  
                                        <option value="{{warnaList.namaWarna}}" *ngFor="let warnaList of listOfWarna;let i=index" >{{warnaList.namaWarna}}</option>  
                                    </select> </div>
                            </div>                            
                            <div class="col">
                                <label>Line Type</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="tipe" formControlName="tipe" name="tipe" type="text" placeholder="Line Type">
                                        <option value="" >- Select Line Type -</option>
                                        <option value="{{typeLine}}" *ngFor="let typeLine of listOfTipeLine;let i=index" >{{typeLine}}</option>  
                                    </select> </div>
                            </div>  
                            <div class="col">
                                <label style="color: transparent;">-</label>
                                <div id="bloodhound">
                                    <button type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" (click)="searchLine()">Search</button>                                    
                                     </div>
                            </div>                                                        	                   		
                        </div>
                    </form>
                    <!-- end of filter line -->

                     <!-- form untuk filter kail -->
                     <form [formGroup]="inquiryProductKailForm" *ngIf="productTypeforHTMLFilter == 'Kail' ">
                        <div class="form-group row">                        
                            <div class="col">
                            <label>Size</label>
                            <div id="the-basics">
                                <input class="typeahead"  id="ukuran" formControlName="ukuran" name="ukuran" type="text" placeholder="Size"> </div>
                            </div>
                            <div class="col">
                                <label style="color: transparent;">-</label>
                                <div id="bloodhound">
                                    <button type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" (click)="searchKail()">Search</button>                                    
                                     </div>
                            </div>                             
                        </div>
                    </form>
                    <!-- end of filter kail -->    

                     <!-- form untuk filter acc -->
                     <form [formGroup]="inquiryProductAccForm" *ngIf="productTypeforHTMLFilter == 'Acc' ">
                        <div class="form-group row">  
                            <div class="col">
                                <label>Type</label>
                                <div id="bloodhound">
                                  <select class="typeahead"  id="tipe" formControlName="tipe" name="tipe" type="text" placeholder="Color">
                                      <option value="" >- Select Type -</option>  
                                      <option value="{{tipeList.namaTipe}}" *ngFor="let tipeList of listOfTipeAcc;let i=index" >{{tipeList.namaTipe}}</option>  
                                  </select> </div>
                            </div>                                                  
                            <div class="col">
                            <label>Size</label>
                            <div id="the-basics">
                                <input class="typeahead"  id="ukuran" formControlName="ukuran" name="ukuran" type="text" placeholder="Size"> </div>
                            </div>
                            <div class="col">
                                <label>Color</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="warna" formControlName="warna" name="warna" type="text" placeholder="Color">
                                        <option value="" >- Select Color -</option>  
                                        <option value="{{warnaList.namaWarna}}" *ngFor="let warnaList of listOfWarna;let i=index" >{{warnaList.namaWarna}}</option>  
                                    </select> </div>
                            </div>
                        </div>   
                        <div class="form-group row">    
                            <div class="col">
                                <label>Size</label>
                                <div id="the-basics">
                                    <input class="typeahead"  id="size" formControlName="size" name="size" type="text" placeholder="size"> </div>
                            </div>
                            <div class="col">
                              <label>Lbs</label>
                              <div id="the-basics">
                                  <input class="typeahead"  id="lbs" formControlName="lbs" name="lbs" type="text" placeholder="lbs"> </div>
                          </div>
                          <div class="col">
                            <label>Liter</label>
                            <div id="the-basics">
                                <input class="typeahead"  id="liter" formControlName="liter" name="liter" type="text" placeholder="liter"> </div>     
                      </div>                                                        
                            <div class="col">
                                <label style="color: transparent;">-</label>
                                <div id="bloodhound">
                                    <button type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" (click)="searchAcc()">Search</button>                                    
                                     </div>
                            </div>                             
                        </div>
                    </form>
                    <!-- end of filter acc -->      
                    
                     <!-- form untuk filter bait -->
                     <form [formGroup]="inquiryProductBaitForm" *ngIf="productTypeforHTMLFilter == 'Bait' ">
                        <div class="form-group row">  
                            <div class="col">
                                <label>Type</label>
                                <div id="bloodhound">
                                  <select class="typeahead"  id="tipe" formControlName="tipe" name="tipe" type="text" placeholder="Color">
                                      <option value="" >- Select Type -</option>  
                                      <option value="{{tipeList.namaTipe}}" *ngFor="let tipeList of listOfTipeBait;let i=index" >{{tipeList.namaTipe}}</option>  
                                  </select> </div>
                            </div>                                                  
                            <div class="col">
                            <label>Size</label>
                            <div id="the-basics">
                                <input class="typeahead"  id="ukuran" formControlName="ukuran" name="ukuran" type="text" placeholder="Size"> </div>
                            </div>
                            <div class="col">
                                <label>Color</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="warna" formControlName="warna" name="warna" type="text" placeholder="Color">
                                        <option value="" >- Select Color -</option>  
                                        <option value="{{warnaList.namaWarna}}" *ngFor="let warnaList of listOfWarna;let i=index" >{{warnaList.namaWarna}}</option>  
                                    </select> </div>
                            </div>
                        </div>   
                        <div class="form-group row">    
                            <div class="col">
                                <label>Varian</label>
                                <div id="the-basics">
                                    <select class="typeahead"  id="varian" formControlName="varian" name="varian" type="text" placeholder="Varian">
                                        <option value="" >- Select Varian -</option>  
                                        <option value="{{varianList.namaVarian}}" *ngFor="let varianList of listOfVarianBait;let i=index" >{{varianList.namaVarian}}</option>  
                                    </select> </div>
                            </div>
                            <div class="col">
                              <label>Berat</label>
                              <div id="the-basics">
                                  <input class="typeahead"  id="berat" formControlName="berat" name="berat" type="text" placeholder="Weight"> </div>
                          </div>                                                    
                            <div class="col">
                                <label style="color: transparent;">-</label>
                                <div id="bloodhound">
                                    <button type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" (click)="searchBait()">Search</button>                                    
                                     </div>
                            </div>                             
                        </div>
                    </form>
                    <!-- end of filter bait -->                       
                    
                </div>
            </div>
        </div>
    </div>
</div>

<div class="col-md-12 grid-margin stretch-card">
	<div class="card">
		<div class="card-body">
            <h4 class="card-title">Detail Product :</h4>
            
                	<div class="table-responsive">
                    
                    <!-- table for type joran-->
                    <table class="table table-hover" *ngIf="productTypeforHTMLFilter == 'Rod' ">
                      <thead>
                        <tr>
                          <th>Product Name</th>
                          <th>Brand</th>
                          <th>Size</th>
                          <th>Min Lbs</th>
                          <th>Max Lbs</th>
                          <th>Ring</th>
                          <th>Min PE</th>
                          <th>Max PE</th>
                          <th>Colour</th>
                          <th>Material</th>
                          <th>Prod. Date</th>
                          <th>Type</th>
                        </tr>
                      </thead>
                      <tbody >
                        <tr *ngFor="let listJoranDetail of listOfProductDetailJoran | paginate: { itemsPerPage: 10, currentPage: pJoran }; let i= index" >
                          <td>{{listJoranDetail.Nama}}</td>
                          <td>{{listJoranDetail.Merk}}</td>
                          <td>{{listJoranDetail.Ukuran}}</td>
                          <td>{{listJoranDetail.MinLbs}}</td>
                          <td>{{listJoranDetail.MaxLbs}}</td>
                          <td>{{listJoranDetail.Ring}}</td>
                          <td>{{listJoranDetail.MinPE}}</td>
                          <td>{{listJoranDetail.MaxPE}}</td>
                          <td>{{listJoranDetail.Warna}}</td>
                          <td>{{listJoranDetail.Bahan}}</td>
                          <td>{{listJoranDetail.TahunProduksi}}</td>
                          <td>{{listJoranDetail.Tipe}}</td>
                       </tr>
                       <tr>
                        <td colspan="12" style="text-align: center;">
                           <pagination-controls (pageChange)="pJoran = $event"></pagination-controls>
                        </td>
                      </tr>        
                      </tbody>
                    </table>

                      <!-- table for type reel-->
                      <table class="table table-hover" *ngIf="productTypeforHTMLFilter == 'Reel' ">
                        <thead>
                          <tr>
                            <th>Product Name</th>
                            <th>Brand</th>
                            <th>Size</th>
                            <th>PH Type</th>
                            <th>Way Type</th>
                            <th>Ball Bearing</th>
                            <th>Colour</th>
                            <th>Type</th>
                          </tr>
                        </thead>
                        <tbody >
                          <tr *ngFor="let listReelDetail of listOfProductDetailReel | paginate: { itemsPerPage: 10, currentPage: pReel }; let i= index" >
                            <td>{{listReelDetail.Nama}}</td>
                            <td>{{listReelDetail.Merk}}</td>
                            <td>{{listReelDetail.Ukuran}}</td>
                            <td>{{listReelDetail.PhType}}</td>
                            <td>{{listReelDetail.WayType}}</td>
                            <td>{{listReelDetail.BallBearing}}</td>
                            <td>{{listReelDetail.Warna}}</td>
                            <td>{{listReelDetail.Tipe}}</td>
                         </tr>
                         <tr>
                          <td colspan="8" style="text-align: center;">
                             <pagination-controls (pageChange)="pReel = $event"></pagination-controls>
                          </td>
                        </tr>        
                        </tbody>
                      </table>

                      <!-- table for type line-->
                      <table class="table table-hover" *ngIf="productTypeforHTMLFilter == 'Line' ">
                        <thead>
                          <tr>
                            <th>Product Name</th>
                            <th>Brand</th>
                            <th>Strength</th>
                            <th>Length</th>
                            <th>Msr Unit</th>
                            <th>Connecting Type</th>
                            <th>Fl. Carbon Type</th>
                            <th>PE</th>
                            <th>LBS</th>
                            <th>Diameter</th>
                            <th>Type No.</th>
                            <th>Colour</th>
                            <th>Type</th>
                          </tr>
                        </thead>
                        <tbody >
                          <tr *ngFor="let listLineDetail of listOfProductDetailLine | paginate: { itemsPerPage: 10, currentPage: pLine }; let i= index" >
                            <td>{{listLineDetail.Nama}}</td>
                            <td>{{listLineDetail.Merk}}</td>
                            <td>{{listLineDetail.Kekuatan}}</td>
                            <td>{{listLineDetail.Ukuran}}</td>
                            <td>{{listLineDetail.Satuan}}</td>
                            <td>{{listLineDetail.typeConnectorSenar}}</td>
                            <td>{{listLineDetail.typeFluoroCarbon}}</td>
                            <td>{{listLineDetail.Pe}}</td>
                            <td>{{listLineDetail.Lbs}}</td>
                            <td>{{listLineDetail.Diameter}}</td>
                            <td>{{listLineDetail.TipeNo}}</td>
                            <td>{{listLineDetail.Warna}}</td>
                            <td>{{listLineDetail.Tipe}}</td>
                         </tr>
                         <tr>
                          <td colspan="13" style="text-align: center;">
                             <pagination-controls (pageChange)="pLine = $event"></pagination-controls>
                          </td>
                        </tr>        
                        </tbody>
                      </table>   

                      <!-- table for type kail-->
                      <table class="table table-hover" *ngIf="productTypeforHTMLFilter == 'Kail' ">
                        <thead>
                          <tr>
                            <th>Product Name</th>
                            <th>Brand</th>
                            <th>Size</th>
                          </tr>
                        </thead>
                        <tbody >
                          <tr *ngFor="let listKailDetail of listOfProductDetailKail | paginate: { itemsPerPage: 10, currentPage: pKail }; let i= index" >
                            <td>{{listKailDetail.Nama}}</td>
                            <td>{{listKailDetail.Merk}}</td>
                            <td>{{listKailDetail.Ukuran}}</td>
                         </tr>
                         <tr>
                          <td colspan="3" style="text-align: center;">
                             <pagination-controls (pageChange)="pKail = $event"></pagination-controls>
                          </td>
                        </tr>        
                        </tbody>
                      </table>   

                      <!-- table for type acc-->
                      <table class="table table-hover" *ngIf="productTypeforHTMLFilter == 'Acc' ">
                        <thead>
                          <tr>
                            <th>Product Name</th>
                            <th>Brand</th>
                            <th>Ukuran</th>
                            <th>Colour</th>     
                            <th>Type</th>
                            <th>Size</th>
                            <th>Lbs</th>
                            <th>Liter</th>                                                       
                          </tr>
                        </thead>
                        <tbody >
                          <tr *ngFor="let listAccDetail of listOfProductDetailAcc | paginate: { itemsPerPage: 10, currentPage: pAcc }; let i= index" >
                            <td>{{listAccDetail.Nama}}</td>
                            <td>{{listAccDetail.Merk}}</td>
                            <td>{{listAccDetail.Ukuran}}</td>
                            <td>{{listAccDetail.Warna}}</td>
                            <td>{{listAccDetail.Tipe}}</td>
                            <td>{{listAccDetail.Size}}</td>
                            <td>{{listAccDetail.Lbs}}</td>
                            <td>{{listAccDetail.Liter}}</td>                     
                         </tr>
                         <tr>
                          <td colspan="8" style="text-align: center;">
                             <pagination-controls (pageChange)="pAcc = $event"></pagination-controls>
                          </td>
                        </tr>        
                        </tbody>
                      </table>         
                      <!-- table for type bait-->
                      <table class="table table-hover" *ngIf="productTypeforHTMLFilter == 'Bait' ">
                        <thead>
                          <tr>
                            <th>Product Name</th>
                            <th>Brand</th>
                            <th>Ukuran</th>
                            <th>Colour</th>     
                            <th>Type</th>
                            <th>Weight</th>
                            <th>Varian</th>                                                     
                          </tr>
                        </thead>
                        <tbody >
                          <tr *ngFor="let listBaitDetail of listOfProductDetailBait | paginate: { itemsPerPage: 10, currentPage: pBait }; let i= index" >
                            <td>{{listBaitDetail.Nama}}</td>
                            <td>{{listBaitDetail.Merk}}</td>
                            <td>{{listBaitDetail.Ukuran}}</td>
                            <td>{{listBaitDetail.Warna}}</td>
                            <td>{{listBaitDetail.Tipe}}</td>
                            <td>{{listBaitDetail.Berat}}</td>
                            <td>{{listBaitDetail.Varian}}</td>             
                         </tr>
                         <tr>
                          <td colspan="7" style="text-align: center;">
                             <pagination-controls (pageChange)="pBait = $event"></pagination-controls>
                          </td>
                        </tr>        
                        </tbody>
                      </table>                      

                </div>     
		</div>
	</div>
</div>