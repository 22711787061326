import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute, PreloadingStrategy } from "@angular/router";
import { FormGroup, FormControl, FormBuilder, Validators,ReactiveFormsModule } from '@angular/forms';
import  { callGlobalFuction } from "../../callGlobalFuction";
import { transactionMaster } from '../../model/transaction/transactionpos';
import  { imageGenerator } from "../../globalfunction/imageGenerator";
import  { PaymentMethod } from "../../globalfunction/methodUtil";
import pdfMake from 'pdfmake/build/pdfmake';
import { productmastermodel } from '../../model/product/productmaster';
import { productdetailjoranmodel } from '../../model/product/productDetailJoran';
import { productdetailreelmodel } from '../../model/product/productDetailReel';
import { productdetaillinemodel } from '../../model/product/productDetailLine';
import { productdetailkailmodel } from '../../model/product/productDetailKail';
import { productdetailaccmodel } from '../../model/product/productDetailAcc';
import { productdetailbaitmodel } from '../../model/product/productDetailBait';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { DatePipe } from '@angular/common';
import { stock } from '../../model/product/stock';
declare var $: any; 
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-grosirpos',
  templateUrl: './grosirpos.component.html',
  styleUrls: ['./grosirpos.component.css']
})
export class GrosirposComponent implements OnInit {

  constructor(private fb: FormBuilder, private getService:callGlobalFuction,public router:Router,public route:ActivatedRoute,
    public imageGenerator: imageGenerator, private datepipe: DatePipe, private paymentMethod:PaymentMethod) { }
    pdf:any;
    cariBarangForm= new FormGroup({
      // barcode: new FormControl('',[
      //   Validators.required
      // ])
      productName: new FormControl('',[
        Validators.required
      ])                
    });

    checkoutForm= new FormGroup({
      metodeBayar: new FormControl('',[
        Validators.required
      ]),
      jumlahUang: new FormControl('',[
      ])                  
    });

    serviceReturn:any;
    trMasterModel :transactionMaster;
    listOfTransactionModel=[];

    
    
    searchProductBarcode()
    {
      let jsonInput = {};
      jsonInput['barcode'] = this.cariBarangForm.get('barcode').value;
      jsonInput['transactionTypeId']=2;
      
      let res={}
        let stringJsonInput = JSON.stringify(jsonInput);
  
        console.log(stringJsonInput);
         res=this.getService.restItemsServiceGetwithParamRestItems('inquiryProductByBarcodePOS',stringJsonInput)
        .subscribe(
          data => {
            this.serviceReturn = data;
            
            if(this.serviceReturn.status=="S")
            {
              console.log(this.serviceReturn)
               this.trMasterModel = new transactionMaster();
               this.trMasterModel.Id = this.serviceReturn.result.productDetailId;
               this.trMasterModel.NamaBarang=this.serviceReturn.result.nama_barang;
               this.trMasterModel.NamaCategory=this.serviceReturn.result.nama_kategori;
               this.trMasterModel.MerkId=this.serviceReturn.result.merk_id;
               this.trMasterModel.CategoryId=this.serviceReturn.result.categoryId;
               this.trMasterModel.NamaMerk=this.serviceReturn.result.nama_merk;
               this.trMasterModel.SellPrice= "0";
               this.trMasterModel.Ukuran=this.serviceReturn.result.ukuran;
               this.trMasterModel.Qty= 1;
               this.listOfTransactionModel.push(this.trMasterModel);
               this.cariBarangForm.reset();
            }
            else
            {
              console.log(this.serviceReturn)
              alert("Master Product Search Failed");
              this.cariBarangForm.reset()
            }
          }
        )  
    }

  //inquiry master product
  serviceReturn5:any;
  productMasterModel :productmastermodel;
  listOfProductMasterModel=[];

    searchbyname()
    {
  
      let jsonInput = {};
      jsonInput['namaBarang'] = this.cariBarangForm.get('productName').value;
      
      let res={}
        let stringJsonInput = JSON.stringify(jsonInput);
        this.listOfProductMasterModel=[];
  
         res=this.getService.restItemsServiceGetwithParamRestItems('inquiryCategoryAndBrandByProductName',stringJsonInput)
        .subscribe(
          data => {
            this.serviceReturn5 = data;
            if(this.serviceReturn5.status=="S")
            {
              for(let i=0;i<this.serviceReturn5.listProduct.length;i++)
              {
              this.productMasterModel = new productmastermodel();
              this.productMasterModel.Id = this.serviceReturn5.listProduct[i].id;
              this.productMasterModel.MerkId = this.serviceReturn5.listProduct[i].merk_id;
              this.productMasterModel.CategoryId = this.serviceReturn5.listProduct[i].category_id;
              this.productMasterModel.NamaBarang = this.serviceReturn5.listProduct[i].nama_barang;
              this.productMasterModel.NamaMerk = this.serviceReturn5.listProduct[i].nama_merk;
              this.productMasterModel.NamaCategory = this.serviceReturn5.listProduct[i].nama_kategori;
              this.listOfProductMasterModel.push(this.productMasterModel);
              }
            }
            else
            {
              alert("Master Product Search Failed");
            }
          }
        )  
    }

    activeCategory : any;
    productTypeforHTML="";
       //inquiry detail product
  serviceReturn6:any;
  productDetailJoran :productdetailjoranmodel;
  listOfProductDetailJoran=[];
  productDetailReel :productdetailreelmodel;
  listOfProductDetailReel=[];
  productDetailLine :productdetaillinemodel;
  listOfProductDetailLine=[];
  productDetailKail :productdetailkailmodel;
  listOfProductDetailKail=[];
  productDetailAcc :productdetailaccmodel;
  listOfProductDetailAcc=[];
  productDetailBait :productdetailbaitmodel;
  listOfProductDetailBait=[];

    searchbynameDetail(id:string, categoryId:string, merkId:string)
  {
    this.activeCategory=categoryId;

    $('#exampleModal2').modal('hide'); 
    let jsonInput = {};
    
    let res={}
    jsonInput['productId'] = id;
    jsonInput['kategoriId'] = categoryId;
    jsonInput['merkId'] = merkId;
    let stringJsonInput = JSON.stringify(jsonInput);
    console.log(stringJsonInput)
      
       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryProductDetailAllByCategoryAndBrand',stringJsonInput)
      .subscribe(
        data => {
          this.serviceReturn6 = data;
          console.log(this.serviceReturn6)
          if(this.serviceReturn6.status=="S")
          {
        
            console.log(this.serviceReturn6)
            //inquiry detail untuk joran
            if(this.serviceReturn6.categoryId==1 && this.serviceReturn6.listProduct.listJoranDetail.length>0)
            {
              this.productTypeforHTML="Rod";
              this.listOfProductDetailJoran=[];
              for(let i=0;i<this.serviceReturn6.listProduct.listJoranDetail.length;i++)
              {
              this.productDetailJoran = new productdetailjoranmodel();
              this.productDetailJoran.Ukuran = this.serviceReturn6.listProduct.listJoranDetail[i].ukuran;
              this.productDetailJoran.Warna = this.serviceReturn6.listProduct.listJoranDetail[i].nama_warna;
              this.productDetailJoran.WarnaId = this.serviceReturn6.listProduct.listJoranDetail[i].warna_joran_id;
              this.productDetailJoran.Ring = this.serviceReturn6.listProduct.listJoranDetail[i].ring;
              this.productDetailJoran.Bahan = this.serviceReturn6.listProduct.listJoranDetail[i].nama_bahan;
              this.productDetailJoran.BahanId = this.serviceReturn6.listProduct.listJoranDetail[i].bahan_joran_id;
              this.productDetailJoran.Nama = this.serviceReturn6.listProduct.listJoranDetail[i].nama_barang;
              this.productDetailJoran.TahunProduksi = this.serviceReturn6.listProduct.listJoranDetail[i].tahun_produksi;
              this.productDetailJoran.MinLbs = this.serviceReturn6.listProduct.listJoranDetail[i].lbs_min;  
              this.productDetailJoran.MaxLbs = this.serviceReturn6.listProduct.listJoranDetail[i].nama_tipe_joran; 
              this.productDetailJoran.Merk = this.serviceReturn6.listProduct.listJoranDetail[i].nama_merk; 
              this.productDetailJoran.MerkId = this.serviceReturn6.listProduct.listJoranDetail[i].merk_id;
              this.productDetailJoran.MaxLbs = this.serviceReturn6.listProduct.listJoranDetail[i].lbs_max;  
              this.productDetailJoran.MaxPE = this.serviceReturn6.listProduct.listJoranDetail[i].pe_max;  
              this.productDetailJoran.MinPE = this.serviceReturn6.listProduct.listJoranDetail[i].pe_min; 
              this.productDetailJoran.Tipe = this.serviceReturn6.listProduct.listJoranDetail[i].nama_tipe;   
              this.productDetailJoran.TipeId = this.serviceReturn6.listProduct.listJoranDetail[i].tipe_joran_id;
              this.productDetailJoran.DetailId = this.serviceReturn6.listProduct.listJoranDetail[i].productDetailId; 
              this.productDetailJoran.Id = this.serviceReturn6.listProduct.listJoranDetail[i].productId;
              this.listOfProductDetailJoran.push(this.productDetailJoran);
              }
            }
            //end of inquiry detail untuk joran

             //inquiry detail untuk reel
             else if(this.serviceReturn6.categoryId==2 && this.serviceReturn6.listProduct.listReelDetail.length>0)
             {
               this.productTypeforHTML="Reel";
               this.listOfProductDetailReel=[];
               for(let i=0;i<this.serviceReturn6.listProduct.listReelDetail.length;i++)
               {
               this.productDetailReel = new productdetailreelmodel();
               console.log(this.serviceReturn6)
               this.productDetailReel.Ukuran = this.serviceReturn6.listProduct.listReelDetail[i].ukuran;                                                                 
               this.productDetailReel.WarnaId = this.serviceReturn6.listProduct.listReelDetail[i].warna_reel_id;
               this.productDetailReel.PhType = this.serviceReturn6.listProduct.listReelDetail[i].ph_type;
               this.productDetailReel.Warna = this.serviceReturn6.listProduct.listReelDetail[i].nama_warna;
               this.productDetailReel.BallBearing = this.serviceReturn6.listProduct.listReelDetail[i].ball_bearing;
               this.productDetailReel.TipeId = this.serviceReturn6.listProduct.listReelDetail[i].tipe_reel_id;
               this.productDetailReel.WayType = this.serviceReturn6.listProduct.listReelDetail[i].way_type;
               this.productDetailReel.Nama = this.serviceReturn6.listProduct.listReelDetail[i].nama_barang;
               this.productDetailReel.Tipe = this.serviceReturn6.listProduct.listReelDetail[i].nama_tipe;                                                                 
               this.productDetailReel.Merk = this.serviceReturn6.listProduct.listReelDetail[i].nama_merk;
               this.productDetailReel.MerkId = this.serviceReturn6.listProduct.listReelDetail[i].merk_id;               
               this.productDetailReel.DetailId = this.serviceReturn6.listProduct.listReelDetail[i].productDetailId;
               this.productDetailReel.Id = this.serviceReturn6.listProduct.listReelDetail[i].productId;               
               this.listOfProductDetailReel.push(this.productDetailReel);
               }
             }
             //end of inquiry detail untuk reel

             //inquiry detail untuk line
            else if(this.serviceReturn6.categoryId==3 && this.serviceReturn6.listProduct.listLineDetail.length>0)
             {
               this.productTypeforHTML="Line";
               this.listOfProductDetailLine=[];
               for(let i=0;i<this.serviceReturn6.listProduct.listLineDetail.length;i++)
               {
               this.productDetailLine = new productdetaillinemodel();
               this.productDetailLine.WarnaId = this.serviceReturn6.listProduct.listLineDetail[i].warna_line_id;
               this.productDetailLine.Satuan = this.serviceReturn6.listProduct.listLineDetail[i].satuan_ukur;
               this.productDetailLine.Ukuran = this.serviceReturn6.listProduct.listLineDetail[i].ukr_panjang;
               this.productDetailLine.Kekuatan = this.serviceReturn6.listProduct.listLineDetail[i].kekuatan;
               this.productDetailLine.TypeConnectorSenar = this.serviceReturn6.listProduct.listLineDetail[i].tipe_konektor_senar;
               this.productDetailLine.TipeNo = this.serviceReturn6.listProduct.listLineDetail[i].tipe_no;
               this.productDetailLine.TypeFluoroCarbon = this.serviceReturn6.listProduct.listLineDetail[i].tipe_fluor_carbon;
               this.productDetailLine.Warna = this.serviceReturn6.listProduct.listLineDetail[i].nama_warna;
               this.productDetailLine.Diameter = this.serviceReturn6.listProduct.listLineDetail[i].diameter;
               this.productDetailLine.Pe = this.serviceReturn6.listProduct.listLineDetail[i].pe;
               this.productDetailLine.TipeId = this.serviceReturn6.listProduct.listLineDetail[i].tipe_line_id;
               this.productDetailLine.Nama = this.serviceReturn6.listProduct.listLineDetail[i].nama_barang;
               this.productDetailLine.Tipe = this.serviceReturn6.listProduct.listLineDetail[i].nama_tipe;
               this.productDetailLine.Lbs = this.serviceReturn6.listProduct.listLineDetail[i].lbs;
               this.productDetailLine.Merk = this.serviceReturn6.listProduct.listLineDetail[i].nama_merk;
               this.productDetailLine.MerkId = this.serviceReturn6.listProduct.listLineDetail[i].merk_id;               
               this.productDetailLine.Id = this.serviceReturn6.listProduct.listLineDetail[i].productId;
               this.productDetailLine.DetailId = this.serviceReturn6.listProduct.listLineDetail[i].productDetailId;                              
               this.listOfProductDetailLine.push(this.productDetailLine);
               }
             }
             //end of inquiry detail untuk line

             //inquiry detail untuk kail
            else if(this.serviceReturn6.categoryId==4 && this.serviceReturn6.listProduct.listKailDetail.length>0)
            {
              this.productTypeforHTML="Kail";
              this.listOfProductDetailKail=[];
              for(let i=0;i<this.serviceReturn6.listProduct.listKailDetail.length;i++)
              {
              this.productDetailKail = new productdetailkailmodel();
              this.productDetailKail.Id = this.serviceReturn6.listProduct.listKailDetail[i].productId;
              this.productDetailKail.DetailId = this.serviceReturn6.listProduct.listKailDetail[i].productDetailId;
              this.productDetailKail.Ukuran = this.serviceReturn6.listProduct.listKailDetail[i].ukuran;
              this.productDetailKail.Merk = this.serviceReturn6.listProduct.listKailDetail[i].nama_merk;
              this.productDetailKail.MerkId = this.serviceReturn6.listProduct.listKailDetail[i].merk_id;              
              this.productDetailKail.Nama = this.serviceReturn6.listProduct.listKailDetail[i].nama_barang;              
              this.listOfProductDetailKail.push(this.productDetailKail);
              }
            }
            //end of inquiry detail untuk kail

             //inquiry detail untuk acc
             else if(this.serviceReturn6.categoryId==5 && this.serviceReturn6.listProduct.listAksesorisDetail.length>0)
             {
               this.productTypeforHTML="Acc";
               this.listOfProductDetailAcc=[];
               for(let i=0;i<this.serviceReturn6.listProduct.listAksesorisDetail.length;i++)
               {
               this.productDetailAcc = new productdetailaccmodel();
               this.productDetailAcc.DetailId = this.serviceReturn6.listProduct.listAksesorisDetail[i].productDetailId;
               this.productDetailAcc.Id = this.serviceReturn6.listProduct.listAksesorisDetail[i].productId;               
               this.productDetailAcc.Ukuran = this.serviceReturn6.listProduct.listAksesorisDetail[i].ukuran;
               this.productDetailAcc.Merk = this.serviceReturn6.listProduct.listAksesorisDetail[i].nama_merk;
               this.productDetailAcc.MerkId = this.serviceReturn6.listProduct.listAksesorisDetail[i].merk_id;               
               this.productDetailAcc.Nama = this.serviceReturn6.listProduct.listAksesorisDetail[i].nama_barang;
               this.productDetailAcc.Warna = this.serviceReturn6.listProduct.listAksesorisDetail[i].nama_warna;
               this.productDetailAcc.WarnaId = this.serviceReturn6.listProduct.listAksesorisDetail[i].warna_aksesoris_id; 
               this.productDetailAcc.Lbs = this.serviceReturn6.listProduct.listAksesorisDetail[i].lbs; 
               this.productDetailAcc.Liter = this.serviceReturn6.listProduct.listAksesorisDetail[i].liter; 
               this.productDetailAcc.Tipe = this.serviceReturn6.listProduct.listAksesorisDetail[i].nama_tipe; 
               this.productDetailAcc.TipeId = this.serviceReturn6.listProduct.listAksesorisDetail[i].tipe_aksesoris_id;  
               this.productDetailAcc.Size = this.serviceReturn6.listProduct.listAksesorisDetail[i].size;                             
               this.listOfProductDetailAcc.push(this.productDetailAcc);
               }
             }
             //end of inquiry detail untuk acc            
             //inquiry detail untuk bait
             else if(this.serviceReturn6.categoryId==6 && this.serviceReturn6.listProduct.listUmpanDetail.length>0)
             {
               this.productTypeforHTML="Bait";
               this.listOfProductDetailBait=[];
               for(let i=0;i<this.serviceReturn6.listProduct.listUmpanDetail.length;i++)
               {
               this.productDetailBait = new productdetailbaitmodel();
               this.productDetailBait.DetailId = this.serviceReturn6.listProduct.listUmpanDetail[i].productDetailId;
               this.productDetailBait.Id = this.serviceReturn6.listProduct.listUmpanDetail[i].productId;               
               this.productDetailBait.Ukuran = this.serviceReturn6.listProduct.listUmpanDetail[i].ukuran;
               this.productDetailBait.Merk = this.serviceReturn6.listProduct.listUmpanDetail[i].nama_merk;
               this.productDetailBait.MerkId = this.serviceReturn6.listProduct.listUmpanDetail[i].merkId;               
               this.productDetailBait.Nama = this.serviceReturn6.listProduct.listUmpanDetail[i].nama_barang;
               this.productDetailBait.Warna = this.serviceReturn6.listProduct.listUmpanDetail[i].nama_warna;
               this.productDetailBait.WarnaId = this.serviceReturn6.listProduct.listUmpanDetail[i].warna_umpan_id; 
               this.productDetailBait.Tipe = this.serviceReturn6.listProduct.listUmpanDetail[i].nama_tipe; 
               this.productDetailBait.TipeId = this.serviceReturn6.listProduct.listUmpanDetail[i].tipe_umpan_id;   
               this.productDetailBait.Berat = this.serviceReturn6.listProduct.listUmpanDetail[i].berat;     
               this.productDetailBait.Varian = this.serviceReturn6.listProduct.listUmpanDetail[i].namaVarian;   
               this.productDetailBait.VarianId = this.serviceReturn6.listProduct.listUmpanDetail[i].varianId;                       
               this.listOfProductDetailBait.push(this.productDetailBait);
               }
             }
             //end of inquiry detail untuk bait    
  
            else{
              alert("Product Detail Not Found");
            }

          }
          else
          {
            alert("Inquiry Detail Product Failed");
          }
        }
      )  
  } 

  // addToList2(objectModel:any)
  // {
  
  //   this.trMasterModel = new transactionMaster();
  //   this.trMasterModel.Id = objectModel.DetailId;

  //   if(this.activeCategory==1 || this.activeCategory==2)
  //   {
  //   this.trMasterModel.NamaBarang=objectModel.Merk + " " +objectModel.Nama+ " " +objectModel.Ukuran+ " " +objectModel.Tipe+ " " +objectModel.Warna;
  //   }
  //   else if(this.activeCategory==3)
  //   {
  //   this.trMasterModel.NamaBarang=objectModel.Merk + " " +objectModel.Nama+ " " +objectModel.Ukuran+ " Tp.No " +objectModel.TipeNo
  //   + " PE: " +objectModel.Pe+ " Lbs: " +objectModel.Lbs+" "+objectModel.Diameter +" "+objectModel.Kekuatan +" "+objectModel.Warna;
  //   }    
  //   else if(this.activeCategory==4)
  //   {
  //   this.trMasterModel.NamaBarang=objectModel.Merk + " " +objectModel.Nama+ " " +objectModel.Ukuran;
  //   }
  //   else if(this.activeCategory==5)
  //   {
  //   this.trMasterModel.NamaBarang=objectModel.Merk + " " +objectModel.Nama+ " " +objectModel.Ukuran
  //   + " Tipe: " +objectModel.Tipe+ " Lbs: " +objectModel.Lbs+" "+objectModel.Size+" "+objectModel.Warna;
  //   }
  //   else if(this.activeCategory==6)
  //   {
  //   this.trMasterModel.NamaBarang=objectModel.Merk + " " +objectModel.Nama+ " " +objectModel.Ukuran
  //   + " Tipe: " +objectModel.Tipe+ " " +objectModel.Berat+" "+objectModel.Varian+" "+objectModel.Warna;
  //   }    
  //   this.trMasterModel.Stock=this.stockModel.Stock;
  //   this.trMasterModel.NamaCategory=this.productTypeforHTML;
  //   this.trMasterModel.MerkId=objectModel.MerkId;
  //   this.trMasterModel.CategoryId=this.activeCategory;
  //   this.trMasterModel.NamaMerk=objectModel.Merk;
  //   this.trMasterModel.SellPrice= "0";
  //   this.trMasterModel.Ukuran=objectModel.Ukuran;
  //   this.trMasterModel.Qty= 1;
  //   this.listOfTransactionModel.push(this.trMasterModel);
  //   $('#exampleModal3').modal('hide');
  //   this.cariBarangForm.reset();
  // }
  stockModel :stock = new stock();
  serviceReturn7:any;
  addToList(objectModel:any)
  {

    let jsonInput = {};
    jsonInput['categoryId']=parseInt(this.activeCategory);
    jsonInput['productDetailId']=parseInt(objectModel.DetailId); 
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      console.log(stringJsonInput)
       res=this.getService.restItemsServiceGetwithParamRestItems('warehouse/inquiryStock',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn7 = data;
          console.log(this.serviceReturn7);
          if(this.serviceReturn7.status=="S")
          {
            this.stockModel = new stock();
            //this.stockModel.MasterStockId = this.serviceReturn7.result.masterStockId;
            this.stockModel.Uom = this.serviceReturn7.result.uom;
            this.stockModel.Stock = this.serviceReturn7.result.stock;
            this.stockModel.JumlahPerDus = this.serviceReturn7.result.jumlahPerDus;


            this.trMasterModel = new transactionMaster();
    this.trMasterModel.Id = objectModel.DetailId;

    if(this.activeCategory==1 || this.activeCategory==2)
    {
    this.trMasterModel.NamaBarang=objectModel.Merk + " " +objectModel.Nama+ " " +objectModel.Ukuran+ " " +objectModel.Tipe+ " " +objectModel.Warna;
    }
    else if(this.activeCategory==3)
    {
    this.trMasterModel.NamaBarang=objectModel.Merk + " " +objectModel.Nama+ " " +objectModel.Ukuran+ " Tp.No " +objectModel.TipeNo
    + " PE: " +objectModel.Pe+ " Lbs: " +objectModel.Lbs+" "+objectModel.Diameter +" "+objectModel.Kekuatan +" "+objectModel.Warna;
    }    
    else if(this.activeCategory==4)
    {
    this.trMasterModel.NamaBarang=objectModel.Merk + " " +objectModel.Nama+ " " +objectModel.Ukuran;
    }
    else if(this.activeCategory==5)
    {
    this.trMasterModel.NamaBarang=objectModel.Merk + " " +objectModel.Nama+ " " +objectModel.Ukuran
    + " Tipe: " +objectModel.Tipe+ " Lbs: " +objectModel.Lbs+" "+objectModel.Size+" "+objectModel.Warna;
    }
    else if(this.activeCategory==6)
    {
    this.trMasterModel.NamaBarang=objectModel.Merk + " " +objectModel.Nama+ " " +objectModel.Ukuran
    + " Tipe: " +objectModel.Tipe+ " " +objectModel.Berat+" "+objectModel.Varian+" "+objectModel.Warna;
    }    
    this.trMasterModel.Stock=this.stockModel.Stock;
    this.trMasterModel.NamaCategory=this.productTypeforHTML;
    this.trMasterModel.MerkId=objectModel.MerkId;
    this.trMasterModel.CategoryId=this.activeCategory;
    this.trMasterModel.NamaMerk=objectModel.Merk;
    this.trMasterModel.SellPrice= "0";
    this.trMasterModel.Ukuran=objectModel.Ukuran;
    this.trMasterModel.Qty= 1;
    this.listOfTransactionModel.push(this.trMasterModel);
    $('#exampleModal3').modal('hide');
    this.cariBarangForm.reset();
          }
          else{
            alert("Inquiry Stock Failed");
          }
        }
      )
  }

    priceModelGrosir :stock = new stock();
    listPriceModelGrosir=[]
    serviceReturn2:any;
    indexTemp=0;
    hargaSukaSukaTemp=0;
    inquiryGrosirDetail(id:number,categoryId:number,index:number)
    {
    let jsonInput = {};
    jsonInput['categoryId']=categoryId;
    jsonInput['productDetailId']=id; 
    this.indexTemp=index;
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      
       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryProductPrice',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn2 = data;
          if(this.serviceReturn2.status=="S")
          {
            this.listPriceModelGrosir=[];
            for(let i=0;i<this.serviceReturn2.result.sellPriceList[1].priceList.length;i++)
            {
              this.priceModelGrosir = new stock();
              this.priceModelGrosir.SellPrice=this.serviceReturn2.result.sellPriceList[1].priceList[i].sellPrice;
              this.priceModelGrosir.NamaHarga=this.serviceReturn2.result.sellPriceList[1].priceList[i].namaHarga;
              this.priceModelGrosir.MinQty=this.serviceReturn2.result.sellPriceList[1].priceList[i].minQty;
              this.priceModelGrosir.MinAllowQty=this.serviceReturn2.result.sellPriceList[1].priceList[i].allowMinQty;
              this.priceModelGrosir.PriceDetailId=this.serviceReturn2.result.sellPriceList[1].priceList[i].priceDetailId;
              this.listPriceModelGrosir.push(this.priceModelGrosir);
            }            

          }
          else{
            alert("Inquiry Price Detail Failed");
          }
        }
      )
      }
    
    jsonPaymentMethod={};
    listOfPaymentMethod=[];
    serviceReturn3:any;
    jsonListSales={};
    lisfOfSales=[];
  
    searchPaymentMethod()
    {
  
      let jsonInput = {};
      jsonInput['kode']="paymentType";
      jsonInput['categoryId']="";
      let res={}
        let stringJsonInput = JSON.stringify(jsonInput);
        this.listOfPaymentMethod=[];
  
         res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
        .subscribe( 
          data => {
            this.serviceReturn3 = data;
            console.log(this.serviceReturn3);
            if(this.serviceReturn3.status=="S")
            {
              for(let i=0;i<this.serviceReturn3.listCode.length;i++)
              {
              this.jsonPaymentMethod={};
              this.jsonPaymentMethod['namaPayment']=this.serviceReturn3.listCode[i].namaPayment;
              this.jsonPaymentMethod['id']=this.serviceReturn3.listCode[i].id;
              this.listOfPaymentMethod.push(this.jsonPaymentMethod);
              }
            }
            else{
              alert("List of Payment Menthod Not Found");
            }
          }
        )  
    }      

      grandtotal=0;
      change=0;
      buttonStatus = "false";
      calculateTotal(index:number)
      {
        if(this.listOfTransactionModel[index].Qty<=0 || this.listOfTransactionModel[index].SellPrice<=0)
        {
          alert("Qty or Price Must be Greater Than 0");
          this.buttonStatus="false";
        }
        else
        {
        this.buttonStatus="true";
        this.grandtotal=0;
        this.listOfTransactionModel[index].Total=this.listOfTransactionModel[index].SellPrice*this.listOfTransactionModel[index].Qty;
        for(let i=0;i<this.listOfTransactionModel.length;i++)
        {this.grandtotal+=this.listOfTransactionModel[i].total}
        }
      }

    pilih(price:number)
    {
      
      this.listOfTransactionModel[this.indexTemp].SellPrice=price;
      $("#exampleModal").modal("hide");
      this.calculateTotal(this.indexTemp);
      this.hargaSukaSukaTemp=0;
    }
    calculateChange()
    {
      this.change=0;
      this.change=parseInt(this.checkoutForm.get('jumlahUang').value)-this.grandtotal;
    }

    hapusTransaksi(index:number)
    {
      this.listOfTransactionModel.splice(index, 1);
      if(this.listOfTransactionModel.length==0)
      {
        this.grandtotal=0;
      }
      for (let i=0;i<this.listOfTransactionModel.length;i++)
      {
        this.calculateTotal(this.listOfTransactionModel.length-1);
      }
    }

    numberWithCommas(x : number) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    date=new Date();
    latest_date="";
    jsonListPrint={};
    listJsonListPrint=[];
    totalHargaPrint=0;
    transNumber="";
    serviceReturn4:any;
    checkout()
    {

    this.latest_date =this.datepipe.transform(this.date, 'dd-MM-yyyy hh:mm');
    this.listJsonListPrint=[];
    let jsonInput = {};
    let listOfProductTr=[];
    this.totalHargaPrint=0;
    jsonInput['customerId']=0;
    jsonInput['userId']=sessionStorage.getItem('userId');
    jsonInput['noPo']="";
    jsonInput['paymentTypeId']=parseInt(this.checkoutForm.get('metodeBayar').value);
    jsonInput['kodeTransaksi']=6; 
    jsonInput['customerName']=sessionStorage.getItem("username");
    jsonInput['tanggalTransaksi']="";
    jsonInput['noTransaksi']="";
    jsonInput['totalHarga']=this.grandtotal;
    for(let i=0;i<this.listOfTransactionModel.length;i++)
    {
      let jsonInputProduct={};
      jsonInputProduct["productDetailId"]= this.listOfTransactionModel[i].Id;
      jsonInputProduct["categoryId"]= this.listOfTransactionModel[i].CategoryId;
      jsonInputProduct["basePrice"]= 0
      jsonInputProduct["sellPrice"]= this.listOfTransactionModel[i].SellPrice;
      jsonInputProduct["sellPriceDiscount"]= this.listOfTransactionModel[i].SellPrice;
      jsonInputProduct["nominalDiscount"]= 0;
      jsonInputProduct["discount"]= 0,
      jsonInputProduct["qtyTotal"]= this.listOfTransactionModel[i].Qty;
      listOfProductTr.push(jsonInputProduct);
    }
    jsonInput['listProduct']=listOfProductTr;
    console.log(jsonInput);

    let res={}
    let stringJsonInput = JSON.stringify(jsonInput);
    this.transNumber="";
    console.log(stringJsonInput);
     res=this.getService.restItemsServiceGetwithParamRestItems('transaction/createTransaction',stringJsonInput)
    .subscribe(
      data => {
        this.serviceReturn4 = data;
        console.log(JSON.stringify(this.serviceReturn4))
        if(this.serviceReturn4.status=="S")
        {
          
           this.transNumber = this.serviceReturn4.result.referenceNumber;
           alert("Transaction Success - "+this.serviceReturn4.result.referenceNumber);
           this.totalHargaPrint = this.serviceReturn4.result.totalHarga;
           for(let i=0;i<this.serviceReturn4.result.salesProductList.length;i++)
           {
             this.jsonListPrint={};
             this.jsonListPrint['merkBarang'] = this.serviceReturn4.result.salesProductList[i].productWrap.productDetail.namaMerk;
             this.jsonListPrint['namaBarang'] = this.serviceReturn4.result.salesProductList[i].productWrap.productDetail.namaBarang + " " + this.serviceReturn4.result.salesProductList[i].productWrap.productDetail.detail+ " " + this.serviceReturn4.result.salesProductList[i].productWrap.productDetail.detail2;
             //this.jsonListPrint['detail2'] = this.serviceReturn4.result.salesProductList[i].productWrap.productDetail.detail2;
             this.jsonListPrint['total'] = this.numberWithCommas(this.serviceReturn4.result.salesProductList[i].productWrap.sellPrice*this.serviceReturn4.result.salesProductList[i].productWrap.qty)
             this.jsonListPrint['sellPrice'] = this.numberWithCommas(this.serviceReturn4.result.salesProductList[i].productWrap.sellPrice);
             this.jsonListPrint['qty'] = this.serviceReturn4.result.salesProductList[i].productWrap.qty;
             this.listJsonListPrint.push(this.jsonListPrint);
           }
           if(this.checkoutForm.get('jumlahUang').value=="" || this.checkoutForm.get('jumlahUang').value==null)
           {
             this.checkoutForm.get('jumlahUang').setValue(0);
           }
           this.printPOpdf();
           this.checkoutForm.reset();
           this.cariBarangForm.reset();
           this.grandtotal=0;
           this.listOfTransactionModel=[];
           this.change=0;
        }
        else
        {
          alert("Transaction Failed - "+this.serviceReturn4.errMes);
          location.reload();
        }
      }
    ) 
    }



    buildTableBody(data, columns) {
      var body = [];
      let x: Array<string>
      x = ["Brand", "Product", "Qty", "Price", "Total Price"]
      
      body.push(x);
      data.forEach(function(row) {
          var dataRow = [];
  
          columns.forEach(function(column) {
              dataRow.push(row[column].toString());
          })
  
          body.push(dataRow);
      });
  
      return body;
  }
  
   tableData(data, columns) {
      return {
      layout: 'lightHorizontalLines', // optional
          table: {
              headerRows: 1,
              fontSize:10, 
              widths: [80,160,80, 100, 100],
              body: this.buildTableBody(data, columns),
          },style:'table'
      };
  }  
  
  
    // print()
    // {
  
    //   var dd = {
    //     pageSize: {
    //       width: 204,
    //       height: 3000,
    //       pageOrientation: 'portrait',
    //     },
    //     pageMargins: [10,20],
  
    //     styles: {
    //       judul: {
    //          margin:[0,8,0,8],
    //          alignment:'center',
    //        },
    //        judul2: {
    //         margin:[0,5,0,2],
    //         alignment:'center',
    //         fontSize:'9'          
    //       },         
    //        contain: {
    //         margin:[5,2,0,2],
    //         alignment:'left',
    //         fontSize:'8.5'
    //       }, 
    //       contain3: {
    //         margin:[5,2,0,2],
    //         alignment:'left',
    //         fontSize:'7'
    //       }, 
    //       contain2: {
    //         margin:[0,2,5,2],
    //         alignment:'right',
    //         fontSize:'8.5'
    //       },  
    //       total: {
    //         margin:[5,2,0,2],
    //         alignment:'left',
    //         fontSize:'9',
    //         bold: true
    //       }, 
    //       total2: {
    //         margin:[0,2,5,2],
    //         alignment:'right',
    //         fontSize:'9',
    //         bold: true
    //       }, 
    //       footer: {
    //         margin:[0,2,5,2],
    //         alignment:'center',
    //         fontSize:'9'
    //       },      
    //       footer2: {
    //         margin:[0,2,5,2],
    //         alignment:'center',
    //         fontSize:'8'
    //       }, 
    //       header: {
    //         margin:[0,5,5,2],
    //         alignment:'center',
    //         fontSize:'8'
    //       },                                                 
    //      },
  
    //     content: [
    //       {image: this.imageGenerator.goGetImage(),alignment:'center',width:80},
    //       {text:'Ruko Terrace 9  Jl. Jati Utama Blok D NO.52 Suvarna Sutera. Phone/Whatsapp : 0813-1415-9678',style:'header'},
    //       {text: this.latest_date+' - ' + this.transNumber+'#'+sessionStorage.getItem('username'),style:'judul2',bold:true},                
    //       {text:'SALES RECEIPT',fontSize: 12,
    //       bold: true,style:'judul'},
    //       {canvas: [{ type: 'line', x1: 0, y1: 3, x2: 226-2*20, y2: 3, lineWidth: 2 }], margin:[0,0,0,8]},
          
    //       this.tableData(this.listJsonListPrint, ['merkBarang','namaBarang','qty','sellPrice','total']),
          
    //         {canvas: [{ type: 'line', x1: 0, y1: 3, x2: 226-2*20, y2: 3, lineWidth: 2 }], margin:[0,0,0,8]},
    //         {  columns : [
    //           {text:'Payment Method',style:'contain'},
    //           {text: this.paymentMethod.getPaymentMethod(this.checkoutForm.get('metodeBayar').value),style:'contain2'}]
    //           },
    //           {  columns : [
    //             {text:'Payment Amount',style:'contain'},
    //             {text: this.numberWithCommas(this.checkoutForm.get('jumlahUang').value),style:'contain2'}]
    //             },                             
    //           {  columns : [
    //             {text:'Change',style:'contain'},
    //             {text: this.numberWithCommas(this.change),style:'contain2'}]
    //             }, 
    //         {canvas: [{ type: 'line', x1: 0, y1: 3, x2: 226-2*20, y2: 3, lineWidth: 2 }], margin:[0,0,0,8]},
    //         {  columns : [
    //         {text:'GRAND TOTAL',style:'contain'},
    //         {text:'Rp. '+this.numberWithCommas(this.totalHargaPrint),style:'contain2'}]
    //         },           
    //         {canvas: [{ type: 'line', x1: 0, y1: 3, x2: 226-2*20, y2: 3, lineWidth: 2 }], margin:[0,0,0,8]},
    //           {text:'TERIMA KASIH',style:'footer'},
    //           {text:'Barang yang sudah dibeli tidak dapat ditukar atau dikembalikan',style:'footer2'},
    //           {text:'--- GUDANG PANCING ---',style:'footer2'}            
    //     ]
    //   }
    //   return dd;
    // }

    buildPdf() {
  
      var docDefinition = {
     pageSize: { width: 684, height: 1000 },
     pageOrientation: 'portrait',
     pageMargins: [60,30],
   
         styles: {
          judul: {
            
             margin:[0,5,0,15]
           },
           headerInfo: {
             fontSize: 10,
             margin:[0,0,0,2]
           },
           ttd: {
             fontSize: 10,
             margin:[0,35,0,0]
           },
           headerInfoBottom: {
             fontSize: 10,
             margin:[0,10,0,0],
             alignment:'right'
           }
            ,
           table: {
             fontSize: 9,
             alignment:'left',
             margin:[0,10,0,0]
           } 
           ,contain2: {
           margin:[0,8,0,0],
           alignment:'right',
           fontSize:'9'
           }, 
           contain: {
            margin:[0,8,0,0],
            alignment:'left',
            fontSize:'9'
            },  
            footer: {
              margin:[0,5,0,0],
              alignment:'center',
              fontSize:'9'
              },
              footer2: {
                margin:[0,5,0,0],
                alignment:'center',
                fontSize:'9'
                },                            
         },
   
       content:[
   
        {
         columns : [
         
         {text:'INVOICE',fontSize: 14,
             bold: true,},{image: this.imageGenerator.goGetImage(),alignment:'right',width:70}],style:'judul'
         },
   
         {  columns : [
         {text:'Create Date              : '+this.latest_date,alignment:'left',style:'headerInfo'},
         {text:'Ruko Terrace 9  Jl. Jati Utama Blok D NO.52 Suvarna Sutera',alignment:'right',style:'headerInfo'}]
         },
   
         {  columns : [
         {text:'Trans No.                  : '+this.transNumber+'#'+sessionStorage.getItem('username'),alignment:'left',style:'headerInfo'},
         {text:'Phone/Whatsapp : 0813-1415-9678',alignment:'right',style:'headerInfo'}]
         },  
  
   
          //this.table(jsonParsed['itemList'], ['productId', 'productType','productName','price','totalOrder','ammount']),
          this.tableData(this.listJsonListPrint, ['merkBarang','namaBarang','qty','sellPrice','total']),
   
        //{text:'TOTAL : Rp. '+this.numberWithCommas(this.totalHargaPrint),alignment:'right',style:'headerInfoBottom'},
   
        {  columns : [
          {text:'Payment Method',style:'contain'},
          {text: this.paymentMethod.getPaymentMethod(this.checkoutForm.get('metodeBayar').value),style:'contain2'}]
          },
          {  columns : [
            {text:'Payment Amount',style:'contain'},
            {text: this.numberWithCommas(this.checkoutForm.get('jumlahUang').value),style:'contain2'}]
            },                             
          {  columns : [
            {text:'Change',style:'contain'},
            {text: this.numberWithCommas(this.change),style:'contain2'}]
            }, 
        {canvas: [{ type: 'line', x1: 0, y1: 3, x2: 580, y2: 3, lineWidth: 2 }], margin:[0,0,0,8]},
        {  columns : [
        {text:'GRAND TOTAL',style:'contain'},
        {text:'Rp. '+this.numberWithCommas(this.totalHargaPrint),style:'contain2'}]
        },           
          {text:'TERIMA KASIH',style:'footer'},
          {text:'Barang yang sudah dibeli tidak dapat ditukar atau dikembalikan',style:'footer2'},
          {text:'--- GUDANG PANCING ---',style:'footer2'}            

         ]
       }
   
      
      return docDefinition;
   }
  
    printPOpdf()
  {
  this.pdf = pdfMake;
      this.pdf.createPdf(this.buildPdf()).open();
  }

  ngOnInit(): void {
    this.searchPaymentMethod();
  }

}
