import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators,ReactiveFormsModule } from '@angular/forms';
import  { callGlobalFuction } from "../../callGlobalFuction";
import { Router,ActivatedRoute } from "@angular/router";
import { productmastermodel } from '../../model/product/productmaster';
declare var $: any; 
@Component({
  selector: 'app-insertproduct',
  templateUrl: './insertproduct.component.html',
  styleUrls: ['./insertproduct.component.css']
})
export class InsertproductComponent implements OnInit {

  productTypeforHTMLFilter="";

  constructor(private fb: FormBuilder, private getService:callGlobalFuction,public router:Router,public route:ActivatedRoute) { }

   //keperluan dropdownlist
   serviceReturn:any;
   listOfBahanJoran=[];
   jsonBahanJoran={};
   serviceReturn2:any;
   listOfTipeJoran=[];
   jsonTipeJoran={};
   serviceReturn3:any;
   listOfWarna=[];
   jsonWarna={};
   serviceReturn4:any;
   listOfMerk=[]; 
   serviceReturn7:any;
   listOfTipeReel=[];    
   jsonTipeReel={};
   serviceReturn8:any;
   listOfTipeLine=[];    
   jsonTipeLine={};   
    serviceReturn9:any;
    listOfTipeAcc=[];    
    jsonTipeAcc={};
    serviceReturn10:any;
    serviceReturn11:any;
    listOfTipeBait=[];    
    jsonTipeBait={};
    listOfVarianBait=[];    
    jsonVarianBait={};

   searchXTCodebyCategory()
   {
     if(this.productTypeforHTMLFilter=="Rod")
     {
       this.searchTipeJoran();
       this.searchBahanJoran();
     }
     else if(this.productTypeforHTMLFilter=="Reel")
     {
       this.searchTipeReel();
     } 
     else if(this.productTypeforHTMLFilter=="Line")
     {
       this.searchTipeLine();
     }          
     else if(this.productTypeforHTMLFilter=="Acc")
     {
       this.searchTipeAcc();
     }     
     else if(this.productTypeforHTMLFilter=="Bait")
     {
       this.searchTipeBait();
       this.searchVarianBait();
     }            
   }
   searchBahanJoran()
   {
     let jsonInput = {};
     jsonInput['kode']="bahan";
     jsonInput['categoryId']="1";
     let res={}
       let stringJsonInput = JSON.stringify(jsonInput);
       this.listOfBahanJoran=[];
 
        res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
       .subscribe( 
         data => {
           this.serviceReturn = data;
           if(this.serviceReturn.status=="S")
           {
             for(let i=0;i<this.serviceReturn.listCode.length;i++)
             {
              this.jsonBahanJoran={};  
             this.jsonBahanJoran['namaBahan'] = this.serviceReturn.listCode[i].namaBahan;
             this.jsonBahanJoran['kodeBahan'] = this.serviceReturn.listCode[i].id;
             this.listOfBahanJoran.push(this.jsonBahanJoran);
             }
           }
           else{
             alert("List of Rod Material Not Found");
           }
 
         }
       )  
   }
   searchTipeJoran()
   {
 
     let jsonInput = {};
     jsonInput['kode']="tipe";
     jsonInput['categoryId']="1";
     let res={}
       let stringJsonInput = JSON.stringify(jsonInput);
       this.listOfTipeJoran=[];
 
        res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
       .subscribe( 
         data => {
           this.serviceReturn2 = data;
           if(this.serviceReturn2.status=="S")
           {

             for(let i=0;i<this.serviceReturn2.listCode.length;i++)
             {
             this.jsonTipeJoran={};
             this.jsonTipeJoran['namaTipe'] = this.serviceReturn2.listCode[i].namaTipe;
             this.jsonTipeJoran['kodeTipe'] = this.serviceReturn2.listCode[i].id;
             this.listOfTipeJoran.push(this.jsonTipeJoran);
             }
           }
           else{
             alert("List of Rod Type Not Found");
           }
         }
       )  
   }
   searchWarna()
   {
 
     let jsonInput = {};
     jsonInput['kode']="warna";
     jsonInput['categoryId']="";
     let res={}
       let stringJsonInput = JSON.stringify(jsonInput);
       this.listOfWarna=[];
 
        res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
       .subscribe( 
         data => {
           this.serviceReturn3 = data;
           if(this.serviceReturn3.status=="S")
           {

             for(let i=0;i<this.serviceReturn3.listCode.length;i++)
             {
              this.jsonWarna={};  
             this.jsonWarna['namaWarna'] = this.serviceReturn3.listCode[i].namaWarna;
             this.jsonWarna['id'] = this.serviceReturn3.listCode[i].id;
             this.listOfWarna.push(this.jsonWarna);
             }
           }
           else{
             alert("List of Color Not Found");
           }
         }
       )  
   }
   searchMerk()
   {
 
     let jsonInput = {};
     jsonInput['kode']="merk";
     jsonInput['categoryId']="";
     let res={}
       let stringJsonInput = JSON.stringify(jsonInput);
       this.listOfMerk=[];
 
        res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
       .subscribe( 
         data => {
           this.serviceReturn4 = data;
           if(this.serviceReturn4.status=="S")
           {
             for(let i=0;i<this.serviceReturn4.listCode.length;i++)
             {
             let namaMerk = this.serviceReturn4.listCode[i].id+" - "+this.serviceReturn4.listCode[i].namaMerk;
             this.listOfMerk.push(namaMerk);
             }
           }
           else{
             alert("List of Brand Not Found");
           }
         }
       )  
   }   
   searchTipeReel()
   {
    let jsonInput = {};
    jsonInput['kode']="tipe";
    jsonInput['categoryId']="2";
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      this.listOfTipeReel=[];

       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn7 = data;
          if(this.serviceReturn7.status=="S")
          {

            for(let i=0;i<this.serviceReturn7.listCode.length;i++)
            {
            this.jsonTipeReel={};
            this.jsonTipeReel['namaTipe'] = this.serviceReturn7.listCode[i].namaTipe;
            this.jsonTipeReel['kodeTipe'] = this.serviceReturn7.listCode[i].id;
            this.listOfTipeReel.push(this.jsonTipeReel);            
            }
            console.log(this.listOfTipeReel)
          }
          else{
            alert("List of Reel Type Not Found");
          }
        }
      )  
   }
   searchTipeLine()
   {
    let jsonInput = {};
    jsonInput['kode']="tipe";
    jsonInput['categoryId']="3";
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      this.listOfTipeLine=[];

       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn8 = data;
          if(this.serviceReturn8.status=="S")
          {

            for(let i=0;i<this.serviceReturn8.listCode.length;i++)
            {
            this.jsonTipeLine={};
            this.jsonTipeLine['namaTipe'] = this.serviceReturn8.listCode[i].namaTipe;
            this.jsonTipeLine['kodeTipe'] = this.serviceReturn8.listCode[i].id;
            this.listOfTipeLine.push(this.jsonTipeLine);            
            }
            console.log(this.listOfTipeLine)
          }
          else{
            alert("List of Line Type Not Found");
          }
        }
      )  
   }
   searchTipeAcc()
   {
 
     let jsonInput = {};
     jsonInput['kode']="tipe";
     jsonInput['categoryId']="5";
     let res={}
       let stringJsonInput = JSON.stringify(jsonInput);
       this.listOfTipeAcc=[];
 
        res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
       .subscribe( 
         data => {
           this.serviceReturn9 = data;
           if(this.serviceReturn9.status=="S")
           {
 
             for(let i=0;i<this.serviceReturn9.listCode.length;i++)
             {
             this.jsonTipeAcc={};
             this.jsonTipeAcc['namaTipe'] = this.serviceReturn9.listCode[i].namaTipe;
             this.jsonTipeAcc['kodeTipe'] = this.serviceReturn9.listCode[i].id;
             this.listOfTipeAcc.push(this.jsonTipeAcc);
             }
           }
           else{
             alert("List of Acc Type Not Found");
           }
         }
       )  
   }
   searchTipeBait()
   {
    let jsonInput = {};
    jsonInput['kode']="tipe";
    jsonInput['categoryId']="6";
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      this.listOfTipeBait=[];

       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn10 = data;
          if(this.serviceReturn10.status=="S")
          {

            for(let i=0;i<this.serviceReturn10.listCode.length;i++)
            {
            this.jsonTipeBait={};
            this.jsonTipeBait['namaTipe'] = this.serviceReturn10.listCode[i].namaTipe;
            this.jsonTipeBait['kodeTipe'] = this.serviceReturn10.listCode[i].id;
            this.listOfTipeBait.push(this.jsonTipeBait);
            }
          }
          else{
            alert("List of Bait Type Not Found");
          }
        }
      )  
   }
   searchVarianBait()
   {
    let jsonInput = {};
    jsonInput['kode']="bahan";
    jsonInput['categoryId']="6";
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      this.listOfTipeBait=[];

       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn11 = data;
          if(this.serviceReturn11.status=="S")
          {

            for(let i=0;i<this.serviceReturn11.listCode.length;i++)
            {
            this.jsonVarianBait={};
            this.jsonVarianBait['namaVarian'] = this.serviceReturn11.listCode[i].namaBahan;
            this.jsonVarianBait['kodeVarian'] = this.serviceReturn11.listCode[i].id;
            this.listOfVarianBait.push(this.jsonVarianBait);
            }
          }
          else{
            alert("List of Bait Varian Not Found");
          }
        }
      )  
   }
   //end of keperluan dropdownlist

  // model untuk joran
  insertProductJoranForm= new FormGroup({
    nama: new FormControl('',[
      Validators.required  
    ]), 
    merk: new FormControl('',[
      Validators.required
    ]),     
    ukuran: new FormControl('',[
      Validators.required
    ]), 
    minLbs: new FormControl('',[
    ]),
    maxLbs: new FormControl('',[
    ]),
    minPE: new FormControl('',[
    ]),
    maxPE: new FormControl('',[
    ]),    
    ring: new FormControl('',[
      Validators.required
    ]),
    tahunProduksi: new FormControl('',[
    ]),
    warna: new FormControl('',[
      Validators.required
    ]),
    bahan: new FormControl('',[
      Validators.required
    ]),
    tipe: new FormControl('',[
      Validators.required
    ]),                    
  });
  // end of model untuk joran

  // model untuk reel
  insertProductReelForm= new FormGroup({
    nama: new FormControl('',[
      Validators.required  
    ]), 
    merk: new FormControl('',[
      Validators.required
    ]),     
    ukuran: new FormControl('',[
      Validators.required
    ]), 
    ballBearing: new FormControl('',[
      Validators.required
    ]), 
    tipePh: new FormControl('',[
      Validators.required
    ]),
    tipeWay: new FormControl('',[
      Validators.required
    ]),
    warna: new FormControl('',[
      Validators.required
    ]),
    tipe: new FormControl('',[
      Validators.required
    ])                    
  });
  // end of model untuk reel

  // model untuk line
  insertProductLineForm= new FormGroup({
    nama: new FormControl('',[
      Validators.required  
    ]), 
    merk: new FormControl('',[
      Validators.required
    ]),     
    ukuran: new FormControl('',[
      Validators.required
    ]), 
    satuanUkur: new FormControl('',[
      Validators.required
    ]), 
    kekuatan: new FormControl('',[
    ]),
    pe: new FormControl('',[
    ]),
    diameter: new FormControl('',[
    ]),    
    lbs: new FormControl('',[
    ]),
    warna: new FormControl('',[
      Validators.required
    ]),
    tipe: new FormControl('',[
      Validators.required
    ]),
    tipeNo: new FormControl('',[
    ]),
    tipeFluoroCarbon: new FormControl('',[
      Validators.required
    ]),
    tipeConnector: new FormControl('',[
      Validators.required
    ])                    
  });
  // end of model untuk line  

    // model untuk kail
    insertProductHookForm= new FormGroup({
      nama: new FormControl('',[
        Validators.required  
      ]), 
      merk: new FormControl('',[
        Validators.required
      ]),     
      ukuran: new FormControl('',[
        Validators.required
      ])        
    });
    // end of model untuk kail  

    // model untuk acc
    insertProductAccForm= new FormGroup({
      nama: new FormControl('',[
        Validators.required  
      ]), 
      merk: new FormControl('',[
        Validators.required
      ]),
      warna: new FormControl('',[
        Validators.required
      ]),           
      ukuran: new FormControl('',[
        Validators.required
      ]),           
      liter: new FormControl('',[
        Validators.required
      ]),           
      tipe: new FormControl('',[
        Validators.required
      ]),           
      size: new FormControl('',[
        Validators.required
      ]),           
      lbs: new FormControl('',[
        Validators.required
      ])             
              
    });
    // end of model untuk acc   

    // model untuk bait
      insertProductBaitForm= new FormGroup({
        nama: new FormControl('',[
          Validators.required  
        ]), 
        merk: new FormControl('',[
          Validators.required
        ]),
        warna: new FormControl('',[
          Validators.required
        ]),           
        ukuran: new FormControl('',[
          Validators.required
        ]),           
        berat: new FormControl('',[
          Validators.required
        ]),           
        tipe: new FormControl('',[
          Validators.required
        ]),           
        varian: new FormControl('',[
          Validators.required
        ])      
                
      });
      // end of model untuk bait     


  // global function for all
  p:number=1;
  statusConfirmation=0;
  serviceReturn5:any;
  serviceReturn6:any;
  productMaster :productmastermodel;
  productMaster2 :productmastermodel;
  listOfProductMaster =[];

   getProductDetailClass()
   {
    this.productMaster2 = new productmastermodel();
     if(this.productTypeforHTMLFilter=="Rod")
     {
       this.productMaster2.CategoryId="1";
       this.productMaster2.NamaBarang=this.insertProductJoranForm.get('nama').value;
       let merkId = this.insertProductJoranForm.get('merk').value;
       console.log(merkId);
       this.productMaster2.MerkId=merkId.substring(0,merkId.indexOf(" "));
     }
     else if(this.productTypeforHTMLFilter=="Reel")
     {
       this.productMaster2.CategoryId="2";
       this.productMaster2.NamaBarang=this.insertProductReelForm.get('nama').value;
       let merkId = this.insertProductReelForm.get('merk').value;
       console.log(merkId);
       this.productMaster2.MerkId=merkId.substring(0,merkId.indexOf(" "));
     }  
     else if(this.productTypeforHTMLFilter=="Line")
     {
       this.productMaster2.CategoryId="3";
       this.productMaster2.NamaBarang=this.insertProductLineForm.get('nama').value;
       let merkId = this.insertProductLineForm.get('merk').value;
       console.log(merkId);
       this.productMaster2.MerkId=merkId.substring(0,merkId.indexOf(" "));
     } 
     else if(this.productTypeforHTMLFilter=="Hook")
     {
       this.productMaster2.CategoryId="4";
       this.productMaster2.NamaBarang=this.insertProductHookForm.get('nama').value;
       let merkId = this.insertProductHookForm.get('merk').value;
       console.log(merkId);
       this.productMaster2.MerkId=merkId.substring(0,merkId.indexOf(" "));
     } 
     else if(this.productTypeforHTMLFilter=="Acc")
     {
       this.productMaster2.CategoryId="5";
       this.productMaster2.NamaBarang=this.insertProductAccForm.get('nama').value;
       let merkId = this.insertProductAccForm.get('merk').value;
       console.log(merkId);
       this.productMaster2.MerkId=merkId.substring(0,merkId.indexOf(" "));
     }  
     else if(this.productTypeforHTMLFilter=="Bait")
     {
       this.productMaster2.CategoryId="6";
       this.productMaster2.NamaBarang=this.insertProductBaitForm.get('nama').value;
       let merkId = this.insertProductBaitForm.get('merk').value;
       console.log(merkId);
       this.productMaster2.MerkId=merkId.substring(0,merkId.indexOf(" "));
     }          
   }

  cekIfProductExist()
  { 
    this.getProductDetailClass();
     let jsonInput = {};
     jsonInput['categoryId']=parseInt(this.productMaster2.CategoryId);
     jsonInput['productName']=this.productMaster2.NamaBarang;
     jsonInput['merkId']=parseInt(this.productMaster2.MerkId);
     
     let res={}
       let stringJsonInput = JSON.stringify(jsonInput);
       console.log(stringJsonInput)
       this.listOfMerk=[];
 
        res=this.getService.restItemsServiceGetwithParamRestItems('checkIfExistProductNameBrandCategory',stringJsonInput)
       .subscribe( 
         data => {
           this.serviceReturn5 = data;
           if(this.serviceReturn5.status=="S")
           {
             this.listOfProductMaster=[];
             for(let i=0;i<this.serviceReturn5.productList.length;i++)
             {
              this.productMaster = new productmastermodel();
              this.productMaster.Id=this.serviceReturn5.productList[i].id;
              this.productMaster.MerkId=this.serviceReturn5.productList[i].merk_id;
              this.productMaster.NamaMerk=this.serviceReturn5.productList[i].nama_merk;
              this.productMaster.NamaBarang=this.serviceReturn5.productList[i].nama_barang;
              this.listOfProductMaster.push(this.productMaster);
              this.searchWarna();
              this.searchMerk();
             }
           }
           else{
             alert("List of Product Confirmation Not Found");
             this.statusConfirmation=1;
             this.searchWarna();
             this.searchMerk();
           }
         }
       )  
  }

  insertProduct(id:number)
  {
    let jsonInput={};
    let jsonInput2={};
    if(this.productTypeforHTMLFilter=="Rod")
    {
        jsonInput2['ukuran']=this.insertProductJoranForm.get('ukuran').value;
        jsonInput2['minLbs']=this.insertProductJoranForm.get('minLbs').value;
        jsonInput2['maxLbs']=this.insertProductJoranForm.get('maxLbs').value;
        jsonInput2['minPE']=this.insertProductJoranForm.get('minPE').value;
        jsonInput2['maxPE']=this.insertProductJoranForm.get('maxPE').value;
        jsonInput2['ring']=this.insertProductJoranForm.get('ring').value;
        jsonInput2['tahunProduksi']=this.insertProductJoranForm.get('tahunProduksi').value;
        jsonInput2['warnaJoranId']=parseInt(this.insertProductJoranForm.get('warna').value);
        jsonInput2['bahanJoranId']=parseInt(this.insertProductJoranForm.get('bahan').value);
        jsonInput2['tipeJoranId']=parseInt(this.insertProductJoranForm.get('tipe').value);
        jsonInput['joranDetail']=jsonInput2;
        jsonInput['categoryId']=1;
        jsonInput['productId']=id;
        jsonInput['userId']=sessionStorage.getItem('userid');
        jsonInput['merkId']=parseInt(this.insertProductJoranForm.get('merk').value);
        jsonInput['productName']=this.insertProductJoranForm.get('nama').value;
      }  
      else if(this.productTypeforHTMLFilter=="Reel")
      {
          jsonInput2['ukuran']=this.insertProductReelForm.get('ukuran').value;
          jsonInput2['ballBearing']=this.insertProductReelForm.get('ballBearing').value;
          jsonInput2['tipePh']=this.insertProductReelForm.get('tipePh').value;
          jsonInput2['tipeWay']=this.insertProductReelForm.get('tipeWay').value;
          jsonInput2['warnaReelId']=parseInt(this.insertProductReelForm.get('warna').value);
          jsonInput2['tipeReelId']=parseInt(this.insertProductReelForm.get('tipe').value);
          jsonInput['reelDetail']=jsonInput2;
          jsonInput['categoryId']=2;
          jsonInput['productId']=id;
          jsonInput['userId']=sessionStorage.getItem('userid');
          jsonInput['merkId']=parseInt(this.insertProductReelForm.get('merk').value);
          jsonInput['productName']=this.insertProductReelForm.get('nama').value;
        }
      else if(this.productTypeforHTMLFilter=="Line")
        {
            jsonInput2['ukuranPanjang']=this.insertProductLineForm.get('ukuran').value;
            jsonInput2['satuanUkur']=this.insertProductLineForm.get('satuanUkur').value;
            jsonInput2['kekuatan']=this.insertProductLineForm.get('kekuatan').value;
            jsonInput2['pe']=this.insertProductLineForm.get('pe').value;
            jsonInput2['lbs']=this.insertProductLineForm.get('lbs').value;
            jsonInput2['tipeKonektorSenar']=this.insertProductLineForm.get('tipeConnector').value;
            jsonInput2['tipeFluorCarbon']=this.insertProductLineForm.get('tipeFluoroCarbon').value;
            jsonInput2['tipeNo']=this.insertProductLineForm.get('tipeNo').value;
            jsonInput2['warnaLineId']=this.insertProductLineForm.get('warna').value;
            jsonInput2['tipeLineId']=this.insertProductLineForm.get('tipe').value;
            jsonInput2['diameter']=this.insertProductLineForm.get('diameter').value;
            jsonInput['lineDetail']=jsonInput2;
            jsonInput['categoryId']=3;
            jsonInput['productId']=id;     
            jsonInput['userId']=sessionStorage.getItem('userid');       
            jsonInput['merkId']=parseInt(this.insertProductLineForm.get('merk').value);
            jsonInput['productName']=this.insertProductLineForm.get('nama').value;
        }                       
      else if(this.productTypeforHTMLFilter=="Hook")
        {
            jsonInput2['ukuran']=this.insertProductHookForm.get('ukuran').value;
            jsonInput['kailDetail']=jsonInput2;
            jsonInput['categoryId']=4;
            jsonInput['productId']=id;            
            jsonInput['merkId']=parseInt(this.insertProductHookForm.get('merk').value);
            jsonInput['userId']=sessionStorage.getItem('userid');
            jsonInput['productName']=this.insertProductHookForm.get('nama').value;
        }
      else if(this.productTypeforHTMLFilter=="Acc")
        {
            jsonInput2['ukuran']=this.insertProductAccForm.get('ukuran').value;
            jsonInput2['warnaAksesorisId']=parseInt(this.insertProductAccForm.get('warna').value);
            jsonInput2['liter']=this.insertProductAccForm.get('liter').value;
            jsonInput2['lbs']=this.insertProductAccForm.get('lbs').value;
            jsonInput2['size']=this.insertProductAccForm.get('size').value;
            jsonInput2['tipeAksesorisId']=parseInt(this.insertProductAccForm.get('tipe').value);
            jsonInput['aksesorisDetail']=jsonInput2;
            jsonInput['productId']=id;
            jsonInput['productName']=this.insertProductAccForm.get('nama').value;
            jsonInput['categoryId']=5;  
            jsonInput['userId']=sessionStorage.getItem('userid');          
            jsonInput['merkId']=parseInt(this.insertProductAccForm.get('merk').value);
        } 
        else if(this.productTypeforHTMLFilter=="Bait")
        {
            jsonInput2['ukuran']=this.insertProductBaitForm.get('ukuran').value;
            jsonInput2['warnaUmpanId']=parseInt(this.insertProductBaitForm.get('warna').value);
            jsonInput2['berat']=this.insertProductBaitForm.get('berat').value;
            jsonInput2['varian']=this.insertProductBaitForm.get('varian').value;
            jsonInput2['tipeUmpanId']=parseInt(this.insertProductBaitForm.get('tipe').value);
            jsonInput['umpanDetail']=jsonInput2;
            jsonInput['productId']=id;
            jsonInput['productName']=this.insertProductBaitForm.get('nama').value;
            jsonInput['categoryId']=6;  
            jsonInput['userId']=sessionStorage.getItem('userid');          
            jsonInput['merkId']=parseInt(this.insertProductBaitForm.get('merk').value);
        }                    
      let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      console.log(stringJsonInput)

       res=this.getService.restItemsServiceGetwithParamRestItems('insertNewProduct',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn6 = data;
          console.log(this.serviceReturn6);
          if(this.serviceReturn6.status=="S")
          {
            alert("Success Insert Product");
            location.reload();
          }
          else{
            alert("Failed Insert Product");
            $("#exampleModal").modal("hide");
          }
        }
      )  
  }
  //end of global function for all

  panggilCancel()
  {
    this.searchWarna();
    this.searchMerk();
  }

  ngOnInit(): void {
    this.searchWarna();
    this.searchMerk();
  }


}
