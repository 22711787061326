<div class="col-md-12 grid-margin stretch-card">
	<div class="card">
		<div class="card-body">
			<h4 class="card-title">Master Product</h4>
			            <form class="form-sample"  [formGroup]="inquiryMasterProductForm">
                        <div class="row">
                        <div class="col-md-6">
 						 <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Name</label>
                            <div class="col-sm-6">
                              <input type="text" class="form-control form-control-md" 
                              formControlName="productName" id="productName" name="productName"/>
	               				<div class="form-group" style="color: #E83833;" *ngIf="inquiryMasterProductForm.controls.productName.invalid && (inquiryMasterProductForm.controls.productName.dirty || inquiryMasterProductForm.controls.productName.touched)">
                  	 			<p id="errMessage"  *ngIf="inquiryMasterProductForm.controls.productName.errors.required">
                  	 			Product Name is Required</p>
                  			</div> 
                            </div>
                            <div class="col-sm-3">
                               <button type="submit" class="btn btn-primary mr-2" [disabled]="inquiryMasterProductForm.invalid"  (click)="searchbyname()">Search</button>
                            </div>
                          </div>
                        </div>
                        </div>                        
		              </form>        		

                	<div class="table-responsive">
                    <table class="table table-hover" >
                      <thead>
                        <tr>
                          <th>Product Name</th>
                          <th>Brand</th>
                          <th>Category</th>
                        </tr>
                      </thead>
                      <tbody >
                       <tr *ngFor="let listProductMaster of listOfProductMasterModel; let i= index" style="cursor: pointer;"
                       [routerLink]="['/inquiryproductmasterdetail']" [queryParams]="{ id: listProductMaster.Id, 
                        categoryId: listProductMaster.CategoryId, merkId:listProductMaster.MerkId}">
                          <td>{{listProductMaster.NamaBarang}}</td>
                          <td>{{listProductMaster.NamaMerk}}</td>
                          <td>{{listProductMaster.NamaCategory}}</td>
                       </tr>       
                      </tbody>
                    </table>
                	</div>
                    
                     
		</div>
	</div>
</div>