export class purchaseOrderDetail{

    constructor(values: Object = {}) {
          Object.assign(this, values);
    }
    private id : number;
    private hargaDasar :number;
    private totalMasuk : number;
    private sudahMasuk : number;
    private belumMasuk : number;
    private namaMerek : string;
    private detail1 : string;
    private detail2 :string;
    private namaBarang : string;
    private productDetailId : number;
    private categoryId : number;
    
    public get Id():number{
        return this.id;
    }
    public get HargaDasar():number{
        return this.hargaDasar;
    }
    public get TotalMasuk():number{
        return this.totalMasuk;
    }
    public get SudahMasuk():number{
        return this.sudahMasuk;
    }
    public get BelumMasuk():number{
        return this.belumMasuk;
    }
    public get NamaMerek():string{
        return this.namaMerek;
    }
    public get NamaBarang():string{
        return this.namaBarang;
    }
    public get Detail1():string{
        return this.detail1;
    }
    public get Detail2():string{
        return this.detail2;
    }
    public get ProductDetailId():number{
        return this.productDetailId;
    }
    public get CategoryId():number{
        return this.categoryId;
    }

    public set Id (id:number){
        this.id=id;
    }
    public set HargaDasar (hargaDasar:number){
        this.hargaDasar=hargaDasar;
    }
    public set TotalMasuk (totalMasuk:number){
        this.totalMasuk=totalMasuk;
    }
    public set BelumMasuk (belumMasuk:number){
        this.belumMasuk=belumMasuk;
    }
    public set SudahMasuk (sudahMasuk:number){
        this.sudahMasuk=sudahMasuk;
    }
    public set NamaMerek (namaMerek:string){
        this.namaMerek=namaMerek;
    }    
    public set NamaBarang (namaBarang:string){
        this.namaBarang=namaBarang;
    } 
    public set Detail1 (detail1:string){
        this.detail1=detail1;
    }    
    public set Detail2 (detail2:string){
        this.detail2=detail2;
    }    
    public set ProductDetailId (productDetailId:number){
        this.productDetailId=productDetailId;
    }
    public set CategoryId (categoryId:number){
        this.categoryId=categoryId;
    }

    }