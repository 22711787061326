export class purchaseOrder{

    constructor(values: Object = {}) {
          Object.assign(this, values);
    }
    private id : number;
    private supplierId : number;
    private namaSupplier : string;
    private createUsername : string;  
    private tanggalTransaksi : string;
    private pelunasanTertunda :number;
    private statusPO : string;
    private totalHarga : number;
    private sudahBayar : number;
    private belumBayar : number;
    private nomorPO : string;
    
    
    public get Id():number{
        return this.id;
    }
    public get SupplierId():number{
        return this.supplierId;
    }
    public get NamaSupplier():string{
        return this.namaSupplier;
    }    
    public get CreateUsername():string{
        return this.createUsername;
    }
    public get TanggalTransaksi():string{
        return this.tanggalTransaksi;
    }
    public get NomorPO():string{
        return this.nomorPO;
    }
    public get StatusPO():string{
        return this.statusPO;
    }
    public get TotalHarga():number{
        return this.totalHarga;
    }
    public get SudahBayar():number{
        return this.sudahBayar;
    }
    public get BelumBayar():number{
        return this.belumBayar;
    }
    public get PelunasanTertunda():number{
        return this.pelunasanTertunda;
    }

    public set Id (id:number){
        this.id=id;
    }
    public set SupplierId (supplierId:number){
        this.supplierId=supplierId;
    }
    public set NamaSupplier (namaSupplier:string){
        this.namaSupplier=namaSupplier;
    }    
    public set CreateUsername (createUsername:string){
        this.createUsername=createUsername;
    }
    public set TanggalTransaksi (tanggalTransaksi:string){
        this.tanggalTransaksi=tanggalTransaksi;
    }
    public set NomorPO (nomorPO:string){
        this.nomorPO=nomorPO;
    }
    public set StatusPO (statusPO:string){
        this.statusPO=statusPO;
    }
    public set TotalHarga (totalHarga:number){
        this.totalHarga=totalHarga;
    }
    public set SudahBayar (sudahBayar:number){
        this.sudahBayar=sudahBayar;
    }
    public set BelumBayar (belumBayar:number){
        this.belumBayar=belumBayar;
    }
    public set PelunasanTertunda (pelunasanTertunda:number){
        this.pelunasanTertunda=pelunasanTertunda;
    }    
    }