 <router-outlet name="login"></router-outlet> 


<div class="container-scroller">
<router-outlet name='header'></router-outlet>   
    <div class="container-fluid page-body-wrapper">
      <div class="main-panel container">
      		<div class="content-wrapper">
			<router-outlet></router-outlet>
			</div>    
			<router-outlet name='footer'></router-outlet>  
	  </div>
	</div>

</div>