<div class="col-md-12 grid-margin stretch-card">
    <div class="card">
		<div class="card-body">
        <div class="row">
            <div class="col-md-2">
                <h4 class="card-title">Transaksi Retail</h4>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <form [formGroup]="cariBarangForm">
                    <div class="form-group row">                        
                        <div class="col">
                        <label>Product Code</label>
                        <div id="the-basics">
                            <input class="typeahead"  id="barcode" formControlName="barcode" name="barcode" type="text"
                            placeholder="Product Code"> </div>
                        </div>
                        <div class="col">
                            <label style="color: transparent;">-</label>
                            <div id="bloodhound">
                                <button type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" 
                                (click)="searchProduct()" data-toggle="modal" data-target="#exampleModalRetail"
                                [disabled]="cariBarangForm.invalid">Search</button>
                             </div>                             
                      </div>                     
                    </div>
                </form>
            </div>
        </div>  
        <div class="row">
            <div class="col-md-12">
                <form [formGroup]="cariBarangFormNama">
                    <div class="form-group row">                        
                        <div class="col">
                        <label>Product Name</label>
                        <div id="the-basics">
                            <input class="typeahead"  id="productName" formControlName="productName" name="productName" type="text"
                            placeholder="Product Name"> </div>
                        </div>
                        <div class="col">
                            <label style="color: transparent;">-</label>
                             <button type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" 
                             [disabled]="cariBarangFormNama.invalid" data-toggle="modal" 
                             data-target="#exampleModal2" (click)="searchbyname()">Search Product</button>
                         </div>                    
                    </div>
                </form>
            </div>
        </div>   
        <div class="row">
            <div class="table-responsive">
                <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>Hapus</th>                          
                        <th>No.</th>  
                        <th>Product Name</th>
                        <th>Price</th>
                        <th>Qty</th>                                              
                        <th>Total</th>
                  
                      </tr>
                    </thead>
                    <tbody >
                     <tr *ngFor="let listOfProductSales of listOfTransactionModel; let i= index">
                        <td style="color: red; cursor: pointer;" (click)="hapusTransaksi(i)">Del</td>                      
                        <td>{{i+1}}</td> 
                        <td>{{listOfProductSales.NamaBarang}} 
                          <div class="badge badge-pill badge-outline-primary">{{listOfProductSales.Stock}}</div>
                        </td>
                        <td>{{listOfProductSales.SellPrice | number: '1.0-0'}}</td>
                        <td>
                        <div id="the-basics">
                        <input class="typeahead" (keyup)="searchUpdatePriceByQty(i)" [(ngModel)]=listOfProductSales.Qty 
                        type="text" > </div></td>
                        <!-- Diganti menggunakan function di atas
                        <div id="the-basics">
                        <input class="typeahead" (keyup)="calculateTotal(i)" [(ngModel)]=listOfProductSales.Qty 
                        type="text" > </div></td> -->
                        <!-- Di hide karna tidak menggunakan diskon
                          <td>
                          <div id="the-basics">
                          <input class="typeahead" (keyup)="calculateTotal(i)" [(ngModel)]=listOfProductSales.Disc  
                          type="text" > </div></td>
                          <td>
                          <div id="the-basics">
                            <input class="typeahead" (keyup)="calculateTotalNominal(i)" [(ngModel)]=listOfProductSales.DiscNominal 
                            type="text" > </div></td>                                                   -->
                        <td>{{listOfProductSales.total | number: '1.0-0'}}</td>

                     </tr>  
                     <tr>
                        <td colspan="5" style="text-align: right;">Grand Total</td>
                       <td>Rp.{{grandtotal | number: '1.0-0'}}</td>
                       </tr>                           
                    </tbody>
                  </table>
            </div>
        </div>   

        <div class="row">
            <div class="col-md-12">
                <form [formGroup]="checkoutForm">
                    <div class="form-group row">                        
                        <div class="col">
                            <label>Payment Type</label>
                            <div id="bloodhound">
                              <select class="typeahead"  id="metodeBayar" formControlName="metodeBayar" name="metodeBayar" type="text" placeholder="Payment Type">
                                <option value="" >- Select Brand -</option>  
                                <option value="{{paymentList.id}}" *ngFor="let paymentList of listOfPaymentMethod;let i=index" >{{paymentList.namaPayment}}</option>  
                            </select> </div>
                        </div> 
                        <div class="col" >
                            <label>Amount</label>
                            <div id="the-basics">
                                <input class="typeahead" (keyup)="calculateChange()" id="jumlahUang" formControlName="jumlahUang" name="jumlahUang" type="text" placeholder="Amount"> </div>
                            </div>    
                            <div class="col" >
                                <label>Change</label><br>  
                                <label>Rp. {{change| number: '1.0-0'}}</label>   
                            </div>                                                
                        <div class="col">
                            <label style="color: transparent;">-</label>
                            <div id="bloodhound">
                                <button type="submit" class="btn btn-success mr-2" style="min-width: 100%;" 
                                (click)="checkout()" [disabled]="checkoutForm.invalid || buttonStatus=='false'" >CheckOut</button>
                             </div>                             
                      </div>
                     
                    </div>
                </form>                
            </div>
        </div>        
        
    </div>
</div>
</div>    


  <!-- search by name popup -->
  <div class="modal fade" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Product List</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
          <div class="modal-body" style="overflow-y: auto; max-height:400px;">
            <table class="table table-hover" >
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Brand</th>
                    <th>Category</th>
                  </tr>
                </thead>
                <tbody >
                 <tr *ngFor="let listProductMaster of listOfProductMasterModel; let i= index" style="cursor: pointer;"
                 data-toggle="modal" data-target="#exampleModal3" (click)="searchbynameDetail(listProductMaster.Id, 
                 listProductMaster.CategoryId,listProductMaster.MerkId)">
                    <td>{{listProductMaster.NamaBarang}}</td>
                    <td>{{listProductMaster.NamaMerk}}</td>
                    <td>{{listProductMaster.NamaCategory}}</td>
                 </tr>       
                </tbody>
              </table>

          </div>          
        <div class="modal-footer">
         
          <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="exampleModal3" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Product Name</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
          <div class="modal-body" style="overflow-y: auto; max-height:400px;">

            <div class="table-responsive">
                                <!-- table for type joran-->
                                <table class="table table-hover" *ngIf="productTypeforHTML=='Rod'">
                                    <thead>
                                      <tr>
                                        <th></th>
                                        <th>Product Name</th>
                                        <th>Brand</th>
                                        <th>Size</th>
                                        <th>Min Lbs</th>
                                        <th>Max Lbs</th>
                                        <th>Ring</th>
                                        <th>Min PE</th>
                                        <th>Max PE</th>
                                        <th>Colour</th>
                                        <th>Material</th>
                                        <th>Prod. Date</th>
                                        <th>Type</th>
                                      </tr>
                                    </thead>
                                    <tbody >
                                     <tr *ngFor="let listJoranDetail of listOfProductDetailJoran; let i= index" >
                                        <td> <button type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" 
                                        (click)="addToList(listJoranDetail)">Order</button></td>
                                        <td>{{listJoranDetail.Nama}}</td>
                                        <td>{{listJoranDetail.Merk}}</td>
                                        <td>{{listJoranDetail.Ukuran}}</td>
                                        <td>{{listJoranDetail.MinLbs}}</td>
                                        <td>{{listJoranDetail.MaxLbs}}</td>
                                        <td>{{listJoranDetail.Ring}}</td>
                                        <td>{{listJoranDetail.MinPE}}</td>
                                        <td>{{listJoranDetail.MaxPE}}</td>
                                        <td>{{listJoranDetail.Warna}}</td>
                                        <td>{{listJoranDetail.Bahan}}</td>
                                        <td>{{listJoranDetail.TahunProduksi}}</td>
                                        <td>{{listJoranDetail.Tipe}}</td>
                                     </tr>       
                                    </tbody>
                                  </table>
              
                                  <!-- table for type Reel-->
                                  <table class="table table-hover" *ngIf="productTypeforHTML=='Reel'">
                                    <thead>
                                      <tr>
                                        <th></th>
                                        <th>Product Name</th>
                                        <th>Brand</th>
                                        <th>Size</th>
                                        <th>Type</th>
                                        <th>Ball Bearing</th>
                                        <th>Colour</th>
                                        <th>PH Type</th>
                                        <th>Way Type</th>
                                      </tr>
                                    </thead>
                                    <tbody >
                                     <tr *ngFor="let listReelDetail of listOfProductDetailReel; let i= index" >
                                      <td> <button type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" 
                                        (click)="addToList(listReelDetail)">Order</button></td>                         
                                        <td>{{listReelDetail.Nama}}</td>
                                        <td>{{listReelDetail.Merk}}</td>
                                        <td>{{listReelDetail.Ukuran}}</td>
                                        <td>{{listReelDetail.Tipe}}</td>
                                        <td>{{listReelDetail.BallBearing}}</td>
                                        <td>{{listReelDetail.Warna}}</td>
                                        <td>{{listReelDetail.PhType}}</td>
                                        <td>{{listReelDetail.WayType}}</td>
                                     </tr>       
                                    </tbody>
                                  </table>
              
                                  <!-- table for type line-->
                                  <table class="table table-hover" *ngIf="productTypeforHTML=='Line'">
                                    <thead>
                                      <tr>
                                        <th></th>                          
                                        <th>Product Name</th>
                                        <th>Brand</th>
                                        <th>Size</th>
                                        <th>Msr. Unit</th>
                                        <th>Type</th>
                                        <th>Colour</th>
                                        <th>Lbs</th>
                                        <th>PE</th>
                                        <th>Fluorocarbon</th>
                                        <th>Connector</th>
                                        <th>Diameter</th>
                                        <th>Power</th>
                                      </tr>
                                    </thead>
                                    <tbody >
                                     <tr *ngFor="let listLineDetail of listOfProductDetailLine; let i= index" >
                                      <td><button type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" 
                                        (click)="addToList(listLineDetail)">Order</button></td>                             
                                        <td>{{listLineDetail.Nama}}</td>
                                        <td>{{listLineDetail.Merk}}</td>
                                        <td>{{listLineDetail.Ukuran}}</td>
                                        <td>{{listLineDetail.Satuan}}</td>
                                        <td>{{listLineDetail.Tipe}}</td>
                                        <td>{{listLineDetail.Warna}}</td>
                                        <td>{{listLineDetail.Lbs}}</td>
                                        <td>{{listLineDetail.Pe}}</td>
                                        <td>{{listLineDetail.TypeFluoroCarbon}}</td>
                                        <td>{{listLineDetail.TypeConnectorSenar}}</td>
                                        <td>{{listLineDetail.Diameter}}</td>
                                        <td>{{listLineDetail.Kekuatan}}</td>
                                     </tr>       
                                    </tbody>
                                  </table>
              
                                  <!-- table for type kail-->
                                  <table class="table table-hover" *ngIf="productTypeforHTML=='Kail'">
                                    <thead>
                                      <tr>
                                        <th></th>                            
                                        <th>Product Name</th>
                                        <th>Brand</th>
                                        <th>Size</th>
                                      </tr>
                                    </thead>
                                    <tbody >
                                     <tr *ngFor="let listKailDetail of listOfProductDetailKail; let i= index" >
                                      <td><button type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" 
                                        (click)="addToList(listKailDetail)">Order</button></td>                            
                                        <td>{{listKailDetail.Nama}}</td>
                                        <td>{{listKailDetail.Merk}}</td>
                                        <td>{{listKailDetail.Ukuran}}</td>
                                     </tr>       
                                    </tbody>
                                  </table>      
              
                                  <!-- table for type Acc-->
                                  <table class="table table-hover" *ngIf="productTypeforHTML=='Acc'">
                                    <thead>
                                      <tr>
                                        <th></th>                            
                                        <th>Product Name</th>
                                        <th>Brand</th>
                                        <th>Ukuran</th>
                                        <th>Colour</th>
                                        <th>Type</th>
                                        <th>Size</th>
                                        <th>Lbs</th>
                                        <th>Liter</th>                                                                                                        
                                      </tr>
                                    </thead>
                                    <tbody >
                                     <tr *ngFor="let listAccDetail of listOfProductDetailAcc; let i= index" >
                                      <td><button type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" 
                                        (click)="addToList(listAccDetail)">Order</button></td>                             
                                        <td>{{listAccDetail.Nama}}</td>
                                        <td>{{listAccDetail.Merk}}</td>
                                        <td>{{listAccDetail.Ukuran}}</td>
                                        <td>{{listAccDetail.Warna}}</td>
                                        <td>{{listAccDetail.Tipe}}</td>
                                        <td>{{listAccDetail.Size}}</td>
                                        <td>{{listAccDetail.Lbs}}</td>
                                        <td>{{listAccDetail.Liter}}</td>                                                                                                        
                                     </tr>       
                                    </tbody>
                                  </table>  
                                <!-- table for type Bait-->
                                <table class="table table-hover" *ngIf="productTypeforHTML=='Bait'">
                                  <thead>
                                    <tr>
                                      <th></th>                            
                                      <th>Product Name</th>
                                      <th>Brand</th>
                                      <th>Ukuran</th>
                                      <th>Colour</th>
                                      <th>Type</th>
                                      <th>Weight</th>    
                                      <th>Varian</th>                                                                                                  
                                    </tr>
                                  </thead>
                                  <tbody >
                                   <tr *ngFor="let listBaitDetail of listOfProductDetailBait; let i= index" >
                                    <td><button type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" 
                                      (click)="addToList(listBaitDetail)">Order</button></td>                                
                                      <td>{{listBaitDetail.Nama}}</td>
                                      <td>{{listBaitDetail.Merk}}</td>
                                      <td>{{listBaitDetail.Ukuran}}</td>
                                      <td>{{listBaitDetail.Warna}}</td>
                                      <td>{{listBaitDetail.Tipe}}</td>
                                      <td>{{listBaitDetail.Berat}}</td>
                                      <td>{{listBaitDetail.Varian}}</td>                                                                                                             
                                   </tr>       
                                  </tbody>
                                </table>                                  
            </div>

          </div>          
        <div class="modal-footer">
         
          <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>