export class stock{

    constructor(values: Object = {}) {
          Object.assign(this, values);
    }
    private masterStockId : number;
    private priceDetailId : number;
    private stock : number;
    private jumlahPerDus : string;  
    private namaContainer : string;
    private uom : string;
    private tanggalCreatePo : string;
    private nomorPo : string
    private basePrice : number;
    private sellPrice : number;
    private tanggalUpdateHarga : string;
    private productNameGabungan : string;
    private namaHarga : string;
    private minQty : number;
    private minAllowQty : number;
    
    
    public get MasterStockId():number{
        return this.masterStockId;
    }
    public get PriceDetailId():number{
        return this.priceDetailId;
    }
    public get Stock():number{
        return this.stock;
    }
    public get JumlahPerDus():string{
        return this.jumlahPerDus;
    }
    public get NamaContainer():string{
        return this.namaContainer;
    }
    public get Uom():string{
        return this.uom;
    }
    public get TanggalCreatePo():string{
        return this.tanggalCreatePo;
    }
    public get NomorPo():string{
        return this.nomorPo;
    }
    public get BasePrice():number{
        return this.basePrice;
    }
    public get SellPrice():number{
        return this.sellPrice;
    }
    public get TanggalUpdateHarga():string{
        return this.tanggalUpdateHarga;
    }
    public get ProductNameGabungan():string{
        return this.productNameGabungan;
    }
    public get NamaHarga():string{
        return this.namaHarga;
    }    
    public get MinQty():number{
        return this.minQty;
    }    
    public get MinAllowQty():number{
        return this.minAllowQty;
    }    

    public set MasterStockId (masterStockId:number){
        this.masterStockId=masterStockId;
    }
    public set PriceDetailId (priceDetailId:number){
        this.priceDetailId=priceDetailId;
    }
    public set Stock (stock:number){
        this.stock=stock;
    }
    public set JumlahPerDus (jumlahPerDus:string){
        this.jumlahPerDus=jumlahPerDus;
    }
    public set NamaContainer (namaContainer:string){
        this.namaContainer=namaContainer;
    }
    public set Uom (uom:string){
        this.uom=uom;
    }
    public set TanggalCreatePo (tanggalCreatePo:string){
        this.tanggalCreatePo=tanggalCreatePo;
    }
    public set NomorPo (nomorPo:string){
        this.nomorPo=nomorPo;
    }
    public set BasePrice (basePrice:number){
        this.basePrice=basePrice;
    }
    public set SellPrice (sellPrice:number){
        this.sellPrice=sellPrice;
    }
    public set TanggalUpdateHarga (tanggalUpdateHarga:string){
        this.tanggalUpdateHarga=tanggalUpdateHarga;
    }
    public set ProductNameGabungan (productNameGabungan:string){
        this.productNameGabungan=productNameGabungan;
    }
    public set NamaHarga (namaHarga:string){
        this.namaHarga=namaHarga;
    }
    public set MinQty (minQty:number){
        this.minQty=minQty;
    }
    public set MinAllowQty (minAllowQty:number){
        this.minAllowQty=minAllowQty;
    }

    }