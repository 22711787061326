import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FormGroup, FormControl, FormBuilder, Validators,ReactiveFormsModule, FormArray } from '@angular/forms';
import { Router,ActivatedRoute } from "@angular/router";
import  { callGlobalFuction } from "../callGlobalFuction";
import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

//for login
  serviceReturn:any;
  statusService:any;
  errMessage="";

    loginForm= new FormGroup({
    username: new FormControl('',[
    Validators.required  	
    ]),
    password: new FormControl('',[
    Validators.required
    ])           
  });

  constructor(private _fb: FormBuilder,public router:Router, 
  	private getService:callGlobalFuction,public route:ActivatedRoute) { }





  encryptData(keys, value){
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
    {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
}


    loginAction(){
      let res={}
    let jsonInput = {};
    jsonInput['username'] = this.loginForm.get('username').value;
    console.log(this.loginForm.get('password').value);
    jsonInput['password'] = this.encryptData("8374637483919284",this.loginForm.get('password').value);   
    console.log(jsonInput);
       res=this.getService.restItemsServiceGetwithParamRestItems("gpuser/login",JSON.stringify(jsonInput))
      .subscribe(
        data => {
          this.serviceReturn = data;
          console.log(this.serviceReturn);
          if (this.serviceReturn.status=="S") {
          	sessionStorage.setItem("username", this.loginForm.get('username').value)
            sessionStorage.setItem("userid", this.serviceReturn.userId)
          	this.router.navigateByUrl("/retailpos");
          }
          else
          {
          	alert("Login Failed");
          }
        }
      )
  }

  ngOnInit(): void {
  }

}
