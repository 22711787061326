export class productdetailkailmodel{

    constructor(values: Object = {}) {
          Object.assign(this, values);
    }
    private id : number;
    private detailId : number;    
    private barcode :string;
    private nama : string;
    private merk : string;
    private merkId : string;    
    private ukuran : string;    
    private connectorCategoryId : number;

    
    public get ConnectorCategoryId():number{
        return this.connectorCategoryId;
    } 

    public get Id():number{
        return this.id;
    }
    public get DetailId():number{
        return this.detailId;
    }    
    public get Barcode ():string{
        return this.barcode;
    }

    public get Nama() : string{
        return this.nama;
    }
    public get Merk() : string{
        return this.merk;
    }
    public get MerkId() : string{
        return this.merkId;
    }
    public get Ukuran() : string{
        return this.ukuran;
    }

    public set Id (id:number)
    {
        this.id=id;
    }

    public set DetailId (detailId:number)
    {
        this.detailId=detailId;
    }
    public set Nama (nama:string)
    {
        this.nama=nama;
    }
    public set Merk (merk:string)
    {
        this.merk=merk;
    }
    public set Barcode (barcode:string)
    {
        this.barcode=barcode;
    }
    public set MerkId (merkId:string)
    {
        this.merkId=merkId;
    }    
    public set Ukuran (ukuran:string)
    {
        this.ukuran=ukuran;
    }
    public set ConnectorCategoryId (connectorCategoryId:number)
    {
        this.connectorCategoryId=connectorCategoryId;
    }
    
    }