import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators,ReactiveFormsModule } from '@angular/forms';
import  { callGlobalFuction } from "../../callGlobalFuction";
import { Router,ActivatedRoute } from "@angular/router";
import { brand } from '../../model/config/brand';

@Component({
  selector: 'app-merkconfig',
  templateUrl: './merkconfig.component.html',
  styleUrls: ['./merkconfig.component.css']
})
export class MerkconfigComponent implements OnInit {

  constructor(private fb: FormBuilder, private getService:callGlobalFuction,
    public router:Router,public route:ActivatedRoute) { }

  serviceReturn:any;
  listOfMerk=[]; 
  brandSetting :brand;
  brandSettingUpdate :brand;
  p:number=1;

  updateBrandForm= new FormGroup({
    id: new FormControl('',[
      Validators.required  
    ]), 
    brandName: new FormControl('',[
      Validators.required
    ]),     
    brandCode: new FormControl('',[
      Validators.required
    ]),  
  });

  insertBrandForm= new FormGroup({
    brandName: new FormControl('',[
      Validators.required
    ]),     
    brandCode: new FormControl('',[
      Validators.required
    ]),  
  });

  searchMerk()
   {
 
     let jsonInput = {};
     jsonInput['kode']="merk";
     jsonInput['categoryId']="";
     let res={}
       let stringJsonInput = JSON.stringify(jsonInput);
       this.listOfMerk=[];
 
        res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
       .subscribe( 
         data => {
           this.serviceReturn = data;
           if(this.serviceReturn.status=="S")
           {
            console.log(this.serviceReturn);
            for(let i=0;i<this.serviceReturn.listCode.length;i++)
            {
            this.brandSetting = new brand();
            this.brandSetting.Id = this.serviceReturn.listCode[i].id;
            this.brandSetting.NamaMerek = this.serviceReturn.listCode[i].namaMerk;
            this.brandSetting.KodeMerek = this.serviceReturn.listCode[i].kodeMerk;
            this.listOfMerk.push(this.brandSetting);
            }
           }
           else{
             alert("List of Brand Not Found");
           }
         }
       )  
   }   

   openModalUpdateBrand(brandModel:any)
   {
      this.brandSettingUpdate = brandModel;
      this.updateBrandForm.get('id').setValue(this.brandSettingUpdate.Id);
      this.updateBrandForm.get('brandName').setValue(this.brandSettingUpdate.NamaMerek);
      this.updateBrandForm.get('brandCode').setValue(this.brandSettingUpdate.KodeMerek);
   }

   serviceReturn2:any;
   updateBrand()
   {
     let jsonInput = {};
     jsonInput['id']=parseInt(this.updateBrandForm.get('id').value);
     jsonInput['nama']=this.updateBrandForm.get('brandName').value;
     jsonInput['kode']= this.updateBrandForm.get('brandCode').value;
     jsonInput['tipeKode']= "merk"
     jsonInput['keterangan']= "";
     jsonInput['categoryId']= "";
     console.log(jsonInput)
 
     let res={}
     let stringJsonInput = JSON.stringify(jsonInput);
     console.log(stringJsonInput);
 
      res=this.getService.restItemsServiceGetwithParamRestItems('updateCode',stringJsonInput)
     .subscribe( 
       data => {
         this.serviceReturn2 = data;
         console.log(this.serviceReturn2)
         if(this.serviceReturn2.status=="S")
         {
           alert("Success Update Brand");
           location.reload();
         }
         else{
           alert("Failed Update Brand "+this.serviceReturn2.errMes);
         }
       }
     )  
   }
 

   serviceReturn3:any;
  deleteConfirm(id:number)
  {
    this.deleteTempId=0;
    this.deleteTempId=id;
  }
  deleteTempId=0;
  deleteBrand()
   {
     let jsonInput = {};
     jsonInput['codeId'] = this.deleteTempId;
     jsonInput['tipeKode'] = "merk";
  
     let res={}
       let stringJsonInput = JSON.stringify(jsonInput);
       console.log(stringJsonInput);
        res=this.getService.restItemsServiceGetwithParamRestItems('deleteCode',stringJsonInput)
       .subscribe(
         data => {
           this.serviceReturn3 = data;
           console.log(this.serviceReturn3);
           if(this.serviceReturn3.status=="S")
           {
             alert("Success Delete Brand");
             location.reload();
           }
  
           else
           {
             alert("Failed Delete Brand "+this.serviceReturn3.errMes);
           }
         }
       )   
   }

   serviceReturn4:any;
   insertBrand()
   {
     let jsonInput = {};
     jsonInput['nama']=this.insertBrandForm.get('brandName').value;
     jsonInput['kode']= this.insertBrandForm.get('brandCode').value;
     jsonInput['tipeKode']= "merk"
     jsonInput['keterangan']= "";
     jsonInput['categoryId']= "";
     console.log(jsonInput)
 
     let res={}
     let stringJsonInput = JSON.stringify(jsonInput);
     console.log(stringJsonInput);
 
      res=this.getService.restItemsServiceGetwithParamRestItems('insertNewCode',stringJsonInput)
     .subscribe( 
       data => {
         this.serviceReturn4 = data;
         console.log(this.serviceReturn4)
         if(this.serviceReturn4.status=="S")
         {
           alert("Success insert Brand");
           location.reload();
         }
         else{
           alert("Failed Insert Brand "+this.serviceReturn4.errMes);
         }
       }
     )  
   }

  ngOnInit(): void {
    this.searchMerk();
  }

}
