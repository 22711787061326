<div class="col-md-12 grid-margin stretch-card">
	<div class="card">
		<div class="card-body">
			<h4 class="card-title">Create Incoming Transaction</h4>		         
 						 <div class="form-group row">                                                   
                            <div class="col">
                                <label>Supplier</label>
                                <div id="the-basics">
                                    <select  class="form-control" 
                                     type="text" placeholder="supplierId"  [(ngModel)]="supplierIdTemp" (change)="inquiryPOHeader()">
                                        <option value="" >-</option>
                                        <option value={{supplierList.Id}}  *ngFor="let supplierList of listOfSupplier; 
                                        let i = index">{{supplierList.KodeSupplier}} - {{supplierList.NamaSupplier}}</option>
                                    </select> 
                                </div>
                            </div>  
                            <div class="col">
                              <label>PO Number</label>
                              <div id="the-basics">
                                  <select  class="form-control"  [(ngModel)]="poHeaderIdTemp"
                                   type="text" placeholder="Supplier">
                                      <option value="" >-</option>
                                      <option value={{listOfPOHeader.Id}} *ngFor="let listOfPOHeader of listOfPOHeader; 
                                      let i = index">{{listOfPOHeader.NomorPO}}</option>
                                  </select> 
                              </div>
                          </div>                                
                             <div class="col">
                                <label style="color: transparent;">-</label>
                                <button type="submit" class="btn btn-primary mr-2" style="min-width: 100%;"
                                 (click)="inquiryPoDetail()" data-toggle="modal" [disabled]="poHeaderIdTemp==''"
                                 data-target="#exampleModal">Search</button>
                            </div>                     
                </div>
                <form class="form-sample"  [formGroup]="incomingForm"> 	
                <div class="form-group row">                                                   
                  <div class="col">
                      <label>Delivery Order Number</label>
                      <div id="the-basics">
                        <input class="typeahead"  id="nomorSJ" formControlName="nomorSJ" 
                        name="nomorSJ" type="text" placeholder="DO Number"> </div>
                      </div>
                  <div class="col">
                    <label>Incoming Date</label>
                    <div id="the-basics">
                      <div id="datepicker-popup2"  class="input-group date datepicker">
                          <input type="text" class="form-control"  formControlName="tanggalTransaksi" id="tanggalTransaksi" name="tanggalTransaksi">
                          <span class="input-group-addon input-group-append border-left">
                            <span class="mdi mdi-calendar input-group-text"></span>
                          </span>
                        </div> 
                  </div>
                </div>     
                <div class="col">
                  <label>Received By</label>
                  <div id="the-basics">
                    <input class="typeahead"  id="diterimaOleh" formControlName="diterimaOleh" 
                    name="diterimaOleh" type="text" placeholder="Received By"> </div>
                  </div>                           
                   <div class="col">
                      <label style="color: transparent;">-</label>
                      <button type="submit" class="btn btn-danger mr-2" style="min-width: 100%;" [disabled]="incomingForm.invalid"
                       (click)="incomingItem()" >Proceed</button>
                  </div>                     
      </div>     
      </form>           
                	 <div class="table-responsive">
                        <table class="table table-hover" >
                          <thead>
                            <tr>
                              <th>Product</th>
                              <th>PO Number</th>
                              <th>Qty</th>
                            </tr>
                          </thead>
                          <tbody >
                           <tr *ngFor="let listOfDataTemp of listOfDataTemp | paginate: { itemsPerPage: 10, currentPage: p }; let i= index"
                           style="cursor: pointer;" >
                              <td>{{listOfDataTemp.detail}}</td>
                              <td>{{listOfDataTemp.poNumber}}</td>
                              <td>{{listOfDataTemp.qty  | number}}</td>
                           </tr> 
                           <td colspan="6" style="text-align: center;">
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                         </td>                             
                          </tbody>
                        </table>
                        </div>  
                    
		</div>
	</div>
</div>



<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Incoming Product </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
          <div class="modal-body" style="overflow-y: auto; max-height:500px;">       
  
            <table class="table table-hover" >
                <thead>
                  <tr>
                    <th>Brand</th>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Total</th>
                    <th>Delivered</th>
                    <th>Outstanding</th>
                    <th style="color: transparent;">-</th>
                  </tr>
                </thead>
                <tbody >
                  <tr *ngFor="let purchaseOrderDetail of listOfPODetail ; let i= index">
                    <td *ngIf="checkqty[i]=='true'">{{purchaseOrderDetail.NamaMerek}}</td>
                    <td style="color:darkred" *ngIf="checkqty[i]=='false'">{{purchaseOrderDetail.NamaMerek}}</td>
                    <td *ngIf="checkqty[i]=='true'">{{purchaseOrderDetail.NamaBarang}}<br><br>{{purchaseOrderDetail.Detail1}}<br><br>{{purchaseOrderDetail.Detail2}}</td>
                    <td style="color:darkred" *ngIf="checkqty[i]=='false'">{{purchaseOrderDetail.NamaBarang}}<br><br>{{purchaseOrderDetail.Detail1}}<br><br>{{purchaseOrderDetail.Detail2}}</td>
                    <td *ngIf="checkqty[i]=='true'">Rp. {{purchaseOrderDetail.HargaDasar | number}}</td>
                    <td style="color:darkred" *ngIf="checkqty[i]=='false'">Rp. {{purchaseOrderDetail.HargaDasar | number}}</td>
                    <td *ngIf="checkqty[i]=='true'">{{purchaseOrderDetail.TotalMasuk}}</td>
                    <td style="color:darkred" *ngIf="checkqty[i]=='false'">{{purchaseOrderDetail.TotalMasuk}}</td>
                    <td *ngIf="checkqty[i]=='true'">{{purchaseOrderDetail.SudahMasuk}}</td>
                    <td style="color:darkred" *ngIf="checkqty[i]=='false'">{{purchaseOrderDetail.SudahMasuk}}</td>
                    <td *ngIf="checkqty[i]=='true'">{{purchaseOrderDetail.BelumMasuk}}</td>
                    <td style="color:darkred" *ngIf="checkqty[i]=='false'">{{purchaseOrderDetail.BelumMasuk}}</td>
                    <td><input type="text" class="form-control" style="max-width: 160px;" (change)="validasiQty()" [(ngModel)]="qtyMasuk[i]"></td>
                  </tr>
                </tbody>
              </table>
  
          </div>          
        <div class="modal-footer">
          <button type="button" class="btn btn-success" data-dismiss="modal" (click)="addToTempList()" [disabled]="hasilakhir=='false'">Add</button>
          <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>

