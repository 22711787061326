import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators,ReactiveFormsModule } from '@angular/forms';
import  { callGlobalFuction } from "../../callGlobalFuction";
import { Router,ActivatedRoute } from "@angular/router";
import  { DateTimeUtil } from "../../globalfunction/dateTimeUtil";
import { productmastermodel } from '../../model/product/productmaster';
import { productdetailjoranmodel } from '../../model/product/productDetailJoran';
import { productdetailreelmodel } from '../../model/product/productDetailReel';
import { productdetaillinemodel } from '../../model/product/productDetailLine';
import { productdetailkailmodel } from '../../model/product/productDetailKail';
import { productdetailaccmodel } from '../../model/product/productDetailAcc';
import { productdetailbaitmodel } from '../../model/product/productDetailBait';
import { transactionMaster } from '../../model/transaction/transactionpos';
import { stock } from '../../model/product/stock';
declare var $ :any;

@Component({
  selector: 'app-createstockadjustment',
  templateUrl: './createstockadjustment.component.html',
  styleUrls: ['./createstockadjustment.component.css']
})
export class CreatestockadjustmentComponent implements OnInit {

  constructor(private fb: FormBuilder, private getService:callGlobalFuction,
    public router:Router,public route:ActivatedRoute,public dateTimeUtil: DateTimeUtil) { }

    inquiryMasterProductForm= new FormGroup({
      productName: new FormControl('',[
      Validators.required
      ])                     
    });

      //inquiry master product
      productMasterModel :productmastermodel;
      listOfProductMasterModel=[];

      //inquiry detail product
      serviceReturn:any;
      serviceReturn2:any;
      serviceReturn3:any;
      serviceReturn4:any;
      productDetailJoran :productdetailjoranmodel;
      listOfProductDetailJoran=[];
      productDetailReel :productdetailreelmodel;
      listOfProductDetailReel=[];
      productDetailLine :productdetaillinemodel;
      listOfProductDetailLine=[];
      productDetailKail :productdetailkailmodel;
      listOfProductDetailKail=[];
      productDetailAcc :productdetailaccmodel;
      listOfProductDetailAcc=[];
      productTypeforHTML="";
      productDetailBait :productdetailbaitmodel;
      listOfProductDetailBait=[];

      trMasterModel:transactionMaster;
      listOfProductMasterModelAdd=[];

      stockAdjustmentForm= new FormGroup({
        tipeTransaksi: new FormControl('',[Validators.required
        ]),
        qty: new FormControl('',[Validators.required
        ]),
        keterangan: new FormControl('',[Validators.required
        ]),                                 
      });

    searchbyname()
    {
  
      let jsonInput = {};
      jsonInput['namaBarang'] = this.inquiryMasterProductForm.get('productName').value;
      
      let res={}
        let stringJsonInput = JSON.stringify(jsonInput);
        this.listOfProductMasterModel=[];
  
         res=this.getService.restItemsServiceGetwithParamRestItems('inquiryCategoryAndBrandByProductName',stringJsonInput)
        .subscribe(
          data => {
            this.serviceReturn2 = data;
            if(this.serviceReturn2.status=="S")
            {
              for(let i=0;i<this.serviceReturn2.listProduct.length;i++)
              {
              this.productMasterModel = new productmastermodel();
              this.productMasterModel.Id = this.serviceReturn2.listProduct[i].id;
              this.productMasterModel.MerkId = this.serviceReturn2.listProduct[i].merk_id;
              this.productMasterModel.CategoryId = this.serviceReturn2.listProduct[i].category_id;
              this.productMasterModel.NamaBarang = this.serviceReturn2.listProduct[i].nama_barang;
              this.productMasterModel.NamaMerk = this.serviceReturn2.listProduct[i].nama_merk;
              this.productMasterModel.NamaCategory = this.serviceReturn2.listProduct[i].nama_kategori;
              this.listOfProductMasterModel.push(this.productMasterModel);
              }
            }
            else
            {
              alert("Master Product Search Failed");
            }
          }
        )  
    }

    activeCategory : any;
    searchbynameDetail(id:string, categoryId:string, merkId:string)
    {
      this.activeCategory=categoryId;
  
      $('#exampleModal').modal('hide'); 
      let jsonInput = {};
      
      let res={}
      jsonInput['productId'] = id;
      jsonInput['kategoriId'] = categoryId;
      jsonInput['merkId'] = merkId;
      let stringJsonInput = JSON.stringify(jsonInput);
      console.log(stringJsonInput)
        
         res=this.getService.restItemsServiceGetwithParamRestItems('inquiryProductDetailAllByCategoryAndBrand',stringJsonInput)
        .subscribe(
          data => {
            this.serviceReturn = data;
            console.log(this.serviceReturn)
            if(this.serviceReturn.status=="S")
            {
              //inquiry detail untuk joran
              if(this.serviceReturn.categoryId==1 && this.serviceReturn.listProduct.listJoranDetail.length>0)
              {
                this.productTypeforHTML="Rod";
                this.listOfProductDetailJoran=[];
                for(let i=0;i<this.serviceReturn.listProduct.listJoranDetail.length;i++)
                {
                this.productDetailJoran = new productdetailjoranmodel();
                this.productDetailJoran.Ukuran = this.serviceReturn.listProduct.listJoranDetail[i].ukuran;
                this.productDetailJoran.Warna = this.serviceReturn.listProduct.listJoranDetail[i].nama_warna;
                this.productDetailJoran.WarnaId = this.serviceReturn.listProduct.listJoranDetail[i].warna_joran_id;
                this.productDetailJoran.Ring = this.serviceReturn.listProduct.listJoranDetail[i].ring;
                this.productDetailJoran.Bahan = this.serviceReturn.listProduct.listJoranDetail[i].nama_bahan;
                this.productDetailJoran.BahanId = this.serviceReturn.listProduct.listJoranDetail[i].bahan_joran_id;
                this.productDetailJoran.Nama = this.serviceReturn.listProduct.listJoranDetail[i].nama_barang;
                this.productDetailJoran.TahunProduksi = this.serviceReturn.listProduct.listJoranDetail[i].tahun_produksi;
                this.productDetailJoran.MinLbs = this.serviceReturn.listProduct.listJoranDetail[i].lbs_min;  
                this.productDetailJoran.MaxLbs = this.serviceReturn.listProduct.listJoranDetail[i].nama_tipe_joran; 
                this.productDetailJoran.Merk = this.serviceReturn.listProduct.listJoranDetail[i].nama_merk; 
                this.productDetailJoran.MerkId = this.serviceReturn.listProduct.listJoranDetail[i].merk_id;
                this.productDetailJoran.MaxLbs = this.serviceReturn.listProduct.listJoranDetail[i].lbs_max;  
                this.productDetailJoran.MaxPE = this.serviceReturn.listProduct.listJoranDetail[i].pe_max;  
                this.productDetailJoran.MinPE = this.serviceReturn.listProduct.listJoranDetail[i].pe_min; 
                this.productDetailJoran.Tipe = this.serviceReturn.listProduct.listJoranDetail[i].nama_tipe;   
                this.productDetailJoran.TipeId = this.serviceReturn.listProduct.listJoranDetail[i].tipe_joran_id;
                this.productDetailJoran.DetailId = this.serviceReturn.listProduct.listJoranDetail[i].productDetailId; 
                this.productDetailJoran.Id = this.serviceReturn.listProduct.listJoranDetail[i].productId;
                this.listOfProductDetailJoran.push(this.productDetailJoran);
                }
              }
              //end of inquiry detail untuk joran
  
               //inquiry detail untuk reel
               else if(this.serviceReturn.categoryId==2 && this.serviceReturn.listProduct.listReelDetail.length>0)
               {
                 this.productTypeforHTML="Reel";
                 this.listOfProductDetailReel=[];
                 for(let i=0;i<this.serviceReturn.listProduct.listReelDetail.length;i++)
                 {
                 this.productDetailReel = new productdetailreelmodel();
                 console.log(this.serviceReturn)
                 this.productDetailReel.Ukuran = this.serviceReturn.listProduct.listReelDetail[i].ukuran;                                                                 
                 this.productDetailReel.WarnaId = this.serviceReturn.listProduct.listReelDetail[i].warna_reel_id;
                 this.productDetailReel.PhType = this.serviceReturn.listProduct.listReelDetail[i].ph_type;
                 this.productDetailReel.Warna = this.serviceReturn.listProduct.listReelDetail[i].nama_warna;
                 this.productDetailReel.BallBearing = this.serviceReturn.listProduct.listReelDetail[i].ball_bearing;
                 this.productDetailReel.TipeId = this.serviceReturn.listProduct.listReelDetail[i].tipe_reel_id;
                 this.productDetailReel.WayType = this.serviceReturn.listProduct.listReelDetail[i].way_type;
                 this.productDetailReel.Nama = this.serviceReturn.listProduct.listReelDetail[i].nama_barang;
                 this.productDetailReel.Tipe = this.serviceReturn.listProduct.listReelDetail[i].nama_tipe;                                                                 
                 this.productDetailReel.Merk = this.serviceReturn.listProduct.listReelDetail[i].nama_merk;
                 this.productDetailReel.MerkId = this.serviceReturn.listProduct.listReelDetail[i].merk_id;               
                 this.productDetailReel.DetailId = this.serviceReturn.listProduct.listReelDetail[i].productDetailId;
                 this.productDetailReel.Id = this.serviceReturn.listProduct.listReelDetail[i].productId;               
                 this.listOfProductDetailReel.push(this.productDetailReel);
                 }
               }
               //end of inquiry detail untuk reel
  
               //inquiry detail untuk line
              else if(this.serviceReturn.categoryId==3 && this.serviceReturn.listProduct.listLineDetail.length>0)
               {
                 this.productTypeforHTML="Line";
                 this.listOfProductDetailLine=[];
                 for(let i=0;i<this.serviceReturn.listProduct.listLineDetail.length;i++)
                 {
                 this.productDetailLine = new productdetaillinemodel();
                 this.productDetailLine.WarnaId = this.serviceReturn.listProduct.listLineDetail[i].warna_line_id;
                 this.productDetailLine.Satuan = this.serviceReturn.listProduct.listLineDetail[i].satuan_ukur;
                 this.productDetailLine.Ukuran = this.serviceReturn.listProduct.listLineDetail[i].ukr_panjang;
                 this.productDetailLine.Kekuatan = this.serviceReturn.listProduct.listLineDetail[i].kekuatan;
                 this.productDetailLine.TypeConnectorSenar = this.serviceReturn.listProduct.listLineDetail[i].tipe_konektor_senar;
                 this.productDetailLine.TipeNo = this.serviceReturn.listProduct.listLineDetail[i].tipe_no;
                 this.productDetailLine.TypeFluoroCarbon = this.serviceReturn.listProduct.listLineDetail[i].tipe_fluor_carbon;
                 this.productDetailLine.Warna = this.serviceReturn.listProduct.listLineDetail[i].nama_warna;
                 this.productDetailLine.Diameter = this.serviceReturn.listProduct.listLineDetail[i].diameter;
                 this.productDetailLine.Pe = this.serviceReturn.listProduct.listLineDetail[i].pe;
                 this.productDetailLine.TipeId = this.serviceReturn.listProduct.listLineDetail[i].tipe_line_id;
                 this.productDetailLine.Nama = this.serviceReturn.listProduct.listLineDetail[i].nama_barang;
                 this.productDetailLine.Tipe = this.serviceReturn.listProduct.listLineDetail[i].nama_tipe;
                 this.productDetailLine.Lbs = this.serviceReturn.listProduct.listLineDetail[i].lbs;
                 this.productDetailLine.Merk = this.serviceReturn.listProduct.listLineDetail[i].nama_merk;
                 this.productDetailLine.MerkId = this.serviceReturn.listProduct.listLineDetail[i].merk_id;               
                 this.productDetailLine.Id = this.serviceReturn.listProduct.listLineDetail[i].productId;
                 this.productDetailLine.DetailId = this.serviceReturn.listProduct.listLineDetail[i].productDetailId;                              
                 this.listOfProductDetailLine.push(this.productDetailLine);
                 }
               }
               //end of inquiry detail untuk line
  
               //inquiry detail untuk kail
              else if(this.serviceReturn.categoryId==4 && this.serviceReturn.listProduct.listKailDetail.length>0)
              {
                this.productTypeforHTML="Kail";
                this.listOfProductDetailKail=[];
                for(let i=0;i<this.serviceReturn.listProduct.listKailDetail.length;i++)
                {
                this.productDetailKail = new productdetailkailmodel();
                this.productDetailKail.Id = this.serviceReturn.listProduct.listKailDetail[i].productId;
                this.productDetailKail.DetailId = this.serviceReturn.listProduct.listKailDetail[i].productDetailId;
                this.productDetailKail.Ukuran = this.serviceReturn.listProduct.listKailDetail[i].ukuran;
                this.productDetailKail.Merk = this.serviceReturn.listProduct.listKailDetail[i].nama_merk;
                this.productDetailKail.MerkId = this.serviceReturn.listProduct.listKailDetail[i].merk_id;              
                this.productDetailKail.Nama = this.serviceReturn.listProduct.listKailDetail[i].nama_barang;              
                this.listOfProductDetailKail.push(this.productDetailKail);
                }
              }
              //end of inquiry detail untuk kail
  
               //inquiry detail untuk acc
               else if(this.serviceReturn.categoryId==5 && this.serviceReturn.listProduct.listAksesorisDetail.length>0)
               {
                 this.productTypeforHTML="Acc";
                 this.listOfProductDetailAcc=[];
                 for(let i=0;i<this.serviceReturn.listProduct.listAksesorisDetail.length;i++)
                 {
                 this.productDetailAcc = new productdetailaccmodel();
                 this.productDetailAcc.DetailId = this.serviceReturn.listProduct.listAksesorisDetail[i].productDetailId;
                 this.productDetailAcc.Id = this.serviceReturn.listProduct.listAksesorisDetail[i].productId;               
                 this.productDetailAcc.Ukuran = this.serviceReturn.listProduct.listAksesorisDetail[i].ukuran;
                 this.productDetailAcc.Merk = this.serviceReturn.listProduct.listAksesorisDetail[i].nama_merk;
                 this.productDetailAcc.MerkId = this.serviceReturn.listProduct.listAksesorisDetail[i].merk_id;               
                 this.productDetailAcc.Nama = this.serviceReturn.listProduct.listAksesorisDetail[i].nama_barang;
                 this.productDetailAcc.Warna = this.serviceReturn.listProduct.listAksesorisDetail[i].nama_warna;
                 this.productDetailAcc.WarnaId = this.serviceReturn.listProduct.listAksesorisDetail[i].warna_aksesoris_id; 
                 this.productDetailAcc.Lbs = this.serviceReturn.listProduct.listAksesorisDetail[i].lbs; 
                 this.productDetailAcc.Liter = this.serviceReturn.listProduct.listAksesorisDetail[i].liter; 
                 this.productDetailAcc.Tipe = this.serviceReturn.listProduct.listAksesorisDetail[i].nama_tipe; 
                 this.productDetailAcc.TipeId = this.serviceReturn.listProduct.listAksesorisDetail[i].tipe_aksesoris_id;  
                 this.productDetailAcc.Size = this.serviceReturn.listProduct.listAksesorisDetail[i].size;                             
                 this.listOfProductDetailAcc.push(this.productDetailAcc);
                 }
               }
               //end of inquiry detail untuk acc            
             //inquiry detail untuk bait
             else if(this.serviceReturn.categoryId==6 && this.serviceReturn.listProduct.listUmpanDetail.length>0)
             {
               this.productTypeforHTML="Bait";
               this.listOfProductDetailBait=[];
               console.log(this.serviceReturn);
               for(let i=0;i<this.serviceReturn.listProduct.listUmpanDetail.length;i++)
               {
               this.productDetailBait = new productdetailbaitmodel();
               this.productDetailBait.DetailId = this.serviceReturn.listProduct.listUmpanDetail[i].productDetailId;
               this.productDetailBait.Id = this.serviceReturn.listProduct.listUmpanDetail[i].productId;               
               this.productDetailBait.Ukuran = this.serviceReturn.listProduct.listUmpanDetail[i].ukuran;
               this.productDetailBait.Merk = this.serviceReturn.listProduct.listUmpanDetail[i].nama_merk;
               this.productDetailBait.MerkId = this.serviceReturn.listProduct.listUmpanDetail[i].merkId;               
               this.productDetailBait.Nama = this.serviceReturn.listProduct.listUmpanDetail[i].nama_barang;
               this.productDetailBait.Warna = this.serviceReturn.listProduct.listUmpanDetail[i].nama_warna;
               this.productDetailBait.WarnaId = this.serviceReturn.listProduct.listUmpanDetail[i].warna_umpan_id; 
               this.productDetailBait.Tipe = this.serviceReturn.listProduct.listUmpanDetail[i].nama_tipe; 
               this.productDetailBait.TipeId = this.serviceReturn.listProduct.listUmpanDetail[i].tipe_umpan_id;   
               this.productDetailBait.Berat = this.serviceReturn.listProduct.listUmpanDetail[i].berat;     
               this.productDetailBait.Varian = this.serviceReturn.listProduct.listUmpanDetail[i].namaVarian;   
               this.productDetailBait.VarianId = this.serviceReturn.listProduct.listUmpanDetail[i].varianId;                       
               this.listOfProductDetailBait.push(this.productDetailBait);
               }
             }
             //end of inquiry detail untuk bait    
              else{
                alert("Product Detail Not Found");
              }
  
            }
            else
            {
              alert("Inquiry Detail Product Failed");
            }
          }
        )  
    }

    addToList(objectModel:any,catId:number)
    {
      console.log(objectModel)
      this.inquiryStockHeader(objectModel.DetailId,catId);
      this.trMasterModel = new transactionMaster();
      this.listOfProductMasterModelAdd=[];
      this.trMasterModel.Id=objectModel.DetailId;
      this.trMasterModel.NamaBarang=objectModel.Merk + " " +objectModel.Nama+ " " +objectModel.Ukuran;
      this.trMasterModel.CategoryId=this.activeCategory;
      this.trMasterModel.Qty=0;
      this.trMasterModel.BasePrice=0;
      this.trMasterModel.Total=0;
      this.listOfProductMasterModelAdd.push(this.trMasterModel);
      $('#exampleModal2').modal('hide');
    }
    stockModel :stock = new stock();
    inquiryStockHeader(id:any, categoryId:any)
    {
  
      let jsonInput = {};
      jsonInput['categoryId']=parseInt(categoryId);
      jsonInput['productDetailId']=parseInt(id); 
      let res={}
        let stringJsonInput = JSON.stringify(jsonInput);
        console.log(stringJsonInput)
         res=this.getService.restItemsServiceGetwithParamRestItems('warehouse/inquiryStock',stringJsonInput)
        .subscribe( 
          data => {
            this.serviceReturn4 = data;
            console.log(this.serviceReturn4);
            if(this.serviceReturn4.status=="S")
            {
              this.stockModel = new stock();
              //this.stockModel.MasterStockId = this.serviceReturn4.result.masterStockId;
              this.stockModel.Uom = this.serviceReturn4.result.uom;
              this.stockModel.Stock = this.serviceReturn4.result.stock;
              this.stockModel.JumlahPerDus = this.serviceReturn4.result.jumlahPerDus;
            }
            else{
              alert("Inquiry Stock Failed");
            }
          }
        )
    }

    createStockAdjustment()
  {
    let jsonInput = {};
    jsonInput['categoryId'] = parseInt(this.listOfProductMasterModelAdd[0].CategoryId);
    jsonInput['productDetailId'] = parseInt(this.listOfProductMasterModelAdd[0].Id);
    jsonInput['userId'] = parseInt(sessionStorage.getItem('userid'));
    if(this.stockAdjustmentForm.get('tipeTransaksi').value=="tambah")
    {
      jsonInput['kodeTransaksi'] = 3;
    }
    else{
      jsonInput['kodeTransaksi'] = 8;
    }
    jsonInput['qtyAdjustment'] = parseInt(this.stockAdjustmentForm.get('qty').value);
    jsonInput['keteranganAdjustment'] = this.stockAdjustmentForm.get('keterangan').value;
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      console.log(stringJsonInput)
       res=this.getService.restItemsServiceGetwithParamRestItems('warehouse/createStockAdjustment',stringJsonInput)
      .subscribe(
        data => {
          this.serviceReturn3 = data;
          console.log(this.serviceReturn3);
          if(this.serviceReturn3.status=="S")
          {
            alert("Success Create Adjustment");
            this.router.navigateByUrl('stockadjustment');
          }
          else
          {
            alert("Failed Create Adjustment");
          }
        }
      )  
  }
  

  ngOnInit(): void {
  }

}
