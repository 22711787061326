import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators,ReactiveFormsModule } from '@angular/forms';
import  { callGlobalFuction } from "../../callGlobalFuction";
import { Router,ActivatedRoute } from "@angular/router";
import { purchaseOrder } from '../../model/transaction/purchaseorder';
import { supplier } from '../../model/transaction/supplier';
import  { DateTimeUtil } from "../../globalfunction/dateTimeUtil";
import { productmastermodel } from '../../model/product/productmaster';
import { productdetailjoranmodel } from '../../model/product/productDetailJoran';
import { productdetailreelmodel } from '../../model/product/productDetailReel';
import { productdetaillinemodel } from '../../model/product/productDetailLine';
import { productdetailkailmodel } from '../../model/product/productDetailKail';
import { productdetailaccmodel } from '../../model/product/productDetailAcc';
import { transactionMaster } from '../../model/transaction/transactionpos';
import { productdetailbaitmodel } from '../../model/product/productDetailBait';
declare var $ :any;

@Component({
  selector: 'app-createpo',
  templateUrl: './createpo.component.html',
  styleUrls: ['./createpo.component.css']
})
export class CreatepoComponent implements OnInit {

  constructor(private fb: FormBuilder, private getService:callGlobalFuction,
    public router:Router,public route:ActivatedRoute,public dateTimeUtil: DateTimeUtil) { }
    productTypeforHTML="";
    //inquiry purchase order
    serviceReturn3:any;
    supplier :supplier;
    listOfSupplier=[];    
    p:number=1;
    //add product
    trMasterModel:transactionMaster;
    listOfProductMasterModelAdd=[];
    serviceReturn4:any;
    purchaseOrderForm= new FormGroup({
      poNumber: new FormControl('',[
      ]),
      supplierId: new FormControl('',[
      ]),
      tanggalTransaksi: new FormControl('',[
      ]),                                 
    });

  //inquiry master product
  serviceReturn2:any;
  productMasterModel :productmastermodel;
  listOfProductMasterModel=[];

   inquiryMasterProductForm= new FormGroup({
    productName: new FormControl('',[
    Validators.required
    ])                     
  });

   //inquiry detail product
  serviceReturn:any;
  productDetailJoran :productdetailjoranmodel;
  listOfProductDetailJoran=[];
  productDetailReel :productdetailreelmodel;
  listOfProductDetailReel=[];
  productDetailLine :productdetaillinemodel;
  listOfProductDetailLine=[];
  productDetailKail :productdetailkailmodel;
  listOfProductDetailKail=[];
  productDetailAcc :productdetailaccmodel;
  listOfProductDetailAcc=[];
  productDetailBait :productdetailbaitmodel;
  listOfProductDetailBait=[];

  inquirySupplierList()
  {
    let jsonInput = {};
    jsonInput['kode']="supplier";
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn3 = data;
          if(this.serviceReturn3.status=="S")
          {
            this.listOfSupplier=[];
            console.log(this.serviceReturn3)
            for(let i=0;i<this.serviceReturn3.listCode.length;i++)
            {
            this.supplier = new supplier();
            this.supplier.NamaSupplier = this.serviceReturn3.listCode[i].namaSupplier;
            this.supplier.KodeSupplier = this.serviceReturn3.listCode[i].kodeSupplier;
            this.supplier.Alamat = this.serviceReturn3.listCode[i].alamat;
            this.supplier.Email = this.serviceReturn3.listCode[i].noHP;
            this.supplier.NoHP = this.serviceReturn3.listCode[i].email;
            this.supplier.Keterangan = this.serviceReturn3.listCode[i].keterangan;
            this.supplier.Id = this.serviceReturn3.listCode[i].id;
            this.listOfSupplier.push(this.supplier);            
            }
          }
          else{
            alert("Supplier Not Found");
          }
        }
      ) 
  }

  searchbyname()
  {

    let jsonInput = {};
    jsonInput['namaBarang'] = this.inquiryMasterProductForm.get('productName').value;
    
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      this.listOfProductMasterModel=[];

       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryCategoryAndBrandByProductName',stringJsonInput)
      .subscribe(
        data => {
          this.serviceReturn = data;
          if(this.serviceReturn.status=="S")
          {
            for(let i=0;i<this.serviceReturn.listProduct.length;i++)
            {
            this.productMasterModel = new productmastermodel();
            this.productMasterModel.Id = this.serviceReturn.listProduct[i].id;
            this.productMasterModel.MerkId = this.serviceReturn.listProduct[i].merk_id;
            this.productMasterModel.CategoryId = this.serviceReturn.listProduct[i].category_id;
            this.productMasterModel.NamaBarang = this.serviceReturn.listProduct[i].nama_barang;
            this.productMasterModel.NamaMerk = this.serviceReturn.listProduct[i].nama_merk;
            this.productMasterModel.NamaCategory = this.serviceReturn.listProduct[i].nama_kategori;
            this.listOfProductMasterModel.push(this.productMasterModel);
            }
          }
          else
          {
            alert("Master Product Search Failed");
          }
        }
      )  
  }
  
  searchbynameDetail(id:string, categoryId:string, merkId:string)
  {
    this.activeCategory=categoryId;

    $('#exampleModal').modal('hide'); 
    let jsonInput = {};
    
    let res={}
    jsonInput['productId'] = id;
    jsonInput['kategoriId'] = categoryId;
    jsonInput['merkId'] = merkId;
    let stringJsonInput = JSON.stringify(jsonInput);
    console.log(stringJsonInput)
      
       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryProductDetailAllByCategoryAndBrand',stringJsonInput)
      .subscribe(
        data => {
          this.serviceReturn = data;
          console.log(this.serviceReturn)
          if(this.serviceReturn.status=="S")
          {
            console.log(this.serviceReturn)
            //inquiry detail untuk joran
            if(this.serviceReturn.categoryId==1 && this.serviceReturn.listProduct.listJoranDetail.length>0)
            {
              this.productTypeforHTML="Rod";
              this.listOfProductDetailJoran=[];
              for(let i=0;i<this.serviceReturn.listProduct.listJoranDetail.length;i++)
              {
              this.productDetailJoran = new productdetailjoranmodel();
              this.productDetailJoran.Ukuran = this.serviceReturn.listProduct.listJoranDetail[i].ukuran;
              this.productDetailJoran.Warna = this.serviceReturn.listProduct.listJoranDetail[i].nama_warna;
              this.productDetailJoran.WarnaId = this.serviceReturn.listProduct.listJoranDetail[i].warna_joran_id;
              this.productDetailJoran.Ring = this.serviceReturn.listProduct.listJoranDetail[i].ring;
              this.productDetailJoran.Bahan = this.serviceReturn.listProduct.listJoranDetail[i].nama_bahan;
              this.productDetailJoran.BahanId = this.serviceReturn.listProduct.listJoranDetail[i].bahan_joran_id;
              this.productDetailJoran.Nama = this.serviceReturn.listProduct.listJoranDetail[i].nama_barang;
              this.productDetailJoran.TahunProduksi = this.serviceReturn.listProduct.listJoranDetail[i].tahun_produksi;
              this.productDetailJoran.MinLbs = this.serviceReturn.listProduct.listJoranDetail[i].lbs_min;  
              this.productDetailJoran.MaxLbs = this.serviceReturn.listProduct.listJoranDetail[i].nama_tipe_joran; 
              this.productDetailJoran.Merk = this.serviceReturn.listProduct.listJoranDetail[i].nama_merk; 
              this.productDetailJoran.MerkId = this.serviceReturn.listProduct.listJoranDetail[i].merk_id;
              this.productDetailJoran.MaxLbs = this.serviceReturn.listProduct.listJoranDetail[i].lbs_max;  
              this.productDetailJoran.MaxPE = this.serviceReturn.listProduct.listJoranDetail[i].pe_max;  
              this.productDetailJoran.MinPE = this.serviceReturn.listProduct.listJoranDetail[i].pe_min; 
              this.productDetailJoran.Tipe = this.serviceReturn.listProduct.listJoranDetail[i].nama_tipe;   
              this.productDetailJoran.TipeId = this.serviceReturn.listProduct.listJoranDetail[i].tipe_joran_id;
              this.productDetailJoran.DetailId = this.serviceReturn.listProduct.listJoranDetail[i].productDetailId; 
              this.productDetailJoran.Id = this.serviceReturn.listProduct.listJoranDetail[i].productId;
              this.listOfProductDetailJoran.push(this.productDetailJoran);
              }
            }
            //end of inquiry detail untuk joran

             //inquiry detail untuk reel
             else if(this.serviceReturn.categoryId==2 && this.serviceReturn.listProduct.listReelDetail.length>0)
             {
               this.productTypeforHTML="Reel";
               this.listOfProductDetailReel=[];
               for(let i=0;i<this.serviceReturn.listProduct.listReelDetail.length;i++)
               {
               this.productDetailReel = new productdetailreelmodel();
               console.log(this.serviceReturn)
               this.productDetailReel.Ukuran = this.serviceReturn.listProduct.listReelDetail[i].ukuran;                                                                 
               this.productDetailReel.WarnaId = this.serviceReturn.listProduct.listReelDetail[i].warna_reel_id;
               this.productDetailReel.PhType = this.serviceReturn.listProduct.listReelDetail[i].ph_type;
               this.productDetailReel.Warna = this.serviceReturn.listProduct.listReelDetail[i].nama_warna;
               this.productDetailReel.BallBearing = this.serviceReturn.listProduct.listReelDetail[i].ball_bearing;
               this.productDetailReel.TipeId = this.serviceReturn.listProduct.listReelDetail[i].tipe_reel_id;
               this.productDetailReel.WayType = this.serviceReturn.listProduct.listReelDetail[i].way_type;
               this.productDetailReel.Nama = this.serviceReturn.listProduct.listReelDetail[i].nama_barang;
               this.productDetailReel.Tipe = this.serviceReturn.listProduct.listReelDetail[i].nama_tipe;                                                                 
               this.productDetailReel.Merk = this.serviceReturn.listProduct.listReelDetail[i].nama_merk;
               this.productDetailReel.MerkId = this.serviceReturn.listProduct.listReelDetail[i].merk_id;               
               this.productDetailReel.DetailId = this.serviceReturn.listProduct.listReelDetail[i].productDetailId;
               this.productDetailReel.Id = this.serviceReturn.listProduct.listReelDetail[i].productId;               
               this.listOfProductDetailReel.push(this.productDetailReel);
               }
             }
             //end of inquiry detail untuk reel

             //inquiry detail untuk line
            else if(this.serviceReturn.categoryId==3 && this.serviceReturn.listProduct.listLineDetail.length>0)
             {
               this.productTypeforHTML="Line";
               this.listOfProductDetailLine=[];
               for(let i=0;i<this.serviceReturn.listProduct.listLineDetail.length;i++)
               {
               this.productDetailLine = new productdetaillinemodel();
               this.productDetailLine.WarnaId = this.serviceReturn.listProduct.listLineDetail[i].warna_line_id;
               this.productDetailLine.Satuan = this.serviceReturn.listProduct.listLineDetail[i].satuan_ukur;
               this.productDetailLine.Ukuran = this.serviceReturn.listProduct.listLineDetail[i].ukr_panjang;
               this.productDetailLine.Kekuatan = this.serviceReturn.listProduct.listLineDetail[i].kekuatan;
               this.productDetailLine.TypeConnectorSenar = this.serviceReturn.listProduct.listLineDetail[i].tipe_konektor_senar;
               this.productDetailLine.TipeNo = this.serviceReturn.listProduct.listLineDetail[i].tipe_no;
               this.productDetailLine.TypeFluoroCarbon = this.serviceReturn.listProduct.listLineDetail[i].tipe_fluor_carbon;
               this.productDetailLine.Warna = this.serviceReturn.listProduct.listLineDetail[i].nama_warna;
               this.productDetailLine.Diameter = this.serviceReturn.listProduct.listLineDetail[i].diameter;
               this.productDetailLine.Pe = this.serviceReturn.listProduct.listLineDetail[i].pe;
               this.productDetailLine.TipeId = this.serviceReturn.listProduct.listLineDetail[i].tipe_line_id;
               this.productDetailLine.Nama = this.serviceReturn.listProduct.listLineDetail[i].nama_barang;
               this.productDetailLine.Tipe = this.serviceReturn.listProduct.listLineDetail[i].nama_tipe;
               this.productDetailLine.Lbs = this.serviceReturn.listProduct.listLineDetail[i].lbs;
               this.productDetailLine.Merk = this.serviceReturn.listProduct.listLineDetail[i].nama_merk;
               this.productDetailLine.MerkId = this.serviceReturn.listProduct.listLineDetail[i].merk_id;               
               this.productDetailLine.Id = this.serviceReturn.listProduct.listLineDetail[i].productId;
               this.productDetailLine.DetailId = this.serviceReturn.listProduct.listLineDetail[i].productDetailId;                              
               this.listOfProductDetailLine.push(this.productDetailLine);
               }
             }
             //end of inquiry detail untuk line

             //inquiry detail untuk kail
            else if(this.serviceReturn.categoryId==4 && this.serviceReturn.listProduct.listKailDetail.length>0)
            {
              this.productTypeforHTML="Kail";
              this.listOfProductDetailKail=[];
              for(let i=0;i<this.serviceReturn.listProduct.listKailDetail.length;i++)
              {
              this.productDetailKail = new productdetailkailmodel();
              this.productDetailKail.Id = this.serviceReturn.listProduct.listKailDetail[i].productId;
              this.productDetailKail.DetailId = this.serviceReturn.listProduct.listKailDetail[i].productDetailId;
              this.productDetailKail.Ukuran = this.serviceReturn.listProduct.listKailDetail[i].ukuran;
              this.productDetailKail.Merk = this.serviceReturn.listProduct.listKailDetail[i].nama_merk;
              this.productDetailKail.MerkId = this.serviceReturn.listProduct.listKailDetail[i].merk_id;              
              this.productDetailKail.Nama = this.serviceReturn.listProduct.listKailDetail[i].nama_barang;              
              this.listOfProductDetailKail.push(this.productDetailKail);
              }
            }
            //end of inquiry detail untuk kail

             //inquiry detail untuk acc
             else if(this.serviceReturn.categoryId==5 && this.serviceReturn.listProduct.listAksesorisDetail.length>0)
             {
               this.productTypeforHTML="Acc";
               this.listOfProductDetailAcc=[];
               for(let i=0;i<this.serviceReturn.listProduct.listAksesorisDetail.length;i++)
               {
               this.productDetailAcc = new productdetailaccmodel();
               this.productDetailAcc.DetailId = this.serviceReturn.listProduct.listAksesorisDetail[i].productDetailId;
               this.productDetailAcc.Id = this.serviceReturn.listProduct.listAksesorisDetail[i].productId;               
               this.productDetailAcc.Ukuran = this.serviceReturn.listProduct.listAksesorisDetail[i].ukuran;
               this.productDetailAcc.Merk = this.serviceReturn.listProduct.listAksesorisDetail[i].nama_merk;
               this.productDetailAcc.MerkId = this.serviceReturn.listProduct.listAksesorisDetail[i].merk_id;               
               this.productDetailAcc.Nama = this.serviceReturn.listProduct.listAksesorisDetail[i].nama_barang;
               this.productDetailAcc.Warna = this.serviceReturn.listProduct.listAksesorisDetail[i].nama_warna;
               this.productDetailAcc.WarnaId = this.serviceReturn.listProduct.listAksesorisDetail[i].warna_aksesoris_id; 
               this.productDetailAcc.Lbs = this.serviceReturn.listProduct.listAksesorisDetail[i].lbs; 
               this.productDetailAcc.Liter = this.serviceReturn.listProduct.listAksesorisDetail[i].liter; 
               this.productDetailAcc.Tipe = this.serviceReturn.listProduct.listAksesorisDetail[i].nama_tipe; 
               this.productDetailAcc.TipeId = this.serviceReturn.listProduct.listAksesorisDetail[i].tipe_aksesoris_id;  
               this.productDetailAcc.Size = this.serviceReturn.listProduct.listAksesorisDetail[i].size;                             
               this.listOfProductDetailAcc.push(this.productDetailAcc);
               }
             }
             //end of inquiry detail untuk acc            
             //inquiry detail untuk bait
             else if(this.serviceReturn.categoryId==6 && this.serviceReturn.listProduct.listUmpanDetail.length>0)
             {
               this.productTypeforHTML="Bait";
               this.listOfProductDetailBait=[];
               for(let i=0;i<this.serviceReturn.listProduct.listUmpanDetail.length;i++)
               {
               this.productDetailBait = new productdetailbaitmodel();
               this.productDetailBait.DetailId = this.serviceReturn.listProduct.listUmpanDetail[i].productDetailId;
               this.productDetailBait.Id = this.serviceReturn.listProduct.listUmpanDetail[i].productId;               
               this.productDetailBait.Ukuran = this.serviceReturn.listProduct.listUmpanDetail[i].ukuran;
               this.productDetailBait.Merk = this.serviceReturn.listProduct.listUmpanDetail[i].nama_merk;
               this.productDetailBait.MerkId = this.serviceReturn.listProduct.listUmpanDetail[i].merkId;               
               this.productDetailBait.Nama = this.serviceReturn.listProduct.listUmpanDetail[i].nama_barang;
               this.productDetailBait.Warna = this.serviceReturn.listProduct.listUmpanDetail[i].nama_warna;
               this.productDetailBait.WarnaId = this.serviceReturn.listProduct.listUmpanDetail[i].warna_umpan_id; 
               this.productDetailBait.Tipe = this.serviceReturn.listProduct.listUmpanDetail[i].nama_tipe; 
               this.productDetailBait.TipeId = this.serviceReturn.listProduct.listUmpanDetail[i].tipe_umpan_id;   
               this.productDetailBait.Berat = this.serviceReturn.listProduct.listUmpanDetail[i].berat;     
               this.productDetailBait.Varian = this.serviceReturn.listProduct.listUmpanDetail[i].namaVarian;   
               this.productDetailBait.VarianId = this.serviceReturn.listProduct.listUmpanDetail[i].varianId;                       
               this.listOfProductDetailBait.push(this.productDetailBait);
               }
             }
             //end of inquiry detail untuk bait    
  
            else{
              alert("Product Detail Not Found");
            }

          }
          else
          {
            alert("Inquiry Detail Product Failed");
          }
        }
      )  
  }

  activeCategory : any;
  addToList(objectModel:any)
  {
    this.trMasterModel = new transactionMaster();
    this.trMasterModel.Id=objectModel.DetailId;
    if(this.activeCategory==1 || this.activeCategory==2)
    {
    this.trMasterModel.NamaBarang=objectModel.Merk + " " +objectModel.Nama+ " " +objectModel.Ukuran+ " " +objectModel.Tipe+ " " +objectModel.Warna;
    }
    else if(this.activeCategory==3)
    {
    this.trMasterModel.NamaBarang=objectModel.Merk + " " +objectModel.Nama+ " " +objectModel.Ukuran+ " Tp.No " +objectModel.TipeNo
    + " PE: " +objectModel.Pe+ " Lbs: " +objectModel.Lbs+" "+objectModel.Diameter +" "+objectModel.Kekuatan +" "+objectModel.Warna;
    }    
    else if(this.activeCategory==4)
    {
    this.trMasterModel.NamaBarang=objectModel.Merk + " " +objectModel.Nama+ " " +objectModel.Ukuran;
    }
    else if(this.activeCategory==5)
    {
    this.trMasterModel.NamaBarang=objectModel.Merk + " " +objectModel.Nama+ " " +objectModel.Ukuran
    + " Tipe: " +objectModel.Tipe+ " Lbs: " +objectModel.Lbs+" "+objectModel.Size+" "+objectModel.Warna;
    }
    else if(this.activeCategory==6)
    {
    this.trMasterModel.NamaBarang=objectModel.Merk + " " +objectModel.Nama+ " " +objectModel.Ukuran
    + " Tipe: " +objectModel.Tipe+ " " +objectModel.Berat+" "+objectModel.Varian+" "+objectModel.Warna;
    }    
    this.trMasterModel.CategoryId=this.activeCategory;
    this.trMasterModel.Qty=0;
    this.trMasterModel.BasePrice=0;
    this.trMasterModel.Total=0;
    this.listOfProductMasterModelAdd.push(this.trMasterModel);
    $('#exampleModal2').modal('hide');
  }

  grandTotal=0;
  calculateTotal(index:number)
  {
    this.grandTotal=0;
    this.listOfProductMasterModelAdd[index].Total=this.listOfProductMasterModelAdd[index].Qty*this.listOfProductMasterModelAdd[index].BasePrice;
    for(let i=0;i<this.listOfProductMasterModelAdd.length;i++)
    {this.grandTotal+=this.listOfProductMasterModelAdd[i].Total}
  }

  hapusTransaksi(index:number)
  {
    this.listOfProductMasterModelAdd.splice(index, 1);
    if(this.listOfProductMasterModelAdd.length==0)
    {
      this.grandTotal=0;
    }
    for (let i=0;i<this.listOfProductMasterModelAdd.length;i++)
    {
      this.calculateTotal(this.listOfProductMasterModelAdd.length-1);
    }
  }

  createPo()
  {
    let jsonInput = {};
    jsonInput['supplierId'] = this.purchaseOrderForm.get('supplierId').value;
    jsonInput['nomorPO'] = this.purchaseOrderForm.get('poNumber').value;
    jsonInput['userId'] = sessionStorage.getItem('userid');
    jsonInput['tanggalTransaksi'] = this.dateTimeUtil.reverseDateToBVFormat2($('#tanggalTransaksi').first().val());
    let listJsonTemp = [];
    for(let i=0;i<this.listOfProductMasterModelAdd.length;i++)
    {
      let jsonAddProduct= {};
      jsonAddProduct['productDetailId']=this.listOfProductMasterModelAdd[i].Id;
      jsonAddProduct['categoryId']=this.listOfProductMasterModelAdd[i].CategoryId;
      jsonAddProduct['basePrice']=this.listOfProductMasterModelAdd[i].BasePrice;
      jsonAddProduct['qtyTotal']=this.listOfProductMasterModelAdd[i].Qty;
      listJsonTemp.push(jsonAddProduct);
    }
    jsonInput['listProduct']=listJsonTemp;
    jsonInput['kodeTransaksi']=1;


    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      console.log(stringJsonInput)
       res=this.getService.restItemsServiceGetwithParamRestItems('transaction/createPO',stringJsonInput)
      .subscribe(
        data => {
          this.serviceReturn4 = data;
          console.log(this.serviceReturn4);
          if(this.serviceReturn4.status=="S")
          {
            alert("Success Create PO");
            this.router.navigateByUrl('purchaseorder');
          }
          else
          {
            alert("Failed Create PO");
          }
        }
      )  
  }

  ngOnInit(): void {
    this.inquirySupplierList();
    this.listOfProductMasterModelAdd=[];
    $('#datepicker-popup2').datepicker({
      enableOnReadonly: true,
      todayHighlight: true,
    });
  }

}
