<div class="col-md-12 grid-margin stretch-card">
	<div class="card">
		<div class="card-body">
			<h4 class="card-title">Stock Adjustment Order</h4>
			            <form class="form-sample"  [formGroup]="adjustmentForm"> 
 						 <div class="form-group row">
                            <div class="col">
                                <label>Start Date</label>
                                <div id="the-basics">
                                    <div id="datepicker-popup"  class="input-group date datepicker">
                                        <input type="text" class="form-control"  formControlName="startDate" id="startDate" name="startDate">
                                        <span class="input-group-addon input-group-append border-left">
                                          <span class="mdi mdi-calendar input-group-text"></span>
                                        </span>
                                      </div> 
                                </div>
                            </div>   
                            <div class="col">
                                <label>End Date</label>
                                <div id="the-basics">
                                    <div id="datepicker-popup2"  class="input-group date datepicker">
                                        <input type="text" class="form-control"  formControlName="endDate" id="endDate" name="endDate">
                                        <span class="input-group-addon input-group-append border-left">
                                          <span class="mdi mdi-calendar input-group-text"></span>
                                        </span>
                                      </div> 
                                </div>
                            </div>                                                                                 
                            <div class="col">
                                <label style="color: transparent;">-</label>
                                <button type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" (click)="inquiryAdjustment()"
                                [disabled]="">Search</button>
                            </div> 
                            <div class="col">
                              <label style="color: transparent;">-</label>
                              <button type="submit" class="btn btn-warning mr-2" style="min-width: 100%;" routerLink="/createstockadjustment">Create Adjustment</button>
                          </div>                                                        
                        </div>
		              </form>        		

                    <table class="table table-hover" style="table-layout: fixed;">
                        <thead>
                          <tr>
                            <th>Product Name</th>
                            <th>Tr. Name</th>
                            <th>Tr. Date</th>
                            <th>Qty</th>
                            <th>Description</th>
                            <th>Username</th>
                          </tr>
                        </thead>
                        <tbody >
                          <tr *ngFor="let listOFStockAdjusment of listOFStockAdjusment | paginate: { itemsPerPage: 10, currentPage: p}; let i= index">
                            <td style="overflow:hidden; min-width: 300px;white-space: pre-wrap;">{{listOFStockAdjusment.ProductDetail}}</td>
                            <td style="overflow:hidden;  white-space: pre-wrap;">{{listOFStockAdjusment.NamaTransaksi}}</td>
                            <td>{{listOFStockAdjusment.TanggalTransaksi}}</td>
                            <td>{{listOFStockAdjusment.Qty| number}}</td>
                            <td style="overflow:hidden;  white-space: pre-wrap;">{{listOFStockAdjusment.Keterangan}}</td>
                            <td>{{listOFStockAdjusment.Username}}</td>
                          </tr>
                          <td colspan="6" style="text-align: center;">
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                         </td> 
                        </tbody>
                    </table>
          
		</div>
	</div>
</div>