export class productdetailreelmodel{

    constructor(values: Object = {}) {
          Object.assign(this, values);
    }
    private id : number;
    private detailId : number;    
    private nama : string;
    private merk : string;  
    private merkId : string;        
    private ukuran : string;
    private warna : string;
    private barcode :string;
    private tipe : string;
    private tipeId : number;
    private ballBearing : string;
    private warnaId : number;
    private phType : string;
    private wayType : string;
    private connectorCategoryId : number;

    public get ConnectorCategoryId():number{
        return this.connectorCategoryId;
    } 
    public get Id():number{
        return this.id;
    }
    public get Barcode ():string{
        return this.barcode;
    }
    public get DetailId():number{
        return this.detailId;
    }    
    public get Nama() : string{
        return this.nama;
    }
    public get Merk() : string{
        return this.merk;
    }
    public get MerkId() : string{
        return this.merkId;
    }    
    public get Ukuran() : string{
        return this.ukuran;
    }
    public get Warna() : string{
        return this.warna;
    }
    public get Tipe() : string{
        return this.tipe;
    }
    public get TipeId() : number{
        return this.tipeId;
    }
    public get BallBearing() : string{
        return this.ballBearing;
    }
    public get WarnaId() : number{
        return this.warnaId;
    }
    public get PhType() : string{
        return this.phType;
    }
    public get WayType() : string{
        return this.wayType;
    }
    

    public set Id (id:number)
    {
        this.id=id;
    }
    public set DetailId (detailId:number)
    {
        this.detailId=detailId;
    }
    public set Nama (nama:string)
    {
        this.nama=nama;
    }
    public set Merk (merk:string)
    {
        this.merk=merk;
    }
    public set MerkId (merkId:string)
    {
        this.merkId=merkId;
    }    
    public set Ukuran (ukuran:string)
    {
        this.ukuran=ukuran;
    }
    public set Warna (warna:string)
    {
        this.warna=warna;
    }
    public set Tipe (tipe:string)
    {
        this.tipe=tipe;
    }
    public set TipeId (tipeId:number)
    {
        this.tipeId=tipeId;
    }
    public set BallBearing (ballBearing:string)
    {
        this.ballBearing=ballBearing;
    }
    public set WarnaId (warnaId:number)
    {
        this.warnaId=warnaId;
    }
    public set Barcode (barcode:string)
    {
        this.barcode=barcode;
    }
    public set PhType (phType:string)
    {
        this.phType=phType;
    }
    public set WayType (wayType:string)
    {
        this.wayType=wayType;
    }
    public set ConnectorCategoryId (connectorCategoryId:number)
    {
        this.connectorCategoryId=connectorCategoryId;
    }


    }