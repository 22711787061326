export class group{

    constructor(values: Object = {}) {
          Object.assign(this, values);
    }
    private id : number;
    private groupName : string;    
    private groupDescription : string;


    public get Id():number{
        return this.id;
    } 
    public get GroupName() : string{
        return this.groupName;
    }
    public get GroupDescription() : string{
        return this.groupDescription;
    }

    public set Id (id:number)
    {
        this.id=id;
    }

    public set GroupName (groupName:string)
    {
        this.groupName=groupName;
    }
    public set GroupDescription (groupDescription:string)
    {
        this.groupDescription=groupDescription;
    }

    }