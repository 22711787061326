export class varian{

    constructor(values: Object = {}) {
          Object.assign(this, values);
    }
    private id : number;
    private namaVarian : string;  
    private kodeVarian : string;
    
    
    public get Id():number{
        return this.id;
    }
    public get NamaVarian():string{
        return this.namaVarian;
    }
    public get KodeVarian():string{
        return this.kodeVarian;
    }


    public set Id (id:number){
        this.id=id;
    }
    public set NamaVarian (namaVarian:string){
        this.namaVarian=namaVarian;
    }
    public set KodeVarian (kodeVarian:string){
        this.kodeVarian=kodeVarian;
    }
    }