<div class="col-md-12 grid-margin stretch-card">
	<div class="card">
		<div class="card-body">
			<h4 class="card-title">Brand Configuration</h4>
 						 <div class="form-group row">
                            <div class="col" >
                              <label style="color: transparent;">-</label>
                              <button type="submit" class="btn btn-danger mr-2" 
                              style="max-width: 200px;" data-toggle="modal" data-target="#exampleModal2">Insert Brand</button>
                          </div>                                                        
                        </div>
		         		
                	<div class="table-responsive">
                    <table class="table table-hover" >
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>Brand Name</th>
                          <th>Brand Code</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody >
                       <tr *ngFor="let listOfMerk of listOfMerk | paginate: { itemsPerPage: 10, currentPage: p }; let i= index">
                          <td>{{listOfMerk.Id}}</td>
                          <td>{{listOfMerk.NamaMerek}}</td>
                          <td>{{listOfMerk.KodeMerek}}</td>
                          <td><i id="icon" class="link-icon mdi mdi-account-settings-variant" 
                            data-toggle="modal" data-target="#exampleModal" (click)="openModalUpdateBrand(listOfMerk)"></i>&nbsp;&nbsp;
                            <i id="icon" class="link-icon mdi mdi-delete-forever" data-toggle="modal" data-target="#deleteModal" (click)= "deleteConfirm(listOfMerk.Id)"></i></td>
                       </tr> 
                       <td colspan="4" style="text-align: center;">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                     </td>                             
                      </tbody>
                    </table>
                	</div> 
            </div>
        </div>
    </div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Update Brand</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="overflow-y: auto; max-height:400px;">
        <form [formGroup]="updateBrandForm">
            <div class="form-group row">                                   
                <div class="col">
                    <label>Id</label>
                    <div id="the-basics">
                        <input class="typeahead"  id="id" formControlName="id" name="id" type="text" placeholder="Id" disabled> </div>
                </div>
                <div class="col">
                    <label>Brand Name</label>
                    <div id="the-basics">
                        <input class="typeahead"  id="brandName" formControlName="brandName" name="brandName" type="text" placeholder="Brand Name"> </div>
                </div>
                <div class="col">
                    <label>Brand Code</label>
                    <div id="the-basics">
                        <input class="typeahead"  id="brandCode" formControlName="brandCode" name="brandCode" type="text" placeholder="Brand Code"> </div>
                </div>
              </div>      
          </form> 
      </div>          
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-dismiss="modal" (click)="updateBrand()">Update</button>
        <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div> 

<div class="modal fade" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Insert Brand</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
          <div class="modal-body" style="overflow-y: auto; max-height:400px;">
            <form [formGroup]="insertBrandForm">
              <div class="form-group row">                                   
                  <div class="col">
                      <label>Brand Name</label>
                      <div id="the-basics">
                          <input class="typeahead"  id="brandName" formControlName="brandName" name="brandName" type="text" placeholder="Brand Name"> </div>
                  </div>
                  <div class="col">
                      <label>Brand Code</label>
                      <div id="the-basics">
                          <input class="typeahead"  id="brandCode" formControlName="brandCode" name="brandCode" type="text" placeholder="Brand Code"> </div>
                  </div>
                </div>      
            </form> 
          </div>          
        <div class="modal-footer">
          <button type="button" class="btn btn-success" data-dismiss="modal" (click)="insertBrand()">Insert</button>
          <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div> 

  <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Delete Brand</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
          <div class="modal-body" style="overflow-y: auto; max-height:400px;">
              <p>Delete This Brand ?</p>
          </div>          
        <div class="modal-footer">
          <button type="button" class="btn btn-success" data-dismiss="modal" (click)="deleteBrand()">Delete</button>
          <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
