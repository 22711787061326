import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators,ReactiveFormsModule } from '@angular/forms';
import  { callGlobalFuction } from "../../callGlobalFuction";
import { Router,ActivatedRoute } from "@angular/router";
import { productdetailjoranmodel } from '../../model/product/productDetailJoran';
import { productdetailreelmodel } from '../../model/product/productDetailReel';
import { productdetaillinemodel } from '../../model/product/productDetailLine';
import { productdetailkailmodel } from '../../model/product/productDetailKail';
import { productdetailaccmodel } from '../../model/product/productDetailAcc';
import { productdetailbaitmodel } from '../../model/product/productDetailBait';

@Component({
  selector: 'app-inquiryproductdetailbycategory',
  templateUrl: './inquiryproductdetailbycategory.component.html',
  styleUrls: ['./inquiryproductdetailbycategory.component.css']
})
export class InquiryproductdetailbycategoryComponent implements OnInit {

  constructor(private fb: FormBuilder, private getService:callGlobalFuction,public router:Router,public route:ActivatedRoute) { }

  productTypeforHTMLFilter="";

  //keperluan dropdownlist
  serviceReturn:any;
  listOfBahanJoran=[];
  serviceReturn2:any;
  listOfTipeJoran=[];
  serviceReturn3:any;
  listOfWarna=[];

  listOfTipeReel=[];
  jsonForReelType={};
  serviceReturn5:any;
  serviceReturn6:any;
  productDetailReel : productdetailreelmodel;
  listOfProductDetailReel=[];
  pReel:number=1;

  listOfTipeLine=[];
  serviceReturn7:any;
  serviceReturn8:any;
  productDetailLine : productdetaillinemodel;
  listOfProductDetailLine=[];
  pLine:number=1;

  listOfTipeKail=[];
  serviceReturn9:any;
  productDetailKail : productdetailkailmodel;
  listOfProductDetailKail=[];
  pKail:number=1;

  listOfTipeAcc=[];
  serviceReturn10:any;
  serviceReturn11:any;
  productDetailAcc : productdetailaccmodel;
  listOfProductDetailAcc=[];
  pAcc:number=1;
  jsonTipeAcc={};

  listOfTipeBait=[];
  serviceReturn12:any;
  serviceReturn13:any;
  productDetailBait : productdetailbaitmodel;
  listOfProductDetailBait=[];
  pBait:number=1;
  jsonTipeBait={};
  serviceReturn14:any;
  listOfVarianBait=[];    
  jsonVarianBait={};

  jsonWarna:{};
  searchWarna()
  {
    let jsonInput = {};
    jsonInput['kode']="warna";
    jsonInput['categoryId']="";
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      this.listOfWarna=[];

       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn3 = data;
          if(this.serviceReturn3.status=="S")
          {

            for(let i=0;i<this.serviceReturn3.listCode.length;i++)
            {
            this.jsonWarna={};  
            this.jsonWarna['namaWarna'] = this.serviceReturn3.listCode[i].namaWarna;
            this.jsonWarna['id'] = this.serviceReturn3.listCode[i].id;
            this.listOfWarna.push(this.jsonWarna);
            }
          }
          else{
            alert("List of Color Not Found");
          }
        }
      )  
  }

  searchXTCodebyCategory()
  {
    if(this.productTypeforHTMLFilter=="Rod")
    {
      this.searchBahanJoran();
      this.searchTipeJoran();
    }
    else if(this.productTypeforHTMLFilter=="Reel")
    {
      this.searchTipeReel();
    }
    else if(this.productTypeforHTMLFilter=="Line")
    {
      this.searchTipeLine();
    }
    else if(this.productTypeforHTMLFilter=="Acc")
    {
      this.searchTipeAcc();
    }
    else if(this.productTypeforHTMLFilter=="Bait")
    {
      this.searchTipeBait();
      this.searchVarianBait();
    }
  }

  searchTipeLine()
  {
    
    let jsonInput = {};
    jsonInput['kode']="tipe";
    jsonInput['categoryId']="3";
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      this.listOfTipeLine=[];
      console.log(stringJsonInput);
       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn7 = data;
          if(this.serviceReturn7.status=="S")
          {
            console.log(this.serviceReturn7)
            for(let i=0;i<this.serviceReturn7.listCode.length;i++)
            {
            let lineType="";
            lineType=this.serviceReturn7.listCode[i].namaTipe;
            this.listOfTipeLine.push(lineType);
            }
          }
          else{
            alert("List of Line Type Not Found");
          }
        }
      )  
  }

  searchTipeReel()
  {
    
    let jsonInput = {};
    jsonInput['kode']="tipe";
    jsonInput['categoryId']="2";
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      this.listOfTipeJoran=[];
      console.log(stringJsonInput);
       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn5 = data;
          if(this.serviceReturn5.status=="S")
          {
            console.log(this.serviceReturn5)
            for(let i=0;i<this.serviceReturn5.listCode.length;i++)
            {
              this.jsonForReelType={};  
            this.jsonForReelType['namaTipe']=this.serviceReturn5.listCode[i].namaTipe;
            this.jsonForReelType['kodeTipe']=this.serviceReturn5.listCode[i].kodeTipe;
            this.listOfTipeJoran.push(this.jsonForReelType);
            }
          }
          else{
            alert("List of Reel Type Not Found");
          }
        }
      )  
  }

  searchBahanJoran()
  {

    let jsonInput = {};
    jsonInput['kode']="bahan";
    jsonInput['categoryId']="1";
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      this.listOfBahanJoran=[];

       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn = data;
          if(this.serviceReturn.status=="S")
          {
            for(let i=0;i<this.serviceReturn.listCode.length;i++)
            {
            let namaBahan = this.serviceReturn.listCode[i].namaBahan;
            this.listOfBahanJoran.push(namaBahan);
            }
          }
          else{
            alert("List of Rod Material Not Found");
          }

        }
      )  
  }
  searchTipeJoran()
  {

    let jsonInput = {};
    jsonInput['kode']="tipe";
    jsonInput['categoryId']="1";
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      this.listOfTipeJoran=[];

       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn2 = data;
          if(this.serviceReturn2.status=="S")
          {
            for(let i=0;i<this.serviceReturn2.listCode.length;i++)
            {
            let namaTipe = this.serviceReturn2.listCode[i].namaTipe;
            this.listOfTipeJoran.push(namaTipe);
            }
          }
          else{
            alert("List of Rod Type Not Found");
          }
        }
      )  
  }
  searchTipeAcc()
  {

    let jsonInput = {};
    jsonInput['kode']="tipe";
    jsonInput['categoryId']="5";
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      this.listOfTipeJoran=[];

       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn11 = data;
          if(this.serviceReturn11.status=="S")
          {

            for(let i=0;i<this.serviceReturn11.listCode.length;i++)
            {
            this.jsonTipeAcc={};
            this.jsonTipeAcc['namaTipe'] = this.serviceReturn11.listCode[i].namaTipe;
            this.jsonTipeAcc['kodeTipe'] = this.serviceReturn11.listCode[i].id;
            this.listOfTipeAcc.push(this.jsonTipeAcc);
            }
          }
          else{
            alert("List of Acc Type Not Found");
          }
        }
      )  
  }
  searchTipeBait()
  {
   let jsonInput = {};
   jsonInput['kode']="tipe";
   jsonInput['categoryId']="6";
   let res={}
     let stringJsonInput = JSON.stringify(jsonInput);
     this.listOfTipeBait=[];

      res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
     .subscribe( 
       data => {
         this.serviceReturn11 = data;
         if(this.serviceReturn11.status=="S")
         {

           for(let i=0;i<this.serviceReturn11.listCode.length;i++)
           {
           this.jsonTipeBait={};
           this.jsonTipeBait['namaTipe'] = this.serviceReturn11.listCode[i].namaTipe;
           this.jsonTipeBait['kodeTipe'] = this.serviceReturn11.listCode[i].id;
           this.listOfTipeBait.push(this.jsonTipeBait);
           }
         }
         else{
           alert("List of Bait Type Not Found");
         }
       }
     )  
  }
  searchVarianBait()
  {
   let jsonInput = {};
   jsonInput['kode']="bahan";
   jsonInput['categoryId']="6";
   let res={}
     let stringJsonInput = JSON.stringify(jsonInput);
     this.listOfTipeBait=[];

      res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
     .subscribe( 
       data => {
         this.serviceReturn14 = data;
         if(this.serviceReturn14.status=="S")
         {

           for(let i=0;i<this.serviceReturn14.listCode.length;i++)
           {
           this.jsonVarianBait={};
           this.jsonVarianBait['namaVarian'] = this.serviceReturn14.listCode[i].namaBahan;
           this.jsonVarianBait['kodeVarian'] = this.serviceReturn14.listCode[i].id;
           this.listOfVarianBait.push(this.jsonVarianBait);
           }
         }
         else{
           alert("List of Bait Varian Not Found");
         }
       }
     )  
  }
  //end of keperluan dropdownlist

  // filter untuk joran
  inquiryProductJoranForm= new FormGroup({
    ukuran: new FormControl('',[
    ]), 
    minLbs: new FormControl('',[
    ]),
    maxLbs: new FormControl('',[
    ]),
    ring: new FormControl('',[
    ]),
    tahunProduksi: new FormControl('',[
    ]),
    warna: new FormControl('',[
    ]),
    bahan: new FormControl('',[
    ]),
    tipe: new FormControl('',[
    ]),                    
  });
  serviceReturn4:any;
  productDetailJoran :productdetailjoranmodel;
  listOfProductDetailJoran=[];
  pJoran:number=1;

  searchJoran()
  {
    let jsonInput = {};
    jsonInput['size']=this.inquiryProductJoranForm.get('ukuran').value;
    jsonInput['minLbs']=this.inquiryProductJoranForm.get('minLbs').value;
    jsonInput['maxLbs']=this.inquiryProductJoranForm.get('maxLbs').value;
    jsonInput['ring']=this.inquiryProductJoranForm.get('ring').value;
    jsonInput['productionDate']=this.inquiryProductJoranForm.get('tahunProduksi').value;
    jsonInput['namaWarna']=this.inquiryProductJoranForm.get('warna').value;
    jsonInput['namaBahan']=this.inquiryProductJoranForm.get('bahan').value;
    jsonInput['namaTipe']=this.inquiryProductJoranForm.get('tipe').value;
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryJoranDetailByFilter',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn4 = data;
          if(this.serviceReturn4.status=="S")
          {
            console.log(this.serviceReturn4);
            this.listOfProductDetailJoran=[];
            for(let i=0;i<this.serviceReturn4.listProduct.length;i++)
            {
              this.productDetailJoran = new productdetailjoranmodel();
              this.productDetailJoran.Ukuran = this.serviceReturn4.listProduct[i].ukuran;
              this.productDetailJoran.Warna = this.serviceReturn4.listProduct[i].nama_warna;
              this.productDetailJoran.Ring = this.serviceReturn4.listProduct[i].ring;
              this.productDetailJoran.Bahan = this.serviceReturn4.listProduct[i].nama_bahan;
              this.productDetailJoran.Nama = this.serviceReturn4.listProduct[i].nama_barang;
              this.productDetailJoran.TahunProduksi = this.serviceReturn4.listProduct[i].tahun_produksi;
              this.productDetailJoran.MinLbs = this.serviceReturn4.listProduct[i].lbs_min;  
              this.productDetailJoran.MaxLbs = this.serviceReturn4.listProduct[i].nama_tipe_joran; 
              this.productDetailJoran.Merk = this.serviceReturn4.listProduct[i].nama_merk; 
              this.productDetailJoran.MaxLbs = this.serviceReturn4.listProduct[i].lbs_max;  
              this.productDetailJoran.MaxPE = this.serviceReturn4.listProduct[i].pe_max;  
              this.productDetailJoran.MinPE = this.serviceReturn4.listProduct[i].pe_min;
              this.productDetailJoran.Tipe = this.serviceReturn4.listProduct[i].nama_tipe;   
              this.productDetailJoran.TipeId = this.serviceReturn4.listProduct[i].tipe_joran_id;   
              this.listOfProductDetailJoran.push(this.productDetailJoran);
            }
          }
          else{
            alert("Rod Detail Not Found");
          }
        }
      ) 
  }
  //end of filter untuk joran


    // filter untuk reel
    inquiryProductReelForm= new FormGroup({
      ukuran: new FormControl('',[
      ]), 
      ballBearing: new FormControl('',[
      ]),
      tipePh: new FormControl('',[
      ]),
      tipeWay: new FormControl('',[
      ]),
      warna: new FormControl('',[
      ]),
      tipe: new FormControl('',[
      ])                   
    });

    searchReel()
    {
      let jsonInput = {};
      let jsonInput2 = {};
      jsonInput['ukuran']=this.inquiryProductReelForm.get('ukuran').value;
      jsonInput['ballBearing']=this.inquiryProductReelForm.get('ballBearing').value;
      jsonInput['tipePh']=this.inquiryProductReelForm.get('tipePh').value;
      jsonInput['tipeWay']=this.inquiryProductReelForm.get('tipeWay').value;
      jsonInput2['reelDetail']=jsonInput;
      jsonInput2['warna']=this.inquiryProductReelForm.get('warna').value;
      jsonInput2['tipeReel']=this.inquiryProductReelForm.get('tipe').value;
      let res={}
        let stringJsonInput = JSON.stringify(jsonInput2);
        res=this.getService.restItemsServiceGetwithParamRestItems('inquiryReelDetailByFilter',stringJsonInput)
        .subscribe( 
          data => {
            this.serviceReturn6 = data;
            if(this.serviceReturn6.status=="S")
            {
              this.listOfProductDetailReel=[];
              for(let i=0;i<this.serviceReturn6.listProduct.length;i++)
              {
                this.productDetailReel = new productdetailreelmodel();
                this.productDetailReel.Ukuran = this.serviceReturn6.listProduct[i].ukuran;
                this.productDetailReel.Warna = this.serviceReturn6.listProduct[i].nama_warna;
                this.productDetailReel.Tipe = this.serviceReturn6.listProduct[i].nama_tipe;
                this.productDetailReel.Merk = this.serviceReturn6.listProduct[i].nama_merk;
                this.productDetailReel.Nama = this.serviceReturn6.listProduct[i].nama_barang;
                this.productDetailReel.WayType = this.serviceReturn6.listProduct[i].way_type;
                this.productDetailReel.BallBearing = this.serviceReturn6.listProduct[i].ball_bearing;
                this.productDetailReel.PhType = this.serviceReturn6.listProduct[i].ph_type;
                this.listOfProductDetailReel.push(this.productDetailReel);
              }
            }
            else{
              alert("Reel Detail Not Found");
            }
          }
        )   

    }
  // end of filter reel


  // filter untuk line
      inquiryProductLineForm= new FormGroup({
        ukuranPanjang: new FormControl('',[
        ]), 
        satuanUkur: new FormControl('',[
        ]),
        kekuatan: new FormControl('',[
        ]),
        pe: new FormControl('',[
        ]),
        lbs: new FormControl('',[
        ]),
        diameter: new FormControl('',[
        ]),
        tipeKonektorSenar: new FormControl('',[
        ]),
        tipeFluorCarbon: new FormControl('',[
        ]),
        tipeNo: new FormControl('',[
        ]),
        warna: new FormControl('',[
        ]),
        tipe: new FormControl('',[
        ])                   
      });
  
      searchLine()
      {
        let jsonInput = {};
        let jsonInput2 = {};
        jsonInput['ukuranPanjang']=this.inquiryProductLineForm.get('ukuranPanjang').value;
        jsonInput['satuanUkur']=this.inquiryProductLineForm.get('satuanUkur').value;
        jsonInput['kekuatan']=this.inquiryProductLineForm.get('kekuatan').value;
        jsonInput['pe']=this.inquiryProductLineForm.get('pe').value;
        jsonInput['lbs']=this.inquiryProductLineForm.get('lbs').value;
        jsonInput['diameter']=this.inquiryProductLineForm.get('diameter').value;
        jsonInput['tipeKonektorSenar']=this.inquiryProductLineForm.get('tipeKonektorSenar').value;
        jsonInput['tipeFluorCarbon']=this.inquiryProductLineForm.get('tipeFluorCarbon').value;
        jsonInput['tipeNo']=this.inquiryProductLineForm.get('tipeNo').value;
        jsonInput2['lineDetail']=jsonInput;
        jsonInput2['warna']=this.inquiryProductLineForm.get('warna').value;
        jsonInput2['tipeLine']=this.inquiryProductLineForm.get('tipe').value;
        let res={}
          let stringJsonInput = JSON.stringify(jsonInput2);       
          console.log(stringJsonInput); 
          res=this.getService.restItemsServiceGetwithParamRestItems('inquiryLineDetailByFilter',stringJsonInput)
          .subscribe( 
            data => {
              this.serviceReturn8 = data;
              if(this.serviceReturn8.status=="S")
              {
                this.listOfProductDetailLine=[];
                for(let i=0;i<this.serviceReturn8.listProduct.length;i++)
                {
                  this.productDetailLine = new productdetaillinemodel();
                  this.productDetailLine.Satuan = this.serviceReturn8.listProduct[i].satuan_ukur;
                  this.productDetailLine.Ukuran = this.serviceReturn8.listProduct[i].ukr_panjang;
                  this.productDetailLine.Kekuatan = this.serviceReturn8.listProduct[i].kekuatan;
                  this.productDetailLine.TypeConnectorSenar = this.serviceReturn8.listProduct[i].tipe_konektor_senar;
                  this.productDetailLine.TipeNo = this.serviceReturn8.listProduct[i].tipe_no;
                  this.productDetailLine.TypeFluoroCarbon = this.serviceReturn8.listProduct[i].tipe_fluor_carbon;
                  this.productDetailLine.Warna = this.serviceReturn8.listProduct[i].nama_warna;
                  this.productDetailLine.Diameter = this.serviceReturn8.listProduct[i].diameter;
                  this.productDetailLine.Pe = this.serviceReturn8.listProduct[i].pe;
                  this.productDetailLine.Tipe = this.serviceReturn8.listProduct[i].nama_tipe;
                  this.productDetailLine.Nama = this.serviceReturn8.listProduct[i].nama_barang;
                  this.productDetailLine.Lbs = this.serviceReturn8.listProduct[i].lbs;
                  this.productDetailLine.Merk = this.serviceReturn8.listProduct[i].nama_merk;
                  this.listOfProductDetailLine.push(this.productDetailLine);                 
                }
              }
              else{
                alert("Line Detail Not Found");
              }
            }
          ) 
        }
  // end of filter line

    // filter untuk kail
    inquiryProductKailForm= new FormGroup({
      ukuran: new FormControl('',[
      ])                
    });

    searchKail()
    {
      let jsonInput = {};
      let jsonInput2 = {};
      jsonInput['ukuran']=this.inquiryProductKailForm.get('ukuran').value;
      jsonInput2['kailDetail']=jsonInput;
      let res={}
        let stringJsonInput = JSON.stringify(jsonInput2);       
        console.log(stringJsonInput); 
        res=this.getService.restItemsServiceGetwithParamRestItems('inquiryKailDetailByFilter',stringJsonInput)
        .subscribe( 
          data => {
            this.serviceReturn9 = data;
            if(this.serviceReturn9.status=="S")
            {
              this.listOfProductDetailKail=[];
              for(let i=0;i<this.serviceReturn9.listProduct.length;i++)
              {
                this.productDetailKail = new productdetailkailmodel();
                this.productDetailKail.Ukuran = this.serviceReturn9.listProduct[i].ukuran;
                this.productDetailKail.Nama = this.serviceReturn9.listProduct[i].nama_barang;
                this.productDetailKail.Merk = this.serviceReturn9.listProduct[i].nama_merk;
                this.listOfProductDetailKail.push(this.productDetailKail);                 
              }
            }
            else{
              alert("Kail Detail Not Found");
            }
          }
        ) 
      }
  // end of filter kail

    // filter untuk acc
    inquiryProductAccForm= new FormGroup({
      ukuran: new FormControl('',[
      ]),
      warna: new FormControl('',[
      ]),  
      size: new FormControl('',[
      ]),  
      liter: new FormControl('',[
      ]),
      lbs: new FormControl('',[
      ]),
      tipe: new FormControl('',[
      ])                                          
    });

    searchAcc()
    {
      let jsonInput = {};
      let jsonInput2 = {};
      jsonInput['ukuran']=this.inquiryProductAccForm.get('ukuran').value;
      jsonInput['size']=this.inquiryProductAccForm.get('size').value;
      jsonInput['lbs']=this.inquiryProductAccForm.get('lbs').value;
      jsonInput['liter']=this.inquiryProductAccForm.get('liter').value;
      jsonInput2['tipeAksesorisId']=this.inquiryProductAccForm.get('tipe').value;
      jsonInput2['aksesorisDetail']=jsonInput;
      jsonInput2['warna']=this.inquiryProductAccForm.get('warna').value;
      jsonInput2['tipe']=this.inquiryProductAccForm.get('tipe').value;

      let res={}
        let stringJsonInput = JSON.stringify(jsonInput2);       
        console.log(stringJsonInput); 
        res=this.getService.restItemsServiceGetwithParamRestItems('inquiryAksesorisDetailByFilter',stringJsonInput)
        .subscribe( 
          data => {
            this.serviceReturn10 = data;
            if(this.serviceReturn10.status=="S")
            {
              this.listOfProductDetailAcc=[];
              for(let i=0;i<this.serviceReturn10.listProduct.length;i++)
              {
                this.productDetailAcc = new productdetailaccmodel();
                this.productDetailAcc.Ukuran = this.serviceReturn10.listProduct[i].ukuran;
                this.productDetailAcc.Nama = this.serviceReturn10.listProduct[i].nama_barang;
                this.productDetailAcc.Merk = this.serviceReturn10.listProduct[i].nama_merk;
                this.productDetailAcc.Warna = this.serviceReturn10.listProduct[i].nama_warna;
                this.productDetailAcc.Lbs = this.serviceReturn10.listProduct[i].lbs; 
                this.productDetailAcc.Liter = this.serviceReturn10.listProduct[i].liter; 
                this.productDetailAcc.Tipe = this.serviceReturn10.listProduct[i].nama_tipe; 
                this.productDetailAcc.TipeId = this.serviceReturn10.listProduct[i].tipe_aksesoris_id;  
                this.productDetailAcc.Size = this.serviceReturn10.listProduct[i].size;                  
                this.listOfProductDetailAcc.push(this.productDetailAcc);                 
              }
            }
            else{
              alert("Accessories Detail Not Found");
            }
          }
        ) 
      }
  // end of filter acc




 // filter untuk bait
 inquiryProductBaitForm= new FormGroup({
  ukuran: new FormControl('',[
  ]),
  warna: new FormControl('',[
  ]),  
  tipe: new FormControl('',[
  ]),  
  varian: new FormControl('',[
  ]),
  berat: new FormControl('',[
  ])                                    
});

searchBait()
{
  let jsonInput = {};
  let jsonInput2 = {};
  jsonInput['ukuran']=this.inquiryProductBaitForm.get('ukuran').value;
  jsonInput['berat']=this.inquiryProductBaitForm.get('berat').value;
  jsonInput2['tipeUmpan']=this.inquiryProductBaitForm.get('tipe').value;
  jsonInput2['umpanDetail']=jsonInput;
  jsonInput2['warna']=this.inquiryProductBaitForm.get('warna').value;
  jsonInput2['varianUmpan']=this.inquiryProductBaitForm.get('varian').value;

  let res={}
    let stringJsonInput = JSON.stringify(jsonInput2);       
    console.log(stringJsonInput); 
    res=this.getService.restItemsServiceGetwithParamRestItems('inquiryUmpanDetailByFilter',stringJsonInput)
    .subscribe( 
      data => {
        this.serviceReturn13 = data;
        if(this.serviceReturn13.status=="S")
        {
          console.log(this.serviceReturn13);
          this.listOfProductDetailBait=[];
          for(let i=0;i<this.serviceReturn13.listProduct.length;i++)
          {
            this.productDetailBait = new productdetailbaitmodel();
            this.productDetailBait.Ukuran = this.serviceReturn13.listProduct[i].ukuran;
            this.productDetailBait.Nama = this.serviceReturn13.listProduct[i].nama_barang;
            this.productDetailBait.Merk = this.serviceReturn13.listProduct[i].nama_merk;
            this.productDetailBait.Warna = this.serviceReturn13.listProduct[i].nama_warna;
            this.productDetailBait.Berat = this.serviceReturn13.listProduct[i].berat; 
            this.productDetailBait.Varian = this.serviceReturn13.listProduct[i].nama_varian; 
            this.productDetailBait.Tipe = this.serviceReturn13.listProduct[i].nama_tipe; 
            this.productDetailBait.TipeId = this.serviceReturn13.listProduct[i].tipe_aksesoris_id;       
            this.listOfProductDetailBait.push(this.productDetailBait);                 
          }
        }
        else{
          alert("Bait Detail Not Found");
        }
      }
    ) 
  }
// end of filter bait


  ngOnInit(): void {
    this.searchWarna();
  }

}
