import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from "@angular/router";
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(public router:Router) { }

  loginUser = sessionStorage.getItem("username");

  ngOnInit(): void {
  }

  goToUpload()
  {
  	this.router.navigateByUrl("/uploadoriginaldata");
  }

  goToTempCaculation()
  {
  	this.router.navigateByUrl("/inquirytagihankur");
  }

  goToFinalCaculation()
  {
  	this.router.navigateByUrl("/generatefinallaporan");
  }
  goToParamterSetting()
  {
  	this.router.navigateByUrl("/paramtersetting");
  }  

  logout()
  {
    sessionStorage.clear()
    this.router.navigateByUrl("");
  }



}
