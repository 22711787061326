import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators,ReactiveFormsModule } from '@angular/forms';
import  { callGlobalFuction } from "../../callGlobalFuction";
import { Router,ActivatedRoute } from "@angular/router";
import  { DateTimeUtil } from "../../globalfunction/dateTimeUtil";
import { dailyReport } from '../../model/transaction/dailyReport';
import  { imageGenerator } from "../../globalfunction/imageGenerator";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
declare var $ :any;

@Component({
  selector: 'app-dailyreport',
  templateUrl: './dailyreport.component.html',
  styleUrls: ['./dailyreport.component.css']
})
export class DailyreportComponent implements OnInit {
  pdf:any;
  constructor(private fb: FormBuilder, private getService:callGlobalFuction,
    public router:Router,public route:ActivatedRoute,public dateTimeUtil: DateTimeUtil, public imageGeneratorService: imageGenerator) { }

  dailyReportForm= new FormGroup({
    tanggalMulai: new FormControl('',[
    ]),
    tanggalAkhir: new FormControl('',[
    ])                        
  });
  p:number=1;
  serviceReturn:any;
  listOFDailyReport=[];
  dailyReportModel :dailyReport;


  inquiryDailySales()
  {
    if(this.dateTimeUtil.reverseDateToBVFormat($('#tanggalMulai').first().val())=="" 
    || this.dateTimeUtil.reverseDateToBVFormat($('#tanggalAkhir').first().val())=="")
    {
      alert("Start Date or End Date Cannot be Empty");
    }

    else
    {
      let jsonInput = {};
      jsonInput['startDate']=this.dateTimeUtil.reverseDateToBVFormat($('#tanggalMulai').first().val());
      jsonInput['endDate']=this.dateTimeUtil.reverseDateToBVFormat($('#tanggalAkhir').first().val());
      console.log(jsonInput);

      let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
       res=this.getService.restItemsServiceGetwithParamRestItems('/report/generate/dailyReport',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn = data;
          if(this.serviceReturn.status=="S")
          {
            console.log(this.serviceReturn);
            this.listOFDailyReport=[];
            for(let i=0;i<this.serviceReturn.result.reportDetail.length;i++)
            {
              let tanggalTransaksiTemp = this.serviceReturn.result.reportDetail[i].tanggalTransaksi;
              //untuk grosir
              for (let k=0;k<this.serviceReturn.result.reportDetail[i].grosirSales.length;k++)
              {
                let jenisTransaksiTemp = "Grosir"
                this.dailyReportModel = new dailyReport();
                this.dailyReportModel.TanggalTransaksi = tanggalTransaksiTemp;
                this.dailyReportModel.JenisTransaksi = jenisTransaksiTemp;
                this.dailyReportModel.TraceNumber =  (k+1).toString();
                this.dailyReportModel.DetailBarang = this.serviceReturn.result.reportDetail[i].grosirSales[k].productDetail.namaMerk + " " + this.serviceReturn.result.reportDetail[i].grosirSales[k].productDetail.namaBarang + " " + this.serviceReturn.result.reportDetail[i].grosirSales[k].productDetail.detail + " " + this.serviceReturn.result.reportDetail[i].grosirSales[k].productDetail.detail2;
                this.dailyReportModel.Qty = this.serviceReturn.result.reportDetail[i].grosirSales[k].totalQty;
                this.dailyReportModel.HargaModal = this.serviceReturn.result.reportDetail[i].grosirSales[k].hargaModal;
                this.dailyReportModel.HargaJual = this.serviceReturn.result.reportDetail[i].grosirSales[k].sales;
                this.dailyReportModel.TotalModal = this.serviceReturn.result.reportDetail[i].grosirSales[k].hargaModal * this.serviceReturn.result.reportDetail[i].grosirSales[k].totalQty;
                this.dailyReportModel.TotalJual = this.serviceReturn.result.reportDetail[i].grosirSales[k].sales * this.serviceReturn.result.reportDetail[i].grosirSales[k].totalQty;
                this.dailyReportModel.NominalDiscount = this.serviceReturn.result.reportDetail[i].grosirSales[k].diskonNominal;
                this.dailyReportModel.TotalProfit = this.serviceReturn.result.reportDetail[i].grosirSales[k].profit * this.serviceReturn.result.reportDetail[i].grosirSales[k].totalQty;
                this.listOFDailyReport.push(this.dailyReportModel);
              }
              //umtuk retail
              for (let j=0;j<this.serviceReturn.result.reportDetail[i].retailSales.length;j++)
              {
                let jenisTransaksiTemp = "Retail"
                this.dailyReportModel = new dailyReport();
                this.dailyReportModel.TanggalTransaksi = tanggalTransaksiTemp;
                this.dailyReportModel.JenisTransaksi = jenisTransaksiTemp;
                this.dailyReportModel.TraceNumber =  (j+1).toString();
                this.dailyReportModel.DetailBarang = this.serviceReturn.result.reportDetail[i].retailSales[j].productDetail.namaMerk + " " + this.serviceReturn.result.reportDetail[i].retailSales[j].productDetail.namaBarang + " " + this.serviceReturn.result.reportDetail[i].retailSales[j].productDetail.detail + " " + this.serviceReturn.result.reportDetail[i].retailSales[j].productDetail.detail2;
                this.dailyReportModel.Qty = this.serviceReturn.result.reportDetail[i].retailSales[j].totalQty;
                this.dailyReportModel.HargaModal = this.serviceReturn.result.reportDetail[i].retailSales[j].hargaModal;
                this.dailyReportModel.HargaJual = this.serviceReturn.result.reportDetail[i].retailSales[j].sales;
                this.dailyReportModel.TotalModal = this.serviceReturn.result.reportDetail[i].retailSales[j].hargaModal * this.serviceReturn.result.reportDetail[i].retailSales[j].totalQty;
                this.dailyReportModel.TotalJual = this.serviceReturn.result.reportDetail[i].retailSales[j].sales * this.serviceReturn.result.reportDetail[i].retailSales[j].totalQty;
                this.dailyReportModel.NominalDiscount = this.serviceReturn.result.reportDetail[i].retailSales[j].diskonNominal;
                this.dailyReportModel.TotalProfit = this.serviceReturn.result.reportDetail[i].retailSales[j].profit * this.serviceReturn.result.reportDetail[i].retailSales[j].totalQty;
                this.listOFDailyReport.push(this.dailyReportModel);
              }
            }
          }
          else{
            alert("Failed Inquiry Daily Sales Report "+this.serviceReturn.errMes);
          }
        }
      ) 
    }
  }


  buildTableBody(data, columns) {
    var body = [];
    console.log(this.jsonListPrint);
    data.forEach(function(row) {
        var dataRow = [];

        columns.forEach(function(column) {
            dataRow.push(row[column].toString());
            console.log(row[column].toString());
        })

        body.push(dataRow);
    });

    return body;
}
  jsonListPrint=[];
  totalPO=0;
  table(data :any, columns) {
    this.jsonListPrint=[];
    let jsonPrint={};
    jsonPrint['date']="Date";
    jsonPrint['type']="Type";
    jsonPrint['trId']="Id";
    jsonPrint['item']="Item";
    jsonPrint['qty']="Qty";
    jsonPrint['basePrice']="Bs.Price";
    jsonPrint['sellPrice']="Sl.Price";
    jsonPrint['totalBase']="Ttl.Base";
    jsonPrint['totalSell']="Ttl.Sell";
    jsonPrint['profit']="Profit";
    this.jsonListPrint.push(jsonPrint);
    for(let i=0;i<data.length;i++)
    {
      let jsonPrint={};
      jsonPrint['date']=data[i].TanggalTransaksi;
      jsonPrint['type']=data[i].JenisTransaksi;
      jsonPrint['trId']=data[i].TraceNumber;
      jsonPrint['item']=data[i].DetailBarang;
      jsonPrint['qty']=this.numberWithCommas(data[i].Qty);
      jsonPrint['basePrice']=this.numberWithCommas(data[i].HargaModal);
      jsonPrint['sellPrice']=this.numberWithCommas(data[i].HargaJual);
      jsonPrint['totalBase']=this.numberWithCommas(data[i].TotalModal);
      jsonPrint['totalSell']=this.numberWithCommas(data[i].TotalJual);
      jsonPrint['profit']=this.numberWithCommas(data[i].TotalProfit);
      this.jsonListPrint.push(jsonPrint)
    }
    return {
      layout: 'lightHorizontalLines', // optional
        table: {
            headerRows: 1,
            fontSize:6, 
            widths: [60,30, 60, 250, 35, 45, 45, 65, 65, 90],
            body: this.buildTableBody(this.jsonListPrint, columns),
        },style:'table'
    };
}

numberWithCommas(x : number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

 buildPdf() {

   var docDefinition = {
  pageSize: { width: 1000, height: 684 },
  pageOrientation: 'landscape',
  pageMargins: [50,20],

      styles: {
       judul: {
         
          margin:[0,5,0,15]
        },
        headerInfo: {
          fontSize: 10,
          margin:[0,0,0,2]
        },
        ttd: {
          fontSize: 10,
          margin:[0,35,0,0]
        },
        headerInfoBottom: {
          fontSize: 11,
          margin:[0,10,0,0],
          alignment:'right'
        }
         ,
        table: {
          fontSize: 9,
          alignment:'left',
          margin:[0,15,0,0]
        }
      },

    content:[

     {
      columns : [
      
      {text:'FINANCIAL REPORT',fontSize: 14,
          bold: true,},{image: this.imageGeneratorService.goGetImage(),alignment:'right',width:70}],style:'judul'
      },
       //this.table(jsonParsed['itemList'], ['productId', 'productType','productName','price','totalOrder','ammount']),
       this.table(this.listOFDailyReport, ['date','type','trId','item','qty','basePrice','sellPrice','totalBase','totalSell','profit']),

     {text:'TOTAL : Rp. '+this.numberWithCommas(this.totalPO),alignment:'right',style:'headerInfoBottom'},

     {
//      columns : [{text: 'DISETUJUI',alignment:'left',margin:[40,0,0,0]},{text: 'DITERIMA',alignment:'right',margin:[0,0,40,0]}],style:'ttd'
   //   columns : [{text: 'HORMAT KAMI',alignment:'right',margin:[0,0,40,0]}],style:'ttd'
      },
 /*    {
      columns : [{text: '(                    )',alignment:'left',margin:[35,0,0,0]}],style:'ttd2'},*/


      ],
    }

   
   return docDefinition;
}

printPOpdf()
{
this.pdf = pdfMake;
    this.pdf.createPdf(this.buildPdf()).open();
}


  ngOnInit(): void {
    $('#datepicker-popup2').datepicker({
      enableOnReadonly: true,
      todayHighlight: true,
    });

    $('#datepicker-popup').datepicker({
      enableOnReadonly: true,
      todayHighlight: true,
    });
  }

}
