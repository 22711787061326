export class mixItem{

    constructor(values: Object = {}) {
          Object.assign(this, values);
    }
    private id : number;
    private groupId : number;    
    private productDetail : string;
    private productDetail2 : string;
    private namaBarang : string;
    private merk : string;


    public get Id():number{
        return this.id;
    } 
    public get GroupId() : number{
        return this.groupId;
    }
    public get ProductDetail() : string{
        return this.productDetail;
    }
    public get ProductDetail2() : string{
        return this.productDetail2;
    }
    public get NamaBarang() : string{
        return this.namaBarang;
    }
    public get Merk() : string{
        return this.merk;
    }


    public set Id (id:number)
    {
        this.id=id;
    }
    public set GroupId (groupId:number)
    {
        this.groupId=groupId;
    }
    public set ProductDetail (productDetail:string)
    {
        this.productDetail=productDetail;
    }
    public set ProductDetail2 (productDetail2:string)
    {
        this.productDetail2=productDetail2;
    }
    public set NamaBarang (namaBarang:string)
    {
        this.namaBarang=namaBarang;
    }
    public set Merk (merk:string)
    {
        this.merk=merk;
    }

    }