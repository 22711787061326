import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators,ReactiveFormsModule } from '@angular/forms';
import  { callGlobalFuction } from "../../callGlobalFuction";
import { Router,ActivatedRoute } from "@angular/router";
import { supplier } from '../../model/config/supplier';

@Component({
  selector: 'app-supplierconfig',
  templateUrl: './supplierconfig.component.html',
  styleUrls: ['./supplierconfig.component.css']
})
export class SupplierconfigComponent implements OnInit {

  constructor(private fb: FormBuilder, private getService:callGlobalFuction,
    public router:Router,public route:ActivatedRoute) { }

    listOfSupplier=[];
    serviceReturn:any;
    supplierSetting :supplier;
    supplierSettingUpdate :supplier;
    p:number=1;

    updateSupplierForm= new FormGroup({
      id: new FormControl('',[
        Validators.required  
      ]), 
      supplierName: new FormControl('',[
        Validators.required
      ]),     
      supplierCode: new FormControl('',[
        Validators.required
      ]),  
      alamat: new FormControl('',[
        Validators.required
      ]),  
      email: new FormControl('',[
        Validators.required
      ]),  
      nomorHp: new FormControl('',[
        Validators.required
      ]),  
      keterangan: new FormControl('',[
        Validators.required
      ]),  
    });
    insertSupplierForm= new FormGroup({
      supplierName: new FormControl('',[
        Validators.required
      ]),     
      supplierCode: new FormControl('',[
        Validators.required
      ]),  
      alamat: new FormControl('',[
        Validators.required
      ]),  
      email: new FormControl('',[
        Validators.required
      ]),  
      nomorHp: new FormControl('',[
        Validators.required
      ]),  
      keterangan: new FormControl('',[
        Validators.required
      ]),  
    });

    searchSupplier()
    {
      let jsonInput = {};
      jsonInput['kode']="supplier";
      jsonInput['categoryId']="";
      let res={}
        let stringJsonInput = JSON.stringify(jsonInput);
        this.listOfSupplier=[];
  
         res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
        .subscribe( 
          data => {
            this.serviceReturn = data;
            if(this.serviceReturn.status=="S")
            {
              console.log(this.serviceReturn);
              for(let i=0;i<this.serviceReturn.listCode.length;i++)
              {
              this.supplierSetting = new supplier();
              this.supplierSetting.Id = this.serviceReturn.listCode[i].id;
              this.supplierSetting.NamaSupplier = this.serviceReturn.listCode[i].namaSupplier;
              this.supplierSetting.KodeSupplier = this.serviceReturn.listCode[i].kodeSupplier;
              this.supplierSetting.Alamat = this.serviceReturn.listCode[i].alamat;
              this.supplierSetting.Email = this.serviceReturn.listCode[i].email;
              this.supplierSetting.NomorHp = this.serviceReturn.listCode[i].noHP;
              this.supplierSetting.Keterangan = this.serviceReturn.listCode[i].keterangan;
              this.listOfSupplier.push(this.supplierSetting);
              }
            }
            else{
              alert("List of Supplier Not Found");
            }
          }
        )  
    }

  openModalUpdateSupplier(supplilerModel:any)
  {
    this.supplierSettingUpdate = supplilerModel;
    this.updateSupplierForm.get('id').setValue(this.supplierSettingUpdate.Id);
    this.updateSupplierForm.get('supplierName').setValue(this.supplierSettingUpdate.NamaSupplier);
    this.updateSupplierForm.get('supplierCode').setValue(this.supplierSettingUpdate.KodeSupplier);
    this.updateSupplierForm.get('alamat').setValue(this.supplierSettingUpdate.Alamat);
    this.updateSupplierForm.get('email').setValue(this.supplierSettingUpdate.Email);
    this.updateSupplierForm.get('nomorHp').setValue(this.supplierSettingUpdate.NomorHp);
    this.updateSupplierForm.get('keterangan').setValue(this.supplierSettingUpdate.Keterangan);
  }

  serviceReturn4:any;
  updateSupplier()
  {
    let jsonInput = {};
    jsonInput['id']=parseInt(this.updateSupplierForm.get('id').value);
    jsonInput['namaSupplier']=this.updateSupplierForm.get('supplierName').value;
    jsonInput['kodeSupplier']= this.updateSupplierForm.get('supplierCode').value;
    jsonInput['alamat']= this.updateSupplierForm.get('alamat').value;
    jsonInput['noHP']= this.updateSupplierForm.get('nomorHp').value;
    jsonInput['email']= this.updateSupplierForm.get('email').value;
    jsonInput['keterangan']= this.updateSupplierForm.get('keterangan').value;
    console.log(jsonInput)
    
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      console.log(stringJsonInput);
       res=this.getService.restItemsServiceGetwithParamRestItems('updateSupplierData',stringJsonInput)
      .subscribe(
        data => {
          this.serviceReturn4 = data;
          console.log(this.serviceReturn4);
          if(this.serviceReturn4.status=="S")
          {
            alert("Success Update Supplier");
            location.reload();
          }
  
          else
          {
            alert("Failed Update Supplier "+this.serviceReturn4.errMes);
          }
        }
      )   
  }

  serviceReturn5:any;
  insertSupplier()
  {
    let jsonInput = {};
    jsonInput['namaSupplier']=this.insertSupplierForm.get('supplierName').value;
    jsonInput['kodeSupplier']= this.insertSupplierForm.get('supplierCode').value;
    jsonInput['alamat']= this.insertSupplierForm.get('alamat').value;
    jsonInput['noHP']= this.insertSupplierForm.get('nomorHp').value;
    jsonInput['email']= this.insertSupplierForm.get('email').value;
    jsonInput['keterangan']= this.insertSupplierForm.get('keterangan').value;
    console.log(jsonInput)
    
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      console.log(stringJsonInput);
       res=this.getService.restItemsServiceGetwithParamRestItems('createSupplier',stringJsonInput)
      .subscribe(
        data => {
          this.serviceReturn5 = data;
          console.log(this.serviceReturn5);
          if(this.serviceReturn5.status=="S")
          {
            alert("Success Insert Supplier");
            location.reload();
          }
  
          else
          {
            alert("Failed Insert Supplier "+this.serviceReturn5.errMes);
          }
        }
      )   
  }


  deleteTempId=0;
  deleteConfirm(id:number)
  {
    this.deleteTempId=0;
    this.deleteTempId=id;
  }

  serviceReturn3:any;
  deleteSupplier()
  {
    let jsonInput = {};
    jsonInput['codeId'] = this.deleteTempId;
    jsonInput['tipeKode'] = "supplier";
    
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      console.log(stringJsonInput);
       res=this.getService.restItemsServiceGetwithParamRestItems('deleteCode',stringJsonInput)
      .subscribe(
        data => {
          this.serviceReturn3 = data;
          console.log(this.serviceReturn3);
          if(this.serviceReturn3.status=="S")
          {
            alert("Success Delete Supplier");
            location.reload();
          }
  
          else
          {
            alert("Failed Delete Color "+this.serviceReturn3.errMes);
          }
        }
      )   
  }

  ngOnInit(): void {
    this.searchSupplier();
  }

}
