<div class="col-md-12 grid-margin stretch-card">
	<div class="card">
		<div class="card-body">
			<h4 class="card-title">Purchase Order</h4>
			            <form class="form-sample"  [formGroup]="purchaseOrderForm"> 
 						 <div class="form-group row">
                            <div class="col">
                                <label>PO Number</label>
                                <div id="the-basics">
                                    <input class="typeahead"  id="poNumber" formControlName="poNumber" 
                                    name="poNumber" type="text" placeholder="PO Number"> </div>
                            </div>  
                            <div class="col">
                                <label>PO Date</label>
                                <div id="the-basics">
                                    <div id="datepicker-popup2"  class="input-group date datepicker">
                                        <input type="text" class="form-control"  formControlName="tanggalTransaksi" id="tanggalTransaksi" name="tanggalTransaksi">
                                        <span class="input-group-addon input-group-append border-left">
                                          <span class="mdi mdi-calendar input-group-text"></span>
                                        </span>
                                      </div> 
                                </div>
                            </div>                                                     
                            <div class="col">
                                <label>Supplier</label>
                                <div id="the-basics">
                                    <select  class="form-control" formControlName="supplierId" id="supplierId" name="supplierId"
                                     type="text" placeholder="Supplier">
                                        <option value="" >-</option>
                                        <option value={{supplierList.Id}} *ngFor="let supplierList of listOfSupplier; 
                                        let i = index">{{supplierList.KodeSupplier}} - {{supplierList.NamaSupplier}}</option>
                                    </select> 
                                </div>
                            </div> 
                            <div class="col">
                              <label>Status PO</label>
                              <div id="the-basics">
                                  <select  class="form-control" formControlName="status" id="status" name="status"
                                   type="text" placeholder="Status PO">
                                      <option value="" >-</option>
                                      <option value="open" >Open</option>
                                      <option value="close" >Close</option>
                                      <option value="forced_closed" >Force Close</option>
                                  </select> 
                              </div>
                          </div>                             
                            <div class="col">
                                <label style="color: transparent;">-</label>
                                <button type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" (click)="inquiryPOHeader()">Search</button>
                            </div> 
                            <div class="col">
                              <label style="color: transparent;">-</label>
                              <button type="submit" class="btn btn-danger mr-2" style="min-width: 100%;" routerLink="/createpo">Create PO</button>
                          </div>                                                        
                        </div>
		              </form>        		

                	<div class="table-responsive">
                    <table class="table table-hover" >
                      <thead>
                        <tr>
                          <th>PO Number</th>
                          <th>PO Date</th>
                          <th>Supplier</th>
                          <th>Create User</th>
                          <th>PO Amount</th>
                          <th>PO Status</th>
                          <th>Close</th>
                        </tr>
                      </thead>
                      <tbody >
                       <tr *ngFor="let listOfPOHeader of listOfPOHeader | paginate: { itemsPerPage: 10, currentPage: p }; let i= index">
                          <td style="cursor: pointer;" data-toggle="modal" 
                          data-target="#exampleModal" (click)="inquiryPoDetail(listOfPOHeader)">{{listOfPOHeader.NomorPO}}</td>
                          <td style="cursor: pointer;" data-toggle="modal" 
                          data-target="#exampleModal" (click)="inquiryPoDetail(listOfPOHeader)">{{listOfPOHeader.TanggalTransaksi}}</td>
                          <td style="cursor: pointer;" data-toggle="modal" 
                          data-target="#exampleModal" (click)="inquiryPoDetail(listOfPOHeader)">{{listOfPOHeader.NamaSupplier}}</td>
                          <td style="cursor: pointer;" data-toggle="modal" 
                          data-target="#exampleModal" (click)="inquiryPoDetail(listOfPOHeader)">{{listOfPOHeader.CreateUsername}}</td>
                          <td style="cursor: pointer;" data-toggle="modal" 
                          data-target="#exampleModal" (click)="inquiryPoDetail(listOfPOHeader)">Rp. {{listOfPOHeader.TotalHarga  | number}}</td>
                          <td style="cursor: pointer;" data-toggle="modal" 
                          data-target="#exampleModal" (click)="inquiryPoDetail(listOfPOHeader)">{{listOfPOHeader.StatusPO}}</td>
                          <td><button type="button" class="btn btn-warning" data-dismiss="modal"
                             (click)="closePoConfirm(listOfPOHeader.Id)" data-toggle="modal" 
                             data-target="#deleteModal">CLOSE</button></td>
                       </tr> 
                       <td colspan="6" style="text-align: center;">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                     </td>                             
                      </tbody>
                    </table>
                	</div> 
		</div>
	</div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Purchase Order Detail </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
        <div class="modal-body" style="overflow-y: auto; max-height:400px;">

          <div class="row" *ngIf="purchaseOrderHeader2">
            <div class="col-md-12">
              <div class="badge badge-info">{{purchaseOrderHeader2.StatusPO}}</div>
            <p>{{purchaseOrderHeader2.NomorPO}} - {{purchaseOrderHeader2.NamaSupplier}}<br>
             Created : {{purchaseOrderHeader2.TanggalTransaksi}} - Rp. {{purchaseOrderHeader2.TotalHarga | number}}<br></p>
            </div>
          </div>          

          <table class="table table-hover" >
              <thead>
                <tr>
                  <th>Brand</th>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Acc. Price</th>
                  <th>Qty. Total</th>
                  <th>Qty. Delivered</th>
                  <th>Qty. Outstanding</th>
                </tr>
              </thead>
              <tbody >
                <tr *ngFor="let purchaseOrderDetail of listOfPODetail ; let i= index">
                  <td>{{purchaseOrderDetail.NamaMerek}}</td>
                  <td>{{purchaseOrderDetail.NamaBarang}}<br><br>{{purchaseOrderDetail.Detail1}}<br><br>{{purchaseOrderDetail.Detail2}}</td>
                  <td>Rp. {{purchaseOrderDetail.HargaDasar | number}}</td>
                  <td>Rp. {{purchaseOrderDetail.TotalMasuk*purchaseOrderDetail.HargaDasar | number}}</td>
                  <td>{{purchaseOrderDetail.TotalMasuk}}</td>
                  <td>{{purchaseOrderDetail.SudahMasuk}}</td>
                  <td>{{purchaseOrderDetail.BelumMasuk}}</td>
                </tr>
              </tbody>
            </table>

        </div>          
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-dismiss="modal" (click)="printPOpdf()">Print</button>
        <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Close PO</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
        <div class="modal-body" style="overflow-y: auto; max-height:400px;">
            <p>Force Close This Purchase Order ?</p>
        </div>          
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-dismiss="modal" (click)="closePo()">Close</button>
        <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>