import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppComponents, AppRoutes } from "./app.routing";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import  { callGlobalFuction } from "./callGlobalFuction";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';
import  { MappingValue } from "./globalfunction/mappingValue";
import  { DateTimeUtil } from "./globalfunction/dateTimeUtil";
import  { imageGenerator } from "./globalfunction/imageGenerator";
import  { PaymentMethod } from "./globalfunction/methodUtil";
import { DatePipe } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { InquiryproductmasterComponent } from './product/inquiryproductmaster/inquiryproductmaster.component';
import { InquiryproductmasterdetailComponent } from './product/inquiryproductmasterdetail/inquiryproductmasterdetail.component';
import { InquiryproductdetailbycategoryComponent } from './product/inquiryproductdetailbycategory/inquiryproductdetailbycategory.component';
import { InsertproductComponent } from './product/insertproduct/insertproduct.component';
import { RetailposComponent } from './transaksi/retailpos/retailpos.component';
import { PurchaseorderComponent } from './transaksi/purchaseorder/purchaseorder.component';
import { CreatepoComponent } from './transaksi/createpo/createpo.component';
import { StockadjustmentComponent } from './transaksi/stockadjustment/stockadjustment.component';
import { CreatestockadjustmentComponent } from './transaksi/createstockadjustment/createstockadjustment.component';
import { IncomingComponent } from './transaksi/incoming/incoming.component';
import { PriceconfigComponent } from './product/priceconfig/priceconfig.component';
import { GroupsettingComponent } from './config/groupsetting/groupsetting.component';
import { MixitemComponent } from './config/mixitem/mixitem.component';
import { InquiryIncomingComponent } from './transaksi/inquiry-incoming/inquiry-incoming.component';
import { GrosirposComponent } from './transaksi/grosirpos/grosirpos.component';
import { DailyreportComponent } from './report/dailyreport/dailyreport.component';
import { ColorconfigComponent } from './config/colorconfig/colorconfig.component';
import { SupplierconfigComponent } from './config/supplierconfig/supplierconfig.component';
import { MerkconfigComponent } from './config/merkconfig/merkconfig.component';
import { VarianumpanComponent } from './config/varianumpan/varianumpan.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    InquiryproductmasterComponent,
    InquiryproductmasterdetailComponent,
    InquiryproductdetailbycategoryComponent,
    InsertproductComponent,
    RetailposComponent,
    PurchaseorderComponent,
    CreatepoComponent,
    StockadjustmentComponent,
    CreatestockadjustmentComponent,
    IncomingComponent,
    PriceconfigComponent,
    GroupsettingComponent,
    MixitemComponent,
    InquiryIncomingComponent,
    GrosirposComponent,
    DailyreportComponent,
    ColorconfigComponent,
    SupplierconfigComponent,
    MerkconfigComponent,
    VarianumpanComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,    
    NgxPaginationModule,
    RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' }),
  ],
  providers: [callGlobalFuction,MappingValue,DatePipe,imageGenerator,DateTimeUtil,PaymentMethod,{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
