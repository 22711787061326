export class productmastermodel{

    constructor(values: Object = {}) {
          Object.assign(this, values);
    }
    private id : string;
    private merkId : string;
    private categoryId : string;  
    private namaBarang : string;
    private namaMerk : string;
    private namaCategory : string;
    
    
    public get Id():string{
        return this.id;
    }
    public get MerkId():string{
        return this.merkId;
    }
    public get CategoryId():string{
        return this.categoryId;
    }
    public get NamaBarang():string{
        return this.namaBarang;
    }
    public get NamaMerk():string{
        return this.namaMerk;
    }
    public get NamaCategory():string{
        return this.namaCategory;
    }
    public set Id (id:string){
        this.id=id;
    }
    public set MerkId (merkId:string){
        this.merkId=merkId;
    }
    public set CategoryId (categoryId:string){
        this.categoryId=categoryId;
    }
    public set NamaBarang (namaBarang:string){
        this.namaBarang=namaBarang;
    }
    public set NamaMerk (namaMerk:string){
        this.namaMerk=namaMerk;
    }
    public set NamaCategory (namaCategory:string){
        this.namaCategory=namaCategory;
    }


    }