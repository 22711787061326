<div class="col-md-12 grid-margin stretch-card">
    <div class="card">
		<div class="card-body">
            <div class="row">
            <div class="col-md-2">
                <h4 class="card-title">Product Category : </h4>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <select type="text" class="form-control" id="productTypeforHTMLFilter" [(ngModel)]="productTypeforHTMLFilter" (change)="searchXTCodebyCategory()">
                        <option value="" >- Select Category -</option> 
                        <option value="Rod">Rod</option> 
                        <option value="Reel">Reel</option>
                        <option value="Line">Line</option>    
                        <option value="Hook">Hook</option>
                        <option value="Acc">Acc</option>
                        <option value="Bait">Bait</option>                                                                                                       
                    </select> 
                  </div>
            </div>    
        </div>
            <div class="row">
                <div class="col-md-12">
                    <!-- form untuk filter joran -->
                    <form [formGroup]="insertProductJoranForm" *ngIf="productTypeforHTMLFilter == 'Rod'">
                        <div class="form-group row">                        
                            <div class="col">
                                <label>Product Name</label>
                                <div id="the-basics">
                                    <input class="typeahead"  id="nama" formControlName="nama" name="nama" type="text" placeholder="Name"> </div>
                            </div>
                            <div class="col">
                                <label>Product Brand</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="merk" formControlName="merk" name="merk" type="text" placeholder="Brand">
                                        <option value="" >- Select Brand -</option>  
                                        <option value="{{merkList}}" *ngFor="let merkList of listOfMerk;let i=index" >{{merkList}}</option>  
                                    </select> </div>
                            </div>                            
                            <div class="col">
                            <label>Size</label>
                            <div id="the-basics">
                                <input class="typeahead"  id="ukuran" formControlName="ukuran" name="ukuran" type="text" placeholder="Size"> </div>
                            </div>
                        </div>
                        <div class="form-group row">                        
                            <div class="col">
                            <label>Min LBS</label>
                            <div id="bloodhound">
                                <input class="typeahead"  id="minLbs" formControlName="minLbs" name="minLbs" type="text" placeholder="Min LBS"> </div>
                            </div>
                            <div class="col">
                                <label>Max LBS</label>
                                <div id="bloodhound">
                                    <input class="typeahead"  id="maxLbs" formControlName="maxLbs" name="maxLbs" type="text" placeholder="Max LBS"> </div>
                            </div>
                            <div class="col">
                                <label>Production Date</label>
                                <div id="bloodhound">
                                    <input class="typeahead"  id="tahunProduksi" formControlName="tahunProduksi" name="tahunProduksi" type="text" placeholder="Production Date"> </div>
                            </div>
                            <div class="col">
                                <label>Ring</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="ring" formControlName="ring" name="ring" type="text" placeholder="Ring">
                                        <option value="" >- Select Ring -</option> 
                                        <option value="Fuji">Fuji</option>
                                        <option value="Non Fuji">Non Fuji</option>       
                                    </select> </div>
                            </div>                            

                        </div>
                        <div class="form-group row">   
                            <div class="col">
                                <label>Min PE</label>
                                <div id="bloodhound">
                                    <input class="typeahead"  id="minPE" formControlName="minPE" name="minPE" type="text" placeholder="Min PE"> </div>
                                </div>
                                <div class="col">
                                    <label>Max PE</label>
                                    <div id="bloodhound">
                                        <input class="typeahead"  id="maxPE" formControlName="maxPE" name="maxPE" type="text" placeholder="Max PE"> </div>
                                </div>                            
                            <div class="col">
                                <label>Color</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="warna" formControlName="warna" name="warna" type="text" placeholder="Color">
                                        <option value="" >- Select Color -</option>  
                                        <option value="{{warnaList.id}}" *ngFor="let warnaList of listOfWarna;let i=index" >{{warnaList.namaWarna}}</option>  
                                    </select> </div>
                            </div>                            
                            <div class="col">
                                <label>Material</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="bahan" formControlName="bahan" name="bahan" type="text" placeholder="Material">
                                        <option value="" >- Select Material -</option>
                                        <option value="{{materialList.kodeBahan}}" *ngFor="let materialList of listOfBahanJoran;let i=index" >{{materialList.namaBahan}}</option>  
                                    </select> </div>
                            </div>  
                            <div class="col">
                                <label>Type</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="tipe" formControlName="tipe" name="tipe" type="text" placeholder="Type">
                                        <option value="" >- Select Type -</option> 
                                        <option value="{{typeList.kodeTipe}}" *ngFor="let typeList of listOfTipeJoran;let i=index" >{{typeList.namaTipe}}</option>
                                    </select> </div>
                            </div>                                                         	                   		
                        </div>
                    </form>
                    <form [formGroup]="insertProductReelForm" *ngIf="productTypeforHTMLFilter == 'Reel'">
                        <div class="form-group row">                        
                            <div class="col">
                                <label>Product Name</label>
                                <div id="the-basics">
                                    <input class="typeahead"  id="nama" formControlName="nama" name="nama" type="text" placeholder="Name"> </div>
                            </div>
                            <div class="col">
                                <label>Product Brand</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="merk" formControlName="merk" name="merk" type="text" placeholder="Brand">
                                        <option value="" >- Select Brand -</option>  
                                        <option value="{{merkList}}" *ngFor="let merkList of listOfMerk;let i=index" >{{merkList}}</option>  
                                    </select> </div>
                            </div>                            
                            <div class="col">
                            <label>Size</label>
                            <div id="the-basics">
                                <input class="typeahead"  id="ukuran" formControlName="ukuran" name="ukuran" type="text" placeholder="Size"> </div>
                            </div>
                            <div class="col">
                                <label>Ball Bearing</label>
                                <div id="the-basics">
                                    <input class="typeahead"  id="ballBearing" formControlName="ballBearing" name="ballBearing" type="text" placeholder="Ball Bearing"> </div>
                                </div>                            
                        </div>
                        <div class="form-group row">                        
                            <div class="col">
                                <label>PH Type</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="tipePh" formControlName="tipePh" name="tipePh" type="text" placeholder="PH Type">
                                        <option value="" >- Select PH Type -</option> 
                                        <option value="PH">PH</option>
                                        <option value="Non PH">Non PH</option>       
                                    </select> </div>
                            </div>
                            <div class="col">
                                <label>Way Type</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="tipeWay" formControlName="tipeWay" name="tipeWay" type="text" placeholder="Way Type">
                                        <option value="" >- Select Way Type -</option> 
                                        <option value="Way">Way</option>
                                        <option value="Non One">Non One</option>       
                                    </select> </div>
                            </div>
                            <div class="col">
                                <label>Color</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="warna" formControlName="warna" name="warna" type="text" placeholder="Color">
                                        <option value="" >- Select Color -</option>  
                                        <option value="{{warnaList.id}}" *ngFor="let warnaList of listOfWarna;let i=index" >{{warnaList.namaWarna}}</option>  
                                    </select> </div>
                            </div>                           
                            <div class="col">
                                <label>Reel Type</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="tipe" formControlName="tipe" name="tipe" type="text" placeholder="Type">
                                        <option value="" >- Select Type -</option> 
                                        <option value="{{typeList.kodeTipe}}" *ngFor="let typeList of listOfTipeReel;let i=index" >{{typeList.namaTipe}}</option>
                                    </select> </div>
                            </div>   
                        </div>

                    </form>  
                    <form [formGroup]="insertProductLineForm" *ngIf="productTypeforHTMLFilter == 'Line'">
                        <div class="form-group row">                        
                            <div class="col">
                                <label>Product Name</label>
                                <div id="the-basics">
                                    <input class="typeahead"  id="nama" formControlName="nama" name="nama" type="text" placeholder="Name"> </div>
                            </div>
                            <div class="col">
                                <label>Product Brand</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="merk" formControlName="merk" name="merk" type="text" placeholder="Brand">
                                        <option value="" >- Select Brand -</option>  
                                        <option value="{{merkList}}" *ngFor="let merkList of listOfMerk;let i=index" >{{merkList}}</option>  
                                    </select> </div>
                            </div>                            
                            <div class="col">
                            <label>Size</label>
                            <div id="the-basics">
                                <input class="typeahead"  id="ukuran" formControlName="ukuran" name="ukuran" type="text" placeholder="Size"> </div>
                            </div>
                            <div class="col">
                                <label>Msr. Unit</label>
                                <div id="the-basics">
                                    <select class="typeahead"  id="satuanUkur" formControlName="satuanUkur" name="satuanUkur" type="text" placeholder="Measurement Unit">
                                        <option value="" >- Select Msr. Unit -</option> 
                                        <option value="Yards">Yards</option>
                                        <option value="Meter">Meter</option>       
                                    </select> </div>
                                </div>                            
                        </div>
                        <div class="form-group row">                        
                            <div class="col">
                                <label>Strength</label>
                                <div id="bloodhound">
                                    <input class="typeahead"  id="kekuatan" formControlName="kekuatan" name="kekuatan" type="text" placeholder="Strength"> </div>
                            </div>
                            <div class="col">
                                <label>Type No.</label>
                                <div id="bloodhound">
                                    <input class="typeahead"  id="tipeNo" formControlName="tipeNo" name="tipeNo" type="tipeNo" placeholder="Type No."> </div>
                            </div>
                            <div class="col">
                                <label>PE</label>
                                <div id="bloodhound">
                                    <input class="typeahead"  id="pe" formControlName="pe" name="pe" type="pe" placeholder="PE"> </div>
                            </div>
                            <div class="col">
                                <label>Lbs</label>
                                <div id="bloodhound">
                                    <input class="typeahead"  id="lbs" formControlName="lbs" name="lbs" type="text" placeholder="Lbs"> </div>
                            </div>                            
                            <div class="col">
                                <label>Color</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="warna" formControlName="warna" name="warna" type="text" placeholder="Color">
                                        <option value="" >- Select Color -</option>  
                                        <option value="{{warnaList.id}}" *ngFor="let warnaList of listOfWarna;let i=index" >{{warnaList.namaWarna}}</option>  
                                    </select> </div>
                            </div>                           
  
                        </div>
                        <div class="form-group row">
                            <div class="col">
                                <label>Diameter</label>
                                <div id="bloodhound">
                                    <input class="typeahead"  id="diameter" formControlName="diameter" name="diameter" type="text" placeholder="Diameter"> </div>
                            </div>                               
                            <div class="col">
                                <label>Fl. Carbon Type</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="tipeFluoroCarbon" formControlName="tipeFluoroCarbon" name="tipeFluoroCarbon" type="text" placeholder="Fl. Carbon Type">
                                        <option value="" >- Fl. Carbon Type -</option> 
                                        <option value="Y" >Yes</option>
                                        <option value="N" >No</option>
                                    </select> </div>
                            </div> 
                            <div class="col">
                                <label>Connector Type</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="tipe" formControlName="tipeConnector" name="tipeConnector" type="tipeConnector" placeholder="Type Connector">
                                        <option value="" >- Select Connector Type -</option> 
                                        <option value="Single Pack" >Single Pack</option>
                                        <option value="Connecting " >Connecting </option>
                                        <option value="Roll" >Roll</option>
                                    </select> </div>
                            </div> 
                            <div class="col">
                                <label>Line Type</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="tipe" formControlName="tipe" name="tipe" type="text" placeholder="Type">
                                        <option value="" >- Select Type -</option> 
                                        <option value="{{typeList.kodeTipe}}" *ngFor="let typeList of listOfTipeLine;let i=index" >{{typeList.namaTipe}}</option>
                                    </select> </div>
                            </div> 
                        </div>

                    </form>
                    <form [formGroup]="insertProductHookForm" *ngIf="productTypeforHTMLFilter == 'Hook'">
                        <div class="form-group row">                        
                            <div class="col">
                                <label>Product Name</label>
                                <div id="the-basics">
                                    <input class="typeahead"  id="nama" formControlName="nama" name="nama" type="text" placeholder="Name"> </div>
                            </div>
                            <div class="col">
                                <label>Product Brand</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="merk" formControlName="merk" name="merk" type="text" placeholder="Brand">
                                        <option value="" >- Select Brand -</option>  
                                        <option value="{{merkList}}" *ngFor="let merkList of listOfMerk;let i=index" >{{merkList}}</option>  
                                    </select> </div>
                            </div>                            
                            <div class="col">
                            <label>Size</label>
                            <div id="the-basics">
                                <input class="typeahead"  id="ukuran" formControlName="ukuran" name="ukuran" type="text" placeholder="Size"> </div>
                            </div>         
                        </div>

                    </form> 
                    <form [formGroup]="insertProductAccForm" *ngIf="productTypeforHTMLFilter == 'Acc'">
                        <div class="form-group row">                        
                            <div class="col">
                                <label>Product Name</label>
                                <div id="the-basics">
                                    <input class="typeahead"  id="nama" formControlName="nama" name="nama" type="text" placeholder="Name"> </div>
                            </div>
                            <div class="col">
                                <label>Product Brand</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="merk" formControlName="merk" name="merk" type="text" placeholder="Brand">
                                        <option value="" >- Select Brand -</option>  
                                        <option value="{{merkList}}" *ngFor="let merkList of listOfMerk;let i=index" >{{merkList}}</option>  
                                    </select> </div>
                            </div>     
                            <div class="col">
                                <label>Color</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="warna" formControlName="warna" name="warna" type="text" placeholder="Color">
                                        <option value="" >- Select Color -</option>  
                                        <option value="{{warnaList.id}}" *ngFor="let warnaList of listOfWarna;let i=index" >{{warnaList.namaWarna}}</option>  
                                    </select> </div>
                            </div>                                                    
                            <div class="col">
                            <label>Measurement</label>
                            <div id="the-basics">
                                <input class="typeahead"  id="ukuran" formControlName="ukuran" name="ukuran" type="text" placeholder="Measurement"> </div>
                            </div>         
                        </div>
                        <div class="form-group row">                        
                                <div class="col">
                                    <label>Type</label>
                                    <div id="bloodhound">
                                      <select class="typeahead"  id="tipe" formControlName="tipe" name="tipe" type="text" placeholder="Color">
                                          <option value="" >- Select Color -</option>  
                                          <option value="{{tipeList.kodeTipe}}" *ngFor="let tipeList of listOfTipeAcc;let i=index" >{{tipeList.namaTipe}}</option>  
                                      </select> </div>
                                </div>
                                <div class="col">
                                  <label>Size</label>
                                  <div id="the-basics">
                                      <input class="typeahead"  id="size" formControlName="size" name="size" type="text" placeholder="size"> </div>
                              </div>
                              <div class="col">
                                <label>Lbs</label>
                                <div id="the-basics">
                                    <input class="typeahead"  id="lbs" formControlName="lbs" name="lbs" type="text" placeholder="lbs"> </div>
                            </div>
                            <div class="col">
                              <label>Liter</label>
                              <div id="the-basics">
                                  <input class="typeahead"  id="liter" formControlName="liter" name="liter" type="text" placeholder="liter"> </div>     
                        </div>
                        </div>
                    </form>   
                    <form [formGroup]="insertProductBaitForm" *ngIf="productTypeforHTMLFilter == 'Bait'">
                        <div class="form-group row">                        
                            <div class="col">
                                <label>Product Name</label>
                                <div id="the-basics">
                                    <input class="typeahead"  id="nama" formControlName="nama" name="nama" type="text" placeholder="Name"> </div>
                            </div>
                            <div class="col">
                                <label>Product Brand</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="merk" formControlName="merk" name="merk" type="text" placeholder="Brand">
                                        <option value="" >- Select Brand -</option>  
                                        <option value="{{merkList}}" *ngFor="let merkList of listOfMerk;let i=index" >{{merkList}}</option>  
                                    </select> </div>
                            </div>     
                            <div class="col">
                                <label>Color</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="warna" formControlName="warna" name="warna" type="text" placeholder="Color">
                                        <option value="" >- Select Color -</option>  
                                        <option value="{{warnaList.id}}" *ngFor="let warnaList of listOfWarna;let i=index" >{{warnaList.namaWarna}}</option>  
                                    </select> </div>
                            </div>                                                    
                            <div class="col">
                            <label>Measurement</label>
                            <div id="the-basics">
                                <input class="typeahead"  id="ukuran" formControlName="ukuran" name="ukuran" type="text" placeholder="Measurement"> </div>
                            </div>         
                        </div>
                        <div class="form-group row">                        
                                <div class="col">
                                    <label>Type</label>
                                    <div id="bloodhound">
                                      <select class="typeahead"  id="tipe" formControlName="tipe" name="tipe" type="text" placeholder="Color">
                                          <option value="" >- Select Type -</option>  
                                          <option value="{{tipeList.kodeTipe}}" *ngFor="let tipeList of listOfTipeBait;let i=index" >{{tipeList.namaTipe}}</option>  
                                      </select> </div>
                                </div>
                                <div class="col">
                                  <label>Weight</label>
                                  <div id="the-basics">
                                      <input class="typeahead"  id="berat" formControlName="berat" name="berat" type="text" placeholder="Weight"> </div>
                              </div>
                              <div class="col">
                                <label>Varian</label>
                                <div id="bloodhound">
                                    <select class="typeahead"  id="varian" formControlName="varian" name="varian" type="text" placeholder="Varian">
                                        <option value="" >- Select Varian -</option>  
                                        <option value="{{varianList.kodeVarian}}" *ngFor="let varianList of listOfVarianBait;let i=index" >{{varianList.namaVarian}}</option>  
                                    </select> </div>
                            </div>
                        </div>
                    </form>                                                                                                      
                    <div class="form-group row" *ngIf="productTypeforHTMLFilter != '' ">
                        <div class="col-md-3" >
                            <div id="bloodhound">
                                <button *ngIf="productTypeforHTMLFilter == 'Rod'"  type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" 
                                (click)="cekIfProductExist()" data-toggle="modal" data-target="#exampleModal"
                                [disabled]="insertProductJoranForm.invalid">Check</button>
                                <button *ngIf="productTypeforHTMLFilter == 'Reel'"  type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" 
                                (click)="cekIfProductExist()" data-toggle="modal" data-target="#exampleModal"
                                [disabled]="insertProductReelForm.invalid">Check</button>   
                                <button *ngIf="productTypeforHTMLFilter == 'Line'"  type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" 
                                (click)="cekIfProductExist()" data-toggle="modal" data-target="#exampleModal"
                                [disabled]="insertProductLineForm.invalid">Check</button>     
                                <button *ngIf="productTypeforHTMLFilter == 'Hook'"  type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" 
                                (click)="cekIfProductExist()" data-toggle="modal" data-target="#exampleModal"
                                [disabled]="insertProductHookForm.invalid">Check</button>
                                <button *ngIf="productTypeforHTMLFilter == 'Acc'"  type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" 
                                (click)="cekIfProductExist()" data-toggle="modal" data-target="#exampleModal"
                                [disabled]="insertProductAccForm.invalid">Check</button>    
                                <button *ngIf="productTypeforHTMLFilter == 'Bait'"  type="submit" class="btn btn-primary mr-2" style="min-width: 100%;" 
                                (click)="cekIfProductExist()" data-toggle="modal" data-target="#exampleModal"
                                [disabled]="insertProductBaitForm.invalid">Check</button>                                                                                                                                                                                                        
                                </div>
                        </div>
                    </div>
                    <!-- end of filter joran -->
                </div>
            </div>
        </div>
    </div>
</div>


<!-- MODAL CONFIRMATION -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Product Confirmation</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" *ngIf="statusConfirmation==1">
          <p>Error Loanding Product Confirmation</p>
        </div>
        <div class="modal-body" *ngIf="listOfProductMaster.length == 0 && statusConfirmation==0">
            <p>No Duplicate Product Found</p>
          </div>
          <div class="modal-body" *ngIf="listOfProductMaster.length > 0 && statusConfirmation==0">
            <div class="table-responsive">
                <p>Duplicate Product Found !</p>    
                <!-- table for type joran-->
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Action</th>  
                      <th>Product Id</th>
                      <th>Brand</th>
                      <th>Product Name</th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr *ngFor="let listConfirmationDetail of listOfProductMaster | paginate: { itemsPerPage: 5, currentPage: p }; let i= index" >
                      <td><button type="button" class="btn btn-primary" (click)="insertProduct(listConfirmationDetail.Id)" *ngIf="statusConfirmation==0">Insert</button></td>
                      <td>{{listConfirmationDetail.Id}}</td>
                      <td>{{listConfirmationDetail.NamaMerk}}</td>
                      <td>{{listConfirmationDetail.NamaBarang}}</td>
                   </tr>
                   <tr>
                    <td colspan="3" style="text-align: center;">
                       <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </td>
                  </tr>        
                  </tbody>
                </table>


            </div> 
          </div>          
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="insertProduct(0)" *ngIf="statusConfirmation==0 && listOfProductMaster.length==0">Insert</button>
          <button type="button" class="btn btn-light" data-dismiss="modal" (click)="panggilCancel()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
<!-- END OF MODAL CONFIRMATION -->