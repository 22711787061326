<div class="col-md-12 grid-margin stretch-card">
	<div class="card">
		<div class="card-body">
			<h4 class="card-title">Incoming Transaction</h4>		         

                <form class="form-sample"  [formGroup]="incomingForm"> 	
                <div class="form-group row">                                                   
                  <div class="col">
                      <label>DO Number</label>
                      <div id="the-basics">
                        <input class="typeahead"  id="nomorSJ" formControlName="nomorSJ" 
                        name="nomorSJ" type="text" placeholder="DO Number"> </div>
                      </div>
                  <div class="col">
                    <label>Incoming Date</label>
                    <div id="the-basics">
                      <div id="datepicker-popup2"  class="input-group date datepicker">
                          <input type="text" class="form-control"  formControlName="tanggalTransaksi" id="tanggalTransaksi" name="tanggalTransaksi">
                          <span class="input-group-addon input-group-append border-left">
                            <span class="mdi mdi-calendar input-group-text"></span>
                          </span>
                        </div> 
                  </div>
                </div>     
                <div class="col">
                    <label>Supplier</label>
                    <div id="the-basics">
                        <select  class="form-control" formControlName="supplierId" id="supplierId" name="supplierId"
                         type="text" placeholder="Supplier">
                            <option value="" >-</option>
                            <option value={{supplierList.Id}} *ngFor="let supplierList of listOfSupplier; 
                            let i = index">{{supplierList.KodeSupplier}} - {{supplierList.NamaSupplier}}</option>
                        </select> 
                    </div>
                </div>    
                <div class="col">
                  <label>Received By</label>
                  <div id="the-basics">
                    <input class="typeahead"  id="diterimaOleh" formControlName="diterimaOleh" 
                    name="diterimaOleh" type="text" placeholder="Received By"> </div>
                  </div>                           
                   <div class="col">
                      <label style="color: transparent;">-</label>
                      <button type="submit" class="btn btn-primary mr-2" style="min-width: 100%;"
                       (click)="inquiryIncoming()">Search</button>
                  </div>    
                  <div class="col">
                    <label style="color: transparent;">-</label>
                    <button type="submit" class="btn btn-warning mr-2" style="min-width: 100%;"
                    routerLink="/incoming">Create Incoming</button>
                </div>                   
      </div>     
      </form>           
                	 <div class="table-responsive">
                        <table class="table table-hover" >
                          <thead>
                            <tr>
                              <th>Supplier</th>
                              <th>DO Number</th>
                              <th>Transaction Date</th>
                              <th>Received By</th>
                            </tr>
                          </thead>
                          <tbody >
                           <tr *ngFor="let listOfDincomingListataTemp of incomingList | paginate: { itemsPerPage: 10, currentPage: p }; let i= index"
                           style="cursor: pointer;" data-toggle="modal" 
                           data-target="#exampleModal" (click)="inquiryIncomingDetail(listOfDincomingListataTemp.Id)">
                              <td>{{listOfDincomingListataTemp.NamaSupplier}}</td>
                              <td>{{listOfDincomingListataTemp.NomorSj}}</td>
                              <td>{{listOfDincomingListataTemp.TanggalTransaksi}}</td>
                              <td>{{listOfDincomingListataTemp.ReceivedBy}}</td>
                           </tr> 
                           <td colspan="4" style="text-align: center;">
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                         </td>                             
                          </tbody>
                        </table> 
                        </div>  
                    
		</div>
	</div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Incoming Detail </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
        <div class="modal-body" style="overflow-y: auto; max-height:400px;">
          <p></p>
          <table class="table table-hover" >
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody >
                <tr *ngFor="let incomingDetailList of incomingDetailList ; let i= index">
                  <td>{{incomingDetailList.Product}}</td>
                  <td>{{incomingDetailList.Qty | number}}</td>
                </tr>
              </tbody>
            </table>

        </div>          
       <div class="modal-footer">
      <!--  <button type="button" class="btn btn-success" data-dismiss="modal" (click)="printPOpdf()">Print</button> -->
        <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>


