<div class="col-md-12 grid-margin stretch-card">
	<div class="card">
		<div class="card-body">
            <h4 class="card-title">Detail Product : {{productTypeforHTML}}</h4>
            
                	<div class="table-responsive">
                    
                    <!-- table for type joran-->
                    <table class="table table-hover" *ngIf="productTypeforHTML=='Rod'">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Code</th>
                          <th>Product Name</th>
                          <th>Brand</th>
                          <th>Size</th>
                          <th>Min Lbs</th>
                          <th>Max Lbs</th>
                          <th>Ring</th>
                          <th>Min PE</th>
                          <th>Max PE</th>
                          <th>Colour</th>
                          <th>Material</th>
                          <th>Prod. Date</th>
                          <th>Type</th>
                        </tr>
                      </thead>
                      <tbody >
                       <tr *ngFor="let listJoranDetail of listOfProductDetailJoran; let i= index" >
                          <td><i id="icon" class="link-icon mdi mdi-account-settings-variant" 
                            data-toggle="modal" data-target="#exampleModal" (click)="openModalUpdateRod(listJoranDetail)"></i>&nbsp;&nbsp;
                          <i id="icon" class="link-icon mdi mdi-buffer" data-toggle="modal" data-target="#exampleModalStock" (click)="inquiryStockHeader(listJoranDetail)"></i>&nbsp;&nbsp;
                          <i id="icon" class="link-icon mdi mdi-barcode"  routerLink="/priceconfig" [queryParams]="{ productDetailId: listJoranDetail.DetailId, 
                            categoryId:1}"></i>&nbsp;&nbsp;
                          <i id="icon" class="link-icon mdi mdi-delete-forever" data-toggle="modal" data-target="#deleteModal" (click)= "deleteConfirm(listJoranDetail.DetailId)"></i></td>
                          <td>{{listJoranDetail.Barcode}}</td>
                          <td>{{listJoranDetail.Nama}}</td>
                          <td>{{listJoranDetail.Merk}}</td>
                          <td>{{listJoranDetail.Ukuran}}</td>
                          <td>{{listJoranDetail.MinLbs}}</td>
                          <td>{{listJoranDetail.MaxLbs}}</td>
                          <td>{{listJoranDetail.Ring}}</td>
                          <td>{{listJoranDetail.MinPE}}</td>
                          <td>{{listJoranDetail.MaxPE}}</td>
                          <td>{{listJoranDetail.Warna}}</td>
                          <td>{{listJoranDetail.Bahan}}</td>
                          <td>{{listJoranDetail.TahunProduksi}}</td>
                          <td>{{listJoranDetail.Tipe}}</td>
                       </tr>       
                      </tbody>
                    </table>

                    <!-- table for type Reel-->
                    <table class="table table-hover" *ngIf="productTypeforHTML=='Reel'">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Code</th>
                          <th>Product Name</th>
                          <th>Brand</th>
                          <th>Size</th>
                          <th>Type</th>
                          <th>Ball Bearing</th>
                          <th>Colour</th>
                          <th>PH Type</th>
                          <th>Way Type</th>
                        </tr>
                      </thead>
                      <tbody >
                       <tr *ngFor="let listReelDetail of listOfProductDetailReel; let i= index" >
                        <td><i id="icon" class="link-icon mdi mdi-account-settings-variant" 
                          data-toggle="modal" data-target="#exampleModal" (click)="openModalUpdateReel(listReelDetail)"></i>&nbsp;&nbsp;
                          <i id="icon" class="link-icon mdi mdi-buffer" data-toggle="modal" data-target="#exampleModalStock" (click)="inquiryStockHeader(listReelDetail)"></i>&nbsp;&nbsp;
                          <i id="icon" class="link-icon mdi mdi-barcode"  routerLink="/priceconfig"  [queryParams]="{ productDetailId: listReelDetail.DetailId, 
                            categoryId:2}"></i>&nbsp;&nbsp;
                          <i id="icon" class="link-icon mdi mdi-delete-forever" data-toggle="modal" data-target="#deleteModal" (click)= "deleteConfirm(listReelDetail.DetailId)"></i></td>                         
                          <td>{{listReelDetail.Barcode}}</td>
                          <td>{{listReelDetail.Nama}}</td>
                          <td>{{listReelDetail.Merk}}</td>
                          <td>{{listReelDetail.Ukuran}}</td>
                          <td>{{listReelDetail.Tipe}}</td>
                          <td>{{listReelDetail.BallBearing}}</td>
                          <td>{{listReelDetail.Warna}}</td>
                          <td>{{listReelDetail.PhType}}</td>
                          <td>{{listReelDetail.WayType}}</td>
                       </tr>       
                      </tbody>
                    </table>

                    <!-- table for type line-->
                    <table class="table table-hover" *ngIf="productTypeforHTML=='Line'">
                      <thead>
                        <tr>
                          <th></th>         
                          <th>Code</th>                 
                          <th>Product Name</th>
                          <th>Brand</th>
                          <th>Size</th>
                          <th>Msr. Unit</th>
                          <th>Type</th>
                          <th>Colour</th>
                          <th>Lbs</th>
                          <th>PE</th>
                          <th>Fluorocarbon</th>
                          <th>Connector</th>
                          <th>Diameter</th>
                          <th>Power</th>
                        </tr>
                      </thead>
                      <tbody >
                       <tr *ngFor="let listLineDetail of listOfProductDetailLine; let i= index" >
                        <td><i id="icon" class="link-icon mdi mdi-account-settings-variant" 
                          data-toggle="modal" data-target="#exampleModal" (click)="openModalUpdateLine(listLineDetail)"></i>&nbsp;&nbsp;
                          <i id="icon" class="link-icon mdi mdi-buffer" data-toggle="modal" data-target="#exampleModalStock" (click)="inquiryStockHeader(listLineDetail)"></i>&nbsp;&nbsp;
                          <i id="icon" class="link-icon mdi mdi-barcode" routerLink="/priceconfig" [queryParams]="{ productDetailId: listLineDetail.DetailId, 
                            categoryId:3}"></i>&nbsp;&nbsp;
                          <i id="icon" class="link-icon mdi mdi-delete-forever" data-toggle="modal" data-target="#deleteModal" (click)= "deleteConfirm(listLineDetail.DetailId)"></i></td>                             
                          <td>{{listLineDetail.Barcode}}</td>
                          <td>{{listLineDetail.Nama}}</td>
                          <td>{{listLineDetail.Merk}}</td>
                          <td>{{listLineDetail.Ukuran}}</td>
                          <td>{{listLineDetail.Satuan}}</td>
                          <td>{{listLineDetail.Tipe}}</td>
                          <td>{{listLineDetail.Warna}}</td>
                          <td>{{listLineDetail.Lbs}}</td>
                          <td>{{listLineDetail.Pe}}</td>
                          <td>{{listLineDetail.TypeFluoroCarbon}}</td>
                          <td>{{listLineDetail.TypeConnectorSenar}}</td>
                          <td>{{listLineDetail.Diameter}}</td>
                          <td>{{listLineDetail.Kekuatan}}</td>
                       </tr>       
                      </tbody>
                    </table>

                    <!-- table for type kail-->
                    <table class="table table-hover" *ngIf="productTypeforHTML=='Kail'">
                      <thead>
                        <tr>
                          <th></th>                            
                          <th>Code</th>
                          <th>Product Name</th>
                          <th>Brand</th>
                          <th>Size</th>
                        </tr>
                      </thead>
                      <tbody >
                       <tr *ngFor="let listKailDetail of listOfProductDetailKail; let i= index" >
                        <td><i id="icon" class="link-icon mdi mdi-account-settings-variant" 
                          data-toggle="modal" data-target="#exampleModal" (click)="openModalUpdateHook(listKailDetail)"></i>&nbsp;&nbsp;
                          <i id="icon" class="link-icon mdi mdi-buffer" data-toggle="modal" data-target="#exampleModalStock" (click)="inquiryStockHeader(listKailDetail)"></i>&nbsp;&nbsp;
                          <i id="icon" class="link-icon mdi mdi-barcode" routerLink="/priceconfig" [queryParams]="{ productDetailId: listKailDetail.DetailId, 
                            categoryId:4}"></i>&nbsp;&nbsp;
                          <i id="icon" class="link-icon mdi mdi-delete-forever" data-toggle="modal" data-target="#deleteModal" (click)= "deleteConfirm(listKailDetail.DetailId)"></i></td>                            
                          <td>{{listKailDetail.Barcode}}</td>
                          <td>{{listKailDetail.Nama}}</td>
                          <td>{{listKailDetail.Merk}}</td>
                          <td>{{listKailDetail.Ukuran}}</td>
                       </tr>       
                      </tbody>
                    </table>      

                    <!-- table for type Acc-->
                    <table class="table table-hover" *ngIf="productTypeforHTML=='Acc'">
                      <thead>
                        <tr>
                          <th></th>     
                          <th>Barcode</th>                       
                          <th>Product Name</th>
                          <th>Brand</th>
                          <th>Ukuran</th>
                          <th>Colour</th>
                          <th>Type</th>
                          <th>Size</th>
                          <th>Lbs</th>
                          <th>Liter</th>                                                                                                        
                        </tr>
                      </thead>
                      <tbody >
                       <tr *ngFor="let listAccDetail of listOfProductDetailAcc; let i= index" >
                        <td><i id="icon" class="link-icon mdi mdi-account-settings-variant" 
                          data-toggle="modal" data-target="#exampleModal" (click)="openModalUpdateAcc(listAccDetail)"></i>&nbsp;&nbsp;
                          <i id="icon" class="link-icon mdi mdi-buffer" data-toggle="modal" data-target="#exampleModalStock" (click)="inquiryStockHeader(listAccDetail)"></i>&nbsp;&nbsp;
                          <i id="icon" class="link-icon mdi mdi-barcode" routerLink="/priceconfig" [queryParams]="{ productDetailId: listAccDetail.DetailId, 
                            categoryId:5}"></i>&nbsp;&nbsp;
                          <i id="icon" class="link-icon mdi mdi-delete-forever" data-toggle="modal" data-target="#deleteModal" (click)= "deleteConfirm(listAccDetail.DetailId)"></i></td>                             
                          <td>{{listAccDetail.Barcode}}</td>
                          <td>{{listAccDetail.Nama}}</td>
                          <td>{{listAccDetail.Merk}}</td>
                          <td>{{listAccDetail.Ukuran}}</td>
                          <td>{{listAccDetail.Warna}}</td>
                          <td>{{listAccDetail.Tipe}}</td>
                          <td>{{listAccDetail.Size}}</td>
                          <td>{{listAccDetail.Lbs}}</td>
                          <td>{{listAccDetail.Liter}}</td>                                                                                                        
                       </tr>       
                      </tbody>
                    </table>                          

                    <!-- table for type Bait-->
                    <table class="table table-hover" *ngIf="productTypeforHTML=='Bait'">
                      <thead>
                        <tr>
                          <th></th>   
                          <th>Code</th>                         
                          <th>Product Name</th>
                          <th>Brand</th>
                          <th>Ukuran</th>
                          <th>Colour</th>
                          <th>Type</th>
                          <th>Weight</th>    
                          <th>Varian</th>                                                                                                  
                        </tr>
                      </thead>
                      <tbody >
                       <tr *ngFor="let listBaitDetail of listOfProductDetailBait; let i= index" >
                        <td><i id="icon" class="link-icon mdi mdi-account-settings-variant" 
                          data-toggle="modal" data-target="#exampleModal" (click)="openModalUpdateBait(listBaitDetail)"></i>&nbsp;&nbsp;
                          <i id="icon" class="link-icon mdi mdi-buffer" data-toggle="modal" data-target="#exampleModalStock" (click)="inquiryStockHeader(listBaitDetail)"></i>&nbsp;&nbsp;
                          <i id="icon" class="link-icon mdi mdi-barcode" routerLink="/priceconfig" [queryParams]="{ productDetailId: listBaitDetail.DetailId, 
                            categoryId:6}"></i>&nbsp;&nbsp;
                          <i id="icon" class="link-icon mdi mdi-delete-forever" data-toggle="modal" data-target="#deleteModal" (click)= "deleteConfirm(listBaitDetail.DetailId)"></i></td>                             
                          <td>{{listBaitDetail.Barcode}}</td>
                          <td>{{listBaitDetail.Nama}}</td>
                          <td>{{listBaitDetail.Merk}}</td>
                          <td>{{listBaitDetail.Ukuran}}</td>
                          <td>{{listBaitDetail.Warna}}</td>
                          <td>{{listBaitDetail.Tipe}}</td>
                          <td>{{listBaitDetail.Berat}}</td>
                          <td>{{listBaitDetail.Varian}}</td>                                                                                                             
                       </tr>       
                      </tbody>
                    </table>

                    </div>     
                       
		</div>
	</div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Update Product</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
        <div class="modal-body">
          <form [formGroup]="insertProductJoranForm" *ngIf="productTypeforHTML == 'Rod'">
            <div class="form-group row">                        
              <div class="col">
                <label>Id</label>
                <div id="the-basics">
                    <input class="typeahead" [disabled] id="id" formControlName="id" name="id" type="text" placeholder="Id"> </div>
            </div>
              <div class="col">
                <label>Detail Id</label>
                <div id="the-basics">
                    <input class="typeahead" [disabled] id="detailId" formControlName="detailId" name="detailId" type="detailId" placeholder="DetailId"> </div>
            </div>
              <div class="col">
                    <label>Product Name</label>
                    <div id="the-basics">
                        <input class="typeahead"  id="nama" formControlName="nama" name="nama" type="text" placeholder="Name"> </div>
                </div>
                <div class="col">
                    <label>Product Brand</label>
                    <div id="bloodhound">
                        <select class="typeahead"  id="merk" formControlName="merk" name="merk" type="text" placeholder="Brand">
                            <option value="" >- Select Brand -</option>  
                            <option value="{{merkList.id}}" *ngFor="let merkList of listOfMerk;let i=index" >{{merkList.namaMerk}}</option>  
                        </select> </div>
                </div>                            
                <div class="col">
                <label>Size</label>
                <div id="the-basics">
                    <input class="typeahead"  id="ukuran" formControlName="ukuran" name="ukuran" type="text" placeholder="Size"> </div>
                </div>
            </div>
            <div class="form-group row">                        
                <div class="col">
                <label>Min LBS</label>
                <div id="bloodhound">
                    <input class="typeahead"  id="minLbs" formControlName="minLbs" name="minLbs" type="text" placeholder="Min LBS"> </div>
                </div>
                <div class="col">
                    <label>Max LBS</label>
                    <div id="bloodhound">
                        <input class="typeahead"  id="maxLbs" formControlName="maxLbs" name="maxLbs" type="text" placeholder="Max LBS"> </div>
                </div>
                <div class="col">
                    <label>Production Date</label>
                    <div id="bloodhound">
                        <input class="typeahead"  id="tahunProduksi" formControlName="tahunProduksi" name="tahunProduksi" type="text" placeholder="Production Date"> </div>
                </div>
                <div class="col">
                    <label>Ring</label>
                    <div id="bloodhound">
                        <select class="typeahead"  id="ring" formControlName="ring" name="ring" type="text" placeholder="Ring">
                            <option value="" >- Select Ring -</option> 
                            <option value="Fuji">Fuji</option>
                            <option value="Non Fuji">Non Fuji</option>       
                        </select> </div>
                </div>                            

            </div>
            <div class="form-group row">   
                <div class="col">
                    <label>Min PE</label>
                    <div id="bloodhound">
                        <input class="typeahead"  id="minPE" formControlName="minPE" name="minPE" type="text" placeholder="Min PE"> </div>
                    </div>
                    <div class="col">
                        <label>Max PE</label>
                        <div id="bloodhound">
                            <input class="typeahead"  id="maxPE" formControlName="maxPE" name="maxPE" type="text" placeholder="Max PE"> </div>
                    </div>                            
                <div class="col">
                    <label>Color</label>
                    <div id="bloodhound">
                        <select class="typeahead"  id="warna" formControlName="warna" name="warna" type="text" placeholder="Color">
                            <option value="" >- Select Color -</option>  
                            <option value="{{warnaList.id}}" *ngFor="let warnaList of listOfWarna;let i=index" >{{warnaList.namaWarna}}</option>  
                        </select> </div>
                </div>                            
                <div class="col">
                    <label>Material</label>
                    <div id="bloodhound">
                        <select class="typeahead"  id="bahan" formControlName="bahan" name="bahan" type="text" placeholder="Material">
                            <option value="" >- Select Material -</option>
                            <option value="{{materialList.kodeBahan}}" *ngFor="let materialList of listOfBahanJoran;let i=index" >{{materialList.namaBahan}}</option>  
                        </select> </div>
                </div>  
                <div class="col">
                    <label>Type</label>
                    <div id="bloodhound">
                        <select class="typeahead"  id="tipe" formControlName="tipe" name="tipe" type="text" placeholder="Type">
                            <option value="" >- Select Type -</option> 
                            <option value="{{typeList.kodeTipe}}" *ngFor="let typeList of listOfTipeJoran;let i=index" >{{typeList.namaTipe}}</option>
                        </select> </div>
                </div>                                                         	                   		
            </div>
        </form>
        <form [formGroup]="insertProductReelForm" *ngIf="productTypeforHTML == 'Reel'">
          <div class="form-group row">  
            <div class="col">
              <label>Id</label>
              <div id="the-basics">
                  <input class="typeahead" [disabled] id="id" formControlName="id" name="id" type="text" placeholder="Id"> </div>
          </div>
            <div class="col">
              <label>Detail Id</label>
              <div id="the-basics">
                  <input class="typeahead" [disabled] id="detailId" formControlName="detailId" name="detailId" type="detailId" placeholder="DetailId"> </div>
          </div>                                  
              <div class="col">
                  <label>Product Name</label>
                  <div id="the-basics">
                      <input class="typeahead"  id="nama" formControlName="nama" name="nama" type="text" placeholder="Name"> </div>
              </div>
              <div class="col">
                <label>Product Brand</label>
                <div id="bloodhound">
                    <select class="typeahead"  id="merk" formControlName="merk" name="merk" type="text" placeholder="Brand">
                        <option value="" >- Select Brand -</option>  
                        <option value="{{merkList.id}}" *ngFor="let merkList of listOfMerk;let i=index" >{{merkList.namaMerk}}</option>  
                    </select> </div>
              </div>                            
              <div class="col">
              <label>Size</label>
              <div id="the-basics">
                  <input class="typeahead"  id="ukuran" formControlName="ukuran" name="ukuran" type="text" placeholder="Size"> </div>
              </div>
              <div class="col">
                  <label>Ball Bearing</label>
                  <div id="the-basics">
                      <input class="typeahead"  id="ballBearing" formControlName="ballBearing" name="ballBearing" type="text" placeholder="Ball Bearing"> </div>
                  </div>                            
          </div>
          <div class="form-group row">                        
              <div class="col">
                  <label>PH Type</label>
                  <div id="bloodhound">
                      <select class="typeahead"  id="tipePh" formControlName="tipePh" name="tipePh" type="text" placeholder="PH Type">
                          <option value="" >- Select PH Type -</option> 
                          <option value="PH">PH</option>
                          <option value="Non PH">Non PH</option>       
                      </select> </div>
              </div>
              <div class="col">
                  <label>Way Type</label>
                  <div id="bloodhound">
                      <select class="typeahead"  id="tipeWay" formControlName="tipeWay" name="tipeWay" type="text" placeholder="Way Type">
                          <option value="" >- Select Way Type -</option> 
                          <option value="Way">Way</option>
                          <option value="Non One">Non One</option>       
                      </select> </div>
              </div>
              <div class="col">
                  <label>Color</label>
                  <div id="bloodhound">
                      <select class="typeahead"  id="warna" formControlName="warna" name="warna" type="text" placeholder="Color">
                          <option value="" >- Select Color -</option>  
                          <option value="{{warnaList.id}}" *ngFor="let warnaList of listOfWarna;let i=index" >{{warnaList.namaWarna}}</option>  
                      </select> </div>
              </div>                           
              <div class="col">
                  <label>Reel Type</label>
                  <div id="bloodhound">
                      <select class="typeahead"  id="tipe" formControlName="tipe" name="tipe" type="text" placeholder="Type">
                          <option value="" >- Select Type -</option> 
                          <option value="{{typeList.kodeTipe}}" *ngFor="let typeList of listOfTipeReel;let i=index" >{{typeList.namaTipe}}</option>
                      </select> </div>
              </div>   
          </div>

      </form>  
        <form [formGroup]="insertProductLineForm" *ngIf="productTypeforHTML == 'Line'">
          <div class="form-group row">  
            <div class="col">
              <label>Id</label>
              <div id="the-basics">
                  <input class="typeahead" [disabled] id="id" formControlName="id" name="id" type="text" placeholder="Id"> </div>
          </div>
            <div class="col">
              <label>Detail Id</label>
              <div id="the-basics">
                  <input class="typeahead" [disabled] id="detailId" formControlName="detailId" name="detailId" type="detailId" placeholder="DetailId"> </div>
          </div>                                       
              <div class="col">
                  <label>Product Name</label>
                  <div id="the-basics">
                      <input class="typeahead"  id="nama" formControlName="nama" name="nama" type="text" placeholder="Name"> </div>
              </div>
              <div class="col">
                  <label>Product Brand</label>
                  <div id="bloodhound">
                    <select class="typeahead"  id="merk" formControlName="merk" name="merk" type="text" placeholder="Brand">
                      <option value="" >- Select Brand -</option>  
                      <option value="{{merkList.id}}" *ngFor="let merkList of listOfMerk;let i=index" >{{merkList.namaMerk}}</option>  
                  </select> </div>
              </div>                            
              <div class="col">
              <label>Size</label>
              <div id="the-basics">
                  <input class="typeahead"  id="ukuran" formControlName="ukuran" name="ukuran" type="text" placeholder="Size"> </div>
              </div>
              <div class="col">
                  <label>Msr. Unit</label>
                  <div id="the-basics">
                      <select class="typeahead"  id="satuanUkur" formControlName="satuanUkur" name="satuanUkur" type="text" placeholder="Measurement Unit">
                          <option value="" >- Select Msr. Unit -</option> 
                          <option value="Yards">Yards</option>
                          <option value="Meter">Meter</option>       
                      </select> </div>
                  </div>                            
          </div>
          <div class="form-group row">                        
              <div class="col">
                  <label>Strength</label>
                  <div id="bloodhound">
                      <input class="typeahead"  id="kekuatan" formControlName="kekuatan" name="kekuatan" type="text" placeholder="Strength"> </div>
              </div>
              <div class="col">
                  <label>Type No.</label>
                  <div id="bloodhound">
                      <input class="typeahead"  id="tipeNo" formControlName="tipeNo" name="tipeNo" type="tipeNo" placeholder="Type No."> </div>
              </div>
              <div class="col">
                  <label>PE</label>
                  <div id="bloodhound">
                      <input class="typeahead"  id="pe" formControlName="pe" name="pe" type="pe" placeholder="PE"> </div>
              </div>
              <div class="col">
                  <label>Lbs</label>
                  <div id="bloodhound">
                      <input class="typeahead"  id="lbs" formControlName="lbs" name="lbs" type="text" placeholder="Lbs"> </div>
              </div>                            
              <div class="col">
                  <label>Color</label>
                  <div id="bloodhound">
                      <select class="typeahead"  id="warna" formControlName="warna" name="warna" type="text" placeholder="Color">
                          <option value="" >- Select Color -</option>  
                          <option value="{{warnaList.id}}" *ngFor="let warnaList of listOfWarna;let i=index" >{{warnaList.namaWarna}}</option>  
                      </select> </div>
              </div>                           

          </div>
          <div class="form-group row">
              <div class="col">
                  <label>Diameter</label>
                  <div id="bloodhound">
                      <input class="typeahead"  id="diameter" formControlName="diameter" name="diameter" type="text" placeholder="Diameter"> </div>
              </div>                               
              <div class="col">
                  <label>Fl. Carbon Type</label>
                  <div id="bloodhound">
                      <select class="typeahead"  id="tipeFluoroCarbon" formControlName="tipeFluoroCarbon" name="tipeFluoroCarbon" type="text" placeholder="Fl. Carbon Type">
                          <option value="" >- Fl. Carbon Type -</option> 
                          <option value="Y" >Yes</option>
                          <option value="N" >No</option>
                      </select> </div>
              </div> 
              <div class="col">
                  <label>Connector Type</label>
                  <div id="bloodhound">
                      <select class="typeahead"  id="tipe" formControlName="tipeConnector" name="tipeConnector" type="tipeConnector" placeholder="Type Connector">
                          <option value="" >- Select Connector Type -</option> 
                          <option value="Single Pack" >Single Pack</option>
                          <option value="Connecting " >Connecting </option>
                          <option value="Roll" >Roll</option>
                      </select> </div>
              </div> 
              <div class="col">
                  <label>Line Type</label>
                  <div id="bloodhound">
                      <select class="typeahead"  id="tipe" formControlName="tipe" name="tipe" type="text" placeholder="Type">
                          <option value="" >- Select Type -</option> 
                          <option value="{{typeList.kodeTipe}}" *ngFor="let typeList of listOfTipeLine;let i=index" >{{typeList.namaTipe}}</option>
                      </select> </div>
              </div> 
          </div>

      </form>
      <form [formGroup]="insertProductHookForm" *ngIf="productTypeforHTML == 'Kail'">
        <div class="form-group row">      
          <div class="col">
            <label>Id</label>
            <div id="the-basics">
                <input class="typeahead" [disabled] id="id" formControlName="id" name="id" type="text" placeholder="Id"> </div>
        </div>
          <div class="col">
            <label>Detail Id</label>
            <div id="the-basics">
                <input class="typeahead" [disabled] id="detailId" formControlName="detailId" name="detailId" type="detailId" placeholder="DetailId"> </div>
        </div>                               
            <div class="col">
                <label>Product Name</label>
                <div id="the-basics">
                    <input class="typeahead"  id="nama" formControlName="nama" name="nama" type="text" placeholder="Name"> </div>
            </div>
            <div class="col">
                <label>Product Brand</label>
                <div id="bloodhound">
                  <select class="typeahead"  id="merk" formControlName="merk" name="merk" type="text" placeholder="Brand">
                    <option value="" >- Select Brand -</option>  
                    <option value="{{merkList.id}}" *ngFor="let merkList of listOfMerk;let i=index" >{{merkList.namaMerk}}</option>  
                </select>  </div>
            </div>                            
            <div class="col">
            <label>Size</label>
            <div id="the-basics">
                <input class="typeahead"  id="ukuran" formControlName="ukuran" name="ukuran" type="text" placeholder="Size"> </div>
            </div>         
        </div>

      </form>    
       <form [formGroup]="insertProductAccForm" *ngIf="productTypeforHTML == 'Acc'">
      <div class="form-group row">     
        <div class="col">
          <label>Id</label>
          <div id="the-basics">
              <input class="typeahead" [disabled] id="id" formControlName="id" name="id" type="text" placeholder="Id"> </div>
      </div>
        <div class="col">
          <label>Detail Id</label>
          <div id="the-basics">
              <input class="typeahead" [disabled] id="detailId" formControlName="detailId" name="detailId" type="detailId" placeholder="DetailId"> </div>
      </div>                               
          <div class="col">
              <label>Product Name</label>
              <div id="the-basics">
                  <input class="typeahead"  id="nama" formControlName="nama" name="nama" type="text" placeholder="Name"> </div>
          </div>
          <div class="col">
              <label>Product Brand</label>
              <div id="bloodhound">
                <select class="typeahead"  id="merk" formControlName="merk" name="merk" type="text" placeholder="Brand">
                  <option value="" >- Select Brand -</option>  
                  <option value="{{merkList.id}}" *ngFor="let merkList of listOfMerk;let i=index" >{{merkList.namaMerk}}</option>  
              </select> </div>
          </div>     
          <div class="col">
              <label>Color</label>
              <div id="bloodhound">
                  <select class="typeahead"  id="warna" formControlName="warna" name="warna" type="text" placeholder="Color">
                      <option value="" >- Select Color -</option>  
                      <option value="{{warnaList.id}}" *ngFor="let warnaList of listOfWarna;let i=index" >{{warnaList.namaWarna}}</option>  
                  </select> </div>
          </div>                                                    
          <div class="col">
          <label>Ukuran</label>
          <div id="the-basics">
              <input class="typeahead"  id="ukuran" formControlName="ukuran" name="ukuran" type="text" placeholder="Ukuran"> </div>
          </div>         
      </div>
      <div class="form-group row">
        <div class="col">
          <label>Type</label>
          <div id="bloodhound">
            <select class="typeahead"  id="tipe" formControlName="tipe" name="tipe" type="text" placeholder="Color">
                <option value="" >- Select Color -</option>  
                <option value="{{tipeList.kodeTipe}}" *ngFor="let tipeList of listOfTipeAcc;let i=index" >{{tipeList.namaTipe}}</option>  
            </select> </div>
      </div>
      <div class="col">
        <label>Size</label>
        <div id="the-basics">
            <input class="typeahead"  id="size" formControlName="size" name="size" type="text" placeholder="size"> </div>
    </div>
    <div class="col">
      <label>Lbs</label>
      <div id="the-basics">
          <input class="typeahead"  id="lbs" formControlName="lbs" name="lbs" type="text" placeholder="lbs"> </div>
  </div>
  <div class="col">
    <label>Liter</label>
    <div id="the-basics">
        <input class="typeahead"  id="liter" formControlName="liter" name="liter" type="text" placeholder="liter"> </div>
</div>      
      </div>

      </form>     
      <form [formGroup]="insertProductBaitForm" *ngIf="productTypeforHTML == 'Bait'">
        <div class="form-group row">  
          <div class="col">
            <label>Id</label>
            <div id="the-basics">
                <input class="typeahead" [disabled] id="id" formControlName="id" name="id" type="text" placeholder="Id"> </div>
        </div>
          <div class="col">
            <label>Detail Id</label>
            <div id="the-basics">
                <input class="typeahead" [disabled] id="detailId" formControlName="detailId" name="detailId" type="detailId" placeholder="DetailId"> </div>
        </div>                          
          <div class="col">
              <label>Product Name</label>
              <div id="the-basics">
                  <input class="typeahead"  id="nama" formControlName="nama" name="nama" type="text" placeholder="Name"> </div>
          </div>
          <div class="col">
              <label>Product Brand</label>
              <div id="bloodhound">
                  <select class="typeahead"  id="merk" formControlName="merk" name="merk" type="text" placeholder="Brand">
                      <option value="" >- Select Brand -</option>  
                      <option value="{{merkList.id}}" *ngFor="let merkList of listOfMerk;let i=index" >{{merkList.namaMerk}}</option>  
                  </select> </div>
          </div>     
          <div class="col">
              <label>Color</label>
              <div id="bloodhound">
                  <select class="typeahead"  id="warna" formControlName="warna" name="warna" type="text" placeholder="Color">
                      <option value="" >- Select Color -</option>  
                      <option value="{{warnaList.id}}" *ngFor="let warnaList of listOfWarna;let i=index" >{{warnaList.namaWarna}}</option>  
                  </select> </div>
          </div>                                                    
      
      </div>
      <div class="form-group row">     
        <div class="col">
          <label>Measurement</label>
          <div id="the-basics">
              <input class="typeahead"  id="ukuran" formControlName="ukuran" name="ukuran" type="text" placeholder="Measurement"> </div>
          </div>                      
              <div class="col">
                  <label>Type</label>
                  <div id="bloodhound">
                    <select class="typeahead"  id="tipe" formControlName="tipe" name="tipe" type="text" placeholder="Color">
                        <option value="" >- Select Type -</option>  
                        <option value="{{tipeList.kodeTipe}}" *ngFor="let tipeList of listOfTipeBait;let i=index" >{{tipeList.namaTipe}}</option>  
                    </select> </div>
              </div>
              <div class="col">
                <label>Weight</label>
                <div id="the-basics">
                    <input class="typeahead"  id="berat" formControlName="berat" name="berat" type="text" placeholder="Weight"> </div>
            </div>
            <div class="col">
              <label>Varian</label>
              <div id="bloodhound">
                  <select class="typeahead"  id="varian" formControlName="varian" name="varian" type="text" placeholder="Varian">
                      <option value="" >- Select Varian -</option>  
                      <option value="{{varianList.kodeVarian}}" *ngFor="let varianList of listOfVarianBait;let i=index" >{{varianList.namaVarian}}</option>  
                  </select> </div>
          </div>
      </div>
  
        </form>                       
        </div>          
      <div class="modal-footer">
        <button type="button" class="btn btn-primary"  *ngIf="productTypeforHTML == 'Rod'" [disabled]="insertProductJoranForm.invalid" (click)="updateData()">Update</button>
        <button type="button" class="btn btn-primary"  *ngIf="productTypeforHTML == 'Reel'" [disabled]="insertProductReelForm.invalid" (click)="updateData()">Update</button>
        <button type="button" class="btn btn-primary"  *ngIf="productTypeforHTML == 'Line'" [disabled]="insertProductLineForm.invalid" (click)="updateData()">Update</button>
        <button type="button" class="btn btn-primary"  *ngIf="productTypeforHTML == 'Kail'" [disabled]="insertProductHookForm.invalid" (click)="updateData()">Update</button>
        <button type="button" class="btn btn-primary"  *ngIf="productTypeforHTML == 'Acc'" [disabled]="insertProductAccForm.invalid" (click)="updateData()">Update</button>
        <button type="button" class="btn btn-primary"  *ngIf="productTypeforHTML == 'Bait'" [disabled]="insertProductBaitForm.invalid" (click)="updateData()">Update</button>                        
        <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="exampleModalStock" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Product Stock - {{stockModel.ProductNameGabungan}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
        <div class="modal-body"  *ngIf="serviceReturn10 !=''">
             <p  *ngIf="stockModel.Stock!=0">Stock : <b>{{stockModel.Stock}}</b> 
             &nbsp; &nbsp; &nbsp; Satuan Unit : <b>{{stockModel.Uom}}</b> 
             &nbsp; &nbsp; &nbsp; Jumlah Per Dus : <b>{{stockModel.JumlahPerDus}}</b></p><br>

             <table class="table table-striped" *ngIf="listStockModelDetail.length>0">
              <thead>
                <tr>                         
                  <th>PO Number</th>
                  <th>PO Create Date</th>
                  <th>Stock</th>
                </tr>
              </thead>
              <tbody >
               <tr *ngFor="let listStockDetail of listStockModelDetail; let i= index" >                          
                  <td>{{listStockDetail.NomorPo}}</td>
                  <td>{{listStockDetail.TanggalCreatePo}}</td>
                  <td>{{listStockDetail.Stock}}</td>
               </tr>       
              </tbody>
            </table> 
        </div>          
      <div class="modal-footer">
       
        <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div> 
<!-- DIBUAT HALAMAN BARU SENDIRI UNTUK HARGA -->
<!-- <div class="modal fade" id="exampleModalPrice" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Product Price - {{priceModel.ProductNameGabungan}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
        <div class="modal-body"  >
             <p *ngIf="serviceReturn12 !=''">Harga Jual : <b>Rp. {{priceModel.SellPrice | number}}</b> 
             &nbsp; &nbsp; &nbsp; Tanggal Update Terakhir : <b>{{priceModel.TanggalUpdateHarga}}</b>
             &nbsp; &nbsp; &nbsp; <button type="button" data-toggle="modal" data-target="#exampleUpdatePrice" 
             (click)="updatePricePop(priceModel.ProductNameGabungan, priceModel.SellPrice, priceModel.MasterStockId)" 
             class="btn btn-primary" >Update Price</button></p><br>

             <table class="table table-striped" *ngIf="listPriceModelDetail.length>0">
              <thead>
                <tr>                         
                  <th>PO Number</th>
                  <th>PO Create Date</th>
                  <th>Stock</th>
                  <th>Base Price</th>
                </tr>
              </thead>
              <tbody >
               <tr *ngFor="let listPriceDetail of listPriceModelDetail; let i= index" >                          
                   <td>{{listPriceDetail.NomorPo}}</td>
                  <td>{{listPriceDetail.TanggalCreatePo}}</td>
                  <td>{{listPriceDetail.Stock}}</td> 
                  <td>Rp. {{listPriceDetail.BasePrice | number}}</td> 
               </tr>       
              </tbody>
            </table> 
        </div>          
      <div class="modal-footer">
       
        <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div> -->

<div class="modal fade" id="exampleUpdatePrice" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel-2" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel-2">Update Price</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Modal body text goes here.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success">Submit</button>
        <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Delete Item</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
        <div class="modal-body" style="overflow-y: auto; max-height:400px;">
            <p>Delete This Product ?</p>
        </div>          
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-dismiss="modal" (click)="deleteProduct()">Delete</button>
        <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>