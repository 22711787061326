import { Injectable } from '@angular/core';


@Injectable()

export class DateTimeUtil {

constructor() { }

getCurrentDate()
{
	var today = new Date();
	var date;
	var month;
	var year;
		if((today.getMonth()+1)<10)
		{
			month='0'+(today.getMonth()+1);
		}
		else
		{
			month=today.getMonth()+1;	
		}

		if((today.getDate())<10)
		{
			date = '0'+(today.getDate());
		}
		else
		{	
			date = today.getDate();
		}

	var currDate = date+''+month+''+today.getFullYear();
	return currDate;
}

reverseDateToBVFormat(date : string)
{
	if(date!=''){
	var newYear = date.substring(6,10);
	var newMonth = date.substring(0,2);
	var newDay = date.substring(3,5);
	return newDay+newMonth+newYear;}
	return '';
}

reverseDateToBVFormat2(date : string)
{
	if(date!=''){
	var newYear = date.substring(6,10);
	var newMonth = date.substring(0,2);
	var newDay = date.substring(3,5);
	return newYear+newMonth+newDay;}
	return '';
}

getMonthAlf(month : string)
{
	if(month == '01') { return 'Januari'}
 	else if(month == '02') { return 'Februari'}
 	else if(month == '03') { return 'Maret'}
 	else if(month == '04') { return 'April'}		
 	else if(month == '05') { return 'Mei'}
 	else if(month == '06') { return 'Juni'}
 	else if(month == '07') { return 'Juli'}
 	else if(month == '08') { return 'Agustus'}
 	else if(month == '09') { return 'September'}
 	else if(month == '10') { return 'Oktober'}
 	else if(month == '11') { return 'November'}
 	else if(month == '12') { return 'Desember'}	
}

getFormattedDate(date:string)
{
	return date.substring(0,2)+' '+this.getMonthAlf(date.substring(2,4))+' '+date.substring(4,8);
}

getFormattedDate2(date:string)
{
	return date.substring(6,8)+' '+this.getMonthAlf(date.substring(4,6))+' '+date.substring(0,4);
}


}