import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators,ReactiveFormsModule } from '@angular/forms';
import  { callGlobalFuction } from "../../callGlobalFuction";
import { Router,ActivatedRoute } from "@angular/router";
import  { DateTimeUtil } from "../../globalfunction/dateTimeUtil";
import { productmastermodel } from '../../model/product/productmaster';
import { productdetailjoranmodel } from '../../model/product/productDetailJoran';
import { productdetailreelmodel } from '../../model/product/productDetailReel';
import { productdetaillinemodel } from '../../model/product/productDetailLine';
import { productdetailkailmodel } from '../../model/product/productDetailKail';
import { productdetailaccmodel } from '../../model/product/productDetailAcc';
import { mixItem } from '../../model/product/mixItem';
declare var $ :any;

@Component({
  selector: 'app-mixitem',
  templateUrl: './mixitem.component.html',
  styleUrls: ['./mixitem.component.css']
})
export class MixitemComponent implements OnInit {

  constructor(private fb: FormBuilder, private getService:callGlobalFuction,
    public router:Router,public route:ActivatedRoute) { }
    productTypeforHTML="";
    activeCategory : number;
    groupId :any;
    p:number=1;

  //inquiry master product
  serviceReturn2:any;
  productMasterModel :productmastermodel;
  listOfProductMasterModel=[];

  searchProductForm= new FormGroup({
    searchProduct: new FormControl('',[
    Validators.required
    ])                     
  });

     //inquiry detail product
     serviceReturn:any;
     productDetailJoran :productdetailjoranmodel;
     listOfProductDetailJoran=[];
     productDetailReel :productdetailreelmodel;
     listOfProductDetailReel=[];
     productDetailLine :productdetaillinemodel;
     listOfProductDetailLine=[];
     productDetailKail :productdetailkailmodel;
     listOfProductDetailKail=[];
     productDetailAcc :productdetailaccmodel;
     listOfProductDetailAcc=[];

  searchbyname()
  {

    let jsonInput = {};
    jsonInput['namaBarang'] = this.searchProductForm.get('searchProduct').value;
    
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      this.listOfProductMasterModel=[];

       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryCategoryAndBrandByProductName',stringJsonInput)
      .subscribe(
        data => {
          this.serviceReturn2 = data;
          if(this.serviceReturn2.status=="S")
          {
            for(let i=0;i<this.serviceReturn2.listProduct.length;i++)
            {
            this.productMasterModel = new productmastermodel();
            this.productMasterModel.Id = this.serviceReturn2.listProduct[i].id;
            this.productMasterModel.MerkId = this.serviceReturn2.listProduct[i].merk_id;
            this.productMasterModel.CategoryId = this.serviceReturn2.listProduct[i].category_id;
            this.productMasterModel.NamaBarang = this.serviceReturn2.listProduct[i].nama_barang;
            this.productMasterModel.NamaMerk = this.serviceReturn2.listProduct[i].nama_merk;
            this.productMasterModel.NamaCategory = this.serviceReturn2.listProduct[i].nama_kategori;
            this.listOfProductMasterModel.push(this.productMasterModel);
            }
          }
          else
          {
            alert("Master Product Search Failed");
          }
        }
      )  
  }

  searchbynameDetail(id:string, categoryId:number, merkId:string)
  {
    this.activeCategory=categoryId;
    

    $('#exampleModal').modal('hide'); 
    let jsonInput = {};
    
    let res={}
    jsonInput['productId'] = id;
    jsonInput['kategoriId'] = categoryId;
    jsonInput['merkId'] = merkId;
    let stringJsonInput = JSON.stringify(jsonInput);
    console.log(stringJsonInput)
      
       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryProductDetailAllByCategoryAndBrand',stringJsonInput)
      .subscribe(
        data => {
          this.serviceReturn = data;
          console.log(this.serviceReturn)
          if(this.serviceReturn.status=="S")
          {
            console.log(this.serviceReturn)
            //inquiry detail untuk joran
            if(this.serviceReturn.categoryId==1 && this.serviceReturn.listProduct.listJoranDetail.length>0)
            {
              this.productTypeforHTML="Rod";
              this.listOfProductDetailJoran=[];
              for(let i=0;i<this.serviceReturn.listProduct.listJoranDetail.length;i++)
              {
              this.productDetailJoran = new productdetailjoranmodel();
              this.productDetailJoran.Ukuran = this.serviceReturn.listProduct.listJoranDetail[i].ukuran;
              this.productDetailJoran.Warna = this.serviceReturn.listProduct.listJoranDetail[i].nama_warna;
              this.productDetailJoran.WarnaId = this.serviceReturn.listProduct.listJoranDetail[i].warna_joran_id;
              this.productDetailJoran.Ring = this.serviceReturn.listProduct.listJoranDetail[i].ring;
              this.productDetailJoran.Bahan = this.serviceReturn.listProduct.listJoranDetail[i].nama_bahan;
              this.productDetailJoran.BahanId = this.serviceReturn.listProduct.listJoranDetail[i].bahan_joran_id;
              this.productDetailJoran.Nama = this.serviceReturn.listProduct.listJoranDetail[i].nama_barang;
              this.productDetailJoran.TahunProduksi = this.serviceReturn.listProduct.listJoranDetail[i].tahun_produksi;
              this.productDetailJoran.MinLbs = this.serviceReturn.listProduct.listJoranDetail[i].lbs_min;  
              this.productDetailJoran.MaxLbs = this.serviceReturn.listProduct.listJoranDetail[i].nama_tipe_joran; 
              this.productDetailJoran.Merk = this.serviceReturn.listProduct.listJoranDetail[i].nama_merk; 
              this.productDetailJoran.MerkId = this.serviceReturn.listProduct.listJoranDetail[i].merk_id;
              this.productDetailJoran.MaxLbs = this.serviceReturn.listProduct.listJoranDetail[i].lbs_max;  
              this.productDetailJoran.MaxPE = this.serviceReturn.listProduct.listJoranDetail[i].pe_max;  
              this.productDetailJoran.MinPE = this.serviceReturn.listProduct.listJoranDetail[i].pe_min; 
              this.productDetailJoran.Tipe = this.serviceReturn.listProduct.listJoranDetail[i].nama_tipe;   
              this.productDetailJoran.TipeId = this.serviceReturn.listProduct.listJoranDetail[i].tipe_joran_id;
              this.productDetailJoran.DetailId = this.serviceReturn.listProduct.listJoranDetail[i].productDetailId; 
              this.productDetailJoran.Id = this.serviceReturn.listProduct.listJoranDetail[i].productId;
              this.listOfProductDetailJoran.push(this.productDetailJoran);
              }
            }
            //end of inquiry detail untuk joran

             //inquiry detail untuk reel
             else if(this.serviceReturn.categoryId==2 && this.serviceReturn.listProduct.listReelDetail.length>0)
             {
               this.productTypeforHTML="Reel";
               this.listOfProductDetailReel=[];
               for(let i=0;i<this.serviceReturn.listProduct.listReelDetail.length;i++)
               {
               this.productDetailReel = new productdetailreelmodel();
               this.productDetailReel.Ukuran = this.serviceReturn.listProduct.listReelDetail[i].ukuran;                                                                 
               this.productDetailReel.WarnaId = this.serviceReturn.listProduct.listReelDetail[i].warna_reel_id;
               this.productDetailReel.PhType = this.serviceReturn.listProduct.listReelDetail[i].ph_type;
               this.productDetailReel.Warna = this.serviceReturn.listProduct.listReelDetail[i].nama_warna;
               this.productDetailReel.BallBearing = this.serviceReturn.listProduct.listReelDetail[i].ball_bearing;
               this.productDetailReel.TipeId = this.serviceReturn.listProduct.listReelDetail[i].tipe_reel_id;
               this.productDetailReel.WayType = this.serviceReturn.listProduct.listReelDetail[i].way_type;
               this.productDetailReel.Nama = this.serviceReturn.listProduct.listReelDetail[i].nama_barang;
               this.productDetailReel.Tipe = this.serviceReturn.listProduct.listReelDetail[i].nama_tipe;                                                                 
               this.productDetailReel.Merk = this.serviceReturn.listProduct.listReelDetail[i].nama_merk;
               this.productDetailReel.MerkId = this.serviceReturn.listProduct.listReelDetail[i].merk_id;               
               this.productDetailReel.DetailId = this.serviceReturn.listProduct.listReelDetail[i].productDetailId;
               this.productDetailReel.Id = this.serviceReturn.listProduct.listReelDetail[i].productId;               
               this.listOfProductDetailReel.push(this.productDetailReel);
               }
             }
             //end of inquiry detail untuk reel

             //inquiry detail untuk line
            else if(this.serviceReturn.categoryId==3 && this.serviceReturn.listProduct.listLineDetail.length>0)
             {
               this.productTypeforHTML="Line";
               this.listOfProductDetailLine=[];
               for(let i=0;i<this.serviceReturn.listProduct.listLineDetail.length;i++)
               {
               this.productDetailLine = new productdetaillinemodel();
               this.productDetailLine.WarnaId = this.serviceReturn.listProduct.listLineDetail[i].warna_line_id;
               this.productDetailLine.Satuan = this.serviceReturn.listProduct.listLineDetail[i].satuan_ukur;
               this.productDetailLine.Ukuran = this.serviceReturn.listProduct.listLineDetail[i].ukr_panjang;
               this.productDetailLine.Kekuatan = this.serviceReturn.listProduct.listLineDetail[i].kekuatan;
               this.productDetailLine.TypeConnectorSenar = this.serviceReturn.listProduct.listLineDetail[i].tipe_konektor_senar;
               this.productDetailLine.TipeNo = this.serviceReturn.listProduct.listLineDetail[i].tipe_no;
               this.productDetailLine.TypeFluoroCarbon = this.serviceReturn.listProduct.listLineDetail[i].tipe_fluor_carbon;
               this.productDetailLine.Warna = this.serviceReturn.listProduct.listLineDetail[i].nama_warna;
               this.productDetailLine.Diameter = this.serviceReturn.listProduct.listLineDetail[i].diameter;
               this.productDetailLine.Pe = this.serviceReturn.listProduct.listLineDetail[i].pe;
               this.productDetailLine.TipeId = this.serviceReturn.listProduct.listLineDetail[i].tipe_line_id;
               this.productDetailLine.Nama = this.serviceReturn.listProduct.listLineDetail[i].nama_barang;
               this.productDetailLine.Tipe = this.serviceReturn.listProduct.listLineDetail[i].nama_tipe;
               this.productDetailLine.Lbs = this.serviceReturn.listProduct.listLineDetail[i].lbs;
               this.productDetailLine.Merk = this.serviceReturn.listProduct.listLineDetail[i].nama_merk;
               this.productDetailLine.MerkId = this.serviceReturn.listProduct.listLineDetail[i].merk_id;               
               this.productDetailLine.Id = this.serviceReturn.listProduct.listLineDetail[i].productId;
               this.productDetailLine.DetailId = this.serviceReturn.listProduct.listLineDetail[i].productDetailId;                              
               this.listOfProductDetailLine.push(this.productDetailLine);
               }
             }
             //end of inquiry detail untuk line

             //inquiry detail untuk kail
            else if(this.serviceReturn.categoryId==4 && this.serviceReturn.listProduct.listKailDetail.length>0)
            {
              this.productTypeforHTML="Kail";
              this.listOfProductDetailKail=[];
              for(let i=0;i<this.serviceReturn.listProduct.listKailDetail.length;i++)
              {
              this.productDetailKail = new productdetailkailmodel();
              this.productDetailKail.Id = this.serviceReturn.listProduct.listKailDetail[i].productId;
              this.productDetailKail.DetailId = this.serviceReturn.listProduct.listKailDetail[i].productDetailId;
              this.productDetailKail.Ukuran = this.serviceReturn.listProduct.listKailDetail[i].ukuran;
              this.productDetailKail.Merk = this.serviceReturn.listProduct.listKailDetail[i].nama_merk;
              this.productDetailKail.MerkId = this.serviceReturn.listProduct.listKailDetail[i].merk_id;              
              this.productDetailKail.Nama = this.serviceReturn.listProduct.listKailDetail[i].nama_barang;              
              this.listOfProductDetailKail.push(this.productDetailKail);
              }
            }
            //end of inquiry detail untuk kail

             //inquiry detail untuk acc
             else if(this.serviceReturn.categoryId==5 && this.serviceReturn.listProduct.listAksesorisDetail.length>0)
             {
               this.productTypeforHTML="Acc";
               this.listOfProductDetailAcc=[];
               for(let i=0;i<this.serviceReturn.listProduct.listAksesorisDetail.length;i++)
               {
               this.productDetailAcc = new productdetailaccmodel();
               this.productDetailAcc.DetailId = this.serviceReturn.listProduct.listAksesorisDetail[i].productDetailId;
               this.productDetailAcc.Id = this.serviceReturn.listProduct.listAksesorisDetail[i].productId;               
               this.productDetailAcc.Ukuran = this.serviceReturn.listProduct.listAksesorisDetail[i].ukuran;
               this.productDetailAcc.Merk = this.serviceReturn.listProduct.listAksesorisDetail[i].nama_merk;
               this.productDetailAcc.MerkId = this.serviceReturn.listProduct.listAksesorisDetail[i].merk_id;               
               this.productDetailAcc.Nama = this.serviceReturn.listProduct.listAksesorisDetail[i].nama_barang;
               this.productDetailAcc.Warna = this.serviceReturn.listProduct.listAksesorisDetail[i].nama_warna;
               this.productDetailAcc.WarnaId = this.serviceReturn.listProduct.listAksesorisDetail[i].warna_aksesoris_id; 
               this.productDetailAcc.Lbs = this.serviceReturn.listProduct.listAksesorisDetail[i].lbs; 
               this.productDetailAcc.Liter = this.serviceReturn.listProduct.listAksesorisDetail[i].liter; 
               this.productDetailAcc.Tipe = this.serviceReturn.listProduct.listAksesorisDetail[i].nama_tipe; 
               this.productDetailAcc.TipeId = this.serviceReturn.listProduct.listAksesorisDetail[i].tipe_aksesoris_id;  
               this.productDetailAcc.Size = this.serviceReturn.listProduct.listAksesorisDetail[i].size;                             
               this.listOfProductDetailAcc.push(this.productDetailAcc);
               }
             }
             //end of inquiry detail untuk acc            

            else{
              alert("Product Detail Not Found");
            }

          }
          else
          {
            alert("Inquiry Detail Product Failed");
          }
        }
      )  
  }

  serviceReturn3:any;
  addToList(objectModel:any)
  {
    let jsonInput = {};
    
    jsonInput['productDetailId'] = objectModel.DetailId;
    jsonInput['groupId'] = this.groupId;
    jsonInput['categoryId'] = this.activeCategory;
    let mixItemList = [];
    mixItemList.push(jsonInput);
    let jsonInputInduk = {};
    jsonInputInduk['mixItemList']=mixItemList;
    jsonInputInduk['userId']=parseInt(sessionStorage.getItem('username'));

    let res={}
      let stringJsonInput = JSON.stringify(jsonInputInduk);
       res=this.getService.restItemsServiceGetwithParamRestItems('warehouse/createMixItem',stringJsonInput)
      .subscribe(
        data => {
          this.serviceReturn3 = data;
          if(this.serviceReturn3.status=="S")
          {
            location.reload();
          }
          else
          {
            alert("Add Mix Item Failed");
          }
        }
      )  
  }

serviceReturn4:any;
mixItemModel:mixItem;
listOfMixItem =[];
searchMixItemDetail(id:any)
{
  let jsonInput = {};
  jsonInput['groupId'] = parseInt(id);
  jsonInput['categoryId'] = 0;
  jsonInput['productDetailId'] = 0;

  console.log(jsonInput);

  let res={}
    let stringJsonInput = JSON.stringify(jsonInput);
    this.listOfProductMasterModel=[];

     res=this.getService.restItemsServiceGetwithParamRestItems('warehouse/inquiryMixItem',stringJsonInput)
    .subscribe(
      data => {
        this.serviceReturn4 = data;
        console.log(this.serviceReturn4);
        if(this.serviceReturn4.status=="S")
        {
          this.listOfMixItem=[];
          for(let i=0;i<this.serviceReturn4.result.mixItem.length;i++)
          {
          this.mixItemModel = new mixItem();
          this.mixItemModel.Id = this.serviceReturn4.result.mixItem[i].mixProductGroupId;
          this.mixItemModel.GroupId = this.serviceReturn4.result.mixItem[i].groupId;
          this.mixItemModel.ProductDetail = this.serviceReturn4.result.mixItem[i].productDetail.detail;
          this.mixItemModel.ProductDetail2 = this.serviceReturn4.result.mixItem[i].productDetail.detail2;
          this.mixItemModel.NamaBarang = this.serviceReturn4.result.mixItem[i].productDetail.namaBarang;
          this.mixItemModel.Merk = this.serviceReturn4.result.mixItem[i].productDetail.namaMerk;
          this.listOfMixItem.push(this.mixItemModel);
          }
        }
        else
        {
          alert("Group Detail Search Failed");
        }
      }
    ) 
}

serviceReturn5:any;
deleteMixItem()
{
  let jsonInput = {};
  jsonInput['mixProductGroupId'] = this.deleteIdTemp;

  let res={}
    let stringJsonInput = JSON.stringify(jsonInput);

     res=this.getService.restItemsServiceGetwithParamRestItems('warehouse/deleteMixItem',stringJsonInput)
    .subscribe(
      data => {
        this.serviceReturn5 = data;
        console.log(this.serviceReturn5);
        if(this.serviceReturn5.status=="S")
        {
          alert("Success Delete Product");
          location.reload();
        }

        else
        {
          alert("Failed Delete Product");
        }
      }
    ) 
}

deleteIdTemp:number;
deleteConfirm(mixItemHeaderObj:any)
{
  this.deleteIdTemp=0;
  this.deleteIdTemp=mixItemHeaderObj;
}
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {

      if(params!=null){
      this.groupId=parseInt(params.groupId);
      this.searchMixItemDetail(params.groupId);
      }

      else
      {
        alert("Cannot Show Item Detail");    
      }
    
    });
  }


}
