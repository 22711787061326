import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators,ReactiveFormsModule } from '@angular/forms';
import  { callGlobalFuction } from "../../callGlobalFuction";
import { Router,ActivatedRoute } from "@angular/router";
import { supplier } from '../../model/transaction/supplier';
import  { DateTimeUtil } from "../../globalfunction/dateTimeUtil";
import { purchaseOrder } from '../../model/transaction/purchaseorder';
import { purchaseOrderDetail } from '../../model/transaction/purchaseorderdetail';
import { JsonPipe } from '@angular/common';
declare var $ :any;

@Component({
  selector: 'app-incoming',
  templateUrl: './incoming.component.html',
  styleUrls: ['./incoming.component.css']
})
export class IncomingComponent implements OnInit {
    //inquiry incoming
    serviceReturn3:any;
    supplier :supplier;
    supplierIdTemp="";
    listOfSupplier=[];    
    p:number=1;

    //inquiry po header
    serviceReturn2:any;
    poHeaderIdTemp=""
    poNumberTemp=""
    purchaseOrderHeader :purchaseOrder; 
    listOfPOHeader=[];

    //inquiry po detail
     serviceReturn:any;
     purchaseOrderDetail :purchaseOrderDetail;    
     listOfPODetail=[];

     //model indexing
     qtyMasuk=[];
     checkqty=[];
     hasilakhir="true";

     incomingForm= new FormGroup({
      nomorSJ: new FormControl('',[Validators.required
      ]),
      diterimaOleh: new FormControl('',[Validators.required
      ]),
      tanggalTransaksi: new FormControl('',[
      ]),                                 
    });


    constructor(private fb: FormBuilder, private getService:callGlobalFuction,
      public router:Router,public route:ActivatedRoute,public dateTimeUtil: DateTimeUtil) { }

  inquirySupplierList()
  {
    let jsonInput = {};
    jsonInput['kode']="supplier";
    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
       res=this.getService.restItemsServiceGetwithParamRestItems('inquiryListCode',stringJsonInput)
      .subscribe( 
        data => {
          this.serviceReturn3 = data;
          if(this.serviceReturn3.status=="S")
          {
            this.listOfSupplier=[];
            console.log(this.serviceReturn3)
            for(let i=0;i<this.serviceReturn3.listCode.length;i++)
            {
            this.supplier = new supplier();
            this.supplier.NamaSupplier = this.serviceReturn3.listCode[i].namaSupplier;
            this.supplier.KodeSupplier = this.serviceReturn3.listCode[i].kodeSupplier;
            this.supplier.Alamat = this.serviceReturn3.listCode[i].alamat;
            this.supplier.Email = this.serviceReturn3.listCode[i].noHP;
            this.supplier.NoHP = this.serviceReturn3.listCode[i].email;
            this.supplier.Keterangan = this.serviceReturn3.listCode[i].keterangan;
            this.supplier.Id = this.serviceReturn3.listCode[i].id;
            this.listOfSupplier.push(this.supplier);            
            }
          }
          else{
            alert("Supplier Not Found");
          }
        }
      ) 
  }

  inquiryPOHeader()
  {
    let jsonInput = {};
    jsonInput['supplierId']=parseInt(this.supplierIdTemp);
    jsonInput['tanggalTransaksi']="";
    jsonInput['nomorPO']="";
    jsonInput['status']="";
    console.log(jsonInput)
    let res={}
    let stringJsonInput = JSON.stringify(jsonInput);
    this.poHeaderIdTemp="";
     res=this.getService.restItemsServiceGetwithParamRestItems('transaction/inquiryPOHeader',stringJsonInput)
    .subscribe( 
      data => {
        this.serviceReturn2 = data;
        if(this.serviceReturn2.status=="S")
        {
          this.listOfPOHeader=[];
          for(let i=0;i<this.serviceReturn2.result.listPoHeader.length;i++)
          {
          this.purchaseOrderHeader = new purchaseOrder();
          this.purchaseOrderHeader.Id = this.serviceReturn2.result.listPoHeader[i].poHeaderId;
          this.purchaseOrderHeader.NomorPO = this.serviceReturn2.result.listPoHeader[i].nomorPO;
          this.purchaseOrderHeader.StatusPO = this.serviceReturn2.result.listPoHeader[i].statusPO;
          if(this.serviceReturn2.result.listPoHeader[i].statusPO=="open")
          {
          this.listOfPOHeader.push(this.purchaseOrderHeader);           
          } 
          }
        }
        else{
          alert("Purchase Order Not Found");
        }
      }
    ) 
  }

  inquiryPoDetail()
  {
    let jsonInput = {};
    jsonInput['poHeaderId']=parseInt(this.poHeaderIdTemp);
    console.log(jsonInput);
    let res={}
    let stringJsonInput = JSON.stringify(jsonInput);
     res=this.getService.restItemsServiceGetwithParamRestItems('transaction/inquiryPODetail',stringJsonInput)
    .subscribe( 
      data => {
        this.serviceReturn3 = data;

        if(this.serviceReturn3.status=="S")
        {
          this.poNumberTemp=this.serviceReturn3.result.noPO;
          this.listOfPODetail=[];
          this.checkqty=[];
          for(let i=0;i<this.serviceReturn3.result.listPoDetail.length;i++)
          {
          this.checkqty[i]="true";
          this.purchaseOrderDetail = new purchaseOrderDetail();
          this.purchaseOrderDetail.NamaMerek = this.serviceReturn3.result.listPoDetail[i].namaMerk;
          this.purchaseOrderDetail.SudahMasuk = this.serviceReturn3.result.listPoDetail[i].qtyMasuk;
          this.purchaseOrderDetail.TotalMasuk = this.serviceReturn3.result.listPoDetail[i].totalQty;
          this.purchaseOrderDetail.BelumMasuk = this.serviceReturn3.result.listPoDetail[i].qtyPending;
          this.purchaseOrderDetail.Detail1 = this.serviceReturn3.result.listPoDetail[i].detail;
          this.purchaseOrderDetail.Detail2 = this.serviceReturn3.result.listPoDetail[i].detail2;
          this.purchaseOrderDetail.NamaBarang = this.serviceReturn3.result.listPoDetail[i].namaBarang;
          this.purchaseOrderDetail.HargaDasar = this.serviceReturn3.result.listPoDetail[i].basePrice;
          this.purchaseOrderDetail.ProductDetailId = this.serviceReturn3.result.listPoDetail[i].productDetailId;
          this.purchaseOrderDetail.CategoryId = this.serviceReturn3.result.listPoDetail[i].categoryId;
          this.listOfPODetail.push(this.purchaseOrderDetail);            
          }
        }
        else{
          alert("Purchase Order Not Found");
        }
      }
    ) 
  }

  listOfDataTemp=[];
  statusAddlist="F"

  validasiQty()
  {
    for(let i=0;i<this.qtyMasuk.length;i++)
    {
      if(this.qtyMasuk[i]<=this.serviceReturn3.result.listPoDetail[i].qtyPending)
      {

        this.checkqty[i]="true";
        this.hasilakhir="true";
      }
      else  {
        this.checkqty[i]="false";
        this.hasilakhir="false";
      }    
    }
    console.log(this.checkqty);
  }

  addToTempList()
  {
    this.listOfDataTemp=[];
    for(let i=0;i<this.qtyMasuk.length;i++)
    {
      let jsonListBarang = {}
      if(this.qtyMasuk[i]>0)
      {
        jsonListBarang['categoryId']=this.serviceReturn3.result.listPoDetail[i].categoryId;
        jsonListBarang['productId']=this.serviceReturn3.result.listPoDetail[i].productDetailId;
        jsonListBarang['poNumber']=this.poNumberTemp;
        jsonListBarang['qty']=parseInt(this.qtyMasuk[i]);
        jsonListBarang['detail']=this.serviceReturn3.result.listPoDetail[i].namaMerk +" "+this.serviceReturn3.result.listPoDetail[i].namaBarang+
        " "+this.serviceReturn3.result.listPoDetail[i].detail +" "+this.serviceReturn3.result.listPoDetail[i].detail2;
        this.listOfDataTemp.push(jsonListBarang);
        }
 
      }
    }
  
  serviceReturn4:any;
  incomingItem()
  {
    let jsonInput = {};
    jsonInput['nomorSuratJalan'] = this.incomingForm.get('nomorSJ').value;
    jsonInput['supplierId'] = parseInt(this.supplierIdTemp);
    jsonInput['receivedBy'] = this.incomingForm.get('diterimaOleh').value;
    jsonInput['tanggalTransaksi'] = this.dateTimeUtil.reverseDateToBVFormat2($('#tanggalTransaksi').first().val());
    jsonInput['listBarang']=this.listOfDataTemp;


    let res={}
      let stringJsonInput = JSON.stringify(jsonInput);
      console.log(stringJsonInput)
       res=this.getService.restItemsServiceGetwithParamRestItems('transaction/createIncomingTransaction',stringJsonInput)
      .subscribe(
        data => {
          this.serviceReturn4 = data;
          console.log(this.serviceReturn4);
          if(this.serviceReturn4.status=="S")
          {
            alert("Success Create Incoming");
            this.router.navigateByUrl('inquiryincoming');
          }
          else
          {
            alert("Failed Create Incoming");
          }
        }
      )  
  }

  ngOnInit(): void {
    this.inquirySupplierList();
    $('#datepicker-popup2').datepicker({
      enableOnReadonly: true,
      todayHighlight: true,
    });
  }

}
