<div class="col-md-12 grid-margin stretch-card">
	<div class="card">
		<div class="card-body">
			<h4 class="card-title">Supplier Configuration</h4>
 						 <div class="form-group row">
                            <div class="col" >
                              <label style="color: transparent;">-</label>
                              <button type="submit" class="btn btn-danger mr-2" 
                              style="max-width: 200px;" data-toggle="modal" data-target="#exampleModal2">Insert Supplier</button>
                          </div>                                                        
                        </div>
		         		
                	<div class="table-responsive">
                    <table class="table table-hover" >
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>Name</th>
                          <th>Code</th>
                          <th>Phone</th>
                          <th>Notes</th>                          
                          <th>Address</th>
                          <th>Email</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody >
                       <tr *ngFor="let listOfSupplier of listOfSupplier | paginate: { itemsPerPage: 10, currentPage: p }; let i= index">
                          <td>{{listOfSupplier.Id}}</td>
                          <td>{{listOfSupplier.NamaSupplier}}</td>
                          <td>{{listOfSupplier.KodeSupplier}}</td>
                          <td>{{listOfSupplier.NomorHp}}</td>
                          <td>{{listOfSupplier.Keterangan}}</td>
                          <td>{{listOfSupplier.Alamat}}</td>
                          <td>{{listOfSupplier.Email}}</td>
                          <td><i id="icon" class="link-icon mdi mdi-account-settings-variant" 
                            data-toggle="modal" data-target="#exampleModal" (click)="openModalUpdateSupplier(listOfSupplier)"></i>&nbsp;&nbsp;
                            <i id="icon" class="link-icon mdi mdi-delete-forever" data-toggle="modal" data-target="#deleteModal" (click)= "deleteConfirm(listOfSupplier.Id)"></i></td>
                          </tr> 
                       <td colspan="8" style="text-align: center;">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                     </td>                             
                      </tbody>
                    </table>
                	</div> 
            </div>
        </div>
    </div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Update Supplier</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="overflow-y: auto; max-height:400px;">
         <form [formGroup]="updateSupplierForm">
            <div class="form-group row">                                   
                <div class="col">
                    <label>Id</label>
                    <div id="the-basics">
                        <input class="typeahead"  id="id" formControlName="id" name="id" type="text" placeholder="Id" disabled> </div>
                </div>
                <div class="col">
                    <label>Supplier Name</label>
                    <div id="the-basics">
                        <input class="typeahead"  id="supplierName" formControlName="supplierName" name="supplierName" type="text" placeholder="Supplier Name"> </div>
                </div>
                <div class="col">
                    <label>Supplier Code</label>
                    <div id="the-basics">
                        <input class="typeahead"  id="supplierCode" formControlName="supplierCode" name="supplierCode" type="text" placeholder="Supplier Code"> </div>
                </div>
                <div class="col">
                    <label>Email</label>
                    <div id="the-basics">
                        <input class="typeahead"  id="email" formControlName="email" name="email" type="text" placeholder="Email"> </div>
                </div>
              </div> 
              <div class="form-group row">                                   
                <div class="col">
                    <label>Address</label>
                    <div id="the-basics">
                        <input class="typeahead"  id="alamat" formControlName="alamat" name="alamat" type="text" placeholder="Address"> </div>
                </div>
                <div class="col">
                    <label>Phone Number</label>
                    <div id="the-basics">
                        <input class="typeahead"  id="nomorHp" formControlName="nomorHp" name="nomorHp" type="text" placeholder="Nomor Hp"> </div>
                </div>
                <div class="col">
                    <label>Description</label>
                    <div id="the-basics">
                        <input class="typeahead"  id="keterangan" formControlName="keterangan" name="keterangan" type="text" placeholder="Description"> </div>
                </div>
              </div>     
          </form>  
      </div>          
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-dismiss="modal" (click)="updateSupplier()">Update</button>
        <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div> 

<div class="modal fade" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Insert Color</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
          <div class="modal-body" style="overflow-y: auto; max-height:400px;">
            <form [formGroup]="insertSupplierForm">
              <div class="form-group row">                                   
                  <div class="col">
                      <label>Supplier Name</label>
                      <div id="the-basics">
                          <input class="typeahead"  id="supplierName" formControlName="supplierName" name="supplierName" type="text" placeholder="Supplier Name"> </div>
                  </div>
                  <div class="col">
                      <label>Supplier Code</label>
                      <div id="the-basics">
                          <input class="typeahead"  id="supplierCode" formControlName="supplierCode" name="supplierCode" type="text" placeholder="Supplier Code"> </div>
                  </div>
                  <div class="col">
                      <label>Email</label>
                      <div id="the-basics">
                          <input class="typeahead"  id="email" formControlName="email" name="email" type="text" placeholder="Email"> </div>
                  </div>
                </div> 
                <div class="form-group row">                                   
                  <div class="col">
                      <label>Address</label>
                      <div id="the-basics">
                          <input class="typeahead"  id="alamat" formControlName="alamat" name="alamat" type="text" placeholder="Address"> </div>
                  </div>
                  <div class="col">
                      <label>Phone Number</label>
                      <div id="the-basics">
                          <input class="typeahead"  id="nomorHp" formControlName="nomorHp" name="nomorHp" type="text" placeholder="Nomor Hp"> </div>
                  </div>
                  <div class="col">
                      <label>Description</label>
                      <div id="the-basics">
                          <input class="typeahead"  id="keterangan" formControlName="keterangan" name="keterangan" type="text" placeholder="Description"> </div>
                  </div>
                </div>     
            </form>  
          </div>          
        <div class="modal-footer">
          <button type="button" class="btn btn-success" data-dismiss="modal" (click)="insertSupplier()">Insert</button>
          <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div> 

  <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Delete Supplier</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
          <div class="modal-body" style="overflow-y: auto; max-height:400px;">
              <p>Delete This Supplier ?</p>
          </div>          
        <div class="modal-footer">
          <button type="button" class="btn btn-success" data-dismiss="modal" (click)="deleteSupplier()">Delete</button>
          <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>