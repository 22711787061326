import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';


@Injectable()
export class callGlobalFuction implements OnInit {
  title = 'app';
  restItems: any;
  restItemsUrl = 'http://82.180.131.250:8881/gudangpancing/api/';
  //restItemsUrl = 'http://localhost:8881/gudangpancing/api/';

  constructor(private http: HttpClient) {}

  ngOnInit() {

  }

  // Rest Items Service: Read all REST Items


  restItemsServicePutRestItems(apiName:string,jsoninput:string) {
    let jsonInputNew = JSON.parse(jsoninput);
  
    return this.http
      .put<any[]>(this.restItemsUrl+apiName,jsonInputNew)
      .pipe(map(data => data));
  }

    restItemsServiceGetwithParamRestItems(apiName:string,jsoninput:string) {
    let jsonInputNew = JSON.parse(jsoninput);
  
    return this.http
      .post<any[]>(this.restItemsUrl+apiName,jsonInputNew)
      .pipe(map(data => data));
  }

      restItemsServicePostUpload(apiName:string,fileinput:FormData) {
    console.log(fileinput)
    return this.http
      .post<any[]>(this.restItemsUrl+apiName,fileinput)
      .pipe(map(data => data));
  }



}